<template>
  <dx-modal
    :value="show"
    @input="close"
    title="Selecione um módulo"
    size="sm"
    id="module-chooser-modal"
  >
    <div class="columns">
      <div
        class="column col-6"
        v-for="module in parsedModules"
        :key="module.key"
      >
        <div
          class="module-item"
          :class="{ current: module.current, available: module.available }"
          @click="select(module)"
        >
          <div class="module-action">
            <fa-icon :icon="module.icon"></fa-icon>
          </div>
          <div class="module-name">{{ module.name }}</div>
        </div>
      </div>
    </div>
  </dx-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { modules as moduleItems } from '@/data/modules';
import { createAndDispatch } from '@/helpers/link';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      module: state => state.auth.module,
    }),
    isSetBranch() {
      return this.user && this.user.branch !== null;
    },
    availableModuleKeys() {
      const modules = this.isSetBranch && Array.isArray(this.user.branch.modules)
        ? this.user.branch.modules
        : [];

      if (!modules.includes('clinical')) {
        modules.push('clinical');
      }

      return modules;
    },
    parsedModules() {
      return moduleItems
        .map((module) => {
          module.current = module.key === this.module;
          module.available = this.availableModuleKeys.includes(module.key);
          return module;
        })
        .sort((a, b) => {
          if (a.available && !b.available) return -1;
          if (b.available && !a.available) return 1;
          return 0;
        });
    },
  },
  methods: {
    ...mapActions({
      setModule: 'setModule',
    }),
    close() {
      this.$emit('close');
    },
    select(module) {
      if (module.available && !module.current) {
        const url = typeof module.url === 'function'
          ? module.url(this.user)
          : module.url;

        if (module.external) {
          createAndDispatch(url, { target: '_blank' });
        } else {
          this.$router.push(url);
          this.setModule(module.key);
        }
        this.close();
      }
      if (module.current) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

#module-chooser-modal {
  .module-item {
    background-color: $gray-color-ultra-light;
    border: $border-width solid $gray-color-ultra-light;
    border-radius: $border-radius;
    cursor: pointer;
    padding: $layout-spacing-lg;
    margin-bottom: $layout-spacing-lg;
    text-align: center;
    transition: all .15s ease;
    .module-action svg {
      color: $gray-color-dark;
      font-size: $layout-spacing-xl;
    }
    .module-name {
      color: $body-font-color;
      font-size: $font-size;
      font-weight: 500;
      margin-top: $layout-spacing;
    }
    &:not(.available) {
      cursor: not-allowed;
      .module-action svg, .module-name {
        color: $gray-color;
      }
    }
    &.current {
      background-color: $info-color;
      border-color: $info-color;
      cursor: default;
      .module-action svg, .module-name {
        color: $light-color;
      }
    }
    &.available:not(.current):hover {
      border-color: $info-color;
      .module-action svg, .module-name {
        color: $info-color;
      }
    }
  }
  .modal-footer {
    padding-bottom: $layout-spacing;
    padding-top: 0;
  }
}
</style>
