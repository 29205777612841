export default [
  {
    path: '/messages',
    component: () => import(/* webpackChunkName: "message" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      // permission: '',
    },
  },
];
