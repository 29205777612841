<template>
  <div class="medical-report-attendance-scheduled-page">
    <h6>Prontuários finalizados com data de retorno</h6>
    <div class="columns">
      <div class="column col-3 col-sm-12 form-group"
           :class="{'has-error': $v.filters.startDate.$error}">
        <label class="form-label">Data inicial</label>
        <dx-input-date
          id="start-date"
          class="form-input text-center"
          v-model="filters.startDate"
          @blur="$v.filters.startDate.$touch()"
        />
      </div>
      <div class="column col-3 col-sm-12 form-group"
           :class="{'has-error': $v.filters.endDate.$error}">
        <label class="form-label">Data final</label>
        <dx-input-date
          id="end-date"
          class="form-input text-center"
          v-model="filters.endDate"
          @blur="$v.filters.endDate.$touch()"
        />
      </div>
      <div class="column col-6 col-sm-12 form-group">
        <label class="form-label">Profissional</label>
        <select id="professional" class="form-select"
                v-model="filters.professionalId">
          <option value="">[Todos os profissionais]</option>
          <option v-for="(item, i) in professionals"
                  :value="item.id" :key="i">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-primary btn-icon-left"
              :class="{loading: printing}"
              @click="print" :disabled="printing">
        <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/form';
import { mapActions } from 'vuex';
import moment from 'moment';
import { date, minDate } from '../../../data/validators';

export default {
  mixins: [formMixin],
  data() {
    return {
      printing: false,
      professionals: [],
      filters: {
        startDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        professionalId: '',
      },
    };
  },
  mounted() {
    this.loadProfessionals();
  },
  validations() {
    const rules = {
      filters: {
        startDate: { required, date },
        endDate: { required, date },
      },
    };

    if (this.filters.startDate && moment(this.filters.startDate).isValid()) {
      rules.filters.endDate = {
        required,
        date,
        minDate: minDate(this.filters.startDate),
      };
    }

    return rules;
  },
  methods: {
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    loadProfessionals() {
      return this.apiFetchProfessionals()
        .then((items) => {
          this.professionals = items.map(item => ({
            id: item.id,
            name: item.name,
          }));
        })
        .catch(() => {});
    },
    print() {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return false;
      }

      const diff = moment(this.filters.endDate)
        .diff(moment(this.filters.startDate), 'months');

      if (diff > 6) {
        this.$toast.show('Período máximo (6 meses)', { type: 'error' });
        return null;
      }

      this.printing = true;

      const params = {
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
      };

      if (this.filters.professionalId) {
        params.professionalId = this.filters.professionalId;
      }

      return this.$file
        .print('/medical-records/scheduled-print', params)
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  @import '~assets/scss/mixins';
  .medical-report-attendance-scheduled-page {
    .footer {
      margin-top: $layout-spacing-lg;
      text-align: right;
    }
  }
</style>
