<template>
  <div class="page-container dashboard-page">
    <portal to="page-name">Dashboard</portal>

    <div class="h6 mb-2 text-gray">Indicadores de hoje</div>
    <div class="dashboard-tiles">
      <div class="tile-item">
        <div class="tile-icon">
          <fa-icon :icon="['fal', 'calendar-alt']"></fa-icon>
        </div>
        <div class="tile-label">Pacientes agendados</div>
        <div class="tile-value">{{ stats.scheduled }}</div>
      </div>

      <div class="tile-item">
        <div class="tile-icon">
          <fa-icon :icon="['fal', 'calendar-check']"></fa-icon>
        </div>
        <div class="tile-label">Pacientes confirmados</div>
        <div class="tile-value">{{ stats.confirmed }}</div>
      </div>

      <div class="tile-item">
        <div class="tile-icon">
          <fa-icon :icon="['fal', 'calendar-times']"></fa-icon>
        </div>
        <div class="tile-label">Pacientes desmarcados</div>
        <div class="tile-value">{{ stats.canceled }}</div>
      </div>
    </div>
    <div class="dashboard-tiles">
      <div class="tile-item">
        <div class="tile-icon">
          <fa-icon :icon="['fal', 'undo']"></fa-icon>
        </div>
        <div class="tile-label">Pacientes com retorno</div>
        <div class="tile-value">{{ stats.returned }}</div>
      </div>

      <div class="tile-item">
        <div class="tile-icon text-warning">
          <fa-icon :icon="['fal', 'money-check-edit-alt']"></fa-icon>
        </div>
        <div class="tile-label">Agendamentos não faturados</div>
        <div class="tile-value">{{ stats.noAccount }}</div>
      </div>

      <div class="tile-item">
        <div class="tile-icon text-secondary">
          <fa-icon :icon="['fal', 'money-check-edit-alt']"></fa-icon>
        </div>
        <div class="tile-label">Agendamentos faturados</div>
        <div class="tile-value">{{ stats.account }}</div>
      </div>
    </div>
    <div class="columns">
      <div class="column col-6">
        <div class="card chart-card">
          <div class="card-body">
            <div class="card-title">Top Convênios</div>
            <st-bar-chart
              :chart-data="charts.insurances.data"
              :options="charts.insurances.options"
              :height="245"/>
          </div>
        </div>
      </div>
      <div class="column col-6 form-group">
        <div class="card chart-card">
          <div class="card-body">
            <div class="card-title">Top Procedimentos</div>
            <st-bar-chart
              :chart-data="charts.expenses.data"
              :options="charts.expenses.options"
              :height="245"/>
          </div>
        </div>
      </div>
<!--      <div class="column col-4 form-group">-->
<!--        <div class="card chart-card">-->
<!--          <div class="card-body">-->
<!--            <div class="card-title h6">Faixa etária</div>-->
<!--            <st-horizontal-bar-chart-->
<!--              :chart-data="charts.ageGroups.data"-->
<!--              :options="charts.ageGroups.options"-->
<!--              :height="245" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="column col-4 form-group mt-2">
        <div class="card chart-card">
          <div class="card-body">
            <div class="card-title">Aniversariantes do dia</div>
            <div class="scroll-list-wrapper">
              <div class="scroll-list">
                <table class="table table-striped table-hover">
                  <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, i) in stats.patients" :key="i">
                    <td>
                      <span class="hover-link"
                            @click="$router.push(`/patients/${item.id}/edit`)">
                        {{ item.name | capitalizeName }}
                      </span><br>
                      <small>
                        <fa-icon class="text-warning mr-1"
                                 :icon="['fal', 'gift']"></fa-icon>
                        {{ item.birthDate | dateOld('year') }}
                      </small><br>
                    </td>
                    <td width="120px" class="text-right">
                      <small>{{ item.phone | phone }}</small>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column col-8 form-group mt-2">
        <div class="card chart-card">
          <div class="card-body">
            <div class="card-title">Atendimentos nos últimos 12 meses</div>
            <st-line-chart
              :chart-data="charts.services.data"
              :options="charts.services.options"
              :height="245"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      stats: {
        scheduled: 0,
        confirmed: 0,
        attended: 0,
        canceled: 0,
        returned: 0,
        noAccount: 0,
        account: 0,
        patients: [],
        ageGroups: [],
        insurances: [],
        expenses: [],
        services: [],
      },
      charts: {
        insurances: {
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              xAxes: [{
                ticks: {
                  callback: (v, i) => i + 1,
                },
              }],
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                },
              }],
            },
          },
        },
        expenses: {
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              xAxes: [{
                ticks: {
                  callback: (v, i) => i + 1,
                },
              }],
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                },
              }],
            },
          },
        },
        services: {
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                },
              }],
              xAxes: [{
                ticks: {
                  callback: value => value,
                },
                stacked: true,
              }],
            },
          },
        },
        ageGroups: this.chartAgeGroupsProps(),
      },
    };
  },
  mounted() {
    this.loadStats();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    ...mapGetters({
      isProfessionalOnly: 'isProfessionalOnly',
    }),
  },
  methods: {
    async loadStats() {
      const params = {};

      if (this.isProfessionalOnly) {
        params.professionalId = this.user.id;
      }

      await this.$http.get('/stats', { params })
        .then(({ data }) => {
          this.stats = data;
          this.loadInsurances();
          this.loadExpenses();
        })
        .catch(() => {});
    },
    loadInsurances() {
      this.charts.insurances.data = {
        labels: this.stats.insurances.map(item => item.name),
        datasets: [{
          label: 'Quantidade',
          data: this.stats.insurances.map(item => item.count),
          backgroundColor: [
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgb(51, 238, 255, 0.2)',
            'rgba(255, 155, 85, 0.2)',
            'rgba(239, 99, 255, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(255, 206, 86, 0.2)',
          ],
          borderColor: [
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(51, 238, 255, 1)',
            'rgba(255, 155, 85, 1)',
            'rgb(239, 99, 255, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(255, 206, 86, 1)',
          ],
          borderWidth: 1,
        }],
      };
      this.charts.insurances.options.tooltips = {
        callbacks: {
          title: (item, data) => data.labels[item[0].index],
        },
      };
    },
    loadExpenses() {
      this.charts.expenses.data = {
        labels: this.stats.expenses.map(item => item.name),
        datasets: [{
          label: 'Quantidade',
          data: this.stats.expenses.map(item => item.count),
          backgroundColor: [
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(51, 238, 255, 0.2)',
            'rgba(255, 155, 85, 0.2)',
            'rgba(239, 99, 255, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(255, 206, 86, 0.2)',
          ],
          borderColor: [
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(51, 238, 255, 1)',
            'rgba(255, 155, 85, 1)',
            'rgba(239, 99, 255, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(255, 206, 86, 1)',
          ],
          borderWidth: 1,
        }],
      };
      this.charts.expenses.options.tooltips = {
        callbacks: {
          title: (item, data) => data.labels[item[0].index],
        },
      };
    },
    loadServices() {
      this.charts.services.data = {
        labels: this.stats.services.map(item => item.date),
        datasets: [{
          label: 'Quantidade',
          data: this.stats.services.map(item => item.count),
          backgroundColor: [
            'rgba(255, 206, 86, 0.2)',
          ],
          borderColor: [
            'rgba(255, 206, 86, 1)',
          ],
          borderWidth: 1,
        }],
      };
      this.charts.services.options.tooltips = {
        callbacks: {
          title: (item, data) => data.labels[item[0].index],
        },
      };
    },
    chartProps(options = {}) {
      return {
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          legend: {
            display: options.displayLegend === undefined ? true : options.displayLegend,
          },
          maintainAspectRatio: false,
        },
        height: 260,
        params: {},
      };
    },
    chartAgeGroupsProps() {
      const props = this.chartProps();
      props.options = {
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            title(item) {
              return `Faixa etária: ${item[0].yLabel}`;
            },
            label(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label || '';
              return `${label}: ${Math.abs(tooltipItem.xLabel)} agendamentos`;
            },
          },
        },
        elements: {
          rectangle: {
            borderWidth: 2,
          },
        },
        scales: {
          xAxes: [{
            ticks: {
              callback: value => Math.abs(value),
            },
          }],
          yAxes: [{
            stacked: true,
          }],
        },
      };

      return props;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  @import "./src/assets/scss/mixins";

  .dashboard-page {
    .dashboard-tiles {
      display: flex;
      padding: 0 0 $layout-spacing-lg 0;
      color: $gray-color-dark;
      .tile-item {
        background-color: $light-color;
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        flex: 1;
        margin-left: $layout-spacing-lg;
        padding: 20px;
        position: relative;
        text-align: center;
        &:first-child {
          margin-left: 0;
        }
      }
      .tile-icon {
        margin-bottom: $layout-spacing;
        svg {
          display: block;
          height: 1.2rem;
          margin: 0 auto;
          width: auto;
        }
      }
      .tile-label {
        font-size: $font-size-sm;
        line-height: $font-size-sm;
        margin: $layout-spacing-sm 0 calc($layout-spacing-sm / 2) 0;
      }
      .tile-value {
        color: $secondary-color;
        font-size: $font-size-lg;
        font-weight: 600;
        line-height: $font-size;
        margin-top: $layout-spacing;
      }
    }
    .chart-card {
      background-color: $light-color;
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      .card-title {
        font-size: $font-size-lg;
        text-align: center;
      }
    }

    .scroll-list-wrapper {
      flex-grow: 1;
      position: relative;
      height: 245px;
    }

    .scroll-list {
      bottom: 0;
      overflow-y: auto;
      top: 0;
      width: 100%;
      max-height: 245px;
      @include scroll-bar();
    }
  }
</style>
