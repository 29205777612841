/* eslint-disable no-restricted-syntax */
import { library } from '@fortawesome/fontawesome-svg-core';
import * as brandIcons from './brands';
import * as lightIcons from './light';
import * as regularIcons from './regular';
import * as solidIcons from './solid';

// Brand icons
for (const key in brandIcons) {
  if ({}.hasOwnProperty.call(brandIcons, key)) {
    library.add(brandIcons[key]);
  }
}

// Light icons
for (const key in lightIcons) {
  if ({}.hasOwnProperty.call(lightIcons, key)) {
    library.add(lightIcons[key]);
  }
}

// Regular icons
for (const key in regularIcons) {
  if ({}.hasOwnProperty.call(regularIcons, key)) {
    library.add(regularIcons[key]);
  }
}

// Solid icons
for (const key in solidIcons) {
  if ({}.hasOwnProperty.call(solidIcons, key)) {
    library.add(solidIcons[key]);
  }
}
