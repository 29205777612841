import Vue from 'vue';
import address from './address';
import { capitalize, capitalizeName, capitalizeWord } from './capitalize';
import cep from './cep';
import cnpj from './cnpj';
import cpf from './cpf';
import currency from './currency';
import number from './number';
import date from './date';
import dateOld from './date-old';
import pad from './pad';
import phone from './phone';
import tuss from './tuss';
import council from './council';

Vue.filter('address', address);
Vue.filter('capitalize', capitalize);
Vue.filter('capitalizeName', capitalizeName);
Vue.filter('capitalizeWord', capitalizeWord);
Vue.filter('cep', cep);
Vue.filter('cnpj', cnpj);
Vue.filter('cpf', cpf);
Vue.filter('currency', currency);
Vue.filter('number', number);
Vue.filter('date', date);
Vue.filter('dateOld', dateOld);
Vue.filter('pad', pad);
Vue.filter('phone', phone);
Vue.filter('tuss', tuss);
Vue.filter('council', council);
