import StringMask from 'string-mask';

const mask = new StringMask('00.00.00.00');
const mask9 = new StringMask('00.00.00.00.0');
const mask10 = new StringMask('00.00.00.00.00');

export default (item) => {
  if (!item) {
    return '';
  }

  const value = item.toString().replace(/\D/g, '');

  if (value.length === 9) return mask9.apply(value);
  if (value.length === 10) return mask10.apply(value);
  return mask.apply(value);
};
