<template>
  <div class="chat-list-item tile tile-centered" @click="select">
    <div class="tile-icon">
      <figure
        class="avatar"
        :data-initial="$avatarLetters(member.name)"
        :class="{ badge: item.unread }"
      />
    </div>
    <div class="tile-content">
      <div class="tile-title">{{ member.name }}</div>
      <div class="tile-subtitle text-ellipsis">{{ lastMessage }}</div>
      <span class="chat-time" v-if="item.lastMessage">{{ time }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      today: moment().startOf('day'),
    };
  },
  computed: {
    ...mapState({
      user: ({ auth }) => auth.user,
    }),
    time() {
      if (this.item.lastMessage && this.item.lastMessage.date) {
        const date = moment(this.item.lastMessage.date);

        return date.isSameOrBefore(this.today)
          ? date.format('DD/MM/YYYY')
          : date.format('HH:mm');
      }

      return '';
    },
    member() {
      return this.item.members.find(({ id }) => id !== this.user.id);
    },
    lastMessage() {
      return this.item.lastMessage ? this.item.lastMessage.text : '--';
    },
  },
  methods: {
    select() {
      this.$emit('select', this.item);
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';

.chat-list-item.tile {
  cursor: pointer;
  position: relative;
  .avatar {
    background-color: $info-color;
    height: 2rem;
    width: 2rem;

    &.badge:after {
      background-color: $warning-color;
      height: $layout-spacing;
      width: $layout-spacing;
    }
  }
  .tile-title {
    font-size: $font-size;
    font-weight: 500;
    line-height: $font-size-lg;
  }
  .tile-subtitle {
    color: $gray-color;
    font-size: $font-size-sm;
    line-height: $font-size-lg;
    margin-top: $layout-spacing-sm;
  }
  .chat-time {
    background-color: $light-color;
    color: $gray-color-dark;
    font-size: $font-size-xs;
    padding: calc($layout-spacing-sm / 2) 0 calc($layout-spacing-sm / 2) $layout-spacing;
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
