<template>
  <dx-modal
    :title="`Detalhes do ${detail.type === 'expense' ? 'pagamento' : 'recebimento'}`"
    :value="show" @input="close()" size="lg"
    v-if="!loading && detail.id"
    id="transaction-modal-detail">
    <div class="transaction-detail">
      <div class="card-detail">
        <div class="card-detail-title">Dados do documento</div>
        <div class="card-detail-data">
          <div class="columns text-center">
            <div class="column col-3 col-md-12 col-sm-12 form-group">
              <small>Data de emissão</small>
              <div class="text-bold">{{ detail.issueDate | date }}</div>
            </div>
            <div class="column col-3 col-md-12 col-sm-12 form-group">
              <small>Data de vencimento</small>
              <div class="text-bold">{{ detail.dueDate | date }}</div>
            </div>
            <div class="column col-3 col-md-12 col-sm-12 form-group">
              <small>Data da baixa</small>
              <div class="text-bold" v-if="detail.payments && detail.payments.length > 0">
                {{ detail.payments[0].date | date }}
              </div>
            </div>
            <div class="column col-3 col-md-12 col-sm-12 form-group">
              <small>Competência</small>
              <div class="text-bold">{{ detail.competenceDate | date('MM/YYYY') }}</div>
            </div>
          </div>
          <div class="divider"/>
          <div class="columns">
            <div class="column col-6 col-md-6 col-sm-12">
              <small>{{ detail.type === 'expense' ? 'Favorecido' : 'Pagador' }}</small>
              <div>{{ detail.entity.name }}</div>
            </div>
            <div class="column col-6 col-md-6 col-sm-12">
              <small>Documento</small>
              <div>{{ detail.document }}</div>
            </div>
          </div>
          <div class="divider"/>
          <div class="columns">
            <div class="column col-6 col-md-6 col-sm-12">
              <small>Categoria</small>
              <div v-if="detail.category">{{ detail.category.name }}</div>
            </div>
            <div class="column col-6 col-md-6 col-sm-12">
              <small>Centro de custo</small>
              <div v-if="detail.costCenter">{{ detail.costCenter.name }}</div>
            </div>
          </div>
          <div class="divider"/>
          <div class="columns">
            <div class="column col-6 col-md-6 col-sm-12">
              <small>Forma de cobrança</small>
              <div>{{ detail.method.name }}</div>
            </div>
            <div class="column col-6 col-md-6 col-sm-12">
              <small>Conta bancária</small>
              <div>{{ detail.bankAccount.name }}</div>
            </div>
          </div>
          <div class="divider"/>
          <div class="columns">
            <div class="column col-3 col-md-6 col-sm-12 form-group">
              <small>Parcela</small>
              <div>
                {{ detail.installment.current }} / {{ detail.installment.total }}
              </div>
            </div>
            <div class="column col-3 col-md-6 col-sm-12 form-group text-right">
              <small>Valor total</small>
              <div>
                {{ detail.value.total | currency }}
              </div>
            </div>
            <div class="column col-3 col-md-6 col-sm-12 form-group text-right">
              <small>Valor documento</small>
              <div class="text-bold">
                {{ detail.value.final | currency }}
              </div>
            </div>
            <div class="column col-3 col-md-6 col-sm-12 form-group text-right">
              <small>Valor pago</small>
              <div class="text-bold">
                <span v-if="detail.payments.length > 0">
                  {{ detail.payments[0].total | currency }}
                </span>
                <span v-else>-</span>
              </div>
            </div>
            <div class="column col-3 col-md-6 col-sm-12 form-group">
              <small>Taxas</small>
              <div>
                {{ detail.value.rate | currency }}
              </div>
            </div>
            <div class="column col-3 col-md-6 col-sm-12 form-group text-right">
              <small>Acréscimo</small>
              <div>
                {{ detail.value.interest | currency }}
              </div>
            </div>
            <div class="column col-3 col-md-6 col-sm-12 form-group text-right">
              <small>Desconto</small>
              <div>
                {{ detail.value.discount | currency }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-detail"
           v-if="detail.services && detail.services.length > 0">
        <div class="card-detail-title">Contas e laudos</div>
        <table class="table">
          <thead>
          <tr>
            <th style="width: 30px">#</th>
            <th>Fatura / Data</th>
            <th>Paciente / Guia</th>
            <th>Procedimento</th>
            <th class="text-right">Valor</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in detail.services" :key="i">
            <td>{{ i + 1 }}</td>
            <td>
              <small>Fatura: </small>{{ item.invoiceCode }}<br>
              <small>Data: {{ item.startDate | date('DD/MM/YYYY HH:mm') }}</small>
            </td>
            <td>
              <small>Paciente: </small>{{ item.patient ? item.patient.name : '-' }}<br>
              <small>Guia: </small>{{ item.referral ? item.referral : '-' }}
            </td>
            <td style="max-width: 250px">
              {{ item.expense.name.substring(0, 60) }}
              <span v-if="item.expense.name.length > 60">...</span>
            </td>
            <td class="text-right" style="min-width: 100px">
              <small>Fatura: </small>{{ item.expense.total | currency }}<br>
              <small>Repasse: </small>{{ item.expense.totalTransfer | currency }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <template slot="footer">
      <button class="btn btn-neutral float-left mr-1"
              :disabled="(detail.cashier && detail.cashier.status === 'closed')"
              @click="remove">Excluir documento</button>
      <dx-dropdown class="float-left"
        id="btn-transfer-print"
        v-if="!detail.cashier
        && detail.services
        && detail.services.length > 0
        && detail.type === 'expense'"
        :items="printTransferTypes"
        :disabled="printing"
        @select="printTransfer"
        label="name"
        direction="left">
        <button class="btn btn-primary"
                :class="{loading: printing}"
                :disabled="printing">
          Imprimir repasse</button>
      </dx-dropdown>
      <button class="btn btn-gray mr-1"
              v-if="detail.status === 'open'"
              @click="save()">Baixar</button>
      <button class="btn btn-primary mr-1" v-else
              :disabled="(detail.cashier && detail.cashier.status === 'closed')"
              @click="reverse()">Estornar</button>
      <button class="btn" @click="close()">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import {
  DELETE_FINANCIAL_TRANSACTION,
  REVERSE_FINANCIAL_TRANSACTION,
} from '@/data/actions/modules/financial';

export default {
  props: {
    show: {
      type: Boolean,
    },
    transactionId: {
      type: String,
    },
  },
  data() {
    return {
      printTransferTypes: [
        { code: 'summarized', name: 'Resumido' },
        { code: 'detailed', name: 'Detalhado' },
      ],
      loading: false,
      printing: false,
      detail: this.blankDetail(),
    };
  },
  computed: {
    disabledDocument() {
      return this.form.services && this.form.services.length > 0;
    },
    canAccessReverseTransaction() {
      if (REVERSE_FINANCIAL_TRANSACTION) {
        return this.$can(REVERSE_FINANCIAL_TRANSACTION);
      }
      return true;
    },
    canAccessRemoveTransaction() {
      if (DELETE_FINANCIAL_TRANSACTION) {
        return this.$can(DELETE_FINANCIAL_TRANSACTION);
      }
      return true;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    save() {
      this.$router.push(`/financial/transactions/${this.detail.id}/payment`);
    },
    reverse() {
      if (!this.canAccessReverseTransaction) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente estornar este documento?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: close => this.$http
              .put(`/financial-transactions/${this.detail.id}/reverse`)
              .catch(() => {})
              .then(() => {
                close();
                this.close(true);
              }),
          },
        ],
      });
    },
    remove() {
      if (!this.canAccessRemoveTransaction) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este documento?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: close => this.$http
              .delete(`/financial-transactions/${this.detail.id}`)
              .catch((e) => {
                if (e.response
                  && e.response.data
                  && e.response.data.message) {
                  this.$toast.show(e.response.data.message, { type: 'error' });
                }
              })
              .then(() => {
                this.close(true);
                close();
              }),
          },
        ],
      });
    },
    close(save = false) {
      this.$emit('close', save);
    },
    load() {
      this.loading = true;
      return this.$http.get(`/financial-transactions/${this.transactionId}`)
        .then(({ data }) => {
          this.detail = data;
          if (data.medicalReport && data.medicalReport.count > 0) {
            this.detail.services.push({
              type: 'medicalReport',
              invoiceCode: 'LAUDOS',
              code: '-',
              patient: null,
              insurance: null,
              expense: {
                name: 'Laudos assinados',
                totalTransfer: data.medicalReport.total,
              },
            });
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    printTransfer({ code }) {
      this.printing = true;

      const params = {
        professionalId: this.detail.entity.id,
        hasFinancialBundle: true,
        accounts: this.detail.services
          .map(account => ({
            accountId: account.id,
            itemId: account.itemId,
          })),
        type: code,
      };

      return this.$file
        .print(
          `/professional-transfer/${this.detail.entity.id}/print`,
          params,
          { method: 'POST' },
        )
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
    blankDetail() {
      return {
        id: '',
        type: '',
        status: '',
        document: '',
        issueDate: '',
        dueDate: '',
        competenceDate: '',
        method: {
          id: '',
          name: '',
        },
        entity: {
          id: '',
          name: '',
        },
        bankAccount: {
          id: '',
          name: '',
        },
        category: {
          id: '',
          name: '',
        },
        costCenter: {
          id: '',
          name: '',
        },
        cashier: {
          id: '',
          operation: '',
          status: '',
        },
        services: [],
        payments: [],
        medicalReport: null,
        installment: {
          total: 1,
          current: 1,
        },
        value: {
          rate: 0,
          total: 0,
          current: 0,
          final: 0,
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.transaction-detail {
  .card-detail {
    background-color: lighten($gray-color-light, 6%);
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing;
    padding: $layout-spacing;
    .card-detail-title {
      font-weight: bold;
      color: $primary-color;
      margin-bottom: $layout-spacing;
    }
    .table th, .table td {
      font-size: 0.52rem;
    }
  }
}

#btn-transfer-print li a:hover {
  background-color: $gray-color-light;
  color: black;
}
</style>
