import {
  LIST_EXPENSES,
  CREATE_EXPENSE,
  UPDATE_EXPENSE,
} from '@/data/actions/modules/general';

export default [
  {
    path: '/clinical/procedures',
    component: () => import(/* webpackChunkName: "procedures" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_EXPENSES,
    },
  },
  {
    path: '/clinical/procedures/create',
    component: () => import(/* webpackChunkName: "procedures" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_EXPENSE,
    },
  },
  {
    path: '/clinical/procedures/:id/edit',
    component: () => import(/* webpackChunkName: "procedures" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_EXPENSE,
    },
  },
];
