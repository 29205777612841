import {
  LIST_FAMILIES,
  CREATE_FAMILY,
  UPDATE_FAMILY,
} from '@/data/actions/modules/stock';

export default [
  {
    path: '/stock/product-families',
    component: () => import(/* webpackChunkName: "product-families" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_FAMILIES,
    },
  },
  {
    path: '/stock/product-families/create',
    component: () => import(/* webpackChunkName: "product-families" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_FAMILY,
    },
  },
  {
    path: '/stock/product-families/:id/edit',
    component: () => import(/* webpackChunkName: "product-families" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_FAMILY,
    },
  },
];
