import {
  LIST_CATEGORIES,
} from '@/data/actions/modules/financial';

export default [
  {
    path: '/financial/categories',
    component: () => import(/* webpackChunkName: "categories" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_CATEGORIES,
    },
  },
];
