export default [
  {
    path: '/message-templates',
    component: () => import(/* webpackChunkName: "message" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      // permission: '',
    },
  },
  {
    path: '/message-templates/create',
    component: () => import(/* webpackChunkName: "message" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      // permission: '',
    },
  },
  {
    path: '/message-templates/:id/edit',
    component: () => import(/* webpackChunkName: "message" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      // permission: '',
    },
  },
];
