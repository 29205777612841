import {
  LIST_CBHPM_TABLE,
  CREATE_CBHPM_TABLE,
  UPDATE_CBHPM_TABLE,
} from '@/data/actions/modules/general';

export default [
  {
    path: '/clinical/cbhpm-tables',
    component: () => import(/* webpackChunkName: "cbhpm" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_CBHPM_TABLE,
    },
  },
  {
    path: '/clinical/cbhpm-tables/create',
    component: () => import(/* webpackChunkName: "cbhpm" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_CBHPM_TABLE,
    },
  },
  {
    path: '/clinical/cbhpm-tables/:id/edit',
    component: () => import(/* webpackChunkName: "cbhpm" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_CBHPM_TABLE,
    },
  },
];
