<template>
  <div class="document-editor">
    <div class="document-editor-header">
      <div class="document-editor-toolbar" ref="editorToolbar"></div>
    </div>
    <div class="document-editor-body">
      <div ref="ckeditor"></div>
    </div>
  </div>
</template>

<script>
import DocumentEditor from '@devindex/ckeditor5-document';

export default {
  props: {
    value: { type: String },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      editor: null,
      timer: null,
    };
  },
  mounted() {
    DocumentEditor
      .create(this.$refs.ckeditor, {
        language: 'pt-br',
        toolbar: {
          items: [
            'heading',
            '|',
            'fontSize',
            'fontColor',
            '|',
            'bold',
            'italic',
            'underline',
            '|',
            'alignment',
            '|',
            'numberedList',
            'bulletedList',
            '|',
            'indent',
            'outdent',
            '|',
            'link',
            'blockQuote',
            'insertTable',
            '|',
            'undo',
            'redo',
          ],
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
          ],
        },
        licenseKey: '',
        typing: {
          transformations: {
            remove: [
              'oneHalf',
              'oneThird',
              'twoThirds',
              'oneForth',
              'threeQuarters',
            ],
          },
        },
      })
      .then((editor) => {
        this.editor = editor;
        this.editor.setData(this.value || '');
        this.updateReadOnlyMode();

        editor.model.document.on('change', () => {
          if (!this.disabled) {
            clearInterval(this.timer);
            this.timer = setTimeout(() => this
              .$emit('input', editor.getData()), 500);
          }
        });

        this.$refs.editorToolbar.appendChild(editor.ui.view.toolbar.element);
        this.$refs.editorToolbar.querySelector('.ck-toolbar')
          .classList.add('ck-reset_all');
      })
      .catch(() => {});
  },
  beforeUpdate() {
    if (this.value !== this.editor.getData()) {
      this.editor.setData(this.value);
    }
  },
  watch: {
    value() {
      if (this.editor !== null && this.value !== this.editor.getData()) {
        this.editor.setData(this.value || '');
      }
    },
    disabled() {
      this.updateReadOnlyMode();
    },
  },
  methods: {
    updateReadOnlyMode() {
      if (this.editor !== null) {
        this.editor.isReadOnly = this.disabled;
      }
    },
  },
  destroyed() {
    if (this.editor !== null) {
      this.editor.destroy();
    }
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';

.document-editor {
  .document-editor-body {
    border: $border-width solid var(--ck-color-toolbar-border);
    border-top: 0;
    border-radius: 0 0 $border-radius $border-radius;
  }
  .ck-toolbar.ck-rounded-corners {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ck-content {
    padding: $layout-spacing $layout-spacing-lg;
    p {
      margin: 0 0 $layout-spacing-sm;
    }
    ol,ul {
      margin: $layout-spacing 0 $layout-spacing $layout-spacing;
      li {
        margin-top: $layout-spacing-sm;
      }
    }
  }
}
</style>
