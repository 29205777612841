<template>
  <dx-modal title="Requer autorização"
            id="master-password" size="sm"
            :value="show" @input="close">
    <div class="columns">
      <div class="column col-12 form-group"
           :class="{'has-error': $v.form.username.$error}">
        <label class="form-label">CPF</label>
        <input type="text" class="form-input text-center"
               v-model="form.username"
               @blur="$v.form.username.$touch()"
               @keypress.enter="save"
               placeholder="000.000.000-00"
               v-mask-cpf v-focus>
        <template v-if="$v.form.username.$error">
          <div class="form-input-hint"
               v-if="!$v.form.username.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.form.username.cpf">CPF inválido</div>
        </template>
      </div>
      <div class="column col-12 form-group"
           :class="{'has-error': $v.form.password.$error}">
        <label class="form-label">Senha</label>
        <input type="password" class="form-input text-center"
               v-model="form.password"
               @blur="$v.form.password.$touch()"
               @keypress.enter="save"
               placeholder="******">
        <template v-if="$v.form.password.$error">
          <div class="form-input-hint"
               v-if="!$v.form.password.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.form.password.minLength">Mínimo 5 caracteres</div>
        </template>
      </div>
    </div>
    <template slot="footer">
      <button class="btn btn-primary btn-icon btn-icon-left mr-1" @click="save">
        <fa-icon :icon="['fal', 'unlock']" />Autorizar
      </button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import { cpf } from 'src/data/validators';
import minLength from 'vuelidate/lib/validators/minLength';

export default {
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      saving: false,
      form: {
        username: '',
        password: '',
      },
    };
  },
  validations() {
    return {
      form: {
        username: { required, cpf },
        password: { required, minLength: minLength(5) },
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      this.saving = true;

      this.$http.post('/master-auth', this.form)
        .then(({ data }) => {
          if ('result' in data && !data.result) {
            this.$toast.error('Usuário sem autorização para esta liberação!');
          }
          this.$emit('result', data.result);
        })
        .catch(e => this.$toast.error(e))
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss">
#master-password {
  input {
    font-size: .9rem;
    height: 2.2rem;
  }
}
</style>
