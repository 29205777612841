export default {
  minLen: 3,
  wait: 300,
  timeout: null,
  isUpdateItems(text) {
    return text.length >= this.minLen;
  },
  callUpdateItems(text, cb) {
    clearTimeout(this.timeout);
    if (this.isUpdateItems(text)) {
      this.timeout = setTimeout(cb, this.wait);
    }
  },
  findItem(items, text, autoSelectOneResult) {
    if (!text) return null;
    if (autoSelectOneResult && items.length === 1) {
      return items[0];
    }
    return null;
  },
};
