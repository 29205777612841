function findAppointment(state, data) {
  return state.items.find(item => item.key === data.key);
}

function clearAppointment(state, data) {
  const found = findAppointment(state, data);
  if (found) {
    if (found.isSlot || found.isArrivalOrder) {
      state.items.splice(state.items.indexOf(found), 1);
    } else {
      found.id = null;
      found.patient = null;
      found.insurance = null;
      found.status = 'available';
    }
  }
}

export default {
  'Attendance.SET_LOADING': function setLoading(state, value) {
    state.loading = value;
  },
  'Attendance.SET_ITEMS': function setItems(state, data) {
    state.items = data;
  },
  'Attendance.UPSERT': function upsert(state, data) {
    if ('originalKey' in data && data.originalKey) {
      clearAppointment(state, { ...data, key: data.originalKey });
    }

    // if (state.filter.date !== data.startDate.substring(0, 10)) return;
    if (state.filter.scheduleId) {
      if (state.filter.scheduleId !== data.scheduleId) {
        return;
      }
    } else {
      const isInScheduleList = state.filter.schedules.some(({ id }) => (
        id === data.scheduleId
      ));
      if (!isInScheduleList) {
        return;
      }
    }

    const found = findAppointment(state, data);
    if (found) {
      data.available.insurancePlans = found.available.insurancePlans;
      Object.assign(found, data);
    } else if (
      state.items.length === 0
      || state.items[state.items.length - 1].startDate < data.startDate
    ) {
      state.items.push(data);
    } else if (state.items[0].startDate > data.startDate) {
      state.items.unshift(data);
    } else {
      let idx = 0;

      state.items.forEach((item, i) => {
        if (data.startDate > item.startDate) {
          idx = i + 1;
        }
      });

      state.items.splice(idx, 0, data);
    }
  },
  'Attendance.REMOVE': function upsert(state, data) {
    clearAppointment(state, data);
  },
  'Attendance.OPEN_CONFIG': function openConfig(state) {
    state.config.show = true;
  },
  'Attendance.CLOSE_CONFIG': function closeConfig(state) {
    state.config.show = false;
  },
  'Attendance.UPDATE_CONFIG': function closeConfig(state, data) {
    state.config.form = {
      ...state.config.form,
      ...data,
    };
  },
};
