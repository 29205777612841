export const degreesParticipation = {
  surgeon: 'Cirurgião',
  first_assistant: 'Primeiro Auxiliar',
  second_assistant: 'Segundo Auxiliar',
  third_assistant: 'Terceiro Auxiliar',
  fourth_assistant: 'Quarto Auxiliar',
  surgical_technician: 'Instrumentador',
  anesthetist: 'Anestesista',
  anesthetist_assistant: 'Auxiliar de Anestesista',
  consultant: 'Consultor',
  perfusionist: 'Perfusionista',
  pediatrician_birth_room: 'Pediatra na sala de parto',
  sadt_assistant: 'Auxiliar SADT',
  clinical: 'Clínico',
  intensivist: 'Intensivista',
};

export function getName(value) {
  return degreesParticipation[value];
}
