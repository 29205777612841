// eslint-disable-next-line import/prefer-default-export
export function getValue(name, defaults = {}) {
  const processEnv = process.env;
  if (processEnv[name]) {
    return processEnv[name];
  }

  if (defaults) {
    if (typeof defaults === 'string') {
      return defaults;
    }

    const mode = processEnv.VUE_APP_MODE;
    if (mode && mode in defaults) {
      return defaults[mode];
    }

    if ('default' in defaults) {
      return defaults.default;
    }
  }

  return null;
}
