const meta = {
  layout: 'master',
  requiresAuth: true,
};

export default [
  {
    path: '/surgery/reports',
    component: () => import(/* webpackChunkName: "report" */ './components/Report.vue'),
    meta,
  },
];
