import {
  LIST_PATIENTS,
  CREATE_PATIENT,
  UPDATE_PATIENT,
} from '@/data/actions/modules/general';

export default [
  {
    path: '/patients',
    component: () => import(/* webpackChunkName: "patients" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_PATIENTS,
    },
  },
  {
    path: '/patients/create',
    component: () => import(/* webpackChunkName: "patients" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_PATIENT,
    },
  },
  {
    path: '/patients/:id/edit',
    component: () => import(/* webpackChunkName: "patients" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_PATIENT,
    },
  },
];
