import moment from 'moment';

export default {
  methods: {
    clone(obj, deep = true) {
      return deep
        ? JSON.parse(JSON.stringify(obj))
        : Object.assign({}, obj);
    },
    onlyNumbers(value, defaultValue = null) {
      let localValue = value;

      switch (typeof localValue) {
        case 'string':
          localValue = localValue.replace(/\D/g, '');
          return localValue === '' ? defaultValue : localValue;
        case 'number':
          return localValue.toString();
        default:
          return defaultValue;
      }
    },
    dateToApi(date, inputFormat = 'L', strict = true) {
      const localDate = moment(date, inputFormat, strict);
      return localDate.isValid() ? localDate.format('YYYY-MM-DD') : null;
    },
    dateFromApi(date, utc = false) {
      const localDate = moment(date);

      if (localDate.isValid()) {
        return utc ? localDate.utc().format('L') : localDate.format('L');
      }
      return null;
    },
    removeAccentuation(text) {
      let data = text.toLowerCase();
      data = data.replace(/[ÁÀÂÃ]/gi, 'a');
      data = data.replace(/[ÉÈÊ]/gi, 'e');
      data = data.replace(/[ÍÌÎ]/gi, 'i');
      data = data.replace(/[ÓÒÔÕ]/gi, 'o');
      data = data.replace(/[ÚÙÛ]/gi, 'u');
      data += data.replace(/[Ç]/gi, 'c');
      return data;
    },
    decimalToApi(value, usFormat = false) {
      let number = value;

      if (typeof number === 'string') {
        number = usFormat
          ? number.replace(/,/g, '')
          : number.replace(/\./g, '').replace(',', '.');
        number = Number(number);
      }
      return number || 0;
    },
    createError(message, data = {}) {
      const error = new Error(message);
      Object.keys(data).forEach((key) => {
        error[key] = data[key];
      });
      return error;
    },
  },
};
