import { http } from '@/plugins/http';
import API from '@/helpers/api';

export async function findOne() {
  return API('/doctoralia/mappings').fetch();
}

export async function createOrUpdate(data) {
  const response = await http.post('/doctoralia/mappings', data);
  return response.data;
}

export async function createOrUpdateItem(data) {
  return createOrUpdate({ professionals: [data] });
}

export async function removeProfessional(id) {
  const response = await http.delete(`/doctoralia/mappings/professionals/${id}`);
  return response.data;
}

export async function syncProfessional(id) {
  const response = await http.post(`/doctoralia/mappings/professionals/${id}/sync`);
  return response.data;
}
