import { http } from '@/plugins/http';
import API from '@/helpers/api';
import { removeAccentuation } from '@/helpers/string';

function makeAddressUrl(professionalId, addressId = null, path = '') {
  let url = `/doctoralia/professionals/${professionalId}/addresses`;
  if (addressId) {
    url += `/${addressId}`;
  }
  return `${url}${path}`;
}

export async function find() {
  const data = await API('/doctoralia/professionals').fetch();
  data.items.sort((a, b) => {
    const nameA = removeAccentuation(a.name);
    const nameB = removeAccentuation(b.name);
    if (nameA > nameB) return 1;
    if (nameA < nameB) return -1;
    return 0;
  });
  return data;
}

export async function findAddresses(professionalId) {
  return API(makeAddressUrl(professionalId)).fetch();
}

export async function findAddressExpenses(professionalId, addressId) {
  return http
    .get(makeAddressUrl(professionalId, addressId, '/expenses'))
    .then(({ data }) => data);
}

export async function createAddressExpense(professionalId, addressId, payload) {
  return http
    .post(makeAddressUrl(professionalId, addressId, '/expenses'), payload)
    .then(({ data }) => data);
}

export async function deleteAddressExpense(professionalId, addressId, expenseId) {
  return http
    .delete(makeAddressUrl(professionalId, addressId, `/expenses/${expenseId}`))
    .then(({ data }) => data);
}

export async function findAddressInsurances(professionalId, addressId) {
  return http
    .get(makeAddressUrl(professionalId, addressId, '/insurances'))
    .then(({ data }) => data);
}

export async function createAddressInsurance(professionalId, addressId, payload) {
  return http
    .post(makeAddressUrl(professionalId, addressId, '/insurances'), payload)
    .then(({ data }) => data);
}

export async function deleteAddressInsurance(professionalId, addressId, insuranceId) {
  return http
    .delete(makeAddressUrl(professionalId, addressId, `/insurances/${insuranceId}`))
    .then(({ data }) => data);
}
