<template>
  <div class="page-container marketing-campaign-page">
    <portal to="page-name">Campanhas</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Campanhas</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column form-group">
            <div class="input-group">
              <input type="text" id="filter-search" v-model="search"
                     class="form-input" placeholder="Pesquisar responsável...">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto">
            <button class="btn btn-primary btn-icon btn-icon-left"
                    @click="openModalCampaign">
              <fa-icon :icon="['fal', 'plus']"/>Nova campanha
            </button>
          </div>
        </div>
        <div class="loading loading-lg" v-if="loading" />
        <template v-else>
          <div class="empty mt-2" v-if="items.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
            </div>
            <p class="empty-title h5">Campanhas</p>
            <p class="empty-subtitle">
              Nenhuma campanha encontrada. Verifique o filtro para realizar novas buscas
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th>#</th>
              <th>Data</th>
              <th>Responsável</th>
              <th>Status</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in items" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ item.date | date('DD/MM/YYYY HH:mm') }}</td>
              <td>{{ item.user.name }}</td>
              <td>{{ item.status === 'finished' ? 'Mensagens enviadas' : '-' }}</td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-gray"
                        @click="editModalCampaign(item.id)">
                  <fa-icon :icon="['fal', 'search']"/>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
      <div class="card-footer">
      </div>
    </div>
    <dx-modal title="Gerenciar campanha" id="modal-campaign" size="lg"
              v-model="modalCampaign.show">
      <template v-if="!modalCampaign.id">
        <div class="h6">Pacientes</div>
        <div class="columns">
          <div class="column col-2 form-group">
            <label class="form-label">Idade Inicial</label>
            <select class="form-select" v-model="modalCampaign.filters.startAge">
              <option value="">Todos</option>
              <option v-for="age in modalCampaign.ageList" :value="age" :key="age">
                {{ age }}
              </option>
            </select>
          </div>
          <div class="column col-2 form-group">
            <label class="form-label">Idade Final</label>
            <select class="form-select" v-model="modalCampaign.filters.endAge">
              <option value="">Todos</option>
              <option v-for="age in modalCampaign.ageList" :value="age" :key="age">
                {{ age }}
              </option>
            </select>
          </div>
          <div class="column col-2 form-group">
            <label class="form-label">Sexo</label>
            <select class="form-select" v-model="modalCampaign.filters.gender">
              <option value="">Todos</option>
              <option value="male">Masculino</option>
              <option value="female">Feminino</option>
            </select>
          </div>
          <div class="column form-group">
            <label class="form-label">Convênio</label>
            <select class="form-select" v-model="modalCampaign.filters.insuranceId">
              <option value="">Todos os convênios</option>
              <option v-for="(item, i) in modalCampaign.insurances" :value="item.id" :key="i">
                {{ item.customName }}
              </option>
            </select>
          </div>
        </div>
        <div class="divider" />
        <div class="h6">Atendimento</div>
        <div class="columns">
          <div class="column col-2 col-md-4 col-sm-12 form-group"
               :class="{'has-error': $v.modalCampaign.filters.startDate.$error}">
            <label class="form-label">De</label>
            <div class="input-group">
              <dx-input-date
                id="appointment-patient-birth-date"
                class="form-input text-center"
                v-model="modalCampaign.filters.startDate"
                @blur="$v.modalCampaign.filters.startDate.$touch()"
              />
            </div>
          </div>
          <div class="column col-2 col-md-4 col-sm-12 form-group"
               :class="{'has-error': $v.modalCampaign.filters.endDate.$error}">
            <label class="form-label">Até</label>
            <dx-input-date
              id="end-date"
              class="form-input text-center"
              v-model="modalCampaign.filters.endDate"
              @blur="$v.modalCampaign.filters.endDate.$touch()"
            />
          </div>
          <div class="column form-group">
            <label class="form-label">Atendido por</label>
            <select class="form-select" v-model="modalCampaign.filters.professionalId">
              <option value="">Todos os profissionais</option>
              <option v-for="(item, i) in modalCampaign.professionals" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="column col-4 form-group">
            <label class="form-label">Procedimento</label>
            <select class="form-select" v-model="modalCampaign.filters.expenseId">
              <option value="">Todos os procedimentos</option>
              <option v-for="(item, i) in modalCampaign.expenses" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="column col-auto form-group mb-2"
               style="align-self: flex-end">
            <button class="btn btn-primary btn-icon-left"
                    :class="{loading: modalCampaign.loading}"
                    :disabled="modalCampaign.loading"
                    @click="loadPatientList">
              <fa-icon class="icon" :icon="['fal', 'search']"></fa-icon>
              Filtrar
            </button>
          </div>
        </div>
        <div class="divider" />
      </template>
      <div class="empty mt-2" v-if="modalCampaign.items.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Campanha</p>
        <p class="empty-subtitle">Utilize os filtros acima para realizar uma campanha</p>
      </div>
      <template v-else>
        <table class="table table-striped table-hover" >
          <thead>
          <tr>
            <th v-if="!modalCampaign.id">Selecione</th>
            <th>Paciente</th>
            <th>Data de nascimento</th>
            <th>Sexo</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in modalCampaign.items" :key="i">
            <td style="width:80px" v-if="!modalCampaign.id">
              <label class="form-checkbox">
                <input type="checkbox" v-model="item.selected">
                <i class="form-icon"></i>
              </label>
            </td>
            <td>{{ item.name }}</td>
            <td>
              <span v-if="item.birthDate">{{ item.birthDate | date }}</span>
              <span v-else>-</span>
            </td>
            <td>{{ item.gender === 'male' ? 'Masculino' : 'Feminino' }}</td>
          </tr>
          </tbody>
        </table>
        <div class="mt-1">
          Total de pacientes: <strong>{{ modalCampaign.items.length + 1 }}</strong>
        </div>
      </template>
      <template slot="footer">
        <div class="divider=" data-content="Informações para o paciente" />
        <div class="btn-group btn-group-block mb-1" v-if="hasItems && !modalCampaign.id">
          <button class="btn btn-sm btn-gray mr-1"
                  @click="insertTag('date')">
            Data atual
          </button>
          <button class="btn btn-sm btn-gray mr-1"
                  @click="insertTag('hour')">
            Hora atual
          </button>
          <button class="btn btn-sm btn-gray mr-1"
                  @click="insertTag('patient_name')">
            Nome do paciente
          </button>
          <button class="btn btn-sm btn-gray mr-1"
                  @click="insertTag('patient_gender')">
            Sexo
          </button>
          <button class="btn btn-sm btn-gray mr-1"
                  @click="insertTag('patient_birth_date')">
            Data de Nascimento
          </button>
          <button class="btn btn-sm btn-gray mr-1"
                  @click="insertTag('patient_age')">
            Idade
          </button>
          <button class="btn btn-sm btn-gray"
                  @click="insertTag('patient_phone')">
            Telefone
          </button>
        </div>
        <div class="columns">
          <div class="column col-12 form-group"
               :class="{'has-error': $v.modalCampaign.message.$error}"
               v-if="hasItems && !modalCampaign.id">
            <textarea id="campaign-message" class="form-input"
                      placeholder="Mensagem para o paciente..."
                      rows="3" v-model="modalCampaign.message" />
          </div>
          <div class="column col-4 form-group"
               :class="{'has-error': $v.modalCampaign.shippingMethod.$error}"
               v-if="hasItems && !modalCampaign.id">
            <select class="form-select" v-model="modalCampaign.shippingMethod">
              <option value="">Forma de envio</option>
              <option value="whatsapp">WhatsApp</option>
              <option value="email">Email</option>
            </select>
          </div>
          <div class="column form-group">
            <button class="btn btn-primary btn-icon btn-icon-left mr-1"
                    v-if="hasItems && !modalCampaign.id"
                    :class="{loading: modalCampaign.saving}"
                    :disabled="modalCampaign.saving
                    || modalCampaign.items.length === 0"
                    @click="save">
              <fa-icon :icon="['fal', 'paper-plane']"/>Salvar e enviar
            </button>
            <button class="btn" @click="modalCampaign.show = false">Sair</button>
          </div>
        </div>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from 'src/data/validators';
import * as professionalApi from '@/app/professional/api';

export default {
  data() {
    return {
      debounceTimeout: null,
      search: '',
      items: [],
      loading: false,
      modalCampaign: {
        show: false,
        loading: false,
        saving: false,
        insurances: [],
        professionals: [],
        expenses: [],
        ageList: [],
        message: '',
        shippingMethod: '',
        filters: this.blankFilters(),
        items: [],
      },
    };
  },
  watch: {
    search: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.load, 600);
      },
      deep: true,
    },
  },
  mounted() {
    this.loadInsurances();
    this.loadProfessionals();
    this.loadExpenses();
    this.load();
    for (let i = 0; i < 100; i += 1) {
      this.modalCampaign.ageList = i;
    }
  },
  computed: {
    hasItems() {
      return this.modalCampaign.items.length > 0;
    },
  },
  validations() {
    const rules = {
      modalCampaign: {
        filters: {
          startDate: { date, required },
          endDate: { date, required },
        },
        message: { required },
        shippingMethod: { required },
      },
    };
    if (this.modalCampaign.filters.startDate) {
      rules.modalCampaign.filters.endDate = {
        date,
        required,
        minDate: minDate(this.modalCampaign.filters.startDate),
      };
    }
    return rules;
  },
  methods: {
    load() {
      this.loading = true;

      const params = {
        limit: 0,
      };

      if (this.search) {
        params.search = this.search;
      }

      return this.$http
        .get('/campaigns', { params })
        .then(({ data }) => {
          this.items = data.items;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.modalCampaign.insurances = data.items;
        })
        .catch(() => {});
    },
    loadProfessionals() {
      return professionalApi.allActive()
        .then((data) => {
          this.modalCampaign.professionals = data.items;
        })
        .catch(this.$toast.error);
    },
    loadExpenses() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/expenses', { params })
        .then(({ data }) => {
          this.modalCampaign.expenses = data.items;
        })
        .catch(() => {});
    },
    loadPatientList() {
      this.$v.modalCampaign.filters.$touch();
      if (this.$v.modalCampaign.filters.$error) {
        return null;
      }

      const duration = moment.duration(moment(this.modalCampaign.filters.endDate)
        .diff(moment(this.modalCampaign.filters.startDate))).asMonths();

      if (duration > 6) {
        this.$toast.show('Período limite (6 meses)', { type: 'error' });
        return false;
      }

      this.modalCampaign.loading = true;

      const params = {};

      if (this.modalCampaign.filters.startAge) {
        params.patientStartAge = this.modalCampaign.filters.startAge;
      }

      if (this.modalCampaign.filters.endAge) {
        params.patientEndAge = this.modalCampaign.filters.endAge;
      }

      if (this.modalCampaign.filters.gender) {
        params.patientGender = this.modalCampaign.filters.gender;
      }

      if (this.modalCampaign.filters.startDate) {
        params.startDate = this.modalCampaign.filters.startDate;
      }

      if (this.modalCampaign.filters.endDate) {
        params.endDate = this.modalCampaign.filters.endDate;
      }

      if (this.modalCampaign.filters.insuranceId) {
        params.insuranceId = this.modalCampaign.filters.insuranceId;
      }

      if (this.modalCampaign.filters.professionalId) {
        params.professionalId = this.modalCampaign.filters.professionalId;
      }

      if (this.modalCampaign.filters.expenseId) {
        params.expenseId = this.modalCampaign.filters.expenseId;
      }

      return this.$http
        .get('/campaigns/generate', { params })
        .then(({ data }) => {
          this.modalCampaign.items = data.items
            .map(item => ({
              id: item.id,
              name: item.name,
              birthDate: item.birthDate,
              gender: item.gender,
              selected: true,
            }));
        })
        .catch(() => {})
        .finally(() => {
          this.modalCampaign.loading = false;
        });
    },
    openModalCampaign() {
      this.$v.modalCampaign.filters.$reset();
      this.modalCampaign.filters = this.blankFilters();
      this.modalCampaign.id = '';
      this.modalCampaign.message = '';
      this.modalCampaign.shippingMethod = '';
      this.modalCampaign.items = [];
      this.modalCampaign.show = true;
    },
    editModalCampaign(id) {
      return this.$http
        .get(`/campaigns/${id}`)
        .then(({ data }) => {
          this.modalCampaign.id = data.id;
          this.modalCampaign.items = data.patients;
          this.modalCampaign.show = true;
        })
        .catch(() => {});
    },
    save() {
      this.$v.modalCampaign.message.$touch();
      this.$v.modalCampaign.shippingMethod.$touch();
      if (this.$v.modalCampaign.message.$error
        || this.$v.modalCampaign.shippingMethod.$error) {
        return null;
      }

      if (this.modalCampaign.items.length === 0 || this.modalCampaign.saving) {
        return null;
      }

      this.modalCampaign.saving = true;

      const data = {
        patientIds: this.modalCampaign.items
          .filter(({ selected }) => selected)
          .map(({ id }) => id),
        message: this.modalCampaign.message,
        shippingMethod: this.modalCampaign.shippingMethod,
      };

      if (data.patientIds.length === 0) {
        this.$toast.show('Selecione um ou mais pacientes!', { type: 'error' });
        return null;
      }

      return this.$http
        .post('/campaigns', data)
        .then(() => {
          this.modalCampaign.show = false;
          this.load();
          this.$toast.show('Campanha gerada com sucesso!');
        })
        .catch(() => {
          this.$toast.show('Ocorreu um erro. Tente novamente!', { type: 'error' });
        })
        .finally(() => {
          this.modalCampaign.saving = false;
        });
    },
    insertTag(item) {
      this.modalCampaign.message += `[${item}]`;
    },
    blankFilters() {
      return {
        startAge: '',
        endAge: '',
        gender: '',
        startDate: '',
        endDate: '',
        insuranceId: '',
        professionalId: '',
        expenseId: '',
      };
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  @import "./src/assets/scss/mixins";

  .marketing-campaign-page {
    .dashboard-title {
      margin-bottom: $layout-spacing;
      font-size: $line-height;
    }
  }
</style>
