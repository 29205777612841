<template>
  <div class="notification-wrapper c-hand" v-if="show" @click="click">
    <div class="toast" :class="`toast-${type}`">
      <div class="notification-header">
        <div class="notification-title">{{ title }}</div>
        <button class="btn btn-clear" @click.stop="close"></button>
      </div>
      <div class="notification-body" :class="ellipsis ? 'text-ellipsis' : ''" v-if="content">
        {{ content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    ellipsis: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: '',
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.body.appendChild(this.$el);
    });
  },
  destroyed() {
    this.$el.remove();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';

.toast {
  background-color: $gray-color-dark;
  border-color: $gray-color-dark;

  &.toast-info {
    background-color: $info-color;
    border-color: $info-color;
  }
}

.notification-wrapper {
  bottom: 1rem;
  position: absolute;
  right: 1rem;
  width: 15rem;
  z-index: $zindex-x + 10;
  .notification-title {
    font-weight: 600;
  }
  .notification-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    .btn {
      margin: 0;
    }
  }
  .notification-body {
    font-size: $font-size-sm;
    margin-top: $layout-spacing-sm;
    min-width: 1px;
  }
}
</style>
