export function whatsappURL(phone, text) {
  phone = `55${phone.replace(/\D/g, '')}`;
  return `https://wa.me/${phone}?text=${encodeURIComponent(text)}`;
}

function parseOptions(options = {}) {
  return {
    type: 'hidden',
    isBlob: false,
    ...options,
  };
}
export function create(data, options = {}) {
  options = parseOptions(options);
  const el = document.createElement('a');
  el.isBlob = options.isBlob;
  el.href = options.isBlob ? URL.createObjectURL(data) : data;

  if (options.type) {
    el.type = options.type;
  }

  if (options.target) {
    el.target = options.target;
  }

  if (options.download) {
    el.download = options.download;
  }

  // Append link to the body
  document.body.appendChild(el);

  return el;
}

export function dispatch(el) {
  // this is necessary as linkEl.click() does not work on the latest firefox
  el.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  setTimeout(() => {
    if (el.isBlob) {
      // For Firefox, it is necessary to delay revoking the ObjectURL
      URL.revokeObjectURL(el.href);
    }
    document.body.removeChild(el);
  }, 100);
}

export function createAndDispatch(data, options = {}) {
  options = parseOptions(options);
  if (options.isBlob && window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(data);
  } else {
    dispatch(create(data, options));
  }
}
