import cpf from '@/filters/cpf';
import cnpj from '@/filters/cnpj';
import { clearNonNumbers } from '@/helpers/string';

export const CPF = 'cpf';
export const CNPJ = 'cnpj';
export const PASSPORT = 'passport';
export const RG = 'rg';
export const PERSON_TYPES = [CPF, PASSPORT, RG];
export const COMPANY_TYPES = [CNPJ];
export const PERSON_PRIMARY_TYPES = [CPF, PASSPORT];
export const PERSON_SECONDARY_TYPES = [RG, PASSPORT];

export default [
  {
    type: CPF,
    label: 'CPF',
    placeholder: '000.000.000-00',
    filter: cpf,
    sanitizer: clearNonNumbers,
  },
  {
    type: CNPJ,
    label: 'CNPJ',
    placeholder: '00.000.000/0000-00',
    filter: cnpj,
    sanitizer: clearNonNumbers,
  },
  {
    type: PASSPORT,
    label: 'Passaporte',
    placeholder: '',
    filter: v => (
      (v || '').toUpperCase()
        .replace(/[^\dA-Z]/g, '')
        .substring(0, 12)
    ),
    sanitizer: v => (
      (v || '').toUpperCase()
        .replace(/[^\dA-Z]/g, '')
    ),
  },
  {
    type: RG,
    label: 'RG',
    placeholder: '',
    filter: v => (
      (v || '').replace(/[^\d-.]/g, '')
        .replace(/([.-])+/g, '$1')
        .substring(0, 15)
    ),
    sanitizer: v => (
      (v || '').replace(/[^\d.-]/g, '')
        .replace(/([.-])+/g, '$1')
        .replace(/\D$/g, '')
    ),
  },
];
