import {
  LIST_GROUPS,
  CREATE_GROUP,
  UPDATE_GROUP,
} from '@/data/actions/modules/stock';

export default [
  {
    path: '/stock/product-groups',
    component: () => import(/* webpackChunkName: "product-group" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_GROUPS,
    },
  },
  {
    path: '/stock/product-groups/create',
    component: () => import(/* webpackChunkName: "product-group" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_GROUP,
    },
  },
  {
    path: '/stock/product-groups/:id/edit',
    component: () => import(/* webpackChunkName: "product-group" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_GROUP,
    },
  },
];
