<template>
  <div class="page-container dashboard-marketing-page">
    <portal to="page-name">Dashboard</portal>
    <div class="dashboard-title">Dashboard</div>
    <div class="text-info">Em desenvolvimento</div>
<!--    <div class="divider" data-content="Satisfação do paciente" />-->
<!--    <div class="dashboard-tiles">-->
<!--      <div class="tile-item c-hand">-->
<!--        <div class="tile-icon text-secondary">-->
<!--          <fa-icon :icon="['fal', 'smile']"></fa-icon>-->
<!--        </div>-->
<!--        <div class="tile-label">Satisfação do paciente</div>-->
<!--        <div class="tile-value">4,5</div>-->
<!--      </div>-->
<!--      <div class="tile-item c-hand">-->
<!--        <div class="tile-icon text-secondary">-->
<!--          <fa-icon :icon="['fal', 'user-clock']"></fa-icon>-->
<!--        </div>-->
<!--        <div class="tile-label">Qualidade do atendimento</div>-->
<!--        <div class="tile-value">3</div>-->
<!--      </div>-->
<!--      <div class="tile-item c-hand">-->
<!--        <div class="tile-icon text-secondary">-->
<!--          <fa-icon :icon="['fal', 'hospital']"></fa-icon>-->
<!--        </div>-->
<!--        <div class="tile-label">Qualidade da estrutura</div>-->
<!--        <div class="tile-value">3,73</div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="divider" data-content="Agendamentos" />-->
<!--    <div class="dashboard-tiles">-->
<!--      <div class="tile-item c-hand">-->
<!--        <div class="tile-icon text-secondary">-->
<!--          <fa-icon :icon="['fal', 'calendar-check']"></fa-icon>-->
<!--        </div>-->
<!--        <div class="tile-label">Taxa de retorno</div>-->
<!--        <div class="tile-value">4/10</div>-->
<!--      </div>-->
<!--      <div class="tile-item c-hand">-->
<!--        <div class="tile-icon text-secondary">-->
<!--          <fa-icon :icon="['fal', 'calendar-minus']"></fa-icon>-->
<!--        </div>-->
<!--        <div class="tile-label">Taxa de cancelamento</div>-->
<!--        <div class="tile-value">15/180</div>-->
<!--      </div>-->
<!--      <div class="tile-item c-hand">-->
<!--        <div class="tile-icon text-secondary">-->
<!--          <fa-icon :icon="['fal', 'calendar-times']"></fa-icon>-->
<!--        </div>-->
<!--        <div class="tile-label">Taxa de absenteísmo</div>-->
<!--        <div class="tile-value">30/180</div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="divider" data-content="Clínica" />-->
<!--    <div class="dashboard-tiles">-->
<!--      <div class="tile-item c-hand">-->
<!--        <div class="tile-icon text-secondary">-->
<!--          <fa-icon :icon="['fal', 'users-medical']"></fa-icon>-->
<!--        </div>-->
<!--        <div class="tile-label">Novos pacientes</div>-->
<!--        <div class="tile-value">450</div>-->
<!--      </div>-->
<!--      <div class="tile-item c-hand">-->
<!--        <div class="tile-icon text-secondary">-->
<!--          <fa-icon :icon="['fal', 'usd-circle']"></fa-icon>-->
<!--        </div>-->
<!--        <div class="tile-label">Ticket médio</div>-->
<!--        <div class="tile-value">R$150,00</div>-->
<!--      </div>-->
<!--      <div class="tile-item c-hand">-->
<!--        <div class="tile-icon text-secondary">-->
<!--          <fa-icon :icon="['fal', 'calculator']"></fa-icon>-->
<!--        </div>-->
<!--        <div class="tile-label">Custo por paciente</div>-->
<!--        <div class="tile-value">R$80,00</div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="divider" data-content="Gráficos" />-->
<!--    <div class="columns">-->
<!--      <div class="column col-6">-->
<!--        <div class="card chart-card">-->
<!--          <div class="card-body">-->
<!--            <div class="card-title">Atendimentos por sexo/idade</div>-->
<!--            <st-bar-chart-->
<!--              :chart-data="charts.hospitalization.data"-->
<!--              :options="charts.hospitalization.options"-->
<!--              :height="245"/>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="column col-6">-->
<!--        <div class="card chart-card">-->
<!--          <div class="card-body">-->
<!--            <div class="card-title">Investimentos em marketing</div>-->
<!--            <st-line-chart-->
<!--              :chart-data="charts.hospitalization.data"-->
<!--              :options="charts.hospitalization.options"-->
<!--              :height="245"/>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      stats: {
        patients: [
          { name: 'Sergio Rodrigues Pinto', hours: ['14:00', '22:00', '06:00'] },
          { name: 'Rafael Veiga de Moraes', hours: ['08:00', '16:00', '00:00'] },
        ],
      },
      charts: {
        hospitalization: {
          data: {
            labels: [
              '10/2020',
              '11/2020',
              '12/2020',
              '01/2021',
              '02/2021',
              '03/2021',
              '04/2021',
              '05/2021',
              '06/2021',
              '07/2021',
              '08/2021',
              '09/2021',
            ],
            datasets: [{
              label: 'Quantidade',
              data: [30, 20, 22, 40, 35, 55, 60, 68, 90, 95, 80, 100],
              backgroundColor: [
                'rgba(255, 206, 86, 0.2)',
              ],
              borderColor: [
                'rgba(255, 206, 86, 1)',
              ],
              borderWidth: 1,
            }],
          },
          options: {
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                },
              }],
              xAxes: [{
                ticks: {
                  callback: value => value,
                },
                stacked: true,
              }],
            },
          },
        },
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  @import "./src/assets/scss/mixins";

  .dashboard-marketing-page {
    .dashboard-title {
      margin-bottom: $layout-spacing;
      font-size: $line-height;
    }
    .dashboard-tiles {
      display: flex;
      padding: 0 0 $layout-spacing-lg 0;
      color: $gray-color-dark;
      .tile-item {
        background-color: $light-color;
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        flex: 1;
        margin-left: $layout-spacing-lg;
        padding: 20px;
        position: relative;
        text-align: center;
        &:first-child {
          margin-left: 0;
        }
      }
      .tile-icon {
        margin-bottom: $layout-spacing;
        svg {
          display: block;
          height: 1.2rem;
          margin: 0 auto;
          width: auto;
        }
      }
      .tile-label {
        font-size: $font-size-sm;
        line-height: $font-size-sm;
        margin: $layout-spacing-sm 0 calc($layout-spacing-sm / 2) 0;
      }
      .tile-value {
        color: $gray-color-dark;
        font-size: $font-size-lg;
        font-weight: 600;
        line-height: $font-size;
        margin-top: $layout-spacing;
      }
    }
    .chart-card {
      background-color: $light-color;
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      .card-title {
        font-size: $font-size-lg;
        text-align: center;
      }
    }
  }
</style>
