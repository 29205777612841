<template>
  <div class="page-container reports-page">
    <portal to="page-name">Relatórios</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Relatórios</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-4 col-md-12 col-sm-12 form-group">
            <ul class="menu">
              <li class="menu-item" v-for="(menu, i) in menus" :key="i"
                  @click="openPrint(menu.path)">
                {{ menu.label }}
              </li>
            </ul>
          </div>
          <div class="column col-8 col-md-12 col-sm-12 form-group">
            <template v-if="canAccess">
              <appointment-productions-report
                v-if="selected === 'appointment-productions'"
              />
              <exam-production-report
                v-else-if="selected === 'exam-production'"
                :insurances = insurances
                :professionals = professionals
              />
              <indication-report
                v-else-if="selected === 'indication-demonstrative'"
              />
              <appointment-marker-report
                v-else-if="selected === 'appointment-marker-report'"
              />
              <patient-alert-report
                v-else-if="selected === 'patient-alert'"
              />
              <medical-record-scheduled-report
                v-else-if="selected === 'medical-record-scheduled-report'"
              />
              <appointment-by-room-report
                v-else-if="selected === 'appointment-by-room-report'"
              />
            </template>
            <forbidden v-else></forbidden>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { REPORT_INDICATION_RESULTS } from '@/data/actions/modules/financial';
import { PRINT_PATIENT_ALERT } from '@/data/actions/modules/general';
import {
  REPORT_EXAM_PRODUCTION,
  PRINT_MEDICAL_RECORD_SCHEDULED,
  PRINT_APPOINTMENT_PRODUCTION_LIST,
  PRINT_APPOINTMENT_LIST,
  PRINT_APPOINTMENTS_BY_ROOM,
} from '@/data/actions/modules/clinical';
import Forbidden from '@/components/auth/Forbidden.vue';
import ExamProductionReport from './examProductionReport.vue';
import IndicationReport from './indicationReport.vue';
import PatientAlertReport from './patientAlertReport.vue';
import AppointmentProductionsReport from './appointmentProductionsReport.vue';
import AppointmentMarkerReport from './appointmentMarkerReport.vue';
import MedicalRecordScheduledReport from './medicalRecordScheduledReport.vue';
import AppointmentByRoomReport from './AppointmentByRoomReport.vue';

export default {
  components: {
    AppointmentProductionsReport,
    AppointmentMarkerReport,
    AppointmentByRoomReport,
    ExamProductionReport,
    Forbidden,
    IndicationReport,
    PatientAlertReport,
    MedicalRecordScheduledReport,
  },
  data() {
    return {
      selected: 'appointment-productions',
      insurances: [],
      professionals: [],
      expenses: [],
      menus: [
        {
          path: 'appointment-productions',
          label: 'Produtividade dos colaboradores',
          permission: PRINT_APPOINTMENT_PRODUCTION_LIST,
        },
        {
          path: 'indication-demonstrative',
          label: 'Agendamentos por indicação',
          permission: REPORT_INDICATION_RESULTS,
        },
        {
          path: 'appointment-marker-report',
          label: 'Agendamentos por marcadores',
          permission: PRINT_APPOINTMENT_LIST,
        },
        {
          path: 'patient-alert',
          label: 'Alerta de pacientes',
          permission: PRINT_PATIENT_ALERT,
        },
        {
          path: 'exam-production',
          label: 'Produção dos profissionais (Exames)',
          permission: REPORT_EXAM_PRODUCTION,
        },
        {
          path: 'medical-record-scheduled-report',
          label: 'Prontuários finalizados com data de retorno',
          permission: PRINT_MEDICAL_RECORD_SCHEDULED,
        },
        {
          path: 'appointment-by-room-report',
          label: 'Agendamentos por sala',
          permission: PRINT_APPOINTMENTS_BY_ROOM,
        },
      ],
    };
  },
  computed: {
    canAccess() {
      const currentMenu = this.menus.find(({ path }) => path === this.selected);

      if (currentMenu && currentMenu.permission) {
        return this.$can(currentMenu.permission);
      }
      return true;
    },
  },
  methods: {
    openPrint(path) {
      this.selected = path;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .reports-page {
    .menu {
      border-right: $border-width solid $border-color;
      box-shadow: none;
      li {
        padding: $layout-spacing 0;
        border-bottom: $border-width solid $border-color;
        &:hover {
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
</style>
