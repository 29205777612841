import Ably from 'ably/promises';
import { ablyApiKey } from '@/config';

let instance;

class AblyService {
  constructor(userId) {
    this.userId = userId;
    this.realtime = new Ably.Realtime({
      key: ablyApiKey,
      clientId: userId,
    });
  }

  static instance(userId = null) {
    if (!instance && userId) {
      instance = new AblyService(userId);
    }
    return instance;
  }
}

export default AblyService;
