import Dialog from 'src/components/modal/Dialog.vue';

let dialog;

function show(title, params = {}) {
  const localParams = Object.assign(
    {
      type: 'success', // success|danger|neutral
      closable: true,
      html: null,
      buttons: null,
    },
    params,
  );

  dialog.show = true;
  dialog.title = title;
  dialog.type = localParams.type;
  dialog.closable = localParams.closable;
  dialog.html = localParams.html;
  dialog.buttons = localParams.buttons;

  return dialog;
}

function close() {
  dialog.show = false;
}

function mount(Vue) {
  const DialogClass = Vue.extend(Dialog);
  dialog = new DialogClass();
  dialog.$mount();
  dialog.$on('close', close);
}

export default function install(Vue) {
  mount(Vue);

  Object.defineProperty(Vue.prototype, '$dialog', {
    get() {
      return { show, close };
    },
  });
}
