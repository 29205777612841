<template>
  <div class="logout-page">
    <div class="inner-content">
      <div>
        <img src="../../../assets/svg/logo-stenci.svg" width="150" alt="stenci"/>
      </div>
      <small>Saíndo do sistema, aguarde...</small>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Logout',
  mounted() {
    this.logout()
      .then(() => this.$router.push({ path: '/login' }));
  },
  methods: {
    ...mapActions(['logout']),
  },
};
</script>

<style lang="scss">
  @import './src/assets/scss/_variables.scss';

  .logout-page {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    .inner-content {
      text-align: center;
    }
  }
</style>
