// import {
//   LIST_PHONEBOOKS,
//   CREATE_PHONEBOOK,
//   UPDATE_PHONEBOOK,
// } from '@/data/actions/modules/general';

export default [
  {
    path: '/audits',
    component: () => import(/* webpackChunkName: "audits" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      // permission: LIST_PHONEBOOKS,
    },
  },
];
