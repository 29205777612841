<template>
  <component
    :is="hasCurrent ? 'chat-window' : 'chat-list'"
    class="chat-container"
    v-show="show"
  />
</template>

<script>
import {
  mapActions,
  mapState,
  mapMutations,
  mapGetters,
} from 'vuex';
import ChatList from '@/components/chat/List.vue';
import ChatWindow from '@/components/chat/Window.vue';
import AblyService from '@/services/ably';

export default {
  components: {
    ChatList,
    ChatWindow,
  },
  data() {
    return {
      search: '',
      message: '',
      messages: [],
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState({
      user: ({ auth }) => auth.user,
      show: ({ chat }) => chat.show,
    }),
    ...mapGetters({
      current: 'chatCurrent',
      encodeChannel: 'encodeChannel',
    }),
    hasCurrent() {
      return !!this.current;
    },
  },
  methods: {
    ...mapMutations({
      setChats: 'Chat.SET_CHATS',
    }),
    ...mapActions({
      newChannel: 'newChatChannel',
      newMessage: 'newChatMessage',
    }),
    async init() {
      const { realtime } = AblyService.instance(this.user.id);

      await realtime.channels
        .get(this.encodeChannel(this.user.id))
        .subscribe(this.eventListener);

      try {
        const { data } = await this.$http.get('/chat/chats');

        this.setChats(data.items.map(item => ({
          id: item.channelId,
          lastMessage: item.lastMessage,
          members: Array.isArray(item.members) && item.members > 0
            ? item.members
            : [
              { id: item.id, name: item.name },
              { id: this.user.id, name: this.user.name },
            ],
        })));
      } catch (e) {
        this.$toast.error(e);
      }
    },
    eventListener({ name, data }) {
      if (name === 'new_message') {
        this.newMessage(data);
      } else if (name === 'new_channel') {
        this.newChannel(data);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

.chat-container.panel {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  top: calc(72px + 1rem);
  width: 18rem;
  z-index: $zindex-1;
  border: 1px solid $info-color;

  .panel-header {
    align-items: center;
    background-color: lighten($info-color, 5%);
    display: flex;
    color: #fff;
    justify-content: space-between;
    .panel-title {
      font-size: $font-size-lg;
      padding-left: $layout-spacing-lg;
      padding-right: $layout-spacing-lg;
    }
    .btn {
      background-color: transparent;
      border: 0;
      color: #fff;
      &:active, &:focus {
        box-shadow: none;
      }
    }
  }
  .panel-body {
    @include scroll-bar();
    padding: $layout-spacing-lg $layout-spacing-lg 0 $layout-spacing-lg;
    position: relative;
  }

  .panel-footer {
    border-top: $border-width solid $border-color;
    .form-group {
      flex: 0 0 auto;
      .form-input {
        height: 2rem;
      }
    }
  }
}
</style>
