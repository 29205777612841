// import { MANAGE_APPOINTMENT_SETTINGS } from '@/data/actions/modules/clinical';
// import Others from './components/Others.vue';

const meta = {
  layout: 'master',
  requiresAuth: true,
  // permission: MANAGE_APPOINTMENT_SETTINGS,
};

export default [
  {
    path: '/others',
    component: () => import(/* webpackChunkName: "others" */ './components/Main.vue'),
    children: [
      // { path: '', component: Others, meta },
    ],
    meta,
  },
];
