import { withParams } from 'vuelidate/lib';
import moment from 'moment';

export default min => withParams({ type: 'minHour', min }, (value) => {
  if (!value) {
    return true;
  }

  return moment(value, 'HH:mm:ss')
    .isSameOrAfter(moment(min || value, 'HH:mm:ss'), 'seconds');
});
