export const accidentIndications = {
  work: 'Trabalho',
  transit: 'Trânsito',
  not_accident: 'Não acidente',
  others: 'Outros',
};

export function getName(value) {
  return accidentIndications[value];
}
