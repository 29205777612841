<template>
  <nav>
    <router-link to="/stock">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'tachometer-alt']"/>
      </div>
      <span class="side-menu-label">Dashboard</span>
    </router-link>
    <router-link to="/stock/balances">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'balance-scale']"/>
      </div>
      <span class="side-menu-label">Consulta Saldo</span>
    </router-link>
    <router-link to="/stock/entries">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'inbox-in']"/>
      </div>
      <span class="side-menu-label">Entradas</span>
    </router-link>
    <router-link to="/stock/outputs">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'inbox-out']"/>
      </div>
      <span class="side-menu-label">Saídas</span>
    </router-link>
    <router-link to="/stock/reports">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'print']"/>
      </div>
      <span class="side-menu-label">Relatórios</span>
    </router-link>
    <router-link to="/stock/others">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'ellipsis-h-alt']"/>
      </div>
      <span class="side-menu-label">Outros recursos</span>
    </router-link>
  </nav>
</template>

<script>
export default {};
</script>
