export const serviceRegimes = {
  outpatient: 'Ambulatorial',
  home: 'Domiciliar',
  hospitalization: 'Internação',
  emergency: 'Pronto-socorro',
  telemedicine: 'Telemedicina',
};

export function getName(value) {
  return serviceRegimes[value];
}
