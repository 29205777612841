export const consultationTypes = {
  first: 'Primeira consulta',
  return: 'Retorno',
  forwarding: 'Por encaminhamento',
  prenatal: 'Pré-natal',
};

export function getName(value) {
  return consultationTypes[value];
}
