<template>
  <div class="page-container">
    <portal to="page-name">Caixa</portal>
    <div class="card card-page" v-if="hasAccess">
      <div class="card-header">
        <h1 class="card-title">Receita/Despesa</h1>
      </div>
      <div class="card-body" v-if="loading">
        <div class="loading"></div>
      </div>
      <div class="card-body" v-if="!loading && openedCashiers.length > 0">
        <div class="columns">
          <div class="column col-12" v-if="form.cashier.id">
            <div class="cash-flow-summary">
              Saldo atual: {{ getBalance() | currency }}
            </div>
          </div>
          <div class="column col-3 form-group" :class="{'has-error': $v.form.cashier.id.$error}">
            <label>Caixa</label>
            <select class="form-select" v-model="form.cashier.id"
                    @blur="$v.form.cashier.id.$touch()"
                    @change="loadCashierSettings">
              <option value="">Selecione um caixa</option>
              <option v-for="(item, i) in openedCashiers" :value="item.id" :key="i">
                {{ item.bankAccount.name }}
              </option>
            </select>
            <template v-if="$v.form.cashier.id.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.cashier.id.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-3 form-group" :class="{'has-error': $v.form.operation.$error}">
            <label>Operação</label>
            <select class="form-select" v-model="form.operation" @blur="$v.form.operation.$touch()"
                    @change="loadCashierSettings">
              <option value="">Selecione uma operação</option>
              <option value="revenue">Receita</option>
              <option value="expense">Despesa</option>
            </select>
            <template v-if="$v.form.operation.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.operation.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-6 form-group" :class="{'has-error': $v.form.category.id.$error}">
            <label>Categoria</label>
            <select class="form-select" v-model="form.category.id"
                    @blur="$v.form.category.id.$touch()">
              <option value="">Selecione uma categoria</option>
              <option v-for="(item, i) in filteredCategories" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
            <template v-if="$v.form.category.id.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.category.id.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-9 form-group"
               :class="{'has-error': $v.form.entity.id.$error}">
            <label class="form-label">Favorecido/Pagador</label>
            <dx-autocomplete
              id="entity-name"
              v-model="entity"
              :readonly="!!form.entity.id"
              :disabled="!form.operation"
              :source="findEntity"
              label="name"
              track-by="id"
              @select="setEntity"
              :debounce="800"
              @blur="$v.form.entity.$touch()"
              placeholder="Nome do paciente, data de nascimento ou CPF"
            >
              <button slot="action" @click="unsetEntity"
                      class="btn btn-action input-group-btn btn-icon"
                      :class="entity ? 'btn-gray' : 'btn-neutral'"
                      tabindex="-1">
                <fa-icon :icon="['fal', entity ? 'times' : 'search']"></fa-icon>
              </button>
              <template v-slot="{ item }">
                <div>{{ item.name }}</div>
                <small class="text-primary" v-if="item.id === null">
                  Favorecido/Pagador não encontrado
                </small>
              </template>
            </dx-autocomplete>
            <template v-if="$v.form.entity.id.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.entity.id.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-3 form-group" :class="{'has-error': $v.form.value.$error}"
            style="margin-top: 3px">
            <label>Valor</label>
            <dx-input-number id="value" v-model="form.value" class="form-input text-right"
                             :precision="2" maxlength="10" @blur="$v.form.value.$touch()"/>
            <template v-if="$v.form.value.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.value.minValue">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-12 form-group">
            <label for="notes">Observação</label>
            <textarea id="notes" class="form-input" type="text" v-model="form.notes" rows="2" />
          </div>
          <div class="column col-1">
            <button class="btn btn-primary" @click="save" :disabled="saving" :class="{saving}">
              Salvar
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" v-else-if="!loading && openedCashiers.length === 0">
        <div class="empty mt-2">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Nenhum caixa aberto</p>
          <p class="empty-subtitle">
            Para realizar a abertura do caixa selecione a opção "Abertura" do menu
          </p>
        </div>
      </div>
    </div>
    <forbidden v-else></forbidden>
  </div>
</template>

<script>
import moment from 'moment';
import { LIST_CASHIER_FINANCIAL_TRANSACTIONS } from '@/data/actions/modules/financial';
import { required, minValue } from 'vuelidate/lib/validators';
import Forbidden from '@/components/auth/Forbidden.vue';

export default {
  data() {
    return {
      loading: false,
      saving: false,
      cashiers: [],
      categories: [],
      openedCashiers: [],
      entity: null,
      form: {
        entity: {
          id: '',
          name: '',
          type: '',
          plan: {
            id: '',
            name: '',
          },
        },
        category: {
          id: '',
        },
        cashier: {
          id: '',
        },
        operation: '',
        value: 0,
        notes: '',
      },
    };
  },
  components: {
    Forbidden,
  },
  computed: {
    hasAccess() {
      return this.$can(LIST_CASHIER_FINANCIAL_TRANSACTIONS);
    },
    filteredCategories() {
      return this.categories.filter(item => item.type === this.form.operation);
    },
  },
  validations() {
    const rules = {
      form: {
        entity: {
          id: { required },
        },
        operation: { required },
        cashier: {
          id: { required },
        },
        category: {
          id: { required },
        },
        value: {
          required,
          minValue: minValue(0.01),
        },
      },
    };
    return rules;
  },
  async mounted() {
    if (this.hasAccess) {
      this.loading = true;
      await this.getCashiers();
      await this.getOpenCashiers();
      await this.getCategories();
      if (this.openedCashiers.length === 1) {
        this.form.cashier.id = this.openedCashiers[0].id;
        this.loadCashierSettings();
      }
    }
    this.loading = false;
  },
  methods: {
    async getCashiers() {
      await this.$http.get('/cashiers/accounts')
        .then(({ data }) => {
          this.cashierSettings = data.items.map(cashier => ({
            // eslint-disable-next-line no-underscore-dangle
            id: cashier.bankAccount._id,
            name: cashier.bankAccount.name,
            categories: cashier.categories,
          }));
        })
        .catch(() => {});
    },
    async getOpenCashiers() {
      const params = {
        limit: 0,
      };
      await this.$http.get('/cashiers?status=open', { params })
        .then(({ data }) => {
          this.openedCashiers = data.items;
          this.openedCashiers.forEach((openCashier) => {
            const cashier = this.cashierSettings
              .find(item => openCashier.bankAccount.id === item.id);
            if (cashier) {
              openCashier.categories = cashier.categories;
              openCashier.enabled = true;
            } else {
              openCashier.enabled = false;
            }
          });
          this.openedCashiers = this.openedCashiers
            .filter(openCashier => openCashier.enabled === true);
        })
        .catch(() => {});
    },
    async getCategories() {
      const params = {
        active: true,
        limit: 0,
      };

      await this.$http.get('/categories', { params })
        .then(({ data }) => {
          if (data.items) {
            this.categories = data.items;
          }
        })
        .catch(() => {});
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      if (this.form.operation === 'expense' && this.getBalance() < this.form.value) {
        this.$toast.show(
          'Não foi possível realizar a operação solicitada, saldo insuficiente!',
          { type: 'error' },
        );
        return;
      }
      this.saving = true;
      const cashier = this.openedCashiers.find(item => item.id === this.form.cashier.id);
      const category = this.categories.find(item => item.id === this.form.category.id);

      if (this.form.operation === 'expense' && this.form.value > cashier.balance) {
        this.$toast.show('Saldo insuficiente para efetuar a operação solicitada', { type: 'error' });
        return;
      }

      const data = {
        cashierId: this.form.cashier.id,
        operation: this.form.operation,
        bankAccount: cashier.bankAccount,
        entity: this.form.entity,
        category: {
          id: category.id,
          name: category.name,
        },
        value: this.form.value,
        notes: this.form.notes,
      };

      this.$http.post('/cashiers/financial', data)
        .then(() => {
          this.openedCashiers.forEach((item) => {
            if (item.id === this.form.cashier.id) {
              item.balance += (this.form.operation === 'revenue'
                ? this.form.value : this.form.value * -1);
            }
          });
          this.clearForm();
          this.$toast.show('Lançamento realizado com sucesso!');
        })
        .catch(() => {
          this.$toast.show('Não foi possível realizar a operação solicitada', { type: 'error' });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    clearForm() {
      this.form.cashier.id = '';
      this.form.operation = '';
      this.form.value = 0;
      this.form.category.id = '';
      this.form.notes = '';
      this.unsetEntity();
      this.$v.form.$reset();
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    findEntity(search) {
      if (!this.form.operation) {
        return [];
      }

      const params = {
        status: 'active',
        search: `^${search}`,
        type: this.form.operation,
      };

      return this.$http.get('/financial-transactions/entities', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        })
        .catch(() => {});
    },
    setEntity(data) {
      if (data) {
        this.form.entity = data;
      } else {
        this.form.entity = {
          id: '',
          name: '',
          type: '',
          plan: {
            id: '',
            name: '',
          },
        };
      }
    },
    unsetEntity() {
      this.setEntity(null);
      this.entity = null;
    },
    getBalance() {
      if (!this.form.cashier.id) return 0;
      const cashier = this.openedCashiers.find(item => item.id === this.form.cashier.id);
      return cashier.balance;
    },
    loadCashierSettings() {
      if (this.form.cashier.id) {
        const cashier = this.openedCashiers.find(item => item.id === this.form.cashier.id);
        if (cashier && this.form.operation === 'revenue') {
          if (cashier.categories && cashier.categories.revenue
            // eslint-disable-next-line no-underscore-dangle
            && cashier.categories.revenue._id) {
            // eslint-disable-next-line no-underscore-dangle
            this.form.category.id = cashier.categories.revenue._id;
          } else {
            this.form.category.id = '';
          }
        }

        if (cashier && this.form.operation === 'expense') {
          if (cashier.categories && cashier.categories.expense
            // eslint-disable-next-line no-underscore-dangle
            && cashier.categories.expense._id) {
            // eslint-disable-next-line no-underscore-dangle
            this.form.category.id = cashier.categories.expense._id;
          } else {
            this.form.category.id = '';
          }
        }
      }
    },
  },
};
</script>
