<template>
  <div class="side-page">
    <div class="side-page-side">
      <slot name="side"></slot>
    </div>
    <div class="side-page-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
};
</script>

<style lang="scss">
  @import '~assets/scss/variables';
  @import '~assets/scss/mixins';

  .side-page {
    display: flex;
    height: 100%;
    .side-page-side, .side-page-content {
      background-color: #F6F9FA;
      overflow-y: auto;
      @include scroll-bar();
    }
    .side-page-side {
      padding: 0 .5rem 0 .5rem;
      height: 100%;
      background-color: #fff;
      flex: 0 0 15rem;
      border-right: $border-width solid $border-color;
      .menu {
        border: 0;
        box-shadow: none;
        .menu-item {
          align-items: center;
          border-top: 0.05rem solid $gray-color-light;
          display: flex;
          justify-content: space-between;
          padding: $layout-spacing 0;
          &:first-child {
            border-top: 0;
          }
          a:hover {
            background: none;
          }
          a:active, a:focus {
            background: none;
            box-shadow: none;
          }
        }
      }
    }
    .side-page-content {
      flex: 1;
    }
  }
</style>
