import {
  LIST_INSURANCES,
  CREATE_INSURANCE,
  UPDATE_INSURANCE,
} from '@/data/actions/modules/general';

export default [
  {
    path: '/clinical/insurances',
    component: () => import(/* webpackChunkName: "insurances" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_INSURANCES,
    },
  },
  {
    path: '/clinical/insurances/create',
    component: () => import(/* webpackChunkName: "insurances" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_INSURANCE,
    },
  },
  {
    path: '/clinical/insurances/:id/edit',
    component: () => import(/* webpackChunkName: "insurances" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_INSURANCE,
    },
  },
];
