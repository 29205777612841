<template>
  <div class="chat-list panel">
    <div class="panel-header">
      <button class="btn btn-action btn-icon" @click="toggleSearch">
        <fa-icon :icon="['fal', 'search']" />
      </button>
      <div class="panel-title text-ellipsis">Mensagens</div>
      <button class="btn btn-action btn-icon" @click="hide">
        <fa-icon :icon="['fal', 'times']" />
      </button>
    </div>

    <div class="panel-body">
      <div class="form-group input-group search-box" v-if="showSearch">
        <input type="text" v-model="search" class="form-input" v-focus>
        <button class="btn btn-gray input-group-btn btn-icon" @click="closeSearch">
          <fa-icon :icon="['fal', 'times']" />
        </button>
      </div>

      <list-item
        v-for="item in availableChats"
        :item="item"
        :key="item.key"
        @select="onSelect"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import ListItem from '@/components/chat/list/Item.vue';

export default {
  components: {
    ListItem,
  },
  data() {
    return {
      search: '',
      showSearch: false,
    };
  },
  computed: {
    ...mapState({
      chats: ({ chat }) => chat.chats,
    }),
    availableChats() {
      const search = this.search.trim();
      const searchRegex = new RegExp(search, 'i');
      const baseDate = new Date(2000, 0, 1);

      return this.chats
        .filter(({ members }) => {
          if (!search) {
            return true;
          }
          return members.some(({ name }) => searchRegex.test(name));
        })
        .sort((a, b) => {
          const compareA = (a.lastMessage ? new Date(a.lastMessage.date) : baseDate).getTime();
          const compareB = (b.lastMessage ? new Date(b.lastMessage.date) : baseDate).getTime();

          if (compareA > compareB) return -1;
          if (compareA < compareB) return 1;

          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;

          return 0;
        });
    },
  },
  methods: {
    ...mapMutations({
      hide: 'Chat.HIDE',
      setCurrent: 'Chat.SET_CURRENT',
    }),
    onSelect(data) {
      this.setCurrent(data);
    },
    toggleSearch() {
      if (this.showSearch) {
        this.closeSearch();
      } else {
        this.openSearch();
      }
    },
    openSearch() {
      this.showSearch = true;
    },
    closeSearch() {
      this.search = '';
      this.showSearch = false;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

.chat-list.panel {
  .panel-body {
    .search-box {
      margin-bottom: $layout-spacing-lg;
    }
    .chat-list-item {
      padding-bottom: $layout-spacing-lg;
    }
  }
}
</style>
