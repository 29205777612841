import Opening from './components/Opening.vue';
import Closure from './components/Closure.vue';
import Attendance from './components/Attendance.vue';
import Operation from './components/Operation.vue';
import Financial from './components/Financial.vue';
import Forward from './components/Forward.vue';
import CashFlow from './components/CashFlow.vue';
import Configuration from './components/Configuration.vue';

const meta = {
  layout: 'master',
  requiresAuth: true,
};

export default [
  {
    path: '/cashier',
    component: () => import(/* webpackChunkName: "opening" */ './components/Main.vue'),
    children: [
      { path: '', component: Opening, meta },
    ],
    meta,
  },
  {
    path: '/cashier-opening',
    component: () => import(/* webpackChunkName: "cashier-opening" */ './components/Main.vue'),
    children: [
      { path: '', component: Opening, meta },
    ],
    meta,
  },
  {
    path: '/cashier-closure',
    component: () => import(/* webpackChunkName: "cashier-closure" */ './components/Main.vue'),
    children: [
      { path: '', component: Closure, meta },
    ],
    meta,
  },
  {
    path: '/cashier-attendances',
    component: () => import(/* webpackChunkName: "cashier-attendances" */ './components/Main.vue'),
    children: [
      { path: '', component: Attendance, meta },
    ],
    meta,
  },
  {
    path: '/cashier-financial',
    component: () => import(/* webpackChunkName: "cashier-financial" */ './components/Main.vue'),
    children: [
      { path: '', component: Financial, meta },
    ],
    meta,
  },
  {
    path: '/cashier-operations',
    component: () => import(/* webpackChunkName: "cashier-operations" */ './components/Main.vue'),
    children: [
      { path: '', component: Operation, meta },
    ],
    meta,
  },
  {
    path: '/cashier-cash-flow',
    component: () => import(/* webpackChunkName: "cashier-cash-flow" */ './components/Main.vue'),
    children: [
      { path: '', component: CashFlow, meta },
    ],
    meta,
  },
  {
    path: '/cashier-referrals',
    component: () => import(/* webpackChunkName: "cashier-referral" */ './components/Main.vue'),
    children: [
      { path: '', component: Forward, meta },
    ],
    meta,
  },
  {
    path: '/cashier-configuration',
    component: () => import(/* webpackChunkName: "cashier-configuration" */ './components/Main.vue'),
    children: [
      { path: '', component: Configuration, meta },
    ],
    meta,
  },
];
