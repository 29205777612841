<template>
  <div class="billing-professional-transfer-page">
    <h6>Relação de repasses</h6>
    <div class="columns">
      <div class="column col-3 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.startDate.$error}">
        <label class="form-label">Data início</label>
        <div class="input-group">
          <dx-input-date
            id="appointment-patient-birth-date"
            class="form-input text-center"
            v-model="filters.startDate"
            @blur="$v.filters.startDate.$touch()"
          />
        </div>
        <template v-if="$v.filters.startDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.startDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.startDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-3 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.endDate.$error}">
        <label class="form-label">Data término</label>
        <dx-input-date
          id="end-date"
          class="form-input text-center"
          v-model="filters.endDate"
          @blur="$v.filters.endDate.$touch()"
        />
        <template v-if="$v.filters.endDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.endDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.endDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-6 col-md-4 col-sm-12 form-group">
        <label class="form-label">Profissional</label>
        <select id="report-professional" class="form-select"
                v-model="filters.professionalId">
          <option value="">[Todos os profissionais]</option>
          <option v-for="(item, i) in professionals" :value="item.id" :key="i">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="column col-6 col-md-4 col-sm-12 form-group">
        <label class="form-label">Agrupado por</label>
        <select id="group" class="form-select" v-model="filters.group">
          <option value="insurance">Convênio</option>
          <option value="professional">Profissional</option>
          <option value="expense">Procedimento</option>
        </select>
      </div>
      <div class="column col-2 col-md-4 col-sm-12 form-group">
        <label class="form-label">Situação</label>
        <select id="paid" class="form-select" v-model="filters.paid">
          <option value="">Todos</option>
          <option value="true">Pagos</option>
          <option value="false">Pendentes</option>
        </select>
      </div>
      <div class="column col-2 col-md-4 col-sm-12 form-group">
        <label class="form-label">Retornos</label>
        <select id="return" class="form-select" v-model="filters.return">
          <option value="true">Exibir</option>
          <option value="false">Não</option>
        </select>
      </div>
      <div class="column col-2 col-md-4 col-sm-12 form-group">
        <label class="form-label">Cortesias</label>
        <select id="courtesy" class="form-select" v-model="filters.courtesy">
          <option value="true">Exibir</option>
          <option value="false">Não</option>
        </select>
      </div>
      <div class="column col-12 col-md-12 col-sm-12 form-group">
        <label class="form-label">Status da fatura</label>
        <div class="btn-group btn-group-block">
          <button class="btn btn-icon-left"
                  :class="hasStatusOpen ? 'btn-gray' : 'btn-neutral'"
                  @click="setStatus('open')">
            <fa-icon :icon="['fal', 'check']" v-if="hasStatusOpen" />Aberto
          </button>
          <button class="btn btn-icon-left"
                  :class="hasStatusInBilling ? 'btn-gray' : 'btn-neutral'"
                  @click="setStatus('in_billing')">
            <fa-icon :icon="['fal', 'check']" v-if="hasStatusInBilling" />Faturando
          </button>
          <button class="btn btn-icon-left"
                  :class="hasStatusClosed ? 'btn-gray' : 'btn-neutral'"
                  @click="setStatus('closed')">
            <fa-icon :icon="['fal', 'check']" v-if="hasStatusClosed" />Fechada
          </button>
          <button class="btn btn-icon-left"
                  :class="hasStatusInAppeal ? 'btn-gray' : 'btn-neutral'"
                  @click="setStatus('in_appeal')">
            <fa-icon :icon="['fal', 'check']" v-if="hasStatusInAppeal" />Recurso
          </button>
          <button class="btn btn-icon-left"
                  :class="hasStatusFinished ? 'btn-gray' : 'btn-neutral'"
                  @click="setStatus('finished')">
            <fa-icon :icon="['fal', 'check']" v-if="hasStatusFinished" />Finalizada
          </button>
        </div>
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-primary btn-icon-left"
              :class="{loading: printing}"
              @click="print" :disabled="printing">
        <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';
import * as professionalApi from '@/app/professional/api';

export default {
  data() {
    return {
      printing: false,
      professionals: [],
      filters: {
        startDate: '',
        endDate: '',
        professionalId: '',
        group: 'professional',
        return: false,
        courtesy: false,
        statuses: ['finished'],
        paid: '',
      },
    };
  },
  validations() {
    const rules = {
      filters: {
        startDate: { date, required },
        endDate: { date, required },
      },
    };
    if (this.filters.startDate && this.filters.startDate.length === 10) {
      rules.filters.endDate = {
        date,
        required,
        minDate: minDate(this.filters.startDate),
      };
    }

    return rules;
  },
  mounted() {
    this.filters.startDate = moment()
      .startOf('month')
      .format('YYYY-MM-DD');
    this.filters.endDate = moment()
      .endOf('month')
      .format('YYYY-MM-DD');
    this.loadProfessionals();
  },
  computed: {
    hasStatusOpen() {
      return this.filters.statuses.find(item => item === 'open');
    },
    hasStatusInBilling() {
      return this.filters.statuses.find(item => item === 'in_billing');
    },
    hasStatusClosed() {
      return this.filters.statuses.find(item => item === 'closed');
    },
    hasStatusInAppeal() {
      return this.filters.statuses.find(item => item === 'in_appeal');
    },
    hasStatusFinished() {
      return this.filters.statuses.find(item => item === 'finished');
    },
  },
  methods: {
    print() {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return false;
      }
      const duration = moment.duration(moment(this.filters.endDate)
        .diff(moment(this.filters.startDate))).asMonths();

      if (duration > 4) {
        this.$toast.show('Período limite (4 meses)', { type: 'error' });
        return false;
      }

      this.printing = true;

      const params = {
        group: this.filters.group,
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
        return: this.filters.return,
        statuses: this.filters.statuses.join(','),
      };

      if (this.filters.professionalId) {
        params.professionalId = this.filters.professionalId;
      }

      if (this.filters.paid !== '') {
        params.paid = this.filters.paid;
      }

      if (this.filters.return !== '') {
        params.return = this.filters.return;
      }

      if (this.filters.courtesy !== '') {
        params.courtesy = this.filters.courtesy;
      }

      return this.$file
        .print('/professional-transfer/print', params)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
    loadProfessionals() {
      professionalApi.allActive()
        .then((data) => {
          this.professionals = data.items;
        })
        .catch(this.$toast.error);
    },
    setStatus(status) {
      const found = this.filters.statuses.find(item => item === status);
      if (found) {
        if (this.filters.statuses.length > 1) {
          this.filters.statuses.splice(this.filters.statuses.indexOf(found), 1);
        }
      } else {
        this.filters.statuses.push(status);
      }
    },
  },
};
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  .billing-professional-transfer-page {
    .footer {
      margin-top: $layout-spacing-lg;
      text-align: right;
    }
  }
</style>
