import {
  LIST_BALANCES,
} from '@/data/actions/modules/stock';

export default [
  {
    path: '/stock/balances',
    component: () => import(/* webpackChunkName: "stock-balances" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_BALANCES,
    },
  },
];
