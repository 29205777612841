import { randomHash } from '@/helpers';

export default {
  'Auth.SET_USER': function setUser(state, user) {
    state.user = user;
  },
  'Auth.SET_TOKEN': function setToken(state, token) {
    state.token = token;
  },
  'Chat.TOGGLE': function toggleChat(state) {
    state.chat.show = !state.chat.show;
  },
  'Chat.SHOW': function showChat(state) {
    state.chat.show = true;
  },
  'Chat.HIDE': function hideChat(state) {
    state.chat.show = false;
  },
  'Chat.SET_CHATS': function setChats(state, chats = []) {
    state.chat.chats = chats.map((item) => {
      item.unread = false;
      item.current = false;
      item.key = randomHash();
      return item;
    });
  },
  'Chat.SET_CURRENT': function setChatCurrent(state, data) {
    state.chat.chats.forEach((item) => {
      item.current = false;
      if (data && item.key === data.key) {
        item.current = true;
        item.unread = false;
      }
    });
  },
};
