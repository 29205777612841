export const LIST_BALANCES = 'stock:listBalances';

export const LIST_PRICE_TABLES = 'stock:listPriceTables';
export const CREATE_PRICE_TABLE = 'stock:createPriceTable';
export const UPDATE_PRICE_TABLE = 'stock:updatePriceTable';
export const DELETE_PRICE_TABLE = 'stock:deletePriceTable';
export const PRINT_PRICE_TABLE = 'stock:printPriceTable';
export const PRINT_PRICE_TABLE_LIST = 'stock:printPriceTableList';

export const LIST_PRODUCTS = 'stock:listProducts';
export const CREATE_PRODUCT = 'stock:createProduct';
export const UPDATE_PRODUCT = 'stock:updateProduct';
export const DELETE_PRODUCT = 'stock:deleteProduct';
export const PRINT_PRODUCT_LIST = 'stock:printProductList';

export const LIST_QUOTATIONS = 'stock:listQuotations';
export const CREATE_QUOTATION = 'stock:createQuotation';
export const UPDATE_QUOTATION = 'stock:updateQuotation';
export const DELETE_QUOTATION = 'stock:deleteQuotation';
export const PRINT_QUOTATION = 'stock:printQuotation';
export const PRINT_QUOTATION_LIST = 'stock:printQuotationList';
export const FINISH_QUOTATION = 'stock:finishQuotation';

export const LIST_ENTRIES = 'stock:listEntries';
export const CREATE_ENTRY = 'stock:createEntry';
export const UPDATE_ENTRY = 'stock:updateEntry';
export const DELETE_ENTRY = 'stock:deleteEntry';
export const PRINT_ENTRY = 'stock:printEntry';
export const PRINT_ENTRY_LIST = 'stock:printEntryList';
export const FINISH_ENTRY = 'stock:finishEntry';

export const LIST_OUTPUTS = 'stock:listOutputs';
export const CREATE_OUTPUT = 'stock:createOutput';
export const UPDATE_OUTPUT = 'stock:updateOutput';
export const DELETE_OUTPUT = 'stock:deleteOutput';
export const PRINT_OUTPUT = 'stock:printOutput';
export const PRINT_OUTPUT_LIST = 'stock:printOutputList';
export const FINISH_OUTPUT = 'stock:finishOutput';

export const LIST_INVENTORIES = 'stock:listInventories';
export const CREATE_INVENTORY = 'stock:createInventory';
export const UPDATE_INVENTORY = 'stock:updateInventory';
export const DELETE_INVENTORY = 'stock:deleteInventory';
export const PRINT_INVENTORY = 'stock:printInventory';
export const FINISH_INVENTORY = 'stock:finishInventory';
export const PRINT_INVENTORY_LIST = 'stock:printInventoryList';

export const LIST_GROUPS = 'stock:listGroups';
export const CREATE_GROUP = 'stock:createGroup';
export const UPDATE_GROUP = 'stock:updateGroup';
export const DELETE_GROUP = 'stock:deleteGroup';
export const PRINT_GROUP_LIST = 'stock:printGroupList';

export const LIST_FAMILIES = 'stock:listFamilies';
export const CREATE_FAMILY = 'stock:createFamily';
export const UPDATE_FAMILY = 'stock:updateFamily';
export const DELETE_FAMILY = 'stock:deleteFamily';
export const PRINT_FAMILY_LIST = 'stock:printFamilyList';

export const LIST_OPERATION_TYPES = 'stock:listOperationTypes';
export const CREATE_OPERATION_TYPE = 'stock:createOperationType';
export const UPDATE_OPERATION_TYPE = 'stock:updateOperationType';
export const DELETE_OPERATION_TYPE = 'stock:deleteOperationType';
export const PRINT_OPERATION_TYPE_LIST = 'stock:printOperationTypeList';

export const LIST_KITS = 'stock:listKits';
export const CREATE_KIT = 'stock:createKit';
export const UPDATE_KIT = 'stock:updateKit';
export const DELETE_KIT = 'stock:deleteKit';
export const PRINT_KIT = 'stock:printKit';
export const DUPLICATE_KIT = 'stock:duplicateKit';

export const REPORT_PRODUCT_MANAGEMENT = 'stock:reportProductManagement';
export const REPORT_STOCK_ENTRY = 'stock:reportStockEntry';
export const REPORT_STOCK_OUTPUT = 'stock:reportStockOutput';
export const REPORT_STOCK_PRODUCT_TRACEABILITY = 'stock:reportStockProductTraceability';

export default [
  {
    action: LIST_BALANCES,
    name: 'Consulta Saldo: Listar',
  },
  {
    action: LIST_PRICE_TABLES,
    name: 'Tabela de preços: Listar',
  },
  {
    action: CREATE_PRICE_TABLE,
    name: 'Tabela de preços: Criar',
  },
  {
    action: UPDATE_PRICE_TABLE,
    name: 'Tabela de preços: Atualizar',
  },
  {
    action: DELETE_PRICE_TABLE,
    name: 'Tabela de preços: Excluir',
  },
  {
    action: PRINT_PRICE_TABLE,
    name: 'Tabela de preços: Imprimir',
  },
  {
    action: PRINT_PRICE_TABLE_LIST,
    name: 'Tabela de preços: Imprimir lista',
  },
  {
    action: LIST_PRODUCTS,
    name: 'Produtos: Listar',
  },
  {
    action: CREATE_PRODUCT,
    name: 'Produtos: Criar',
  },
  {
    action: UPDATE_PRODUCT,
    name: 'Produtos: Atualizar',
  },
  {
    action: DELETE_PRODUCT,
    name: 'Produtos: Excluir',
  },
  {
    action: PRINT_PRODUCT_LIST,
    name: 'Produtos: Imprimir lista',
  },
  {
    action: LIST_QUOTATIONS,
    name: 'Cotações: Listar',
  },
  {
    action: CREATE_QUOTATION,
    name: 'Cotações: Criar',
  },
  {
    action: UPDATE_QUOTATION,
    name: 'Cotações: Atualizar',
  },
  {
    action: DELETE_QUOTATION,
    name: 'Cotações: Excluir',
  },
  {
    action: PRINT_QUOTATION,
    name: 'Cotações: Imprimir',
  },
  {
    action: PRINT_QUOTATION_LIST,
    name: 'Cotações: Imprimir lista',
  },
  {
    action: FINISH_QUOTATION,
    name: 'Cotações: Finalizar',
  },
  {
    action: LIST_ENTRIES,
    name: 'Entradas: Listar',
  },
  {
    action: CREATE_ENTRY,
    name: 'Entradas: Criar',
  },
  {
    action: UPDATE_ENTRY,
    name: 'Entradas: Atualizar',
  },
  {
    action: DELETE_ENTRY,
    name: 'Entradas: Excluir',
  },
  {
    action: PRINT_ENTRY,
    name: 'Entradas: Imprimir',
  },
  {
    action: PRINT_ENTRY_LIST,
    name: 'Entradas: Imprimir lista',
  },
  {
    action: FINISH_ENTRY,
    name: 'Entradas: Finalizar',
  },
  {
    action: LIST_OUTPUTS,
    name: 'Saídas: Listar',
  },
  {
    action: CREATE_OUTPUT,
    name: 'Saídas: Criar',
  },
  {
    action: UPDATE_OUTPUT,
    name: 'Saídas: Atualizar',
  },
  {
    action: DELETE_OUTPUT,
    name: 'Saídas: Excluir',
  },
  {
    action: PRINT_OUTPUT,
    name: 'Saídas: Imprimir',
  },
  {
    action: PRINT_OUTPUT_LIST,
    name: 'Saídas: Imprimir lista',
  },
  {
    action: FINISH_OUTPUT,
    name: 'Saídas: Finalizar',
  },
  {
    action: LIST_INVENTORIES,
    name: 'Inventários: Listar',
  },
  {
    action: CREATE_INVENTORY,
    name: 'Inventários: Criar',
  },
  {
    action: UPDATE_INVENTORY,
    name: 'Inventários: Atualizar',
  },
  {
    action: DELETE_INVENTORY,
    name: 'Inventários: Excluir',
  },
  {
    action: FINISH_INVENTORY,
    name: 'Inventários: Finalizar',
  },
  {
    action: PRINT_INVENTORY,
    name: 'Inventários: Imprimir',
  },
  {
    action: PRINT_INVENTORY_LIST,
    name: 'Inventários: Imprimir lista',
  },
  {
    action: LIST_GROUPS,
    name: 'Grupos: Listar',
  },
  {
    action: CREATE_GROUP,
    name: 'Grupos: Criar',
  },
  {
    action: UPDATE_GROUP,
    name: 'Grupos: Atualizar',
  },
  {
    action: DELETE_GROUP,
    name: 'Grupos: Excluir',
  },
  {
    action: PRINT_GROUP_LIST,
    name: 'Grupos: Imprimir lista',
  },
  {
    action: LIST_FAMILIES,
    name: 'Famílias: Listar',
  },
  {
    action: CREATE_FAMILY,
    name: 'Famílias: Criar',
  },
  {
    action: UPDATE_FAMILY,
    name: 'Famílias: Atualizar',
  },
  {
    action: DELETE_FAMILY,
    name: 'Famílias: Excluir',
  },
  {
    action: PRINT_FAMILY_LIST,
    name: 'Famílias: Imprimir lista',
  },
  {
    action: LIST_KITS,
    name: 'Kits: Listar',
  },
  {
    action: CREATE_KIT,
    name: 'Kits: Criar',
  },
  {
    action: UPDATE_KIT,
    name: 'Kits: Atualizar',
  },
  {
    action: DELETE_KIT,
    name: 'Kits: Excluir',
  },
  {
    action: PRINT_KIT,
    name: 'Kits: Imprimir',
  },
  {
    action: DUPLICATE_KIT,
    name: 'Kits: Duplicar',
  },
  {
    action: LIST_OPERATION_TYPES,
    name: 'Tipos de operação: Listar',
  },
  {
    action: CREATE_OPERATION_TYPE,
    name: 'Tipos de operação: Criar',
  },
  {
    action: UPDATE_OPERATION_TYPE,
    name: 'Tipos de operação: Atualizar',
  },
  {
    action: DELETE_OPERATION_TYPE,
    name: 'Tipos de operação: Excluir',
  },
  {
    action: PRINT_OPERATION_TYPE_LIST,
    name: 'Tipos de operação: Imprimir lista',
  },
  {
    action: REPORT_PRODUCT_MANAGEMENT,
    name: 'Relatórios: Movimentação de produtos',
  },
  {
    action: REPORT_STOCK_ENTRY,
    name: 'Relatórios: Entrada de produtos',
  },
  {
    action: REPORT_STOCK_OUTPUT,
    name: 'Relatórios: Saída de produtos',
  },
  {
    action: REPORT_STOCK_PRODUCT_TRACEABILITY,
    name: 'Relatórios: Rastreabilidade de produtos',
  },
];
