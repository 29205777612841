import {
  LIST_PHONEBOOKS,
  CREATE_PHONEBOOK,
  UPDATE_PHONEBOOK,
} from '@/data/actions/modules/general';

export default [
  {
    path: '/phone-books',
    component: () => import(/* webpackChunkName: "phone-books" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_PHONEBOOKS,
    },
  },
  {
    path: '/phone-books/create',
    component: () => import(/* webpackChunkName: "phone-books" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_PHONEBOOK,
    },
  },
  {
    path: '/phone-books/:id/edit',
    component: () => import(/* webpackChunkName: "phone-books" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_PHONEBOOK,
    },
  },
];
