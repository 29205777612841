<template>
  <div class="card-detail-item">
    <small class="card-detail-item-title" v-if="title">{{ title }}</small>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.card-detail-item {
  margin-bottom: $layout-spacing;
  .card-detail-item-title {
    color: $gray-color-dark;
    display: block;
  }
}
</style>
