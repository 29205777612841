function avatarLetters(name) {
  if (!name) return '';

  const parts = name
    .toUpperCase()
    .replace(/[^A-Z ]/g, '')
    .trim()
    .split(/\s/);

  return parts[0][0] + (parts.length > 1 ? parts[parts.length - 1][0] : '');
}

export default function install(Vue) {
  Object.defineProperty(Vue.prototype, '$avatarLetters', {
    get() {
      return avatarLetters;
    },
  });
}
