<template>
  <div class="has-icon-right">
    <input
      :type="visible ? 'text' : 'password'"
      class="form-input"
      v-bind="$attrs"
      :value="value"
      @input="$emit('input', $event.target.value)"
    >
    <span class="c-hand" @click="visible = !visible" v-if="value">
      <fa-icon
        class="form-icon"
        :icon="['fal', visible ? 'eye' : 'eye-slash']"
      />
    </span>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: String,
  },
  data() {
    return {
      visible: false,
    };
  },
};
</script>
