export function clearNonNumbers(value, defaultValue = null) {
  switch (typeof value) {
    case 'string':
      value = value.replace(/\D/g, '');
      return value === '' ? defaultValue : value;
    case 'number':
      return value.toString();
    default:
      return defaultValue;
  }
}

export function clearNonChars(text) {
  return text ? text.replace(/[^a-zA-Z0-9]/g, ' ').trim() : '';
}

export function removeAccentuation(text) {
  if (!text) return '';

  return text
    .replace(/[ÁÀÂÃ]/g, 'A')
    .replace(/[áàâã]/g, 'a')
    .replace(/[ÉÈÊ]/g, 'E')
    .replace(/[éèê]/g, 'e')
    .replace(/[ÍÌÎ]/g, 'I')
    .replace(/[íìî]/g, 'i')
    .replace(/[ÓÒÔÕ]/g, 'O')
    .replace(/[óòôõ]/g, 'o')
    .replace(/[ÚÙÛÜ]/g, 'U')
    .replace(/[úùûü]/g, 'u')
    .replace(/[Ç]/g, 'C')
    .replace(/[ç]/g, 'c');
}
