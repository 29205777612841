import { show as showToast } from '@/plugins/toast';

export default (http, router) => {
  http.interceptors.response.use(
    response => response,
    (error) => {
      if (error.response) {
        const { status } = error.response;
        if (status === 401) {
          router.push({ path: '/login' });
        } else if (status === 403) {
          showToast('Acesso negado', { type: 'error', timeout: 7000 });
        }
      }

      return Promise.reject(error);
    },
  );
};
