import moment from 'moment';
import localforage from 'localforage';
import { httpX } from '@/plugins/http';
import { clone } from '@/helpers/object';

export async function loadAttendanceFilters({ state, rootGetters }, type = 'elective') {
  const params = {
    limit: 0,
    active: true,
    type,
  };

  if (rootGetters.isProfessionalOnly) {
    params.isProfessional = rootGetters.isProfessionalOnly;
  }

  httpX.get('/schedules', { params })
    .catch(() => ({ items: [] }))
    .then(({ data }) => {
      state.filter.schedules = data.items;
      if (type === 'surgery' && data.items.length > 0) {
        state.filter.scheduleId = data.items[0].id;
      } else if (!data.items.some(({ id }) => id === state.filter.scheduleId)) {
        state.filter.scheduleId = '';
      }
    });
}

export async function loadAttendanceCalendar({
  commit, state, rootState, rootGetters,
}) {
  commit('Attendance.SET_LOADING', true);

  const params = {
    date: moment(state.filter.date).format('YYYY-MM-DD'),
  };

  if (state.filter.scheduleId) {
    params.scheduleId = state.filter.scheduleId;
  }

  if (state.filter.professionalId) {
    params.professionalId = state.filter.professionalId;
  } else if (rootGetters.isProfessionalOnly) {
    params.professionalId = rootState.auth.user.id;
  }

  if (state.filter.status) {
    params.status = state.filter.status;
  }

  httpX.get('/calendar', { params })
    .then(({ data }) => {
      commit('Attendance.SET_ITEMS', data.items);
    })
    .catch(() => {
      commit('Attendance.SET_ITEMS', []);
    })
    .then(() => {
      commit('Attendance.SET_LOADING', false);
    });
}

export async function loadAttendanceConfig({ state, commit }, openModal = true) {
  state.config.processing = true;

  if (!state.config.loaded) {
    const data = await localforage.getItem('attendanceConfig');
    if (data) {
      commit('Attendance.UPDATE_CONFIG', data);
    }
    state.config.loaded = true;
  }

  state.config.processing = false;

  if (state.config.form.placeId && state.config.form.roomName) {
    return state.config.form;
  }

  if (openModal) {
    state.config.show = true;
  }

  return null;
}

export async function saveAttendanceConfig({ state, commit }, data) {
  state.config.processing = true;

  await localforage.setItem('attendanceConfig', clone(data));
  commit('Attendance.UPDATE_CONFIG', data);

  state.config.processing = false;
}
