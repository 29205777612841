import {
  LIST_BANK_ACCOUNTS,
  CREATE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT,
} from '@/data/actions/modules/financial';

export default [
  {
    path: '/financial/bank-accounts',
    component: () => import(/* webpackChunkName: "bank-accounts" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_BANK_ACCOUNTS,
    },
  },
  {
    path: '/financial/bank-accounts/create',
    component: () => import(/* webpackChunkName: "bank-accounts" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_BANK_ACCOUNT,
    },
  },
  {
    path: '/financial/bank-accounts/:id/edit',
    component: () => import(/* webpackChunkName: "bank-accounts" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_BANK_ACCOUNT,
    },
  },
];
