import Main from './components/Main.vue';

const meta = {
  layout: 'master',
  requiresAuth: true,
};

export default [
  {
    path: '/stock/reports',
    component: () => import(/* webpackChunkName: "stock-reports" */ './components/Main.vue'),
    children: [
      { path: '', component: Main, meta },
    ],
    meta,
  },
];
