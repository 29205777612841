export default (value, precision = 2) => {
  value = Number(value);

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) {
    value = 0;
  }

  return new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  })
    .format(value);
};
