export const LIST_ASOS = 'occupational:listAsos';
export const CREATE_ASO = 'occupational:createAso';
export const UPDATE_ASO = 'occupational:updateAso';
export const DELETE_ASO = 'occupational:deleteAso';
export const PRINT_ASO = 'stock:printAso';

export const LIST_CLIENTS = 'occupational:listClients';
export const CREATE_CLIENT = 'occupational:createClient';
export const UPDATE_CLIENT = 'occupational:updateClient';

export default [
  {
    action: LIST_ASOS,
    name: 'ASO: Listar',
  },
  {
    action: CREATE_ASO,
    name: 'ASO: Criar',
  },
  {
    action: UPDATE_ASO,
    name: 'ASO: Atualizar',
  },
  {
    action: DELETE_ASO,
    name: 'ASO: Excluir',
  },
  {
    action: PRINT_ASO,
    name: 'ASO: Imprimir',
  },
  {
    action: LIST_CLIENTS,
    name: 'Empresas: Listar',
  },
  {
    action: CREATE_CLIENT,
    name: 'Empresas: Criar',
  },
  {
    action: UPDATE_CLIENT,
    name: 'Empresas: Atualizar',
  },
];
