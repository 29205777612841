export const types = {
  evaluation: 'Avaliação',
  surgery: 'Cirurgia',
  consultation: 'Consulta',
  daily: 'Diária',
  exam: 'Exame',
  gas: 'Gás',
  laboratory: 'Laboratorial',
  material: 'Material',
  medicine: 'Medicamento',
  opme: 'OPME',
  procedure: 'Procedimento',
  session: 'Sessão',
  tax: 'Taxa',
  others: 'Outros',
};

export function getName(value) {
  return types[value];
}
