<template>
  <div class="form-group">
    <component
      :is="inputComponent"
      :label="label"
      :value="value"
      :options="options"
      :disabled="disabled"
      :readonly="readonly"
      v-bind="params"
      @input="onInput"
    />
  </div>
</template>

<script>
import FormInputBoolean from 'src/components/form-inputs/Boolean.vue';
import FormInputText from 'src/components/form-inputs/Text.vue';
import FormInputChoice from 'src/components/form-inputs/Choice.vue';

const TYPES = {
  boolean: 'form-input-boolean',
  text: 'form-input-text',
  choice: 'form-input-choice',
};

export default {
  props: {
    type: {
      type: String,
      default: 'form-input-text',
    },
    label: String,
    value: [Boolean, String, Array],
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FormInputBoolean,
    FormInputText,
    FormInputChoice,
  },
  mounted() {},
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
  },
  computed: {
    inputComponent() {
      return this.type in TYPES ? TYPES[this.type] : 'text';
    },
  },
};
</script>
