import { AUTHORIZE_DASHBOARD } from '@/data/actions/modules/clinical';
import Main from './components/Main.vue';
import Clinical from './components/Clinical.vue';
import Tutorial from './components/Tutorial.vue';

export default [
  {
    path: '/',
    component: Main,
    meta: {
      layout: 'master',
      requiresAuth: true,
    },
  },
  {
    path: '/tutorial',
    component: Tutorial,
    meta: {
      layout: 'master',
      requiresAuth: true,
    },
  },
  {
    path: '/clinical/indicators',
    component: Clinical,
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: AUTHORIZE_DASHBOARD,
    },
  },
];
