<template>
  <div class="login-page">
    <form @submit.stop.prevent="submit">
      <h2>Faça seu login</h2>
      <span>Insira seus dados abaixo</span>

      <div class="form-body">
        <div class="form-group" :class="{ 'has-error': $v.form.username.$error }">
          <label class="form-label" for="username">Usuário</label>
          <input
            v-model.trim="form.username"
            id="username"
            class="form-input input-lg"
            placeholder="Email, CPF ou CNPJ"
          />
          <template v-if="$v.form.username.$error">
            <div
              class="form-input-hint"
              v-if="!$v.form.username.required"
            >Campo obrigatório</div>
          </template>
        </div>
        <div class="form-group" :class="{ 'has-error': $v.form.password.$error }">
          <label class="form-label" for="password">Senha</label>
          <input
            v-model.trim="form.password"
            type="password"
            id="password"
            class="form-input input-lg"
            placeholder="Senha"
          />
          <template v-if="$v.form.password.$error">
            <div
              class="form-input-hint"
              v-if="!$v.form.password.required"
            >Campo obrigatório</div>
          </template>
        </div>
        <label class="form-switch">
          <input type="checkbox" v-model="form.remember"/>
          <i class="form-icon"/> Manter-me logado
        </label>
      </div>

      <button
        type="submit"
        class="btn btn-lg btn-primary btn-block"
        :class="{ loading }"
        :disabled="loading"
      >Login</button>

      <router-link
        class="btn btn-link btn-block mt-2"
        to="/password-reset"
      >Esqueci minha senha</router-link>
    </form>
  </div>
</template>

<script>
import localforage from 'localforage';
import { mapActions } from 'vuex';
import { customLogo } from '@/config';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'Login',
  data() {
    return {
      form: {
        username: '',
        password: '',
        remember: true,
      },
      loading: false,
      customLogo: customLogo(),
    };
  },
  validations: {
    form: {
      username: { required },
      password: { required },
    },
  },
  mounted() {
    localforage.getItem('login')
      .then((login) => {
        const data = login || {};
        this.form.remember = data.remember;
        this.form.username = data.username;
        document.querySelector(`#${data.username ? 'password' : 'username'}`).focus();
      });
  },
  methods: {
    ...mapActions(['attemptLogin', 'loadUser']),
    validate() {
      this.$v.$touch();
      return !this.$v.$error;
    },
    submit(e) {
      if (!this.validate()) return;

      this.loading = true;

      localforage.setItem('login', {
        username: this.form.remember ? this.form.username : '',
        remember: this.form.remember,
      });

      this.$toast.close();

      const { username, password } = this.form;

      this.attemptLogin({ username, password })
        .then(() => this.$router.push({ path: '/home' }))
        .catch(() => this.$toast.show('Dados de login inválidos', { type: 'error' }))
        .then(() => {
          this.loading = false;
        });

      e.preventDefault();
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
</style>
