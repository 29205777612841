import moment from 'moment';
import { modules } from '@/data/modules';
import { can } from '@/permission';
import { UPDATE_BRANCH, UPDATE_PERMISSION_GROUP } from '@/data/actions/modules/general';
import { MANAGE_APPOINTMENT_SETTINGS } from '@/data/actions/modules/clinical';

export const expiresInDays = ({ user }) => {
  if (user && user.branch && user.branch.expiresAt) {
    return moment(user.branch.expiresAt)
      .diff(moment().startOf('day'), 'days');
  }
  return Infinity;
};

export const branchIsExpired = (state, getters) => (
  getters.expiresInDays < 0
);

export const expiresNotification = (state, getters) => {
  if (getters.expiresInDays <= 30) {
    if (getters.branchIsExpired) {
      return 'O sistema está suspenso. Entre em contato com o administrador.';
    }

    if (!getters.isAdmin) {
      return null;
    }

    let period = `em ${getters.expiresInDays} dias`;
    if (getters.expiresInDays === 1) {
      period = 'amanhã';
    } else if (getters.expiresInDays === 0) {
      period = 'hoje';
    }
    return `O sistema será suspenso ${period}. Entre em contato com o administrador.`;
  }
  return null;
};

export const branchSettings = ({ user }) => (
  user && user.branch ? user.branch.settings : null
);

export const hasRelationship = state => (rel, options = {}) => {
  options = {
    strict: false,
    filter: [],
    ...options,
  };

  if (!state.user || !state.user.branch || !Array.isArray(state.user.branch.rel)) {
    return false;
  }

  const relations = options.filter.length > 0
    ? state.user.branch.rel.filter(v => options.filter.includes(v))
    : state.user.branch.rel;

  const has = relations.includes(rel);
  return options.strict ? has && relations.length === 1 : has;
};

export const isProfessional = (state, getters) => (
  getters.hasRelationship('professional')
);

export const isCollaborator = (state, getters) => (
  getters.hasRelationship('collaborator')
);

export const isProfessionalOnly = (state, getters) => (
  getters.hasRelationship('professional', {
    strict: true,
    filter: ['professional', 'collaborator'],
  })
);

export const isCollaboratorOnly = (state, getters) => (
  getters.hasRelationship('collaborator', {
    strict: true,
    filter: ['professional', 'collaborator'],
  })
);

export const currentModule = (state) => {
  const module = state.module || 'clinical';
  return modules.find(item => item.key === module);
};

export const hasModule = ({ user }) => (name) => {
  if (user && user.branch && user.branch.modules) {
    return user.branch.modules.includes(name);
  }
  return false;
};

export const isAdmin = () => (
  [
    UPDATE_BRANCH,
    UPDATE_PERMISSION_GROUP,
    MANAGE_APPOINTMENT_SETTINGS,
  ].every(can)
);

export const isUserRestricted = ({ user }) => (id) => {
  if (id !== user.id) {
    return [
      '5ed2bcd43bd13d342781ffb4',
      '5ed2bcd53bd13d342781ffb7',
      '5f7f5dc42ed73e36d6381685',
      '605e3053894fc20c5b2be360',
    ].includes(id);
  }
  return false;
};
