<template>
  <div class="page-container help-page">
    <portal to="page-name">Central de Tutoriais em Vídeo</portal>
    <div class="card card-page">
      <div class="card-body">
        <div class="information">
          <div class="information-text">
            <div class="information-title">Central de Tutoriais em Vídeo</div>
            <p>Bem-vindo à nossa Central de Tutoriais em Vídeo!
              Aqui você encontrará uma coleção de vídeos tutoriais que visam facilitar
              sua experiência com o nosso sistema.</p>
            <p>Esses vídeos foram cuidadosamente criados para fornecer
              explicações detalhadas sobre os recursos do sistema. Reserve um tempo para
              explorar e aproveitar ao máximo o potencial do nosso software.</p>
            <strong class="text-info">
              Se ainda estiver com dúvidas, não hesite em entrar em contato com nossa equipe
              de suporte. Estamos aqui para ajudar você a aproveitar ao máximo o sistema.
            </strong>
          </div>
          <div class="information-image">
            <img src="../../../assets/images/login-elements.png" alt="Stenci">
          </div>
        </div>
        <div class="divider" />
        <div class="videos" v-for="(item, i) in videos" :key="i">
          <a :href="item.url" target="_blank" class="video-small">
            <div class="video-inner-small">
              <div class="video-icon">
                <fa-icon :icon="['fas', 'play']"></fa-icon>
              </div>
            </div>
          </a>
          <div>
            <div class="video-title">{{ item.title }}</div>
            <div>{{ item.description }}</div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <label class="form-checkbox">
          <input type="checkbox" />
          <i class="form-icon"/>Não mostrar esta tela na inicialização do sistema
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videos: [
        // {
        //   title: 'Visão geral',
        //   description: `Tenha uma visão geral dos principais recursos do sistema
        //    em um único vídeo explicativo`,
        //   url: '',
        // },
        {
          title: 'Agenda',
          description: `Explore como organizar compromissos e horários com eficiência,
          seja para consultas médicas ou exames`,
          url: 'https://www.youtube.com/watch?v=u0PqDmtcP_Y',
        },
        {
          title: 'Prontuário eletrônico',
          description: `Aprenda a utilizar o prontuário eletrônico do sistema,
           conheça os recursos e como acessar às informações dos pacientes`,
          url: 'https://www.youtube.com/watch?v=_Wc-ywXCwUQ',
        },
        {
          title: 'Gerenciamento da empresa',
          description: `Aprenda configurar sua empresa, configurando elementos como logomarca,
           margens e outras preferências`,
          url: 'https://www.youtube.com/watch?v=y-MAMJAbbos',
        },
        {
          title: 'Cadastrar colaboradores',
          description: `Saiba como cadastrar e gerenciar informações dos colaboradores
          e permissões de acesso`,
          url: 'https://www.youtube.com/watch?v=7-L19Zyr9SY',
        },
        {
          title: 'Cadastrar serviços, taxas e produtos',
          description: 'Explore como adicionar e gerir procedimentos no sistema',
          url: 'https://www.youtube.com/watch?v=phQbWwFlT9M',
        },
        {
          title: 'Cadastrar convênios',
          description: 'Saiba como cadastrar e gerenciar convênios médicos no sistema',
          url: 'https://www.youtube.com/watch?v=vVvWv4vAEKE',
        },
        {
          title: 'Cadastrar profissionais do corpo clínico',
          description: 'Aprenda como cadastrar e gerenciar informações do corpo clínico',
          url: 'https://www.youtube.com/watch?v=vxNPHcV33nQ',
        },
        {
          title: 'Configuração da agenda',
          description: `Descubra como personalizar e configurar as agendas no sistema,
           permitindo uma gestão eficiente dos horários de atendimento`,
          url: 'https://www.youtube.com/watch?v=hmJ3wJMA2y0',
        },
        {
          title: 'Gerenciar modelos de impressos e solicitações',
          description: `Descubra como personalizar e configurar solicitações e impressos,
           permitindo um atendimento eficiente aos pacientes`,
          url: 'https://www.youtube.com/watch?v=Izz-PMsGmzI',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/variables";
  .help-page {
    .information {
      display: grid;
      grid-template-columns: 4fr auto;
      .information-text {
        padding-right: 2rem;
        .information-title {
          font-size: 1.2rem;
          margin-bottom: $layout-spacing-lg;
        }
      }
      .information-image {
        margin-top: $layout-spacing;
        img {
          max-width: 10rem;
        }
      }
    }
    .videos {
      align-items: center;
      display: grid;
      grid-template-columns: auto 1fr;
      margin-top: $layout-spacing-lg;
      .video-title {
        color: $primary-color;
        font-weight: bold;
        font-size: $font-size;
      }
    }
    .video-small {
      background-color: $gray-color-light;
      border-radius: $border-radius;
      display: block;
      height: 2.5rem;
      margin-right: $layout-spacing;
      position: relative;
      width: 4.5rem;
      .video-inner-small {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: $zindex-1;
        &:hover {
          .video-icon svg {
            color: $primary-color-dark;
          }
        }
        .video-icon {
          align-items: center;
          background-color: $light-color;
          border-radius: 50%;
          box-shadow: 0 2px 10px 0 rgba(63, 88, 80, 0.1);
          display: flex;
          height: 1.5rem;
          justify-content: center;
          width: 1.5rem;
          svg {
            color: $secondary-color;
            font-size: .6rem;
            margin-left: .1rem;
            transition: color .15s ease;
          }
        }
      }
    }
  }
</style>
