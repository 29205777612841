// import {
// } from '@/data/actions/modules/financial';

export default [
  {
    path: '/financial/invoices',
    component: () => import(/* webpackChunkName: "budgets" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      // permission: LIST_BUDGETS,
    },
  },
];
