import { LIST_MEDICAL_RECORDS } from '@/data/actions/modules/clinical';

export default [
  {
    path: '/patients/:id/medical-records',
    component: () => import(/* webpackChunkName: "medical-records" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_MEDICAL_RECORDS,
    },
  },
];
