<template>
  <nav>
    <router-link to="/settings">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'ellipsis-h-alt']"/>
      </div>
      <span class="side-menu-label">Outros recursos</span>
    </router-link>
  </nav>
</template>

<script>
export default {};
</script>
