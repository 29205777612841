const meta = {
  layout: 'master',
  requiresAuth: true,
};

export default [
  {
    path: '/financial/',
    component: () => import(/* webpackChunkName: "dashboard" */ './components/Dashboard.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
    },
  },
  {
    path: '/financial/others',
    component: () => import(/* webpackChunkName: "other" */ './components/OtherMain.vue'),
    meta,
  },
  {
    path: '/financial/settings',
    component: () => import(/* webpackChunkName: "settings" */ './components/Settings.vue'),
    meta,
  },
  {
    path: '/clinical-financials',
    component: () => import(/* webpackChunkName: "main" */ './components/FinancialMain.vue'),
    meta,
  },
];
