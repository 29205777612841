import Notification from '@/components/notification/Notification.vue';

let notification;
let timeout;

function close() {
  notification.show = false;
}

function show(title, params = {}) {
  params = Object.assign({
    type: 'success', // success|danger|neutral
    timeout: 3000,
  }, params);

  notification.show = true;
  notification.type = params.type;
  notification.title = title;
  notification.content = params.content || params.body;

  if ('ellipsis' in params) {
    notification.ellipsis = params.ellipsis;
  }

  if (params.timeout) {
    clearTimeout(timeout);
    timeout = setTimeout(close, params.timeout);
  }

  notification.$on('click', () => {
    if ('click' in params) {
      params.click(close);
    }
  });

  return notification;
}

function mount(Vue) {
  const ToastClass = Vue.extend(Notification);
  notification = new ToastClass();
  notification.$mount();
  notification.$on('close', close);
}

export default function install(Vue) {
  mount(Vue);

  Vue.notification = { show, close };

  Object.defineProperty(Vue.prototype, '$notification', {
    get() {
      return { show, close };
    },
  });
}
