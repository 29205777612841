<template>
  <div class="expense-items">
    <div class="h6">Procedimentos</div>
    <button class="btn btn-sm btn-primary btn-icon-left"
            @click="open" v-if="origin === 'sadt'">
      <fa-icon :icon="['fal', 'plus-circle']" />
      Adicionar novo procedimento
    </button>
    <div class="empty" v-if="expenses.length === 0">
      <div class="empty-icon">
        <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
      </div>
      <div class="empty-title h6">Procedimentos</div>
      <div class="empty-subtitle">
        <slot>Nenhum procedimento adicionado a lista!</slot>
      </div>
    </div>
    <template v-else>
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th>#</th>
          <th>Data e hora</th>
          <th>Descrição</th>
          <th class="text-center">Qtde.</th>
          <th class="text-right">Valor Paciente</th>
          <th :class="hideInsuranceValue ? 'text-center' : 'text-right'">
            Valor Convênio
          </th>
          <th class="text-right">Valor Total</th>
          <th class="text-right">Ação</th>
        </tr>
        </thead>
        <expense-item v-for="(expense, i) in expenses"
                      :data="expense"
                      :index="i"
                      :key="i"
                      :hide-insurance-value="hideInsuranceValue"
                      @edit="edit"
                      @remove="remove"
                      @transaction="openTransaction"
        >
        </expense-item>
      </table>
      <div class="text-right text-bold mt-2 mr-2">Total: {{ total | currency }}</div>
    </template>

    <dx-modal id="modal-edit-expense" title="Configurar procedimento" v-model="show" size="lg">
      <div class="columns">
        <div class="column col-12 form-group"
             :class="{'has-error': $v.form.id.$error}">
          <label class="form-label">Procedimento</label>
          <dx-autocomplete
            v-model="expense"
            :source="findExpense"
            label="name"
            track-by="id"
            :debounce="800"
            :disabled="hasExpense"
            @select="addExpense"
            @blur="$v.form.id.$touch()"
            placeholder="Informe o código ou nome da consulta ou outros..."
            input-id="expense-name">
            <button slot="action" v-if="!hasExpense"
                    class="btn btn-action input-group-btn btn-icon btn-neutral"
                    tabindex="-1">
              <fa-icon :icon="['fal', 'search']"></fa-icon>
            </button>
            <button slot="action" v-else
                    class="btn btn-action input-group-btn btn-icon btn-gray"
                    tabindex="-1" @click="clearExpense">
              <fa-icon :icon="['fal', 'times']"></fa-icon>
            </button>
            <template v-slot="{ item }">
              <a>
                <fa-icon :icon="['fal', 'file-medical-alt']" class="text-primary"/>
                {{ item.code | tuss }} - {{ item.expense.name }}
                <small v-if="item.professional"> ({{ item.professional.name }})</small>
              </a>
            </template>
          </dx-autocomplete>
        </div>
        <div class="column col-6 form-group"
             :class="{'has-error': $v.form.professional.id.$error}">
          <label class="form-label">Profissional executante</label>
          <select class="form-select"
                  v-model="form.professional.id"
                  :disabled="!form.id"
                  @change="changedProfessional"
                  @blur="$v.form.professional.id.$touch()">
            <option value="">Selecione um profissional executante</option>
            <option v-for="(item, i) in professionals"
                    :value="item.id" :key="i">
              {{ item.name }} - {{ item.specialty.name }}
            </option>
          </select>
        </div>
        <div class="column col-6 form-group"
             :class="{'has-error': $v.form.professional.accredited.id.$error}">
          <label class="form-label">Profissional credenciado</label>
          <select class="form-select"
                  v-model="form.professional.accredited.id"
                  :disabled="!form.id"
                  @blur="$v.form.professional.accredited.id.$touch()">
            <option value="">Selecione um profissional credenciado</option>
            <option v-for="(item, i) in professionals"
                    :value="item.id" :key="i">
              {{ item.name }} - {{ item.specialty.name }}
            </option>
          </select>
        </div>
        <div class="column col-2 form-group">
          <label class="form-label">Quantidade</label>
          <dx-input-number v-model="form.quantity"
                           :precision="2"
                           :disabled="blockValues"
                           maxlength="10"
                           @blur="recalculate"
                           class="form-input text-center"
          />
        </div>
        <div class="column col-1 form-group"
             :class="{'has-error': $v.form.factor.$error}">
          <label class="form-label">Fator</label>
          <dx-input-number v-model="form.factor"
                           :precision="2"
                           :disabled="blockValues"
                           maxlength="5"
                           @blur="recalculate"
                           class="form-input text-center"
          />
        </div>
        <div class="column col-3 form-group">
          <label class="form-label">Valor Convênio</label>
          <div class="input-lock" v-if="hideInsuranceValue">
            <fa-icon class="text-error" :icon="['fal', 'lock']" />
          </div>
          <dx-input-number v-model="form.value.insurance"
                           class="form-input" v-else
                           :disabled="blockValues"
                           maxlength="10"
                           @blur="recalculate"
                           :precision="2"
          />
        </div>
        <div class="column col-3 form-group">
          <label class="form-label">Valor Paciente</label>
          <dx-input-number v-model="form.value.particular"
                           class="form-input"
                           maxlength="10"
                           :disabled="blockValues"
                           @blur="recalculate"
                           :precision="2"
          />
        </div>
        <div class="column col-3 form-group">
          <label class="form-label">Valor Total</label>
          <dx-input-number class="form-input text-bold"
                           v-model="valueTotal"
                           :precision="2" readonly
          />
        </div>
      </div>
      <small class="text-info text-bold" v-if="blockValues">
        * Os campos valores estão bloqueados conforme regra do plano!
      </small>
      <div class="divider" />
      <div class="columns">
        <div class="column col-3 form-group">
          <label class="form-label">Data e hora inicial</label>
          <div class="input-group"
               :class="{'has-error': $v.form.execution.date.$error
               || $v.form.execution.time.$error}">
            <dx-input-date class="text-center"
                           v-model="form.execution.date"
                           @change="changeStartDateTime"
                           @blur="$v.form.execution.date.$touch()"/>
            <input type="text" class="form-input text-center"
                   v-model="form.execution.time" v-mask="'00:00'"
                   placeholder="00:00"
                   @change="changeStartDateTime"
                   @blur="$v.form.execution.time.$touch()">
          </div>
        </div>
        <div class="column col-2 form-group"
             :class="{'has-error': $v.form.execution.duration.$error}">
          <label class="form-label">Duração (min)</label>
          <input class="form-input text-center"
                 v-model="form.execution.duration"
                 @change="changeStartDateTime"
                 @blur="$v.form.execution.duration.$touch()"
                 v-mask="'000'"
          />
        </div>
        <div class="column col-2 form-group"
             :class="{'has-error': $v.form.way.$error}">
          <label class="form-label">Via</label>
          <select class="form-select" v-model="form.way">
            <option value="">[Selecione]</option>
            <option v-for="(text, value) in ways"
                    :value="value" :key="value">{{ text }}</option>
          </select>
        </div>
        <div class="column col-2 form-group"
             :class="{'has-error': $v.form.technique.$error}">
          <label class="form-label">Técnica</label>
          <select class="form-select" v-model="form.technique">
            <option value="">[Selecione]</option>
            <option v-for="(text, value) in techniques"
                    :value="value" :key="value">{{ text }}</option>
          </select>
        </div>
        <div class="column col-3 form-group"
             :class="{'has-error': $v.form.professional.degreeParticipation.$error}">
          <label class="form-label">Grau de participação</label>
          <select class="form-select"
                  v-model="form.professional.degreeParticipation">
            <option value="">[Selecione]</option>
            <option v-for="(text, value) in degreesParticipation"
                    :value="value" :key="value">{{ text }}</option>
          </select>
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-primary mr-1" @click="save">OK</button>
        <button class="btn" @click="show = false">Sair</button>
      </template>
    </dx-modal>

    <detail-modal
      id="modal-detail"
      v-if="modalDetail.show"
      :show="modalDetail.show"
      :transactionId="modalDetail.id"
      @close="closeModalDetail"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import minValue from 'vuelidate/lib/validators/minValue';
import moment from 'moment';
import { mapActions } from 'vuex';
import { ways } from '@/data/expense-ways';
import { techniques } from '@/data/expense-techniques';
import { degreesParticipation } from '@/data/degrees-participation';
import formMixin from 'src/mixins/form';
import { mergeFrom } from 'src/helpers/object';
import ExpenseItem from './ExpenseItem.vue';
import { date, hour } from '../../../../data/validators';
import DetailModal from '../../transaction/components/Detail.vue';

export default {
  mixins: [formMixin],
  props: {
    origin: {
      type: String,
      default: null,
    },
    source: {
      type: String,
    },
    insurancePlanId: {
      type: String,
    },
    expenses: {
      type: Array,
    },
    generalRules: {
      type: Object,
    },
  },
  components: {
    DetailModal,
    ExpenseItem,
  },
  data() {
    return {
      degreesParticipation,
      techniques,
      ways,
      professionals: [],
      idx: null,
      expense: null,
      form: this.blankForm(),
      total: 0,
      show: false,
      modalDetail: {
        show: false,
        id: '',
      },
    };
  },
  computed: {
    blockValues() {
      return this.generalRules.billing && this.generalRules.billing.blockValues;
    },
    sourceAppointment() {
      return this.source === 'appointments';
    },
    hideInsuranceValue() {
      return this.sourceAppointment
        && this.generalRules.billing
        && this.generalRules.billing.hideInsuranceValue;
    },
    valueTotal() {
      if (this.hideInsuranceValue) {
        return (this.form.value.particular * this.form.factor) * this.form.quantity;
      }
      return this.form.value.total;
    },
    hasExpense() {
      return !!this.form.id;
    },
  },
  validations() {
    const roles = {
      form: {
        id: { required },
        execution: {
          date: { required, date },
          time: { required, hour },
          duration: { required, minValue: minValue(0) },
        },
        professional: {
          id: { required },
          accredited: {
            id: { required },
          },
          degreeParticipation: { required },
        },
        factor: { required },
        technique: {},
        way: {},
      },
    };
    if (this.form.rules.requireWayTechnique) {
      roles.form.way = { required };
      roles.form.technique = { required };
    }
    return roles;
  },
  mounted() {
    this.totalExpenses();
    this.expenses.forEach((item) => {
      if (item.rules
        && item.rules.definition
        && !item.professional.accredited) {
        const found = item.rules.definition.professionals
          .find(professional => professional.id === item.professional.id);
        if (found) {
          if (found.accreditation && found.accreditation.id) {
            item.professional.accredited = found.accreditation;
          } else {
            item.professional.accredited = item.professional;
          }
        } else if (
          item.rules.definition.accreditation
          && item.rules.definition.accreditation.id
        ) {
          item.professional.accredited = item.rules.definition.accreditation;
        }
      }
    });
  },
  methods: {
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    loadProfessionals() {
      return this.apiFetchProfessionals()
        .then((items) => {
          items.forEach((item) => {
            item.professional.specialties.forEach((specialty) => {
              this.professionals.push({
                key: `${item.id}_${specialty.code}`,
                id: item.id,
                name: item.name,
                specialty,
              });
            });
          });
        })
        .catch(() => {});
    },
    open() {
      this.idx = null;
      this.clearExpense();
      this.loadProfessionals()
        .finally(() => {
          this.setWatchers();
        });
      this.show = true;
    },
    edit(idx) {
      this.$v.form.$touch();

      const found = this.expenses[idx];
      this.idx = idx;
      this.form = mergeFrom(this.blankForm(), found);

      this.expense = {
        id: found.id,
        code: found.code,
        name: found.name,
        type: found.type,
      };

      if (!this.form.professional.accredited || !this.form.professional.accredited.id) {
        this.setAccreditedProfessional();
      }

      this.loadProfessionals()
        .finally(() => {
          this.setWatchers();
        });
      this.show = true;
    },
    remove(idx) {
      this.idx = null;
      this.expenses.splice(idx, 1);
      this.totalExpenses();
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      const data = this.clone(this.form);

      if (this.idx === null) {
        this.expenses.push(data);
      } else {
        this.expenses[this.idx] = data;
      }

      this.totalExpenses();

      this.show = false;
    },
    findExpense(search) {
      const params = {
        search,
        insurancePlanId: this.insurancePlanId,
        limit: 30,
        offset: 0,
      };

      return this.$httpX
        .get('/expense-pricing', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{
              id: null,
              expense: {
                id: null,
                type: null,
                name: 'Procedimento não encontrado neste convênio',
              },
              quantity: 0,
              factor: 0,
              definition: {
                insuranceValue: 0,
                particularValue: 0,
              },
            }];
          }
          return data.items;
        });
    },
    clearExpense() {
      this.expense = null;
      this.form = this.blankForm();
      this.$v.form.$reset();
    },
    addExpense(item) {
      if (!item || item.expense.id === null) {
        return;
      }
      this.expense = item.expense;
      this.form.id = item.expense.id;
      this.form.code = item.code;
      this.form.name = item.expense.name;
      this.form.quantity = 1;
      this.form.factor = 1;
      this.form.execution.date = moment().format('YYYY-MM-DD');
      this.form.execution.time = moment().format('HH:mm');
      this.form.value.insurance = item.definition.insuranceValue || 0;
      this.form.value.particular = item.definition.particularValue || 0;
      this.form.value.total = this.form.value.insurance + this.form.value.particular;
      this.form.professional.degreeParticipation = 'clinical';
      if (item.professional) {
        this.form.professional.id = item.professional.id;
        this.form.professional.name = item.professional.name;
        if (item.expense.specialty) {
          this.form.professional.specialty = item.expense.specialty;
        }
      }
      this.changeStartDateTime();
    },
    async closeModalDetail(save) {
      if (save) {
        this.$http.get(`/financial-transactions/${this.modalDetail.id}`)
          .catch((e) => {
            if (e.response && e.response.status === 404) {
              const items = this.expenses
                .filter(({ financialBundleId }) => financialBundleId === this.modalDetail.id);
              items.forEach((item) => {
                item.financialBundleId = null;
                return item;
              });
            }
          });
      }
      this.modalDetail.show = false;
    },
    totalExpenses() {
      const expenses = this.expenses.filter(item => !item.return);
      if (this.hideInsuranceValue) {
        this.total = expenses.reduce((a, b) => (
          a + ((b.value.particular * b.factor) * b.quantity)
        ), 0);
      } else {
        this.total = expenses.reduce((a, b) => a + Number(b.value.total), 0);
      }
    },
    changeStartDateTime() {
      const startDate = this.form.execution.date || moment().format('YYYY-MM-DD');
      const startHour = this.form.execution.time || moment().format('HH:MM');
      const duration = this.form.execution.duration || 30;
      this.form.execution.date = startDate;
      this.form.execution.time = startHour;
      this.form.execution.duration = duration;

      const datetime = `${startDate} ${startHour}`;

      if (!moment(datetime, 'YYYY-MM-DD LT', true).isValid()) {
        return;
      }

      this.form.startDate = moment(datetime).format('YYYY-MM-DD[T]HH:mm');
      this.form.endDate = moment(datetime).add(duration, 'minutes')
        .format('YYYY-MM-DD[T]HH:mm');
    },
    changedProfessional() {
      const params = {
        origin: 'transfer',
        planId: this.insurancePlanId,
        expenseId: this.expense.id,
      };

      return this.$http
        .get(`/professionals/${this.form.professional.id}/expense-prices`, { params })
        .then(({ data }) => {
          if (data && data.items.length > 0) {
            const [item] = data.items;
            this.form.value.insurance = item.definition.insuranceValue || 0;
            this.form.value.particular = item.definition.particularValue || 0;
            this.form.value.total = this.form.value.insurance + this.form.value.particular;
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    openTransaction(id) {
      this.modalDetail.id = id;
      this.modalDetail.show = true;
    },
    recalculate() {
      const quantity = this.form.quantity || 1;
      const factor = this.form.factor || 1;
      const insurance = this.form.value.insurance || 0;
      const particular = this.form.value.particular || 0;

      this.form.quantity = quantity;
      this.form.factor = factor;
      this.form.value.insurance = insurance;
      this.form.value.particular = particular;
      this.form.value.total = ((insurance * factor) + particular) * quantity;
    },
    setWatchers() {
      this.$watch('form.professional.id', function changeExecutantProfessional() {
        this.setExecutantSpecialty();
        this.setAccreditedProfessional();
      });
      this.$watch('form.professional.accredited.id', function changeAccreditedProfessional() {
        this.setAccreditedSpecialty();
      });
    },
    setExecutantSpecialty() {
      const found = this.professionals
        .find(({ id }) => this.form.professional.id === id);
      if (found) {
        this.form.professional.name = found.name;
        this.form.professional.specialty.code = found.specialty.code;
      }
    },
    setAccreditedSpecialty() {
      const found = this.professionals
        .find(({ id }) => this.form.professional.accredited.id === id);
      if (found) {
        this.form.professional.accredited.specialty.code = found.specialty.code;
      }
    },
    setAccreditedProfessional() {
      if (!this.form.professional.id) {
        this.form.professional.accredited = this.blankForm().professional.accredited;
        return;
      }

      let professionalAccredited;

      const definition = this.form.rules && this.form.rules.definition
        ? this.form.rules.definition
        : null;

      if (definition
        && Array.isArray(definition.professionals)
        && definition.professionals.length > 0) {
        const found = definition.professionals
          .find(professional => professional.custom
            && professional.id === this.form.professional.id);
        if (found) {
          if (found.accreditation && found.accreditation.id) {
            professionalAccredited = found.accreditation;
          } else {
            professionalAccredited = this.clone(this.form.professional);
          }
        }
      }

      if (!professionalAccredited && definition && definition.accreditation) {
        professionalAccredited = definition.accreditation;
      }

      if (!professionalAccredited || !professionalAccredited.id) {
        professionalAccredited = this.clone(this.form.professional);
      }

      this.form.professional.accredited = this.clone(professionalAccredited);
    },
    blankForm() {
      return {
        id: '',
        accountId: '',
        startDate: '',
        endDate: '',
        execution: {
          date: '',
          time: '',
          duration: 15,
        },
        code: '',
        name: '',
        rules: {
          requireAuthorization: false,
          requireClinicalIndication: false,
          requireWayTechnique: false,
          definition: {
            accreditation: {
              id: '',
              name: '',
              specialty: {
                code: '',
                name: '',
              },
            },
            professionals: [],
          },
        },
        quantity: 0,
        factor: 1,
        way: '',
        technique: '',
        value: {
          discount: 0,
          interest: 0,
          rate: 0,
          insurance: 0,
          particular: 0,
          total: 0,
        },
        professional: {
          id: '',
          name: '',
          council: {
            name: '',
            record: '',
            state: '',
          },
          specialty: {
            code: '',
            name: '',
          },
          accredited: {
            id: '',
            name: '',
            specialty: {
              code: '',
              name: '',
            },
          },
          degreeParticipation: '',
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";

.expense-items {
  .table th, .table td {
    font-size: $font-size-xs;
  }
  .empty {
    margin: $layout-spacing 0;
    padding: $layout-spacing-xl;
    .empty-icon {
      margin-bottom: $layout-spacing;
    }
    .empty-subtitle {
      margin: 0;
    }
  }
}

#modal-edit-expense {
  .input-lock {
    text-align: center;
    background-color: $gray-color-ultra-light;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    padding: $layout-spacing-sm 0;
  }
}
</style>
