<template>
  <div class="billing-report-page">
    <h6>Demonstrativo de faturamento</h6>
    <div class="columns">
      <div class="column col-3 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.startDate.$error}">
        <label class="form-label">Data início</label>
        <div class="input-group">
          <dx-input-date
            id="appointment-patient-birth-date"
            class="form-input text-center"
            v-model="filters.startDate"
            @blur="$v.filters.startDate.$touch()"
          />
        </div>
        <template v-if="$v.filters.startDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.startDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.startDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-3 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.endDate.$error}">
        <label class="form-label">Data término</label>
        <dx-input-date
          id="end-date"
          class="form-input text-center"
          v-model="filters.endDate"
          @blur="$v.filters.endDate.$touch()"
        />
        <template v-if="$v.filters.endDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.endDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.endDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-3 col-md-4 col-sm-12 form-group">
        <label class="form-label">Agrupado por</label>
        <select id="group" class="form-select" v-model="filters.group">
          <option value="insurance">Convênio</option>
          <option value="professional">Profissional</option>
          <option value="expense">Procedimento</option>
        </select>
      </div>
      <div class="column col-3 col-md-4 col-sm-12 form-group">
        <label class="form-label">Ordenado por</label>
        <select id="group" class="form-select" v-model="filters.sort">
          <option value="date">Data</option>
          <option value="patient">Paciente</option>
        </select>
      </div>
      <div class="column col-6 col-md-6 col-sm-12 form-group">
        <label class="form-label">Status da conta</label>
        <select class="form-select" v-model="filters.status">
          <option value="">[Todas as contas]</option>
          <option value="open">Contas em aberto (Agendamento)</option>
          <option value="in_billing">Contas sendo revisadas (Faturando)</option>
          <option value="closed">Contas fechadas (Envio do XML)</option>
          <option value="finished">Contas finalizadas (Repasse)</option>
          <option value="in_appeal">Contas sendo recursadas (Recurso)</option>
          <option value="canceled">Contas canceladas</option>
        </select>
      </div>
      <div class="column col-6 col-md-6 col-sm-12 form-group">
        <label class="form-label">Repasse</label>
        <select class="form-select" v-model="filters.paid">
          <option value="">[Todos]</option>
          <option value="true">Apenas os realizado</option>
          <option value="false">Apenas não realizado</option>
        </select>
      </div>
      <div class="column col-auto">
        <label class="form-switch">
          <input type="checkbox" v-model="filters.return">
          <i class="form-icon"></i>Exibir retornos
        </label>
      </div>
      <div class="column">
        <label class="form-switch">
          <input type="checkbox" v-model="filters.courtesy">
          <i class="form-icon"></i>Exibir cortesias
        </label>
      </div>
    </div>
    <div class="billing-list">
      <div class="billing-scroll-list-wrapper">
        <div class="scroll-list">
          <div class="list-header">
            <label class="form-checkbox">
              <input type="checkbox"
                     @change="selectAllInsurances"
                     :checked="getInsurancesSelected"
                     :indeterminate.prop="getInsurancesSelected === null">
              <i class="form-icon"></i>
            </label>
            <div class="list-title">Convênios</div>
            <div class="input-group">
              <input type="text" class="form-input"
                     v-model="filters.insurance"
                     placeholder="Filtrar convênio">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1" v-if="!filters.insurance.trim()">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <button class="btn btn-gray input-group-btn btn-icon"
                      @click="filters.insurance = ''"
                      tabindex="-1" v-else>
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </div>
          </div>
          <table class="table table-hover">
            <tbody>
            <tr v-for="(item, i) in filteredInsurances" :key="i">
              <td width="30px">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>{{ item.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="scroll-list-wrapper">
        <div class="scroll-list">
          <div class="list-header">
            <label class="form-checkbox">
              <input type="checkbox"
                     @change="selectAllProfessionals"
                     :checked="getProfessionalsSelected"
                     :indeterminate.prop="getProfessionalsSelected === null">
              <i class="form-icon"></i>
            </label>
            <div class="list-title">Profissionais</div>
            <div class="input-group">
              <input type="text" class="form-input"
                     v-model="filters.professional"
                     placeholder="Filtrar profissional">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1" v-if="!filters.professional.trim()">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <button class="btn btn-gray input-group-btn btn-icon"
                      @click="filters.professional = ''"
                      tabindex="-1" v-else>
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </div>
          </div>
          <table class="table table-hover">
            <tbody>
            <tr v-for="(item, i) in filteredProfessionals" :key="i">
              <td width="30px">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>{{ item.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="scroll-list-wrapper">
        <div class="scroll-list">
          <div class="list-header">
            <div class="columns">
              <div class="column col-12">
                <label class="form-checkbox">
                  <input type="checkbox"
                         @change="selectAllExpenses"
                         :checked="getExpensesSelected"
                         :indeterminate.prop="getExpensesSelected === null">
                  <i class="form-icon"></i>
                </label>
              </div>
            </div>
            <div class="column">
              <select class="form-select"
                      v-model="filters.modality">
                <option value="">[Todas as modalidades]</option>
                <option v-for="(text, value, i) in modalities"
                        :value="value" :key="i">{{ value }} - {{ text }}</option>
              </select>
            </div>
            <div class="column col-5">
              <div class="input-group">
                <input type="text" class="form-input"
                       v-model="filters.expense"
                       placeholder="Filtrar procedimento">
                <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                        tabindex="-1" v-if="!filters.expense.trim()">
                  <fa-icon :icon="['fal', 'search']"/>
                </button>
                <button class="btn btn-gray input-group-btn btn-icon"
                        @click="filters.expense = ''"
                        tabindex="-1" v-else>
                  <fa-icon :icon="['fal', 'times']"/>
                </button>
              </div>
            </div>
          </div>
          <table class="table table-hover">
            <tbody>
            <tr v-for="(item, i) in filteredExpenses" :key="i">
              <td width="30px">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>{{ item.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-gray btn-icon btn-icon-left mr-1"
              :class="{loading: printing}"
              :disabled="printing"
              @click="print('sheet')">
        <fa-icon :icon="['fal', 'file-excel']"/>Gerar planilha
      </button>
      <button class="btn btn-primary btn-icon-left"
              :class="{loading: printing}"
              :disabled="printing"
              @click="print('pdf')">
        <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';
import fuzzy from 'fuzzy';
import formMixin from '@/mixins/form';
import { modalities } from '@/data/expense-modalities';
import * as professionalApi from '@/app/professional/api';

export default {
  mixins: [formMixin],
  data() {
    return {
      printing: false,
      insuranceItems: [],
      professionalItems: [],
      expenseItems: [],
      modalities,
      filters: {
        type: 'detailed',
        group: 'insurance',
        sort: 'date',
        paid: '',
        return: false,
        courtesy: false,
        status: '',
        modality: '',
        startDate: '',
        endDate: '',
        expense: '',
        insurance: '',
        professional: '',
      },
    };
  },
  validations() {
    const rules = {
      filters: {
        startDate: { date, required },
        endDate: { date, required },
      },
    };
    if (this.filters.startDate) {
      rules.filters.endDate = {
        date,
        required,
        minDate: minDate(this.filters.startDate),
      };
    }

    return rules;
  },
  mounted() {
    this.filters.startDate = moment()
      .startOf('month')
      .format('YYYY-MM-DD');
    this.filters.endDate = moment()
      .endOf('month')
      .format('YYYY-MM-DD');
    this.loadInsurances();
    this.loadProfessionals();
    this.loadExpenses();
  },
  computed: {
    getInsurancesSelected() {
      if (!this.filteredInsurances || this.filteredInsurances.length === 0) {
        return false;
      }

      const insurancesSelected = this.filteredInsurances.filter(item => item.selected);
      if (insurancesSelected.length === 0) {
        return false;
      }
      if (insurancesSelected.length === this.filteredInsurances.length) {
        return true;
      }
      return null;
    },
    getProfessionalsSelected() {
      if (!this.filteredProfessionals || this.filteredProfessionals.length === 0) {
        return false;
      }

      const professionalsSelected = this.filteredProfessionals.filter(item => item.selected);
      if (professionalsSelected.length === 0) {
        return false;
      }
      if (professionalsSelected.length === this.filteredProfessionals.length) {
        return true;
      }
      return null;
    },
    getExpensesSelected() {
      if (!this.filteredExpenses || this.filteredExpenses.length === 0) {
        return false;
      }

      const expensesSelected = this.filteredExpenses.filter(item => item.selected);
      if (expensesSelected.length === 0) {
        return false;
      }
      if (expensesSelected.length === this.filteredExpenses.length) {
        return true;
      }
      return null;
    },
    filteredExpenses() {
      let { expenseItems } = this;

      if (this.filters.modality) {
        expenseItems = expenseItems
          .filter(({ modality }) => modality === this.filters.modality);
      }

      if (this.filters.expense.trim()) {
        const results = fuzzy.filter(this.filters.expense, expenseItems, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        expenseItems = results.map(({ original }) => original);
      }

      return expenseItems;
    },
    filteredProfessionals() {
      let { professionalItems } = this;

      if (this.filters.professional.trim()) {
        const results = fuzzy.filter(this.filters.professional, professionalItems, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        professionalItems = results.map(({ original }) => original);
      }

      return professionalItems;
    },
    filteredInsurances() {
      let { insuranceItems } = this;

      if (this.filters.insurance.trim()) {
        const results = fuzzy.filter(this.filters.insurance, insuranceItems, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        insuranceItems = results.map(({ original }) => original);
      }

      return insuranceItems;
    },
  },
  methods: {
    selectAllInsurances(e) {
      this.filteredInsurances.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    selectAllProfessionals(e) {
      this.filteredProfessionals.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    selectAllExpenses(e) {
      this.filteredExpenses.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.insuranceItems = data.items.map(item => ({
            id: item.plan.id,
            name: item.customName,
            selected: true,
          }));
        })
        .catch(() => {});
    },
    loadProfessionals() {
      return professionalApi.allActive()
        .then((data) => {
          this.professionalItems = data.items.map(item => ({
            id: item.id,
            name: item.name,
            selected: true,
          }));
        })
        .catch(this.$toast.error);
    },
    loadExpenses() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$httpX.get('/expenses', { params })
        .then(({ data }) => {
          this.expenseItems = data.items.map(item => ({
            id: item.id,
            name: item.name,
            modality: item.modality,
            selected: true,
          }));
        })
        .catch(() => {});
    },
    print(printType) {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return false;
      }

      this.printing = true;

      const params = {
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
        type: this.filters.type,
        group: this.filters.group,
        return: this.filters.return,
        sort: this.filters.sort,
        printType,
      };

      if (this.filters.paid !== '') {
        params.paid = this.filters.paid;
      }

      if (this.filters.courtesy !== '') {
        params.courtesy = this.filters.courtesy;
      }

      if (this.filters.status) {
        params.status = this.filters.status;
      }

      if (!this.getInsurancesSelected) {
        params.insuranceIds = this.insuranceItems
          .filter(({ selected }) => selected)
          .map(item => item.id).join(',');
      }
      if (!this.getProfessionalsSelected) {
        params.professionalIds = this.professionalItems
          .filter(({ selected }) => selected)
          .map(item => item.id).join(',');
      }

      if (!this.getExpensesSelected || this.filters.modality) {
        params.expenseIds = this.filteredExpenses
          .filter(({ selected }) => selected)
          .map(item => item.id).join(',');
      }

      if (printType === 'sheet') {
        return this.$file
          .download('/reports/billing-demonstrative', params, {
            method: 'POST',
            name: 'Demonstrativo de faturamento.xlsx',
          })
          .catch(() => {})
          .then(() => {
            this.printing = false;
          });
      }

      return this.$file
        .print('/reports/billing-demonstrative', params, { method: 'POST' })
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import '../../../assets/scss/variables';
  @import '../../../assets/scss/mixins';

  .billing-report-page {
    .billing-list {
      display: flex;
      flex-direction: column;
      height: 10rem;
      margin-top: $layout-spacing;
      overflow-y: auto;
      .list-header {
        align-items: center;
        border-bottom: $border-width solid $border-color;
        display: flex;
        padding-bottom: $layout-spacing;
        .list-title {
          flex: 1;
          font-weight: bold;
        }
      }
      .billing-scroll-list-wrapper {
        border: $border-color solid $border-width;
        border-radius: $border-radius;
        flex-grow: 1;
        margin-bottom: $layout-spacing-lg * 2;
        position: relative;
      }
      .scroll-list {
        bottom: 0;
        overflow-y: auto;
        padding: $layout-spacing $layout-spacing-lg;
        position: absolute;
        top: 0;
        width: 100%;
        @include scroll-bar();
        .table th, .table td {
          padding: 0;
        }
        .table td {
          border-bottom: none;
        }
      }
    }
    .footer {
      text-align: right;
    }
  }
</style>
