export const LIST_PARTNERS = 'referral:listPartners';
export const CREATE_PARTNER = 'referral:createPartner';
export const UPDATE_PARTNER = 'referral:updatePartner';
export const DELETE_PARTNER = 'referral:deletePartner';
export const PRINT_PARTNER_LIST = 'referral:printPartnerList';

export const LIST_ORDERS = 'referral:listOrders';
export const CREATE_ORDER = 'referral:createOrder';
export const UPDATE_ORDER = 'referral:updateOrder';
export const DELETE_ORDER = 'referral:deleteOrder';
export const PRINT_ORDER = 'referral:printOrder';
export const PRINT_ORDER_LIST = 'referral:printOrderList';
export const FINISH_ORDER = 'referral:finishOrder';

export default [
  {
    action: LIST_PARTNERS,
    name: 'Parceiros: Listar',
  },
  {
    action: CREATE_PARTNER,
    name: 'Parceiros: Criar',
  },
  {
    action: UPDATE_PARTNER,
    name: 'Parceiros: Atualizar',
  },
  {
    action: DELETE_PARTNER,
    name: 'Parceiros: Excluir',
  },
  {
    action: PRINT_PARTNER_LIST,
    name: 'Parceiros: Imprimir lista',
  },
  {
    action: LIST_ORDERS,
    name: 'Encaminhamentos: Listar',
  },
  {
    action: CREATE_ORDER,
    name: 'Encaminhamentos: Criar',
  },
  {
    action: UPDATE_ORDER,
    name: 'Encaminhamentos: Atualizar',
  },
  {
    action: DELETE_ORDER,
    name: 'Encaminhamentos: Excluir',
  },
  {
    action: PRINT_ORDER,
    name: 'Encaminhamentos: Imprimir guia',
  },
  {
    action: PRINT_ORDER_LIST,
    name: 'Encaminhamentos: Imprimir lista',
  },
  {
    action: FINISH_ORDER,
    name: 'Encaminhamentos: Finalizar',
  },
];
