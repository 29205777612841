<template>
  <div class="page-container">
    <portal to="page-name">Caixa</portal>
    <div class="card card-page" v-if="hasAccess">
      <div class="card-header">
        <h1 class="card-title">Atendimentos</h1>
      </div>
      <div class="card-body" v-if="loading">
        <div class="loading"></div>
      </div>
      <div class="card-body" v-if="!loading && attendances.length > 0">
        <table class="table table-striped table-hover">
          <thead>
          <tr>
            <th>Data</th>
            <th>Paciente</th>
            <th>Tipo do atendimento</th>
            <th class="text-right">Valor</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(attendance, i) in attendances" :key="i">
            <td>{{ attendance.startDate | date }}</td>
            <td>{{ attendance.patient.name }}</td>
            <td>{{ getServiceName(attendance) }}</td>
            <td class="text-right">{{ attendance.total | currency }}</td>
            <td class="text-right">
              <button class="btn btn-sm btn-action btn-icon btn-gray tooltip mr-1"
                      data-tooltip="Editar conta" :disabled="editing"
                      @click="editAccount(attendance.id)">
                <fa-icon :icon="['fal', 'pencil']"/>
              </button>
              <button class="btn btn-sm btn-action btn-icon btn-gray tooltip"
                      data-tooltip="Excluir conta" @click="removeAccount(attendance.id)"
                      :disabled="deleting">
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body" v-else-if="!loading && attendances.length === 0">
        <div class="empty mt-2">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Nenhum atendimento encontrado</p>
          <p class="empty-subtitle">
            Nenhum atendimento pendente de recebimento
          </p>
        </div>
      </div>
      <div class="card-footer" v-if="!loading && attendances.length > 0">
        <div class="columns">
          <div class="column col-3">
            <span>Total a receber:
              <span class="ml-2 text-primary text-bold">
                {{ attendances.reduce((a, b) => a + b.total, 0) | currency }}
              </span>
            </span>
          </div>
          <div class="column col-3">
            <span>Atendimentos:
              <span class="ml-2 text-primary text-bold">{{ attendances.length }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <forbidden v-else></forbidden>
    <referral-modal :show="account.show"
                    :data="account.data"
                    :source="'appointments'"
                    :type="account.type"
                    v-if="account.show"
                    @close="hideAccountModal" />
  </div>
</template>

<script>
import { LIST_CASHIER_ATTENDANCES } from '@/data/actions/modules/financial';
import { getName as serviceTypes } from '@/data/service-types';
import Forbidden from '@/components/auth/Forbidden.vue';
import moment from 'moment';
import ReferralModal from '../../appointment/components/modals/Referral.vue';

export default {
  data() {
    return {
      loading: false,
      editing: false,
      deleting: false,
      attendances: [],
      account: {
        type: '',
        data: {},
        show: false,
      },
    };
  },
  components: {
    Forbidden,
    ReferralModal,
  },
  computed: {
    hasAccess() {
      return this.$can(LIST_CASHIER_ATTENDANCES);
    },
  },
  async mounted() {
    if (this.hasAccess) {
      this.loading = true;
      await this.getAttendances();
    }
    this.loading = false;
  },
  methods: {
    async getAttendances() {
      await this.$http.get('/cashiers/attendances')
        .then(({ data }) => {
          this.attendances = data.items;
        })
        .catch(() => {});
    },
    getServiceName(attendance) {
      return serviceTypes(attendance.serviceType);
    },
    async editAccount(accountId) {
      let account;
      this.editing = true;
      await this.$http.get(`/billing-accounts/${accountId}`)
        .then(({ data }) => {
          const professional = data.expenses.length > 0
            ? data.expenses[0].professional
            : null;
          if (data.invoice && data.invoice.id) {
            data.invoiceId = data.invoice.id;
          }
          data.professionalId = professional.id;
          data.specialtyCode = professional.specialty
            ? professional.specialty.code
            : null;
          data.expenses.forEach((expense) => {
            expense.accountId = accountId;
            expense.execution = {
              date: moment(expense.startDate).format('YYYY-MM-DD'),
              time: moment(expense.startDate).format('HH:mm'),
              duration: moment.duration(moment(expense.endDate)
                .diff(moment(expense.startDate))).asMinutes(),
            };
            expense.rules = {
              requireAuthorization: false,
              requireClinicalIndication: false,
              requireWayTechnique: false,
            };
          });
          account = data;
        });

      const params = {
        expenseIds: account.expenses.map(({ id }) => id).join(','),
      };
      await this.$http.get(
        `/expense-insurances/${account.patient.insurance.plan.id}/rules`,
        { params },
      ).then(({ data }) => {
        if (data.items) {
          if (data.items.length > 0) {
            data.items.forEach((item) => {
              account.expenses.forEach((expense) => {
                if (item.id === expense.id) {
                  if (item.rules) {
                    expense.rules = item.rules;
                  }
                }
              });
            });
          }
        }
      });

      this.account.data = account;
      this.account.type = account.referral.type;
      this.account.show = true;
    },
    async removeAccount(id) {
      const yesAction = async (close) => {
        if (this.loading) return;
        close();

        this.deleting = true;
        await this.$http.delete(`/billing-accounts/${id}`)
          .catch(() => {
            this.$toast.show(
              'Atenção! Ocorreu um erro ao excluir o atendimento.', { type: 'error' },
            );
          });
        await this.getAttendances();
        this.deleting = false;
      };

      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir esse atendimento?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.$nextTick(() => {
                yesAction(close);
              });
            },
          },
        ],
      });
    },
    hideAccountModal() {
      this.account.show = false;
      this.editing = false;
      this.getAttendances();
    },
  },
};
</script>
