function blankFilter() {
  return {
    schedules: [],
    date: new Date(),
    scheduleId: '',
    professionalId: '',
    status: '',
    search: '',
  };
}

export default {
  items: [],
  loading: false,
  filter: blankFilter(),
  config: {
    show: false,
    processing: false,
    loaded: false,
    form: {
      placeId: '',
      roomName: '',
    },
  },
};
