<template>
  <div class="financial-transaction-report-page">
    <h6>Documentos a pagar e a receber</h6>
    <div class="columns form-group">
      <div class="column col-auto form-group">
        <label class="form-label">Data</label>
        <select id="group" class="form-select" v-model="filters.dateType">
          <option value="issueDate">Emissão</option>
          <option value="dueDate">Vencimento</option>
        </select>
      </div>
      <div class="column col-3 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.startDate.$error}">
        <label class="form-label">Data início</label>
        <div class="input-group">
          <dx-input-date
            id="appointment-patient-birth-date"
            class="form-input text-center"
            v-model="filters.startDate"
            @blur="$v.filters.startDate.$touch()"
          />
        </div>
        <template v-if="$v.filters.startDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.startDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.startDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-3 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.endDate.$error}">
        <label class="form-label">Data término</label>
        <dx-input-date
          id="end-date"
          class="form-input text-center"
          v-model="filters.endDate"
          @blur="$v.filters.endDate.$touch()"
        />
        <template v-if="$v.filters.endDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.endDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.endDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column form-group">
        <label class="form-label">Agrupado por</label>
        <select id="group" class="form-select" v-model="filters.group">
          <option value="">Sem agrupamento</option>
          <option value="date">Data</option>
          <option value="entity">Favorecido/Pagador</option>
          <option value="category">Categoria</option>
          <option value="costCenter">Centro de custo</option>
        </select>
      </div>
      <div class="column col-12 form-group">
        <label class="form-label">Favorecido/Pagador</label>
        <dx-autocomplete
          id="entity-name"
          v-model="entity"
          :readonly="!!filters.entity.id"
          :source="findEntity"
          label="name"
          track-by="id"
          :debounce="800"
          @select="setEntity"
          placeholder="Nome do paciente, fornecedor ou convênio..."
        >
          <button slot="action" @click="unsetEntity"
                  class="btn btn-action input-group-btn btn-icon"
                  :class="entity ? 'btn-gray' : 'btn-neutral'"
                  tabindex="-1">
            <fa-icon :icon="['fal', entity ? 'times' : 'search']"></fa-icon>
          </button>
          <template v-slot="{ item }">
            <div>{{ item.name }}</div>
            <small class="text-primary" v-if="item.id === null">
              Favorecido/Pagador não encontrado
            </small>
          </template>
        </dx-autocomplete>
      </div>
    </div>

    <div class="billing-list">
      <div class="scroll-list-wrapper">
        <div class="scroll-list">
          <div class="list-header">
            <label class="form-checkbox">
              <input type="checkbox"
                     @change="selectAllTypes"
                     :checked="getTypeSelected"
                     :indeterminate.prop="getTypeSelected === null">
              <i class="form-icon"></i>
            </label>
            <div class="list-title">Tipo</div>
            <div class="input-group">
              <input type="text" class="form-input"
                     v-model="filters.type"
                     placeholder="Filtrar tipo">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1" v-if="!filters.type.trim()">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <button class="btn btn-gray input-group-btn btn-icon"
                      @click="filters.type = ''"
                      tabindex="-1" v-else>
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </div>
          </div>
          <table class="table table-hover">
            <tbody>
            <tr v-for="(item, i) in filteredTypes" :key="i">
              <td width="30px">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>{{ item.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="scroll-list-wrapper">
        <div class="scroll-list">
          <div class="list-header">
            <label class="form-checkbox">
              <input type="checkbox"
                     @change="selectAllMethods"
                     :checked="getMethodSelected"
                     :indeterminate.prop="getMethodSelected === null">
              <i class="form-icon"></i>
            </label>
            <div class="list-title">Forma de cobrança</div>
            <div class="input-group">
              <input type="text" class="form-input"
                     v-model="filters.method"
                     placeholder="Filtrar forma de cobrança">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1" v-if="!filters.method.trim()">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <button class="btn btn-gray input-group-btn btn-icon"
                      @click="filters.method = ''"
                      tabindex="-1" v-else>
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </div>
          </div>
          <table class="table table-hover">
            <tbody>
            <tr v-for="(item, i) in filteredMethods" :key="i">
              <td width="30px">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>{{ item.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="scroll-list-wrapper">
        <div class="scroll-list">
          <div class="list-header">
            <label class="form-checkbox">
              <input type="checkbox"
                     @change="selectAllCategories"
                     :checked="getCategorySelected"
                     :indeterminate.prop="getCategorySelected === null">
              <i class="form-icon"></i>
            </label>
            <div class="list-title">Categoria</div>
            <div class="input-group">
              <input type="text" class="form-input"
                     v-model="filters.category"
                     placeholder="Filtrar categoria">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1" v-if="!filters.category.trim()">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <button class="btn btn-gray input-group-btn btn-icon"
                      @click="filters.category = ''"
                      tabindex="-1" v-else>
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </div>
          </div>
          <table class="table table-hover">
            <tbody>
            <tr v-for="(item, i) in filteredCategories" :key="i">
              <td width="30px">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td><span v-if="item.parentName">{{ item.parentName }} > </span>{{ item.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="scroll-list-wrapper">
        <div class="scroll-list">
          <div class="list-header">
            <label class="form-checkbox">
              <input type="checkbox"
                     @change="selectAllCostCenters"
                     :checked="getCostCenterSelected"
                     :indeterminate.prop="getCostCenterSelected === null">
              <i class="form-icon"></i>
            </label>
            <div class="list-title">Centro de custo</div>
            <div class="input-group">
              <input type="text" class="form-input"
                     v-model="filters.costCenter"
                     placeholder="Filtrar centro de custo">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1" v-if="!filters.costCenter.trim()">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <button class="btn btn-gray input-group-btn btn-icon"
                      @click="filters.costCenter = ''"
                      tabindex="-1" v-else>
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </div>
          </div>
          <table class="table table-hover">
            <tbody>
            <tr v-for="(item, i) in filteredCostCenters" :key="i">
              <td width="30px">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td><span v-if="item.parentName">{{ item.parentName }} > </span>{{ item.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-gray btn-icon btn-icon-left mr-1"
              :class="{loading: printing}"
              @click="print('sheet')"
              :disabled="printing">
        <fa-icon :icon="['fal', 'file-excel']"/>Gerar planilha
      </button>
      <button class="btn btn-primary btn-icon-left"
              :class="{loading: printing}"
              @click="print('pdf')" :disabled="printing">
        <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';
import fuzzy from 'fuzzy';
import formMixin from '@/mixins/form';

export default {
  mixins: [formMixin],
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      printing: false,
      typeItems: [
        { id: 'expense', name: 'Documentos a pagar', selected: true },
        { id: 'paid', name: 'Documentos pagos', selected: true },
        { id: 'revenue', name: 'Documentos a receber', selected: true },
        { id: 'received', name: 'Documentos recebidos', selected: true },
      ],
      methodItems: [],
      categoryItems: [],
      costCenterItems: [],
      entity: null,
      filters: {
        group: '',
        dateType: 'dueDate',
        startDate: '',
        endDate: '',
        entity: {
          id: '',
          name: '',
        },
        type: '',
        method: '',
        category: '',
        costCenter: '',
      },
    };
  },
  validations() {
    const rules = {
      filters: {
        startDate: { date, required },
        endDate: { date, required },
      },
    };
    if (this.filters.startDate) {
      rules.filters.endDate = {
        date,
        required,
        minDate: minDate(this.filters.startDate),
      };
    }

    return rules;
  },
  mounted() {
    this.filters.startDate = moment()
      .startOf('month')
      .format('YYYY-MM-DD');
    this.filters.endDate = moment()
      .endOf('month')
      .format('YYYY-MM-DD');
    this.loadCategories();
    this.loadCostCenters();
    this.loadMethods();
  },
  computed: {
    getMethodSelected() {
      if (!this.filteredMethods || this.filteredMethods.length === 0) {
        return false;
      }

      const methodSelected = this.filteredMethods.filter(item => item.selected);
      if (methodSelected.length === 0) {
        return false;
      }
      if (methodSelected.length === this.filteredMethods.length) {
        return true;
      }
      return null;
    },
    getCategorySelected() {
      if (!this.filteredCategories || this.filteredCategories.length === 0) {
        return false;
      }

      const selected = this.filteredCategories.filter(item => item.selected);
      if (selected.length === 0) {
        return false;
      }
      if (selected.length === this.filteredCategories.length) {
        return true;
      }
      return null;
    },
    getCostCenterSelected() {
      if (!this.filteredCostCenters || this.filteredCostCenters.length === 0) {
        return false;
      }

      const selected = this.filteredCostCenters.filter(item => item.selected);
      if (selected.length === 0) {
        return false;
      }
      if (selected.length === this.filteredCostCenters.length) {
        return true;
      }
      return null;
    },
    getTypeSelected() {
      if (!this.filteredTypes || this.filteredTypes.length === 0) {
        return false;
      }

      const typeSelected = this.filteredTypes.filter(item => item.selected);
      if (typeSelected.length === 0) {
        return false;
      }
      if (typeSelected.length === this.filteredTypes.length) {
        return true;
      }
      return null;
    },
    filteredMethods() {
      let { methodItems } = this;

      if (this.filters.method.trim()) {
        const results = fuzzy.filter(this.filters.method, methodItems, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        methodItems = results.map(({ original }) => original);
      }

      return methodItems;
    },
    filteredCategories() {
      let { categoryItems } = this;

      if (this.filters.category.trim()) {
        const results = fuzzy.filter(this.filters.category, categoryItems, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        categoryItems = results.map(({ original }) => original);
      }

      return categoryItems;
    },
    filteredCostCenters() {
      let { costCenterItems } = this;

      if (this.filters.costCenter.trim()) {
        const results = fuzzy.filter(this.filters.costCenter, costCenterItems, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        costCenterItems = results.map(({ original }) => original);
      }

      return costCenterItems;
    },
    filteredTypes() {
      let { typeItems } = this;

      if (this.filters.type.trim()) {
        const results = fuzzy.filter(this.filters.type, typeItems, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        typeItems = results.map(({ original }) => original);
      }

      return typeItems;
    },
  },
  methods: {
    selectAllMethods(e) {
      this.filteredMethods.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    selectAllCategories(e) {
      this.filteredCategories.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    selectAllCostCenters(e) {
      this.filteredCostCenters.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    selectAllTypes(e) {
      this.filteredTypes.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    print(printType) {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return false;
      }

      const filteredTypes = this.typeItems
        .filter(({ selected }) => selected);

      if (filteredTypes.length === 0) {
        this.$toast.error('Selecione um ou mais tipos!');
        return false;
      }

      this.printing = true;

      const data = {
        dateType: this.filters.dateType,
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
        group: this.filters.group,
        printType,
        types: filteredTypes.map(item => item.id).join(','),
      };

      if (this.filters.entity.id) {
        data.entityId = this.filters.entity.id;
      }

      const filteredMethods = this.methodItems
        .filter(({ selected }) => selected);

      const filteredCategories = this.categoryItems
        .filter(({ selected }) => selected);

      const filteredCostCenters = this.costCenterItems
        .filter(({ selected }) => selected);

      if (this.methodItems.length !== filteredMethods.length) {
        data.methodIds = filteredMethods.map(item => item.id).join(',');
      }

      if (this.categoryItems.length !== filteredCategories.length) {
        data.categoryIds = filteredCategories.map(item => item.id).join(',');
      }

      if (this.costCenterItems.length !== filteredCostCenters.length) {
        data.costCenterIds = filteredCostCenters.map(item => item.id).join(',');
      }

      if (printType === 'sheet') {
        return this.$file
          .download('/reports/financial-transactions',
            data,
            { method: 'POST' })
          .catch(() => {
            this.$toast.error('Ocorreu um erro. Tente novamente!');
          })
          .finally(() => {
            this.printing = false;
          });
      }

      return this.$file
        .print('/reports/financial-transactions', data, { method: 'POST' })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.printing = false;
        });
    },
    loadCategories() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/categories', { params })
        .then(({ data }) => {
          this.categoryItems = data.items.map(item => ({
            id: item.id,
            parentName: item.parent ? item.parent.name : '',
            name: item.name,
            selected: true,
          }));
        })
        .catch(() => {});
    },
    loadCostCenters() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/cost-centers', { params })
        .then(({ data }) => {
          this.costCenterItems = data.items.map(item => ({
            id: item.id,
            parentName: item.parent ? item.parent.name : '',
            name: item.name,
            selected: true,
          }));
        })
        .catch(() => {});
    },
    loadMethods() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/payment-methods', { params })
        .then(({ data }) => {
          this.methodItems = data.items.map(item => ({
            id: item.id,
            name: item.name,
            selected: true,
          }));
        })
        .catch(() => {});
    },
    findEntity(search) {
      const params = {
        status: 'active',
        search: `^${search}`,
        type: 'expense',
      };

      return this.$http.get('/financial-transactions/entities', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        })
        .catch(() => {});
    },
    setEntity(data) {
      this.filters.entity.id = data ? data.id : null;
      this.filters.entity.name = data ? data.name : null;
    },
    unsetEntity() {
      this.setEntity(null);
      this.entity = null;
    },
  },
};
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  @import '~assets/scss/mixins';
  .financial-transaction-report-page {
    .billing-list {
      display: flex;
      flex-direction: column;
      height: 40rem;
      overflow-y: auto;
      .list-header {
        align-items: center;
        border-bottom: $border-width solid $border-color;
        display: flex;
        padding-bottom: $layout-spacing;
        .list-title {
          flex: 1;
          font-weight: bold;
        }
      }
      .scroll-list-wrapper {
        border: $border-color solid $border-width;
        border-radius: $border-radius;
        flex-grow: 1;
        margin-bottom: $layout-spacing-lg * 2;
        position: relative;
      }
      .scroll-list {
        bottom: 0;
        overflow-y: auto;
        padding: $layout-spacing $layout-spacing-lg;
        position: absolute;
        top: 0;
        width: 100%;
        @include scroll-bar();
        .table th, .table td {
          padding: 0;
        }
        .table td {
          border-bottom: none;
        }
      }
    }
    .footer {
      margin-top: $layout-spacing-lg;
      text-align: right;
    }
  }
</style>
