<template>
  <dx-modal :value="show" :title="'Editar Aporte/Sangria'"
            size="lg" @input="close"
            id="modal-operarion-item">
    <div class="columns form-group">
      <div class="column col-4 col-md-12 col-sm-12 form-group">
        <label>Caixa</label>
        <input id="cashier-name" class="form-input" :value="form.cashier.name" :disabled="true" />
      </div>
      <div class="column col-4 col-md-12 col-sm-12">
        <label>Tipo</label>
        <input id="type" class="form-input"
               :value="form.type === 'incoming' ? 'Aporte' : 'Sangria'" :disabled="true"/>
      </div>
      <div class="column col-4 col-md-12 col-sm-12 form-group"
           :class="{'has-error': $v.form.value.$error}">
        <label>Valor</label>
        <dx-input-number id="value" v-model="form.value" @change="$v.form.value.$touch()"
                         :precision="2" maxlength="10" class="form-input text-right"/>
      </div>
      <div class="column col-12 form-group">
        <label for="notes">Observação</label>
        <textarea id="notes" class="form-input" type="text" v-model="form.notes" rows="2" />
      </div>
    </div>
    <template slot="footer">
      <button class="btn btn-primary btn-icon btn-icon-left mr-1"
              :class="{loading}"
              :disabled="loading"
              @click="save">
        Salvar
      </button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import required from 'vuelidate/lib/validators/required';

export default {
  props: {
    data: {
      type: Object,
    },
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      path: '/cashier-operations',
      loading: false,
      form: {
        id: '',
        cashier: {
          id: '',
          name: '',
        },
        type: '',
        value: 0,
        notes: '',
      },
    };
  },
  validations() {
    const roles = {
      form: {
        value: { required },
      },
    };
    return roles;
  },
  mounted() {
    this.form = this.data;
    this.$v.form.$touch();
  },
  methods: {
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      this.loading = true;
      const data = {
        cashierId: this.form.cashier.id,
        value: this.form.value,
        notes: this.form.notes,
      };

      let success = true;
      this.$http.put(`/cashier-operations/${this.form.id}`, data)
        .then(() => {
          if (this.form.type === 'incoming') {
            this.$toast.show('Aporte atualizado com sucesso!');
          } else {
            this.$toast.show('Sangria atualizado com sucesso!');
          }
        })
        .catch((e) => {
          let errorMessage = 'Ocorreu um erro ao realizar a operação solicitada!';
          if (e.response) {
            if (e.response.status === 403) {
              errorMessage = 'Acesso não autorizado';
            } else if (e.response.data && e.response.data.message) {
              errorMessage = e.response.data.message;
            }
          }
          this.$toast.show(errorMessage, { type: 'error' });
          success = false;
        })
        .finally(() => {
          this.loading = false;
          this.close(success);
        });
    },
    close(save = false) {
      this.$emit('close', save);
    },
  },
};
</script>
