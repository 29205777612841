import { setToken } from '@/plugins/http';
import {
  update as storageUpdate,
  set as storageSet,
} from '@/plugins/storage';

export default (store) => {
  store.subscribe((mutation, { auth }) => {
    if (mutation.type === 'Auth.SET_USER') {
      storageUpdate(auth.user ? auth.user.branch : null);
    } else if (mutation.type === 'Auth.SET_TOKEN') {
      setToken(auth.token);
      storageSet('token', auth.token, true);
    } else if (mutation.type === 'Auth.SET_MODULE') {
      storageSet('module', auth.module, true);
    }
  });
};
