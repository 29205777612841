import {
  LIST_ORDERS,
  CREATE_ORDER,
  UPDATE_ORDER,
} from '@/data/actions/modules/referral';

export default [
  {
    path: '/forwards',
    component: () => import(/* webpackChunkName: "forwards" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_ORDERS,
    },
  },
  {
    path: '/forwards/create',
    component: () => import(/* webpackChunkName: "forwards" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_ORDER,
    },
  },
  {
    path: '/forwards/:id/edit',
    component: () => import(/* webpackChunkName: "forwards" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_ORDER,
    },
  },
];
