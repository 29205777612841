<template>
  <div class="chat-input form-group">
    <input
      :value="value"
      class="form-input"
      @input="onInput"
      @keypress.enter="onSend"
      placeholder="Digite sua mensagem..."
      v-focus
    />
    <button
      class="btn btn-icon chat-input-btn"
      :class="buttonClass"
      @click="onSend"
      data-tooltip="Enviar"
    ><fa-icon :icon="['fal', 'paper-plane']"></fa-icon></button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    sending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    hasContent() {
      return !!this.value.trim();
    },
    buttonClass() {
      return {
        active: this.hasContent,
        loading: this.sending,
        tooltip: !this.sending,
      };
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value);
    },
    onSend() {
      this.$emit('send', this.value);
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';

.chat-input {
  position: relative;
  .form-input {
    padding-right: 1.6rem;
    &:focus {
      border-color: $info-color;
    }
  }
  .btn.chat-input-btn {
    border: 0;
    background-color: transparent;
    color: $gray-color-light;
    padding: $layout-spacing-sm;
    position: absolute;
    right: $layout-spacing;
    top: 50%;
    transform: translateY(-50%);
    transition: .15s ease color;
    &:focus {
      box-shadow: none;
    }
    &.active {
      color: $info-color;
    }
    &.loading::after {
      border-left-color: $info-color;
      border-bottom-color: $info-color;
    }
  }
}
</style>
