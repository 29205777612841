import { MANAGE_CUSTOMER_ACCOUNT } from '@/data/actions/modules/general';

const meta = {
  layout: 'master',
  requiresAuth: true,
  permission: MANAGE_CUSTOMER_ACCOUNT,
};

export default [
  {
    path: '/customer',
    component: () => import(/* webpackChunkName: "settings-main" */ './components/Main.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "settings" */ './components/Customer.vue'),
        meta,
      },
      {
        path: 'payments',
        component: () => import(/* webpackChunkName: "settings" */ './components/Payments.vue'),
        meta,
      },
    ],
    meta,
  },
];
