import { http } from '../http';

export const get = (cep) => {
  if (!cep) {
    return Promise.reject(new Error('CEP não informado'));
  }

  return http.get(`/services/cep/${cep}`).then(({ data }) => data);
};

export const find = (address) => {
  if (!address) {
    return Promise.reject(new Error('Endereço não informado'));
  }

  return http.get(`/services/cep?address=${address}`).then(({ data }) => data);
};

export default function install(Vue) {
  Object.defineProperty(Vue.prototype, '$cep', {
    get() {
      return { get, find };
    },
  });
}
