<template>
  <div class="stock-entry-report-page">
    <h6>Relatório de entradas de produtos</h6>
    <div class="columns">
      <div class="column col-4 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.startDate.$error}">
        <label class="form-label">Data início</label>
        <div class="input-group">
          <dx-input-date
            id="appointment-patient-birth-date"
            class="form-input text-center"
            v-model="filters.startDate"
            @blur="$v.filters.startDate.$touch()"
          />
        </div>
        <template v-if="$v.filters.startDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.startDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.startDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-4 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.endDate.$error}">
        <label class="form-label">Data término</label>
        <dx-input-date
          id="end-date"
          class="form-input text-center"
          v-model="filters.endDate"
          @blur="$v.filters.endDate.$touch()"
        />
        <template v-if="$v.filters.endDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.endDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.endDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-12 form-group">
        <label class="form-label">Almoxarifado</label>
        <select id="place" class="form-select" v-model="filters.placeId">
          <option value="">[Todos os almoxarifados]</option>
          <option v-for="(item, i) in places" :value="item.id" :key="i">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="column col-12 form-group">
        <label class="form-label">Produto</label>
        <dx-autocomplete
          id="product-name"
          v-model="product"
          :readonly="!!filters.product.name"
          :source="findProduct"
          label="name"
          track-by="id"
          @select="setProduct"
          :debounce="800"
          placeholder="Nome ou código do produto"
        >
          <button slot="action" @click="unsetProduct"
                  class="btn btn-action input-group-btn btn-icon"
                  :class="product ? 'btn-gray' : 'btn-neutral'"
                  tabindex="-1">
            <fa-icon :icon="['fal', product ? 'times' : 'search']"></fa-icon>
          </button>
          <template v-slot="{ item }">
            <div>{{ item.name }}</div>
            <small class="text-primary" v-if="item.id === null">
              produto não encontrado
            </small>
          </template>
        </dx-autocomplete>
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-primary btn-icon-left"
              :class="{loading: printing}"
              @click="print" :disabled="printing">
        <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';

export default {
  data() {
    return {
      printing: false,
      product: null,
      places: [],
      filters: {
        startDate: moment()
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment()
          .endOf('month')
          .format('YYYY-MM-DD'),
        placeId: '',
        product: {
          id: '',
          name: '',
        },
      },
    };
  },
  mounted() {
    this.loadPlaces();
  },
  validations() {
    const rules = {
      filters: {
        startDate: { date, required },
        endDate: { date, required },
      },
    };
    if (this.filters.startDate) {
      rules.filters.endDate = {
        date,
        required,
        minDate: minDate(this.filters.startDate),
      };
    }

    return rules;
  },
  methods: {
    findProduct(search) {
      const params = {
        types: ['material', 'medicine', 'others'],
        search: `^${search}`,
      };

      return this.$http.get('/procedures', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        })
        .catch(() => {});
    },
    setProduct(data) {
      this.filters.product.id = data ? data.id : null;
      this.filters.product.name = data ? data.name : null;
    },
    unsetProduct() {
      this.setProduct(null);
      this.product = null;
    },
    print() {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return false;
      }

      const duration = moment.duration(moment(this.filters.endDate)
        .diff(moment(this.filters.startDate))).asMonths();

      if (duration >= 3) {
        this.$toast.show('Período máximo (3 meses)', { type: 'error' });
        return false;
      }

      this.printing = true;

      const params = {
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
      };

      if (this.filters.placeId) {
        params.placeId = this.filters.placeId;
      }

      if (this.filters.product.id) {
        params.productId = this.filters.product.id;
      }

      return this.$file
        .print('/reports/stock-entries', params)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
    loadPlaces() {
      this.places = [];

      const params = {
        active: true,
        limit: 0,
      };

      return this.$http
        .get('/places', { params })
        .then(({ data }) => {
          this.places = data.items;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  .stock-entry-report-page {
    .footer {
      margin-top: $layout-spacing-lg;
      text-align: right;
    }
  }
</style>
