<template>
  <div class="page-container">
    <portal to="page-name">Caixa</portal>
    <div class="card card-page" v-if="hasAccess">
      <div class="card-header">
        <h1 class="card-title">Configuração</h1>
      </div>
      <div class="card-body" v-if="loading">
        <div class="loading"></div>
      </div>
      <div class="card-body" v-else-if="!loading">
        <div class="columns">
          <div class="column col-6" :class="{'has-error': $v.form.cashier.id.$error}">
            <label>Caixa</label>
            <select class="form-select" v-model="form.cashier.id" @change="getCashier()"
                    @blur="$v.form.cashier.id.$touch()">
              <option value="">Selecione um caixa</option>
              <option v-for="(item, i) in cashiers" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="column col-2">
            <button class="btn btn-primary" :class="{loading: saving}" style="margin-top: 18px"
                    @click="save" :disabled="saving">
              Salvar
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" v-if="!loading && form.cashier.id">
        <div class="columns">
          <div class="column col-12">
            <h6 class="text-primary">Categorias</h6>
          </div>
          <div class="column col-6 form-group">
            <label>Entrada</label>
            <select class="form-select" v-model="form.categories.revenue.id">
              <option value="">Selecione uma categoria</option>
              <option v-for="(item, i) in filteredRevenueCategories" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="column col-6 form-group">
            <label>Saída</label>
            <select class="form-select" v-model="form.categories.expense.id">
              <option value="">Selecione uma categoria</option>
              <option v-for="(item, i) in filteredExpenseCategories" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="column col-6 form-group">
            <label>Atendimento</label>
            <select class="form-select" v-model="form.categories.attendance.id">
              <option value="">Selecione uma categoria</option>
              <option v-for="(item, i) in filteredRevenueCategories" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="column col-6 form-group">
            <label>Encaminhamento</label>
            <select class="form-select" v-model="form.categories.forward.id">
              <option value="">Selecione uma categoria</option>
              <option v-for="(item, i) in filteredRevenueCategories" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <br />
        <div class="columns">
          <div v-if="collaborators.length > 0" class="column col-12">
            <h6 class="text-primary">Usuários</h6>
            <table class="table table-hover">
              <thead>
              <tr>
                <th width="40px">
                  <label class="form-checkbox">
                    <input type="checkbox"
                           @change="selectAllCollaborators"
                           :checked="allCollaboratorsSelected"
                           :indeterminate.prop="allCollaboratorsSelected === null">
                    <i class="form-icon"></i>
                  </label>
                </th>
                <th>Usuário</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(collaborator, i) in collaborators" :key="i">
                <td width="40px">
                  <label class="form-checkbox">
                    <input type="checkbox" v-model="collaborator.selected">
                    <i class="form-icon"></i>
                  </label>
                </td>
                <td>{{ collaborator.name }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-body" v-else-if="!loading && !form.cashier.id">
        <div class="empty mt-2">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Nenhum caixa selecionado</p>
          <p class="empty-subtitle">
            Selecione um caixa para realizar a sua configuração
          </p>
        </div>
      </div>
    </div>
    <forbidden v-else></forbidden>
  </div>
</template>

<script>
import moment from 'moment';
import { MANAGE_CASHIER_SETTINGS } from '@/data/actions/modules/financial';
import { required } from 'vuelidate/lib/validators';
import Forbidden from '@/components/auth/Forbidden.vue';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      cashiers: [],
      entities: [],
      categories: [],
      saving: false,
      loading: false,
      form: {
        cashier: { id: '' },
        categories: {
          revenue: { id: '' },
          expense: { id: '' },
          attendance: { id: '' },
          forward: { id: '' },
        },
      },
    };
  },
  components: {
    Forbidden,
  },
  computed: {
    hasAccess() {
      return this.$can(MANAGE_CASHIER_SETTINGS);
    },
    filteredRevenueCategories() {
      return this.categories.filter(item => item.type === 'revenue');
    },
    filteredExpenseCategories() {
      return this.categories.filter(item => item.type === 'expense');
    },
    allCollaboratorsSelected() {
      let allSelected = true;
      this.collaborators.forEach((item) => {
        if (item.selected === false) {
          allSelected = false;
        }
      });
      return allSelected;
    },
  },
  validations() {
    const rules = {
      form: {
        cashier: {
          id: { required },
        },
      },
    };
    return rules;
  },
  async mounted() {
    if (this.hasAccess) {
      this.loading = true;
      await this.getCollaborators();
      await this.loadProfessionals();
      await this.getCategories();
      await this.getCashiers();
    }
    this.loading = false;
  },
  methods: {
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    async getCashiers() {
      const params = {
        cashier: true,
        limit: 0,
      };
      await this.$http.get('/bank-accounts', { params })
        .then(({ data }) => {
          if (data.items && data.items.length > 0) {
            this.cashiers = data.items.map(cashier => ({
              id: cashier.id,
              name: cashier.name,
            }));
          }
        })
        .catch(() => {});
    },
    async getCollaborators() {
      const params = {
        active: true,
        limit: 0,
      };

      await this.$http.get('/collaborators', { params })
        .then(({ data }) => {
          if (data.items && data.items.length > 0) {
            this.collaborators = data.items.map(cashier => ({
              id: cashier.id,
              name: cashier.name,
              selected: false,
            }));
          }
        })
        .catch(() => {});
    },
    async getCategories() {
      const params = {
        active: true,
        limit: 0,
      };

      await this.$http.get('/categories', { params })
        .then(({ data }) => {
          if (data.items) {
            this.categories = data.items;
          }
        })
        .catch(() => {});
    },
    async getCashier() {
      this.loading = true;

      this.form.categories.revenue.id = '';
      this.form.categories.expense.id = '';
      this.form.categories.attendance.id = '';
      this.form.categories.forward.id = '';
      this.collaborators.forEach((item) => {
        item.selected = false;
      });

      if (this.form.cashier.id) {
        await this.$http.get(`/cashier-settings/${this.form.cashier.id}`)
          .then(({ data }) => {
            if (data.categories) {
              if (data.categories.attendance && data.categories.attendance.id) {
                this.form.categories.attendance.id = data.categories.attendance.id;
              }
              if (data.categories.expense && data.categories.expense.id) {
                this.form.categories.expense.id = data.categories.expense.id;
              }
              if (data.categories.revenue && data.categories.revenue.id) {
                this.form.categories.revenue.id = data.categories.revenue.id;
              }
              if (data.categories.forward && data.categories.forward.id) {
                this.form.categories.forward.id = data.categories.forward.id;
              }
            }

            if (data.collaborators && data.collaborators.length > 0) {
              data.collaborators.forEach((item) => {
                const collaborator = this.collaborators.find(
                  collaboratorItem => collaboratorItem.id === item.id,
                );
                if (collaborator) {
                  collaborator.selected = true;
                }
              });
            }
          });
      }
      this.loading = false;
    },
    loadProfessionals() {
      return this.apiFetchProfessionals()
        .then((items) => {
          items.forEach((item) => {
            const found = this.collaborators.find(({ id }) => id === item.id);
            if (!found) {
              this.collaborators.push({
                id: item.id,
                name: item.name,
                selected: false,
              });
            }
          });
          this.collaborators.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          });
        })
        .catch(() => {});
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.saving = true;
      const data = {
        bankAccount: {
          id: this.form.cashier.id,
        },
        categories: this.form.categories,
        collaborators: this.collaborators.filter(item => item.selected === true)
          .map(item => item.id)
          .join(','),
      };

      this.$http.post('/cashier-settings', data)
        .then(() => {
          this.$toast.show('Configuração realizada com sucesso!');
        })
        .catch(() => {
          this.$toast.show('Selecione ao menos um colaborador', { type: 'error' });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    clearForm() {
      this.form.cashier.id = '';

      this.form.categories.revenue.id = '';
      this.form.categories.expense.id = '';
      this.form.categories.attendance.id = '';
      this.form.categories.forward.id = '';

      this.collaborators.forEach((item) => {
        item.selected = false;
      });

      this.$v.form.cashier.$reset();
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    selectAllCollaborators(e) {
      this.collaborators.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
  },
};
</script>
