<template>
  <div class="card card-details">
    <div class="card-header">
      <slot name="header">
        <div class="card-title" v-if="title">{{ title }}</div>
      </slot>
    </div>
    <div class="card-body"><slot /></div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.card-details {
  background-color: $gray-color-ultra-light;
  border: $border-width solid $border-color;

  .card-title {
    color: $info-color;
    font-size: $font-size-lg + $unit-o;
  }
}
</style>
