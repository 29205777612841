import { mergeFrom } from '@/helpers/object';
import api from '../api';

export async function load({ state }) {
  const data = await api.mapping.findOne();
  state.data = mergeFrom(state.blankData(), data);
}

export async function save({ state }, payload) {
  const data = await api.mapping.createOrUpdate(payload);
  state.data = mergeFrom(state.data, data);
}

export async function saveItem({ state }, payload) {
  const data = await api.mapping.createOrUpdateItem(payload);
  state.data = mergeFrom(state.data, data);
}

export async function removeItem({ state }, payload) {
  const found = state.data.professionals.find(({ id }) => payload.id === id);
  if (found) {
    const data = await api.mapping.removeProfessional(payload.id);
    state.data = mergeFrom(state.data, data);
  }
}
