<template>
  <nav>
    <router-link to="/">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'tachometer-alt']"/>
      </div>
      <span class="side-menu-label">Dashboard</span>
    </router-link>
    <router-link to="/appointments">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'calendar-alt']"/>
      </div>
      <span class="side-menu-label">Agenda</span>
    </router-link>
    <router-link to="/attendances">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'stethoscope']"/>
      </div>
      <span class="side-menu-label">Atendimento</span>
    </router-link>
    <router-link to="/patients">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'user-friends']"/>
      </div>
      <span class="side-menu-label">Pacientes</span>
    </router-link>
    <router-link to="/cashier-opening">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'cash-register']"/>
      </div>
      <span class="side-menu-label">Caixa</span>
    </router-link>
    <router-link to="/clinical-financials">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'usd-circle']"/>
      </div>
      <span class="side-menu-label">Financeiro</span>
    </router-link>
    <router-link to="/others">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'ellipsis-h-alt']"/>
      </div>
      <span class="side-menu-label">Outros recursos</span>
    </router-link>
  </nav>
</template>

<script>
export default {};
</script>
