function randomHash() {
  return Math.random().toString(32).slice(-8);
}

export default {
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onInput($event) {
      this.$emit('input', $event.target.value);
    },
  },
  computed: {
    inputId() {
      return this.id || randomHash();
    },
    groupId() {
      return `fg-${this.inputId}`;
    },
  },
};
