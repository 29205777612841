import StringMask from 'string-mask';

const mask = new StringMask('(00) 0000-0000');
const mask9 = new StringMask('(00) 0 0000-0000');

export default (value) => {
  if (!value) {
    return '';
  }

  const phone = value.toString().replace(/\D/g, '');

  return phone.length > 10 ? mask9.apply(phone) : mask.apply(phone);
};
