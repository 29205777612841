import {
  LIST_KITS,
  CREATE_KIT,
  UPDATE_KIT,
} from '@/data/actions/modules/stock';

export default [
  {
    path: '/stock/kits',
    component: () => import(/* webpackChunkName: "kits" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_KITS,
    },
  },
  {
    path: '/stock/kits/create',
    component: () => import(/* webpackChunkName: "kits" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_KIT,
    },
  },
  {
    path: '/stock/kits/:id/edit',
    component: () => import(/* webpackChunkName: "kits" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_KIT,
    },
  },
];
