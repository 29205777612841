<template lang="html">
  <div class="form-autocomplete">
    <form name="autocomplete" @submit.prevent.stop="keyEnter" autocomplete="off">
      <div class="input-group" :class="{'has-icon-right': loading}">
        <input type="search" class="form-input" autocomplete="off"
               v-model="searchText" v-bind="inputAttrs"
               :class="inputAttrs.class || inputClass"
               :placeholder="inputAttrs.placeholder || placeholder"
               :disabled="inputAttrs.disabled || disabled"
               @blur="blur" @focus="focus" @input="inputChange"
               @keyup.enter="keyEnter" @keydown.tab="keyEnter"
               @keydown.up="keyUp" @keydown.down="keyDown">
        <i v-if="loading" class="form-icon loading"></i>
        <slot name="action"></slot>
      </div>
    </form>
    <ul class="menu" v-if="show">
      <li class="menu-item" v-for="(item, i) in internalItems"
          :key="i" @click="onClickItem(item)"
          :class="{'menu-item-active': i === cursor}" @mouseover="cursor = i">
        <slot :item="item" :highlight="highlightItem" :searchText="searchText">
          <a v-html="highlightItem(item)"></a>
        </slot>
      </li>
    </ul>
  </div>
</template>

<script>
import utils from './utils';

/**
 * Based on https://github.com/paliari/v-autocomplete
 */

export default {
  name: 'Autocomplete',
  props: {
    minLen: { type: Number, default: utils.minLen },
    wait: { type: Number, default: utils.wait },
    value: null,
    getLabel: {
      type: Function,
      default: item => item,
    },
    items: Array,
    autoSelectOneItem: { type: Boolean, default: true },
    placeholder: String,
    inputClass: { type: String },
    disabled: { type: Boolean, default: false },
    inputAttrs: { type: Object, default: () => ({}) },
    keepOpen: { type: Boolean, default: false },
    highlight: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      searchText: '',
      showList: false,
      cursor: -1,
      internalItems: this.items || [],
    };
  },
  computed: {
    hasItems() {
      return !!this.internalItems.length;
    },
    show() {
      return (this.showList && this.hasItems) || this.keepOpen;
    },
  },
  methods: {
    highlightItem(value) {
      if (this.highlight && typeof value === 'string') {
        return value
          .replace(new RegExp(this.searchText.trim(), 'ig'), v => `<b class="highlight">${v}</b>`);
      }
      return value;
    },
    inputChange() {
      this.showList = true;
      this.cursor = -1;
      this.onSelectItem(null);
      utils.callUpdateItems(this.searchText, this.updateItems);
      this.$emit('change', this.searchText);
    },
    updateItems() {
      this.$emit('update-items', this.searchText);
    },
    focus() {
      this.$emit('focus', this.searchText);
      this.showList = true;
    },
    blur() {
      this.$emit('blur', this.searchText);
      setTimeout(() => { this.showList = false; }, 200);
    },
    onClickItem(item) {
      this.onSelectItem(item);
      this.$nextTick(() => {
        this.$emit('item-clicked', item);
      });
    },
    onSelectItem(item) {
      if (item) {
        this.internalItems = [item];
        this.searchText = this.getLabel(item);
        this.$emit('item-selected', item);
      } else {
        this.setItems(this.items);
      }
      this.$emit('input', item);
    },
    setItems(items) {
      this.internalItems = items || [];
    },
    clear() {
      this.internalItems = [];
      this.cursor = -1;
      this.searchText = '';
      this.$emit('input', null);
    },
    isSelectedValue(value) {
      return this.internalItems.length === 1 && value === this.internalItems[0];
    },
    keyUp() {
      if (this.cursor > -1) {
        this.cursor -= 1;
        this.itemView(this.$el.getElementsByClassName('v-autocomplete-list-item')[this.cursor]);
      }
    },
    keyDown() {
      if (this.cursor < this.internalItems.length) {
        this.cursor += 1;
        this.itemView(this.$el.getElementsByClassName('v-autocomplete-list-item')[this.cursor]);
      }
    },
    itemView(item) {
      if (item && item.scrollIntoView) {
        item.scrollIntoView(false);
      }
    },
    keyEnter() {
      if (this.showList && this.internalItems[this.cursor]) {
        this.onSelectItem(this.internalItems[this.cursor]);
        this.showList = false;
      }
    },
  },
  created() {
    utils.minLen = this.minLen;
    utils.wait = this.wait;
    this.onSelectItem(this.value);
  },
  watch: {
    items(newValue) {
      this.setItems(newValue);
      const item = utils.findItem(this.items, this.searchText, this.autoSelectOneItem);
      if (item) {
        this.onSelectItem(item);
        this.showList = false;
      }
    },
    value(newValue) {
      if (newValue !== null && !this.isSelectedValue(newValue)) {
        this.onSelectItem(newValue);
        this.searchText = this.getLabel(newValue);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
</style>
