<template>
  <div class="columns">
    <div class="column col-5 col-md-6 col-sm-12 form-group">
      <label for="insurance" class="form-label">Operadora</label>
      <select id="insurance" name="insurance" class="form-select"
              v-model="form.insurance.plan.id">
        <option value="">[Selecione]</option>
      </select>
    </div>
    <div class="column col-4 col-md-6 col-sm-12 form-group">
      <label for="insurance-record" class="form-label">Carteirinha</label>
      <input type="text" id="insurance-record" name="insurance-record"
             class="form-input" v-model="form.insurance.plan.record"
             placeholder="00000000" v-mask-number autocomplete="nope">
    </div>
    <div class="column col-3 col-md-6 col-sm-12 form-group">
      <label for="insurance-validity" class="form-label">Validade</label>
      <input type="text" id="insurance-validity" name="insurance-validity"
             class="form-input" v-model="form.insurance.plan.validity"
             placeholder="00/00/0000" v-mask-date.br autocomplete="nope">
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      form: {
        insurance: {
          id: '',
          plan: {
            id: '',
            record: '',
            validity: '',
            insured: '',
          },
        },
      },
    };
  },
  validations() {
    const rules = {
      form: {},
    };
    return rules;
  },
  methods: {
    load() {
    },
  },
};
</script>
