export default {
  'Auth.SET_USER': function setUser(state, user) {
    state.user = user;
  },
  'Auth.SET_TOKEN': function setToken(state, token) {
    state.token = token;
  },
  'Auth.SET_MODULE': function setModule(state, module) {
    state.module = module;
  },
};
