<template>
  <div class="toast-wrapper" v-if="show">
    <div class="toast" :class="`toast-${type}`">{{ message }}</div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    message: String,
  },
  methods: {

  },
  mounted() {
    this.$nextTick(() => {
      document.body.appendChild(this.$el);
    });
  },
  destroyed() {
    this.$el.remove();
  },
};
</script>

<style lang="scss">
.toast-wrapper {
  .toast {
    white-space: pre-wrap;
  }
}
</style>
