<template>
  <div class="page-container stock-reports-page">
    <portal to="page-name">Relatórios</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Relatórios</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-4 col-md-12 col-sm-12 form-group">
            <ul class="menu">
              <li class="menu-item" v-for="(menu, i) in menus" :key="i"
                  @click="openPrint(menu.path)">
                {{ menu.label }}
              </li>
            </ul>
          </div>
          <div class="column col-8 col-md-12 col-sm-12 form-group">
            <template v-if="canAccess">
              <product-transaction-report
                v-if="selected === 'product-transaction-report'"
              />
              <stock-entry-report
                v-if="selected === 'stock-entry-report'"
              />
              <stock-output-report
                v-if="selected === 'stock-output-report'"
              />
            </template>
            <forbidden v-else></forbidden>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  REPORT_PRODUCT_MANAGEMENT,
  REPORT_STOCK_ENTRY,
  REPORT_STOCK_OUTPUT,
  // REPORT_STOCK_PRODUCT_TRACEABILITY,
} from '@/data/actions/modules/stock';
import Forbidden from '@/components/auth/Forbidden.vue';
import ProductTransactionReport from './ProductStockReport.vue';
import StockEntryReport from './StockEntryReport.vue';
import StockOutputReport from './StockOutputReport.vue';

export default {
  components: {
    Forbidden,
    ProductTransactionReport,
    StockEntryReport,
    StockOutputReport,
  },
  data() {
    return {
      selected: 'product-transaction-report',
      insurances: [],
      professionals: [],
      expenses: [],
      menus: [
        {
          path: 'product-transaction-report',
          label: 'Movimentação de produtos',
          permission: REPORT_PRODUCT_MANAGEMENT,
        },
        {
          path: 'stock-entry-report',
          label: 'Entrada de produtos',
          permission: REPORT_STOCK_ENTRY,
        },
        {
          path: 'stock-output-report',
          label: 'Saída de produtos',
          permission: REPORT_STOCK_OUTPUT,
        },
        // {
        //   path: 'stock-product-traceability-report',
        //   label: 'Rastreabilidade de produtos',
        //   permission: REPORT_STOCK_PRODUCT_TRACEABILITY,
        // },
      ],
    };
  },
  computed: {
    canAccess() {
      const currentMenu = this.menus.find(({ path }) => path === this.selected);

      if (currentMenu && currentMenu.permission) {
        return this.$can(currentMenu.permission);
      }
      return true;
    },
  },
  methods: {
    openPrint(path) {
      this.selected = path;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .stock-reports-page {
    .menu {
      border-right: $border-width solid $border-color;
      box-shadow: none;
      li {
        padding: $layout-spacing 0;
        border-bottom: $border-width solid $border-color;
        &:hover {
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
</style>
