export const closureReasons = {
  discharge_cured: 'Alta Curado',
  discharge_improved: 'Alta Melhorado',
  discharge_by_request: 'Alta a pedido',
  discharge_with_return: 'Alta com previsão de retorno para acompanhamento do paciente',
  discharge_evasion: 'Alta por Evasão',
  discharge_other_reasons: 'Alta por outros motivos',
  discharge_psychiatry: 'Alta de Paciente Agudo em Psiquiatria',
  permanency_disease: 'Permanência, por características próprias da doença',
  permanency_complication: 'Permanência, por intercorrência',
  permanency_familiar_impossibility: 'Permanência, por impossibilidade sócio-familiar',
  permanency_live_donor: 'Permanência, por Processo de doação de órgãos, tecidos e células - doador vivo',
  permanency_death_donor: 'Permanência, por Processo de doação de órgãos, tecidos e células - doador morto',
  permanency_procedure_change: 'Permanência, por mudança de Procedimento',
  permanency_reoperation: 'Permanência, por reoperação',
  permanency_other_reasons: 'Permanência, outros motivos',
  transferred_another_hospital: 'Transferido para outro estabelecimento',
  transferred_home: 'Transferência para Internação Domiciliar',
  death_assistant_doctor: 'Óbito com declaração de óbito fornecida pelo médico assistente',
  death_iml: 'Óbito com declaração de Óbito fornecida pelo Instituto Médico Legal - IML',
  death_svo: 'Óbito com declaração de Óbito fornecida pelo Serviço de Verificação de Óbito - SVO.',
  administrative_closure: 'Encerramento Administrativo',
  discharge_both_mom_son: 'Alta da mãe/puérpera e do recém-nascido',
  discharge_mon_permanency_newborn: 'Alta da mãe/puérpera e permanência do recém-nascido',
  discharge_mon_death_newborn: 'Alta da mãe/puérpera e óbito do recém-nascido',
  discharge_mon_death_fetal: 'Alta da mãe/puérpera com óbito fetal',
  death_both_mon_son: 'Óbito da gestante e do concepto',
  death_mon_discharge_newborn: 'Óbito da mãe/puérpera e alta do recém-nascido',
  death_mon_permanency_newborn: 'Óbito da mãe/puérpera e permanência do recém-nascido',
};

export function getName(value) {
  return closureReasons[value];
}
