<template>
  <div class="referral-account">
    <forward-modal
      v-if="showComponent.forward"
      :show="showComponent.forward"
      :source="source"
      :data="data"
      :type="type"
      @close="close"
    />
    <insurance-particular-modal
      v-if="showComponent.insurance"
      :show="showComponent.insurance"
      :source="source"
      :data="data"
      :type="type"
      @close="close"
    />
    <insurance-particular-modal
      v-if="showComponent.particular"
      :show="showComponent.particular"
      :source="source"
      :data="data"
      :type="type"
      @close="close"
    />
    <consultation-modal
      v-if="showComponent.consultation"
      :show="showComponent.consultation"
      :source="source"
      :data="data"
      :type="type"
      @close="close"
    />
    <sadt-modal
      v-if="showComponent.sadt"
      :show="showComponent.sadt"
      :source="source"
      :data="data"
      :type="type"
      @close="close"
    />
  </div>
</template>

<script>
import ForwardModal from './Forward.vue';
import InsuranceParticularModal from './InsuranceParticular.vue';
import ConsultationModal from './Consultation.vue';
import SadtModal from './Sadt.vue';

export default {
  props: {
    source: {
      type: String,
    },
    show: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    data: {
      id: {
        type: String,
      },
      appointmentId: {
        type: String,
      },
      professionalId: {
        type: String,
      },
      specialtyCode: {
        type: String,
      },
      invoiceId: {
        type: String,
      },
      key: {
        type: String,
      },
      invoice: {
        id: {
          type: String,
        },
        code: {
          type: String,
        },
      },
      startDate: {
        type: String,
      },
      endDate: {
        type: String,
      },
      appointment: {
        id: {
          type: String,
        },
        name: {
          type: String,
        },
        professional: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
        },
        date: {
          type: Date,
        },
      },
      status: {
        type: String,
      },
      referral: {
        type: {
          type: String,
        },
        provider: {
          type: String,
        },
        insurance: {
          type: String,
        },
        main: {
          type: String,
        },
      },
      authorization: {
        date: {
          type: String,
        },
        password: {
          type: String,
        },
        validity: {
          type: String,
        },
      },
      request: {
        date: {
          type: String,
        },
        professional: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
          council: {
            name: {
              type: String,
            },
            state: {
              type: String,
            },
            record: {
              type: String,
            },
          },
          specialty: {
            code: {
              type: String,
            },
            name: {
              type: String,
            },
          },
        },
      },
      patient: {
        id: {
          type: String,
        },
        name: {
          type: String,
        },
        socialName: {
          type: String,
        },
        birthDate: {
          type: Date,
        },
        gender: {
          type: Date,
        },
        insurance: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
          type: {
            type: String,
          },
          plan: {
            id: {
              type: String,
            },
            name: {
              type: String,
            },
            settings: {
              tiss: {
                enabled: {
                  type: Boolean,
                },
                consultationInSadt: {
                  type: Boolean,
                },
              },
              record: {
                required: {
                  type: Boolean,
                },
                requiredValidity: {
                  type: Boolean,
                },
                digits: {
                  enabled: {
                    type: Boolean,
                  },
                  min: {
                    type: Number,
                  },
                  max: {
                    type: Number,
                  },
                },
                fillValidityWithServiceDate: {
                  type: Boolean,
                },
              },
            },
          },
          insured: {
            record: {
              type: String,
            },
            name: {
              type: String,
            },
            validity: {
              type: Date,
            },
          },
        },
        cns: {
          type: String,
        },
        isNewborn: {
          type: Boolean,
        },
      },
      serviceType: {
        type: String,
      },
      consultationType: {
        type: String,
      },
      character: {
        type: String,
      },
      accidentIndication: {
        type: String,
      },
      justification: {
        type: String,
      },
      clinicalIndication: {
        type: String,
      },
      closureReason: {
        type: String,
      },
      occupationalHealth: {
        type: String,
      },
      serviceRegime: {
        type: String,
      },
      specialCoverage: {
        type: String,
      },
      cashier: {
        type: Object,
      },
      installments: {
        type: Array,
      },
      expenses: {
        type: Array,
      },
      total: {
        type: Number,
      },
      courtesy: {
        type: Boolean,
      },
      paymentDate: {
        type: Date,
      },
    },
  },
  components: {
    ForwardModal,
    InsuranceParticularModal,
    ConsultationModal,
    SadtModal,
  },
  data() {
    return {
      showComponent: {
        forward: false,
        insurance: false,
        particular: false,
        consultation: false,
        sadt: false,
      },
    };
  },
  mounted() {
    if (this.type === 'insurance') {
      this.showComponent.insurance = true;
    } else if (this.type === 'referral') {
      this.showComponent.forward = true;
    } else if (this.type === 'particular') {
      this.showComponent.particular = true;
    } else if (this.type === 'consultation') {
      this.showComponent.consultation = true;
    } else if (this.type === 'sadt') {
      this.showComponent.sadt = true;
    }
  },
  methods: {
    close(account) {
      this.$emit('close', account || null);
    },
  },
};
</script>

<style lang="scss">
  @import "../../../../assets/scss/variables";
  .referral-account {
  }
</style>
