import {
  LIST_TEMPLATES,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
} from '@/data/actions/modules/clinical';

export default [
  {
    path: '/clinical/template-main',
    component: () => import(/* webpackChunkName: "templates" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_TEMPLATES,
    },
  },
  {
    path: '/anamnesis-templates/create',
    component: () => import(/* webpackChunkName: "templates" */ './components/anamnesis/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_TEMPLATE,
    },
  },
  {
    path: '/anamnesis-templates/:id/edit',
    component: () => import(/* webpackChunkName: "templates" */ './components/anamnesis/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_TEMPLATE,
    },
  },
  {
    path: '/appointment-templates/create',
    component: () => import(/* webpackChunkName: "templates" */ './components/appointment/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_TEMPLATE,
    },
  },
  {
    path: '/appointment-templates/:id/edit',
    component: () => import(/* webpackChunkName: "templates" */ './components/appointment/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_TEMPLATE,
    },
  },
  {
    path: '/request-templates/create',
    component: () => import(/* webpackChunkName: "templates" */ './components/request/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_TEMPLATE,
    },
  },
  {
    path: '/request-templates/:id/edit',
    component: () => import(/* webpackChunkName: "templates" */ './components/request/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_TEMPLATE,
    },
  },
  {
    path: '/recipe-templates/create',
    component: () => import(/* webpackChunkName: "templates" */ './components/recipe/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_TEMPLATE,
    },
  },
  {
    path: '/recipe-templates/:id/edit',
    component: () => import(/* webpackChunkName: "templates" */ './components/recipe/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_TEMPLATE,
    },
  },
  {
    path: '/document-templates/create',
    component: () => import(/* webpackChunkName: "templates" */ './components/document/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_TEMPLATE,
    },
  },
  {
    path: '/document-templates/:id/edit',
    component: () => import(/* webpackChunkName: "templates" */ './components/document/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_TEMPLATE,
    },
  },
  {
    path: '/report-templates/create',
    component: () => import(/* webpackChunkName: "templates" */ './components/report/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_TEMPLATE,
    },
  },
  {
    path: '/report-templates/:id/edit',
    component: () => import(/* webpackChunkName: "templates" */ './components/report/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_TEMPLATE,
    },
  },
  {
    path: '/surgery-templates/create',
    component: () => import(/* webpackChunkName: "templates" */ './components/surgery/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_TEMPLATE,
    },
  },
  {
    path: '/surgery-templates/:id/edit',
    component: () => import(/* webpackChunkName: "templates" */ './components/surgery/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_TEMPLATE,
    },
  },
];
