function blankData() {
  return {
    id: null,
    mappingId: '',
    professionals: [], // { id, mappingId, addressMappingId, expenses, insurances }
    expenses: [], // { id, mappingId }
    insurances: [], // { id, mappingId }
  };
}

export default {
  loading: false,
  data: blankData(),
  blankData,
};
