<template>
  <div class="page-container">
    <portal to="page-name">Caixa</portal>
    <div class="card card-page" v-if="hasAccess">
      <div class="card-header">
        <h1 class="card-title">Aporte/Sangria</h1>
      </div>
      <div class="card-body" v-if="loading">
        <div class="loading"></div>
      </div>
      <div class="card-body" v-if="!loading && openedCashiers.length > 0">
        <div class="columns">
          <div class="column col-12" v-if="form.cashier.id">
            <div class="cash-flow-summary">
              Saldo atual: {{ getBalance() | currency }}
            </div>
          </div>
          <div class="column col-4 form-group" :class="{'has-error': $v.form.cashier.id.$error}">
            <label>Caixa</label>
            <select class="form-select" v-model="form.cashier.id"
                    @blur="$v.form.cashier.id.$touch()">
              <option value="">Selecione um caixa</option>
              <option v-for="(item, i) in openedCashiers" :value="item.id" :key="i">
                {{ item.bankAccount.name }}
              </option>
            </select>
          </div>
          <div class="column col-4 form-group" :class="{'has-error': $v.form.operation.$error}">
            <label>Operação</label>
            <select class="form-select" v-model="form.operation" @blur="$v.form.operation.$touch()">
              <option value="">Selecione uma operação</option>
              <option value="incoming">Aporte</option>
              <option value="outgoing">Sangria</option>
            </select>
          </div>
          <div class="column col-4 form-group" :class="{'has-error': $v.form.value.$error}">
            <label>Valor</label>
            <dx-input-number id="value" v-model="form.value" @blur="$v.form.value.$touch()"
                             :precision="2" maxlength="10" class="form-input text-right"/>
          </div>
          <div class="column col-12 form-group">
            <label for="notes">Observação</label>
            <textarea id="notes" class="form-input" type="text" v-model="form.notes" rows="2" />
          </div>
          <div class="column col-1">
            <button class="btn btn-primary" @click="save"
                    :disabled="saving" :class="{loading: saving}">
              Salvar
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" v-else-if="!loading && openedCashiers.length === 0">
        <div class="empty mt-2">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Nenhum caixa aberto</p>
          <p class="empty-subtitle">
            Para realizar a abertura do caixa selecione a opção "Abertura" do menu
          </p>
        </div>
      </div>
    </div>
    <forbidden v-else></forbidden>
  </div>
</template>

<script>
import moment from 'moment';
import { CREATE_CASHIER_CASH_OPERATION } from '@/data/actions/modules/financial';
import { required, minValue } from 'vuelidate/lib/validators';
import Forbidden from '@/components/auth/Forbidden.vue';

export default {
  data() {
    return {
      cashierSettings: [],
      openedCashiers: [],
      saving: false,
      loading: false,
      form: {
        cashier: {
          id: '',
        },
        operation: '',
        value: 0,
        notes: '',
      },
    };
  },
  components: {
    Forbidden,
  },
  computed: {
    hasAccess() {
      return this.$can(CREATE_CASHIER_CASH_OPERATION);
    },
  },
  validations() {
    const rules = {
      form: {
        operation: { required },
        cashier: {
          id: { required },
        },
        value: {
          required,
          minValue: minValue(0.01),
        },
      },
    };
    return rules;
  },
  async mounted() {
    if (this.hasAccess) {
      this.loading = true;
      await this.getCashiers();
      await this.getOpenCashiers();
      if (this.openedCashiers.length === 1) {
        this.form.cashier.id = this.openedCashiers[0].id;
      }
    }
    this.loading = false;
  },
  methods: {
    async getCashiers() {
      await this.$http.get('/cashiers/accounts')
        .then(({ data }) => {
          this.cashierSettings = data.items.map(cashier => ({
            // eslint-disable-next-line no-underscore-dangle
            id: cashier.bankAccount._id,
            name: cashier.bankAccount.name,
          }));
        })
        .catch(() => {});
    },
    async getOpenCashiers() {
      const params = {
        limit: 0,
      };
      await this.$http.get('/cashiers?status=open', { params })
        .then(({ data }) => {
          this.openedCashiers = data.items;
          this.openedCashiers.forEach((openCashier) => {
            const cashier = this.cashierSettings
              .find(item => openCashier.bankAccount.id === item.id);
            if (cashier) {
              openCashier.enabled = true;
            } else {
              openCashier.enabled = false;
            }
          });
          this.openedCashiers = this.openedCashiers
            .filter(openCashier => openCashier.enabled === true);
        })
        .catch(() => {});
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      if (this.form.operation === 'outgoing' && this.getBalance() < this.form.value) {
        this.$toast.show(
          'Não foi possível realizar a operação solicitada, saldo insuficiente!',
          { type: 'error' },
        );
        return;
      }
      this.saving = true;

      const cashier = this.openedCashiers.find(item => item.id === this.form.cashier.id);

      const data = {
        cashierId: this.form.cashier.id,
        operation: this.form.operation,
        bankAccount: cashier.bankAccount,
        value: this.form.value,
        notes: this.form.notes,
      };

      this.$http.post('/cashier-operations', data)
        .then(() => {
          this.openedCashiers.forEach((item) => {
            if (item.id === this.form.cashier.id) {
              item.balance += (this.form.operation === 'incoming'
                ? this.form.value : this.form.value * -1);
            }
          });
          if (this.form.operation === 'incoming') {
            this.$toast.show('Aporte realizado com sucesso!');
          } else {
            this.$toast.show('Sangria realizada com sucesso!');
          }
          this.clearForm();
        })
        .catch(() => {
          this.$toast.show('Não foi possível realizar a operação solicitada', { type: 'error' });
        })
        .finally(() => {
          this.saving = false;
          if (this.openedCashiers.length === 1) {
            this.form.cashier.id = this.openedCashiers[0].id;
          }
        });
    },
    clearForm() {
      this.form.cashier.id = '';
      this.form.operation = '';
      this.form.value = 0;
      this.form.notes = '';
      this.$v.form.$reset();
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    getBalance() {
      if (!this.form.cashier.id) return 0;
      const cashier = this.openedCashiers.find(item => item.id === this.form.cashier.id);
      return cashier.balance;
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/variables';
@import '~assets/scss/mixins';

.cash-flow-summary {
  background-color: $gray-color-ultra-light;
  border: $border-color solid $border-width;
  border-radius: $border-radius;
  margin: $layout-spacing-sm 0 $layout-spacing 0;
  padding: $layout-spacing;
}
</style>
