import {
  LIST_ENTRIES,
  CREATE_ENTRY,
  UPDATE_ENTRY,
} from '@/data/actions/modules/stock';

export default [
  {
    path: '/stock/entries',
    component: () => import(/* webpackChunkName: "entries" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_ENTRIES,
    },
  },
  {
    path: '/stock/entries/create',
    component: () => import(/* webpackChunkName: "entries" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_ENTRY,
    },
  },
  {
    path: '/stock/entries/:id/edit',
    component: () => import(/* webpackChunkName: "entries" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_ENTRY,
    },
  },
];
