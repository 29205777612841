import localforage from 'localforage';
import moment from 'moment';
import { http } from 'src/plugins/http';

export async function surgeryLoadFilters({ state }) {
  const params = {
    limit: 0,
    active: true,
  };

  http.get('/insurances', { params })
    .catch(() => ({ items: [] }))
    .then(({ data }) => {
      state.filter.insurances = data.items;
    });

  http.get('/schedules', {
    params: { ...params, type: 'surgery' },
  })
    .catch(() => ({ items: [] }))
    .then(({ data }) => {
      state.filter.schedules = data.items;
      if (state.filter.schedules.length > 0) {
        state.filter.scheduleId = state.filter.schedules[0].id;
      }
    });
}

function makeFilterParams(state) {
  const params = {
    type: 'surgery',
    date: moment(state.filter.date).format('YYYY-MM-DD'),
  };

  if (state.filter.insuranceId) {
    params.insuranceId = state.filter.insuranceId;
  }

  if (state.filter.scheduleId) {
    params.scheduleId = state.filter.scheduleId;
  }

  if (state.filter.professionalId) {
    params.professionalId = state.filter.professionalId;
  }

  return params;
}

export async function surgeryLoadCalendar({ commit, state }) {
  commit('SurgeryAppointment.SET_LOADING', true);

  const params = makeFilterParams(state);

  http.get('/calendar', { params })
    .then(({ data }) => {
      commit('SurgeryAppointment.SET_SCHEDULES', data.schedules);
      commit('SurgeryAppointment.SET_PROFESSIONALS', data.professionals);
      commit('SurgeryAppointment.SET_INSURANCES', data.insurances);
      commit('SurgeryAppointment.SET_ITEMS', data.items);
    })
    .catch(() => {
      commit('SurgeryAppointment.SET_SCHEDULES', []);
      commit('SurgeryAppointment.SET_PROFESSIONALS', []);
      commit('SurgeryAppointment.SET_INSURANCES', []);
      commit('SurgeryAppointment.SET_ITEMS', []);
    })
    .then(() => {
      commit('SurgeryAppointment.SET_LOADING', false);
    });

  await localforage.setItem('surgeryAppointmentFilters', params);
}
