export function isLiteral(value) {
  return (!!value) && (value.constructor === Object);
}

export function mergeFrom(from, to) {
  if (!to) return from;
  Object.keys(from).forEach((key) => {
    if (key in to) {
      // eslint-disable-next-line no-param-reassign
      from[key] = isLiteral(from[key]) ? mergeFrom(from[key], to[key]) : to[key];
    }
  });
  return from;
}

export function deepClone(data) {
  return JSON.parse(JSON.stringify(data));
}

export function clone(data, deep = false) {
  return deep ? deepClone(data) : { ...data };
}
