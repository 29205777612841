import { withParams } from 'vuelidate/lib';

export default withParams({ type: 'cnpj' }, (value) => {
  if (!value) {
    return true;
  }

  const cnpj = value.replace(/\D/g, '');

  if (cnpj === '' || cnpj.length !== 14) {
    return false;
  }

  return true;
});
