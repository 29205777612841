<template>
  <div class="page-container">
    <portal to="page-name">Caixa</portal>
    <div class="card card-page" v-if="hasAccess">
      <div class="card-header">
        <h1 class="card-title">Fluxo de caixa</h1>
      </div>
      <div class="card-body" v-if="loading">
        <div class="loading loading-lg"></div>
      </div>
      <div class="card-body" v-if="!loading && openedCashiers.length > 0">
        <div class="columns">
          <div class="column col-6" :class="{'has-error': $v.form.cashier.id.$error}">
            <label>Caixa</label>
            <select class="form-select" v-model="form.cashier.id"
                    @blur="$v.form.cashier.id.$touch()"
                    @change="getFinancialTransactions">
              <option value="">Selecione um caixa</option>
              <option v-for="(item, i) in openedCashiers" :value="item.id" :key="i">
                {{ item.bankAccount.name }}
              </option>
            </select>
          </div>
          <div class="column col-6" v-if="form.cashier.id">
            <button class="btn btn-primary mr-1" style="margin-top: 18px"
                    :class="{loading}" :disabled="loading"
                    @click="getFinancialTransactions">Atualizar</button>
            <button class="btn btn-primary" style="margin-top: 18px"
                    :class="{loading: printing}" :disabled="printing"
                    @click="printCashFlow">Imprimir</button>
          </div>
        </div>
        <div class="cash-flow-summary" v-if="!loading && financialTransactions.length > 0">
          <div class="columns">
            <div class="column col-12">
              <div class="stenci-title mb-2">Resumo</div>
            </div>
            <div class="column col-4 form-group">
              Dinheiro: <span class="text-bold">{{ summary.money | currency}}</span>
            </div>
            <div class="column col-4 form-group text-center">
              Cartão de crédito: <span class="text-bold">{{ summary.credit_card | currency }}</span>
            </div>
            <div class="column col-4 form-group text-right">
              PIX: <span class="text-bold">{{ summary.pix | currency }}</span>
            </div>
            <div class="column col-4 form-group">
              Cheque: <span class="text-bold">{{ summary.check | currency}}</span>
            </div>
            <div class="column col-4 form-group text-center">
              Cartão de débito: <span class="text-bold">{{ summary.debit_card | currency }}</span>
            </div>
            <div class="column col-4 form-group text-right">
              DOC/TED: <span class="text-bold">{{ summary.doc_ted | currency }}</span>
            </div>
            <div class="column col-4 form-group text-left">
              Outros: <span class="text-bold">{{ summary.others | currency }}</span>
            </div>
          </div>
        </div>
        <div class="columns" v-if="!loading">
          <div v-if="financialTransactions.length > 0" class="column col-12">
            <table class="table table-hover">
              <thead>
              <tr>
                <th>Data</th>
                <th>Tipo</th>
                <th>Favorecido/Pagador</th>
                <th class="text-right">Valor</th>
                <th class="text-right">Saldo (Dinheiro)</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in financialTransactions" :key="i">
                <td width="120px">{{ dateFormat(item.createdAt) }}</td>
                <td width="120px">
                  <div v-if="item.notes" class="tooltip tooltip-right text-secondary"
                       :data-tooltip="item.notes" :class="{'text-error': item.total < 0}">
                    {{ getFinancialType(item.cashier.operation) }}
                    <fa-icon class="text-gray" :icon="['fal', 'info-circle']"/>
                  </div>
                  <div class="text-secondary" :class="{'text-error': item.total < 0}" v-else>
                    {{ getFinancialType(item.cashier.operation) }}
                  </div>
                </td>
                <td>{{ item.entity.name }}</td>
                <td class="text-right text-secondary" :class="{'text-error': item.total < 0}"
                    width="100px">{{ item.total | currency }}</td>
                <td class="text-right text-secondary" :class="{'text-error': item.balance < 0}"
                    width="120px">{{ item.balance | currency }}</td>
                <td class="text-right" width="120px">
                  <button class="btn btn-sm btn-action btn-icon btn-gray tooltip mr-1"
                          data-tooltip="Imprimir lançamento" @click="print(item.id)"
                          :disabled="printing">
                    <fa-icon :icon="['fal', 'print']"/>
                  </button>
                  <router-link v-if="item.cashier.operation === 'forward'"
                               :to="`/forwards/${item.service}/edit`" target="_blank">
                      <span class="btn btn-sm btn-action btn-icon btn-gray tooltip mr-1"
                          data-tooltip="Exibir lançamento">
                      <fa-icon :icon="['fal', 'pencil']"/>
                    </span>
                  </router-link>
                  <button v-else class="btn btn-sm btn-action btn-icon btn-gray tooltip mr-1"
                          data-tooltip="Exibir lançamento" @click="edit(item.id)">
                    <fa-icon :icon="['fal', 'pencil']"/>
                  </button>
                  <button class="btn btn-sm btn-action btn-icon btn-gray tooltip tooltip-left"
                          data-tooltip="Excluir lançamento" @click="remove(item.id)"
                          :disabled="deleting">
                    <fa-icon :icon="['fal', 'times']"/>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="column col-12">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Caixa sem movimentação</p>
              <p class="empty-subtitle">
                Nenhuma lançamento encontrado para o caixa selecionado
              </p>
            </div>
          </div>
        </div>
        <div class="card-footer" v-if="!loading && financialTransactions.length > 0">
          <div class="columns">
            <div class="col-6">
              <span>Movimentação caixa:
                <span class="ml-2 text-bold"
                      :class="summary.balance < 0 ? 'text-error' : 'text-primary'">
                  {{ summary.balance | currency }}
                </span>
              </span>
            </div>
            <div class="col-6 text-right">
              <span>Saldo (dinheiro):
                <span class="ml-2 text-primary text-bold">{{ summary.money | currency }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" v-if="!loading && openedCashiers.length === 0">
        <div class="empty mt-2">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Nenhum caixa aberto</p>
          <p class="empty-subtitle">
            Para realizar a abertura do caixa selecione a opção "Abertura" do menu
          </p>
        </div>
      </div>
    </div>
    <forbidden v-else></forbidden>
    <operation-item-modal :show="operationModal.show"
                    :data="operationModal.data"
                    v-if="operationModal.show"
                    @close="hideOperationModal"/>
    <referral-modal :show="accountModal.show"
                    :data="accountModal.data"
                    :type="accountModal.type"
                    :source="'appointments'"
                    v-if="accountModal.show"
                    @close="hideAccountModal" />
    <detail-modal
      id="modal-detail"
      v-if="financialModal.show"
      :show="financialModal.show"
      :transactionId="financialModal.id"
      @close="hideFinancialModal"
    />
  </div>
</template>

<script>
import moment from 'moment';
import {
  LIST_CASHIER_TRANSACTIONS,
  PRINT_CASHIER_TRANSACTION_LIST,
} from '@/data/actions/modules/financial';
import { required } from 'vuelidate/lib/validators';
import Forbidden from '@/components/auth/Forbidden.vue';
import ReferralModal from '../../appointment/components/modals/Referral.vue';
import DetailModal from '../../billing/transaction/components/Detail.vue';
import OperationItemModal from './OperationItem.vue';

export default {
  data() {
    return {
      loading: false,
      printing: false,
      deleting: false,
      cashierSettings: [],
      openedCashiers: [],
      financialTransactions: [],
      operationModal: {
        data: {
          id: '',
          cashier: {
            id: '',
            name: '',
          },
          type: '',
          value: 0,
          notes: '',
        },
        show: false,
      },
      financialModal: {
        id: '',
        show: '',
      },
      accountModal: {
        type: '',
        data: {},
        show: false,
      },
      summary: {
        balance: 0,
        money: 0,
        check: 0,
        credit_card: 0,
        debit_card: 0,
        doc_ted: 0,
        pix: 0,
        others: 0,
      },
      form: {
        cashier: {
          id: '',
          name: '',
          value: 0,
        },
      },
    };
  },
  components: {
    Forbidden,
    ReferralModal,
    DetailModal,
    OperationItemModal,
  },
  computed: {
    hasAccess() {
      return this.$can(LIST_CASHIER_TRANSACTIONS);
    },
    allowPrint() {
      return this.$can(PRINT_CASHIER_TRANSACTION_LIST);
    },
  },
  validations() {
    const rules = {
      form: {
        cashier: {
          id: { required },
        },
      },
    };
    return rules;
  },
  async mounted() {
    if (this.hasAccess) {
      this.loading = true;
      await this.getCashiers();
      await this.getOpenCashiers();
    }
    this.loading = false;
  },
  methods: {
    async getCashiers() {
      await this.$http.get('/cashiers/accounts')
        .then(({ data }) => {
          this.cashierSettings = data.items.map(cashier => ({
            // eslint-disable-next-line no-underscore-dangle
            id: cashier.bankAccount._id,
            name: cashier.bankAccount.name,
            categories: cashier.categories,
          }));
        })
        .catch(() => {});
    },
    async getOpenCashiers() {
      const params = {
        limit: 0,
      };
      await this.$http.get('/cashiers?status=open', { params })
        .then(({ data }) => {
          this.openedCashiers = data.items;
          this.openedCashiers.forEach((openCashier) => {
            const cashier = this.cashierSettings
              .find(item => openCashier.bankAccount.id === item.id);
            if (cashier) {
              openCashier.categories = cashier.categories;
              openCashier.enabled = true;
            } else {
              openCashier.enabled = false;
            }
          });
          this.openedCashiers = this.openedCashiers
            .filter(openCashier => openCashier.enabled === true);
          if (this.openedCashiers.length === 1) {
            this.form.cashier.id = this.openedCashiers[0].id;
            this.form.cashier.name = this.openedCashiers[0].bankAccount.name;
            this.getFinancialTransactions();
          }
        })
        .catch(() => {});
    },
    async getFinancialTransactions() {
      if (!this.form.cashier.id) {
        this.financialTransactions = [];
        return;
      }
      this.loading = true;
      await this.$http.get(`/cashiers/${this.form.cashier.id}/cash-flow`)
        .then(({ data }) => {
          data.items.forEach((item) => {
            item.balance = 0;
          });
          this.financialTransactions = data.items;
          this.calculateBalance();
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    calculateBalance() {
      let balance = 0;
      this.summary = {
        balance: 0,
        money: 0,
        check: 0,
        credit_card: 0,
        debit_card: 0,
        doc_ted: 0,
        pix: 0,
      };
      this.financialTransactions.forEach((financialItem) => {
        financialItem.installments.forEach((installment) => {
          switch (installment.type) {
            case 'credit_card':
              this.summary.credit_card += (
                financialItem.type === 'revenue'
                  ? installment.value.current : -1 * installment.value.current
              );
              break;
            case 'debit_card':
              this.summary.debit_card += (
                financialItem.type === 'revenue'
                  ? installment.value.current : -1 * installment.value.current
              );
              break;
            case 'check':
              this.summary.check += (
                financialItem.type === 'revenue'
                  ? installment.value.current : -1 * installment.value.current
              );
              break;
            case 'pix':
              this.summary.pix += (
                financialItem.type === 'revenue'
                  ? installment.value.current : -1 * installment.value.current
              );
              break;
            case 'doc':
              this.summary.doc_ted += (
                financialItem.type === 'revenue'
                  ? installment.value.current : -1 * installment.value.current
              );
              break;
            case 'ted':
              this.summary.doc_ted += (
                financialItem.type === 'revenue'
                  ? installment.value.current : -1 * installment.value.current
              );
              break;
            case 'money':
              this.summary.money += (
                financialItem.type === 'revenue'
                  ? installment.value.current : -1 * installment.value.current
              );
              break;
            default:
              this.summary.others += (
                financialItem.type === 'revenue'
                  ? installment.value.current : -1 * installment.value.current
              );
              break;
          }
          if (financialItem.cashier.operation !== 'incoming'
             && financialItem.cashier.operation !== 'outgoing') {
            this.summary.balance += (
              financialItem.type === 'revenue'
                ? installment.value.current : -1 * installment.value.current
            );
          }
          if (installment.type === 'money') {
            balance += (
              financialItem.type === 'revenue'
                ? installment.value.current : -1 * installment.value.current
            );
          }
        });
        financialItem.balance = balance;
      });
    },
    remove(id) {
      const financialItem = this.financialTransactions.find(item => item.id === id);

      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este lançamento?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              let path = 'financial-transactions';
              if (financialItem.cashier.operation === 'incoming'
                || financialItem.cashier.operation === 'outgoing') {
                path = 'cashier-operations';
              }
              this.$http.delete(`/${path}/${id}`)
                .then(() => {
                  const idx = this.financialTransactions.indexOf(financialItem);
                  this.financialTransactions.splice(idx, 1);
                  this.calculateBalance();
                  this.$toast.show('Lançamento removido com sucesso!');
                })
                .catch((e) => {
                  let errorMessage = 'Ocorreu um erro ao realizar a operação solicitada!';
                  if (e.response) {
                    if (e.response.status === 403) {
                      errorMessage = 'Acesso não autorizado';
                    } else if (e.response.data && e.response.data.message) {
                      errorMessage = e.response.data.message;
                    }
                  }
                  this.$toast.show(errorMessage, { type: 'error' });
                })
                .finally(() => {
                  this.deleting = false;
                  close();
                });
            },
          },
        ],
      });
    },
    print(id) {
      this.printing = true;
      const financialItem = this.financialTransactions.find(item => item.id === id);
      if (financialItem.cashier.operation === 'attendance') {
        return this.$file
          .print(`/billing-accounts/${financialItem.service}/print`)
          .then(() => {
            this.printing = false;
          });
      }
      if (financialItem.cashier.operation === 'forward') {
        return this.$file
          .print(`/forwards/${financialItem.service}/print`)
          .then(() => {
            this.printing = false;
          });
      }
      if (financialItem.cashier.operation === 'incoming'
        || financialItem.cashier.operation === 'outgoing') {
        return this.$file
          .print(`/cashier-operations/${financialItem.id}/print`)
          .then(() => {
            this.printing = false;
          });
      }
      return this.$file
        .print(`/financial-transactions/${financialItem.id}/print`)
        .then(() => {
          this.printing = false;
        });
    },
    printCashFlow() {
      if (!this.form.cashier.id) {
        return false;
      }

      if (!this.allowPrint) {
        this.$toast.error('Permissão negada!');
        return false;
      }

      this.printing = true;
      return this.$file
        .print(`/cashiers/${this.form.cashier.id}/print-cash-flow`)
        .catch((e) => {
          let errorMessage = 'Ocorreu um erro ao realizar a impressão!';
          if (e.response) {
            if (e.response.status === 403) {
              errorMessage = 'Acesso não autorizado';
            } else if (e.response.data && e.response.data.message) {
              errorMessage = e.response.data.message;
            }
          }
          this.$toast.show(errorMessage, { type: 'error' });
        })
        .finally(() => {
          this.printing = false;
        });
    },
    edit(id) {
      const financialItem = this.financialTransactions.find(item => item.id === id);
      if (financialItem.cashier.operation === 'attendance') {
        this.editBillingAccount(financialItem.service);
      } else if (financialItem.cashier.operation === 'expense') {
        this.financialModal.id = financialItem.id;
        this.financialModal.show = true;
      } else if (financialItem.cashier.operation === 'revenue') {
        this.financialModal.id = financialItem.id;
        this.financialModal.show = true;
      } else {
        this.operationModal.data = {
          id: financialItem.id,
          cashier: {
            id: this.form.cashier.id,
            name: this.form.cashier.name,
          },
          type: financialItem.cashier.operation,
          value: financialItem.total,
          notes: financialItem.notes,
        };
        this.operationModal.show = true;
      }
    },
    async editBillingAccount(accountId) {
      let account;
      await this.$http.get(`/billing-accounts/${accountId}`)
        .then(({ data }) => {
          const professional = data.expenses.length > 0
            ? data.expenses[0].professional
            : null;
          if (data.invoice && data.invoice.id) {
            data.invoiceId = data.invoice.id;
          }
          data.professionalId = professional.id;
          data.specialtyCode = professional.specialty
            ? professional.specialty.code
            : null;
          data.expenses.forEach((expense) => {
            expense.accountId = accountId;
            expense.execution = {
              date: moment(expense.startDate).format('YYYY-MM-DD'),
              time: moment(expense.startDate).format('HH:mm'),
              duration: moment.duration(moment(expense.endDate)
                .diff(moment(expense.startDate))).asMinutes(),
            };
            expense.rules = {
              requireAuthorization: false,
              requireClinicalIndication: false,
              requireWayTechnique: false,
            };
          });
          account = data;
        });

      const params = {
        expenseIds: account.expenses.map(({ id }) => id).join(','),
      };
      await this.$http.get(
        `/expense-insurances/${account.patient.insurance.plan.id}/rules`,
        { params },
      ).then(({ data }) => {
        if (data.items) {
          if (data.items.length > 0) {
            data.items.forEach((item) => {
              account.expenses.forEach((expense) => {
                if (item.id === expense.id) {
                  if (item.rules) {
                    expense.rules = item.rules;
                  }
                }
              });
            });
          }
        }
      });
      this.accountModal.data = account;
      this.accountModal.type = account.referral.type;
      this.accountModal.show = true;
    },
    clearForm() {
      this.form.cashier.id = '';
      this.form.cashier.name = '';
      this.$v.form.cashier.$reset();
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    getFinancialType(operation) {
      switch (operation) {
        case 'incoming': return 'Aporte';
        case 'outgoing': return 'Sangria';
        case 'revenue': return 'Receita';
        case 'expense': return 'Despesa';
        case 'forward': return 'Encaminhamento';
        default: return 'Atendimento';
      }
    },
    hideAccountModal() {
      this.accountModal.show = false;
      this.getFinancialTransactions();
    },
    hideFinancialModal(save) {
      this.financialModal.show = false;
      if (save) this.getFinancialTransactions();
    },
    hideOperationModal(save) {
      this.operationModal.show = false;
      if (save) this.getFinancialTransactions();
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/variables';
@import '~assets/scss/mixins';

.cash-flow-summary {
  background-color: $gray-color-ultra-light;
  border: $border-color solid $border-width;
  border-radius: $border-radius;
  margin: $layout-spacing-sm 0 $layout-spacing 0;
  padding: $layout-spacing;
}
.footer {
  text-align: right;
}
</style>
