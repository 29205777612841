<template>
  <nav>
    <router-link to="/appointments">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'calendar-alt']"/>
      </div>
      <span class="side-menu-label">Agenda</span>
    </router-link>
    <router-link to="/asos">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'user-hard-hat']"/>
      </div>
      <span class="side-menu-label">ASO</span>
    </router-link>
    <router-link to="/clients">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'building']"/>
      </div>
      <span class="side-menu-label">Empresas</span>
    </router-link>
    <router-link to="/patients">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'user-friends']"/>
      </div>
      <span class="side-menu-label">Pacientes</span>
    </router-link>
  </nav>
</template>

<script>
export default {};
</script>
