<template>
  <div class="page-container marketing-settings-page">
    <portal to="page-name">Configurações</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Configurações</h1>
      </div>
      <div class="card-body">
        <div class="loading loading-lg mt-2" v-if="loading" />
        <table class="table" v-else>
          <thead>
          <tr>
            <th>Parâmetros</th>
            <th>Status</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <setting-row
            v-for="item in settings"
            :item="item"
            :key="item.key"
            @openMessageModal="openMessageModal"
          />
          </tbody>
        </table>
      </div>
      <div class="card-footer">
      </div>
    </div>
    <dx-modal id="modal-message" title="Mensagem"
              v-model="messageModal.show">
      <div class="divider=" data-content="Informações para o paciente" />
      <div class="btn-group btn-group-block mb-1">
        <button class="btn btn-sm btn-gray mr-1"
                @click="insertTag('date')">
          Data atual
        </button>
        <button class="btn btn-sm btn-gray mr-1"
                @click="insertTag('patient_name')">
          Nome do paciente
        </button>
        <button class="btn btn-sm btn-gray mr-1"
                @click="insertTag('patient_birth_date')">
          Data de Nascimento
        </button>
        <button class="btn btn-sm btn-gray mr-1"
                @click="insertTag('patient_age')">
          Idade
        </button>
        <button class="btn btn-sm btn-gray"
                @click="insertTag('patient_phone')">
          Telefone
        </button>
      </div>
      <form-input
        :type="messageModal.data.type"
        :label="messageModal.data.label"
        v-model="messageModal.data.value"
        :options="messageModal.data.options"
        :params="messageModal.data.params || {}"
      />
      <template slot="footer">
        <button class="btn btn-primary mr-1"
                @click="save">Salvar</button>
        <button class="btn" @click="messageModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { capitalize } from '@/filters/capitalize';
import settings from '@/data/settings';
import FormInput from './Input.vue';
import SettingRow from './SettingRow.vue';

export default {
  components: {
    FormInput,
    SettingRow,
  },
  data() {
    return {
      loading: false,
      messageModal: {
        show: false,
        data: {},
      },
      settings: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      return this.$http.get('/customer-settings')
        .then(({ data }) => {
          this.settings = data
            .filter(({ key }) => key.startsWith('relationship.'));
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    openMessageModal(item) {
      this.messageModal.data.key = item.key;
      this.messageModal.data.type = item.additional.type;
      this.messageModal.data.value = item.additional.default;
      this.messageModal.data.options = item.additional.options;
      this.messageModal.data.params = item.additional.params;
      this.messageModal.show = true;
    },
    label(key) {
      return key in settings
        ? settings[key]
        : (
          key.split('.')
            .map(v => v.replace(/[A-Z]/g, $ => ` ${$.toUpperCase()}`))
            .join(' ')
            .trim()
            .split(' ')
            .map(v => capitalize(v))
            .join(' ')
        );
    },
    insertTag(item) {
      const found = this.settings.find(({ key }) => key === this.messageModal.data.key);
      if (found) {
        found.additional.default += `[${item}]`;
      }
    },
    save() {
      const found = this.settings.find(({ key }) => key === this.messageModal.data.key);
      if (found) {
        found.additional.default = this.messageModal.data.value;
        this.$http
          .put('/customer-settings', {
            key: found.key,
            value: found.value,
            additional: this.messageModal.data.value,
          })
          .catch(() => {})
          .then(() => {
            this.saving = false;
          });
      }

      this.messageModal.show = false;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  @import "./src/assets/scss/mixins";

  .marketing-settings-page {
  }
</style>
