import StringMask from 'string-mask';

const mask = new StringMask('00.000.000/0000-00');

export default (value) => {
  if (!value) {
    return '';
  }

  return mask.apply(value.toString().replace(/\D/g, ''));
};
