<template>
  <div class="appointment-by-room-report">
    <h6>Relatório de agendamentos por sala</h6>
    <div class="columns">
      <div class="column col-3 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.startDate.$error}">
        <label class="form-label">Data início</label>
        <div class="input-group">
          <dx-input-date
            id="appointment-patient-birth-date"
            class="form-input text-center"
            v-model="filters.startDate"
            @blur="$v.filters.startDate.$touch()"
          />
        </div>
        <template v-if="$v.filters.startDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.startDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.startDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-3 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.endDate.$error}">
        <label class="form-label">Data término</label>
        <dx-input-date
          id="end-date"
          class="form-input text-center"
          v-model="filters.endDate"
          @blur="$v.filters.endDate.$touch()"
        />
        <template v-if="$v.filters.endDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.endDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.endDate.date" >Data inválida</div>
        </template>
      </div>
    </div>
    <div class="room-list" style="height: 15rem">
      <div class="scroll-list-wrapper">
        <div class="scroll-list">
          <div class="list-header">
            <label class="form-checkbox">
              <input type="checkbox"
                     @change="selectAllPlaces"
                     :checked="getPlacesSelected"
                     :indeterminate.prop="getPlacesSelected === null">
              <i class="form-icon"></i>
            </label>
            <div class="list-title">Salas</div>
          </div>
          <table class="table table-hover">
            <tbody>
            <tr v-for="(item, i) in places" :key="i">
              <td width="30px">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>{{ item.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-primary btn-icon-left"
              :class="{loading: printing}"
              :disabled="printing"
              @click="print">
        <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';
import formMixin from '@/mixins/form';

export default {
  mixins: [formMixin],
  data() {
    return {
      printing: false,
      places: [],
      filters: {
        startDate: '',
        endDate: '',
      },
    };
  },
  validations() {
    const rules = {
      filters: {
        startDate: { date, required },
        endDate: { date, required },
      },
    };
    if (this.filters.startDate) {
      rules.filters.endDate = {
        date,
        required,
        minDate: minDate(this.filters.startDate),
      };
    }

    return rules;
  },
  mounted() {
    this.filters.startDate = moment()
      .startOf('month')
      .format('YYYY-MM-DD');
    this.filters.endDate = moment()
      .endOf('month')
      .format('YYYY-MM-DD');
    this.loadPlaces();
  },
  computed: {
    getPlacesSelected() {
      if (!this.places || this.places.length === 0) {
        return false;
      }

      const selected = this.places.filter(item => item.selected);
      if (selected.length === 0) {
        return false;
      }
      if (selected.length === this.places.length) {
        return true;
      }
      return null;
    },
  },
  methods: {
    selectAllPlaces(e) {
      this.places.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    loadPlaces() {
      const params = {
        active: true,
        type: 'clinical',
      };
      return this.$http.get('/places', { params })
        .then(({ data }) => {
          this.places = data.items.map(item => ({
            id: item.id,
            name: item.name,
            selected: true,
          }));
        })
        .catch(() => {});
    },
    print() {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return false;
      }

      const diff = moment(this.filters.endDate)
        .diff(moment(this.filters.startDate), 'months');

      if (diff > 3) {
        this.$toast.show('Período máximo (3 meses)', { type: 'error' });
        return null;
      }

      const placeIds = this.places
        .filter(({ selected }) => selected)
        .map(item => item.id).join(',');

      if (!placeIds) {
        this.$toast.error('Selecione uma ou mais salas!');
        return false;
      }
      this.printing = true;

      const params = {
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
        placeIds,
      };

      return this.$file
        .print('/appointments/print-places', params)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  @import '~assets/scss/mixins';
  .appointment-by-room-report {
    .room-list {
      display: flex;
      flex-direction: column;
      height: 40rem;
      overflow-y: auto;
      .list-header {
        align-items: center;
        border-bottom: $border-width solid $border-color;
        display: flex;
        padding-bottom: $layout-spacing;
        .list-title {
          flex: 1;
          font-weight: bold;
        }
      }
      .scroll-list-wrapper {
        border: $border-color solid $border-width;
        border-radius: $border-radius;
        flex-grow: 1;
        margin-bottom: $layout-spacing-lg * 2;
        position: relative;
      }
      .scroll-list {
        bottom: 0;
        overflow-y: auto;
        padding: $layout-spacing $layout-spacing-lg;
        position: absolute;
        top: 0;
        width: 100%;
        @include scroll-bar();
        .table th, .table td {
          padding: 0;
        }
        .table td {
          border-bottom: none;
        }
      }
    }
    .footer {
      text-align: right;
    }
  }
</style>
