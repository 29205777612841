<template>
  <div class="entity-files-manager">
    <div class="upload-area" :class="{dragging: upload.dragging}" v-if="canUpload">
      <input type="file" class="input-file" multiple="multiple"
             @dragenter="upload.dragging = true"
             @dragleave="upload.dragging = false"
             @drop="upload.dragging = false"
             @change="uploadChange">
      <div class="upload-inner">
        <fa-icon :icon="['fal', 'upload']"></fa-icon>
        <p>
          Arraste seus arquivos aqui<br>
          ou clique para selecionar<br>
          <small>(20mb max)</small>
        </p>
      </div>
    </div>
    <template v-if="files.length === 0">
      <div class="empty mt-2">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
        </div>
        <div class="empty-title h6">Anexos</div>
        <div class="empty-subtitle">
          <slot>Nenhuma arquivo anexado</slot>
        </div>
      </div>
    </template>
    <div class="columns" v-else>
      <div class="column col-6 col-lg-12 col-xl-6" v-for="(file, idx) in files" :key="idx">
        <div class="tile tile-file" :class="{'upload-failed': file.error}">
          <div class="tile-icon">
            <fa-icon :icon="['fal', file.error ? 'times' : fileIcon(file)]"
                     :class="{'text-error': file.error}" />
          </div>
          <div class="tile-content">
            <div v-if="file.editing" class="input-group"
                 :class="{'has-error': file.description.trim() === ''}">
              <input type="text" class="form-input input-sm" v-model="file.description"
                     @keypress.enter="updateFile(file)">
              <button class="btn btn-gray btn-icon btn-sm input-group-btn"
                      @click="updateFile(file)">
                <fa-icon :icon="['fal', 'check']"></fa-icon>
              </button>
            </div>
            <div class="tile-title" v-else>{{ file.description }}</div>
            <small class="tile-subtitle text-gray" v-if="file.date">
              {{ file.date | date('DD/MM/YYYY HH:mm') }}
            </small>
            <small v-else-if="file.error"
                   class="text-error text-bold">Falha no envio do arquivo</small>
            <small v-else>--</small>
            <div class="file-actions" v-if="file.id && !file.editing">
              <a @click="file.editing=true" v-if="canEdit">editar</a>
              <span v-if="canEdit && canDelete"> | </span>
              <a @click="removeFile(file, idx)" v-if="canDelete">excluir</a>
            </div>
          </div>
          <div class="tile-action">
            <div class="loading" v-if="file.uploading"></div>
            <template v-else-if="file.url">
              <a :href="file.url" target="_blank">
                <fa-icon :icon="['fal', 'external-link-square']"></fa-icon>
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileManager',
  props: {
    entityId: {
      type: String,
      required: true,
    },
    originId: {
      type: String,
    },
    originType: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    listAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      files: [],
      upload: {
        files: [],
        dragging: false,
      },
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    listAll: function loadImages() {
      this.load();
    },
  },
  methods: {
    load() {
      this.$emit('loadingFiles', true);

      const params = {};
      if (this.origin && !this.listAll) {
        params.origin = this.origin;
      }

      this.$httpX.get(`/entities/${this.entityId}/files`, { params })
        .then(({ data }) => {
          this.files = data.map(this.parseFileData);
        })
        .catch(() => {})
        .finally(() => {
          this.$emit('loadingFiles', false);
        });
    },
    uploadChange(e) {
      e.preventDefault();
      if (e.target.files.length) {
        this.upload.files = e.target.files;

        Array.from(this.upload.files).forEach((file) => {
          const item = this.parseFileData({
            extension: file.name.split('.').pop(),
            description: file.name,
            uploading: true,
          });

          this.files.push(item);

          const formData = new FormData();
          formData.append('file', file);

          let path = `/entities/${this.entityId}/files`;
          if (this.origin) {
            path += `?origin=${this.origin}`;
          }

          this.$http.post(path, formData)
            .then(({ data }) => {
              Object.assign(item, data, { uploading: false });
            })
            .catch(() => {
              item.error = true;
              item.uploading = false;
            });
        });
      } else {
        this.upload.files = [];
      }
    },
    updateFile(file) {
      if (file.description.trim() === '') return;

      file.uploading = true;
      this.$http.put(`/files/${file.id}`, { description: file.description })
        .then(() => {
          file.editing = false;
        })
        .catch(() => {})
        .then(() => {
          file.uploading = false;
        });
    },
    removeFile(file, index) {
      file.uploading = true;
      this.$http.delete(`/files/${file.id}`)
        .then(() => {
          this.files.splice(index, 1);
        })
        .catch(() => {})
        .then(() => {
          file.uploading = false;
        });
    },
    parseFileData(data) {
      return {
        id: null,
        url: null,
        extension: '',
        description: '',
        uploading: false,
        editing: false,
        date: null,
        error: false,
        ...data,
      };
    },
    fileIcon(file) {
      switch (file.extension) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'tiff':
        case 'gif':
          return 'file-image';
        case 'pdf':
          return 'file-pdf';
        case 'doc':
        case 'docx':
          return 'file-word';
        case 'xls':
        case 'xlsx':
        case 'csv':
          return 'file-excel';
        default:
          return 'file';
      }
    },
  },
  computed: {
    origin() {
      return this.originType && this.originId
        ? [this.originType, this.originId].join(':')
        : null;
    },
    canUpload() {
      return !this.readonly;
    },
    canEdit() {
      return !this.readonly;
    },
    canDelete() {
      return !this.readonly;
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.entity-files-manager {
  .upload-area {
    align-items: center;
    background-color: lighten($gray-color-light, 5%);
    border: lighten($gray-color, 5%) $unit-h dashed;
    display: flex;
    height: 7rem;
    justify-content: center;
    margin-top: $layout-spacing;
    position: relative;
    &.dragging {
      border-color: $primary-color;
    }
    .input-file {
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .upload-inner {
      display: flex;
      align-items: center;
    }
    svg {
      font-size: 1.8rem;
    }
    p {
      margin: 0 0 0 $layout-spacing;
      text-align: center;
    }
  }

  .tile-file {
    background-color: lighten($gray-color-light, 5%);
    border-radius: $border-radius;
    margin-top: $layout-spacing;
    min-height: 3.5rem;
    .tile-icon {
      align-self: stretch;
      align-items: center;
      border-radius: $border-radius 0 0 $border-radius;
      display: flex;
      background-color: $gray-color-light;
      justify-content: center;
      width: 3rem;
      svg {
        font-size: 1.5rem;
        color: $gray-color-dark;
      }
    }
    .tile-content {
      align-self: center;
      padding: $layout-spacing;
    }
    .tile-title {
      line-height: 1;
    }
    .tile-subtitle {
      line-height: 1;
    }
    .file-actions {
      font-size: $font-size-sm;
      margin-top: $layout-spacing-sm;
      a {
        cursor: pointer;
        color: $gray-color-dark;
        font-size: $font-size-sm;
        transition: color .3s ease;
      }
    }
    .tile-action {
      padding: $layout-spacing;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      a {
        display: block;
      }
      svg {
        font-size: 1.2rem;
        color: $gray-color-dark;
        display: block;
      }
      .loading {
        width: $layout-spacing-lg;
      }
    }
    &:hover {
      .file-actions {
        a {
          color: $primary-color;
        }
      }
    }
  }

  .empty {
    padding: $layout-spacing-lg;
    .empty-icon {
      margin: $layout-spacing 0;
    }
  }
}
</style>
