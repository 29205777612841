<template>
  <div class="page-container">
    <portal to="page-name">Caixa</portal>
    <div class="card card-page" v-if="hasAccess">
      <div class="card-header">
        <h1 class="card-title">Abertura de caixa</h1>
      </div>
      <div class="card-body" v-if="loading">
        <div class="loading"></div>
      </div>
      <div class="card-body" v-if="!loading">
        <div class="columns" v-if="closedCashiers.length > 0">
          <div class="column col-6 form-group" :class="{'has-error': $v.form.cashier.id.$error}">
            <label>Caixa</label>
            <select class="form-select" v-model="form.cashier.id"
                    @blur="$v.form.cashier.id.$touch()">
              <option value="">Selecione um caixa</option>
              <option v-for="(item, i) in closedCashiers" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
            <template v-if="$v.form.cashier.id.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.cashier.id.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-3 form-group" :class="{'has-error': $v.form.initialValue.$error}">
            <label>Aporte inicial</label>
            <dx-input-number id="value" v-model="form.initialValue"
                             class="form-input text-right"
                             :precision="2" maxlength="10"/>
            <template v-if="$v.form.initialValue.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.initialValue.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-12 form-group">
            <label for="notes">Observação</label>
            <textarea id="notes" class="form-input" type="text" v-model="form.notes" rows="2" />
          </div>
          <div class="column col-2">
            <button class="btn btn-primary" :disabled="saving" :class="{loading: saving}"
                    @click="openCashier">Realizar abertura</button>
          </div>
        </div>
        <div class="empty mt-2" v-else-if="cashiers.length > 0 && closedCashiers.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Nenhum caixa a ser aberto</p>
          <p class="empty-subtitle">
            Todos os caixas estão abertos, para realizar o fechamento acesse o menu "Fechamento"
          </p>
        </div>
        <div class="empty mt-2" v-else-if="cashiers.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Nenhum caixa vinculado a este usuário</p>
          <p class="empty-subtitle">
            Acesse o menu "Configuração" para vincular o teu usuário a um caixa já existente,
            caso não tenha permissão de acesso entre em contato com o administrador da
            clínica/hospital
          </p>
        </div>
        <br />
        <div class="columns">
          <div v-if="openedCashiers.length > 0" class="column col-12">
            <table class="table table-hover">
              <thead>
              <tr>
                <th>Data</th>
                <th>Caixa</th>
                <th>Usuário</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(cashier, i) in openedCashiers" :key="i">
                <td>{{ dateFormat(cashier.opening.date) }}</td>
                <td>{{ cashier.bankAccount.name }}</td>
                <td>{{ cashier.opening.user.name }}</td>
                <td class="text-right" width="80px">
                  <button class="btn btn-sm btn-action btn-icon btn-gray tooltip tooltip-left mr-1"
                          data-tooltip="Imprimir relatório de abertura"
                          :disabled="printing"
                          :class="{ loading: printing }"
                          @click="print(cashier.id)">
                    <fa-icon :icon="['fal', 'print']"/>
                  </button>
                  <button class="btn btn-sm btn-action btn-icon btn-gray tooltip tooltip-left"
                          data-tooltip="Cancelar abertura"
                          :disabled="deleting"
                          @click="cancelCashier(cashier.id)">
                    <fa-icon :icon="['fal', 'times']"/>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else-if="cashiers.length > 0 && openedCashiers.length === 0" class="column col-12">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Nenhum caixa aberto</p>
              <p class="empty-subtitle">
                Selecione o caixa, informe o aporte inicial e clique no botão "Realizar abertura"
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <forbidden v-else></forbidden>
  </div>
</template>

<script>
import moment from 'moment';
import { OPEN_CASHIER } from '@/data/actions/modules/financial';
import { required } from 'vuelidate/lib/validators';
import Forbidden from '@/components/auth/Forbidden.vue';

export default {
  data() {
    return {
      cashiers: [],
      openedCashiers: [],
      loading: false,
      saving: false,
      printing: false,
      deleting: false,
      form: {
        cashier: {
          id: '',
          value: 0,
        },
        initialValue: 0,
        notes: '',
      },
    };
  },
  components: {
    Forbidden,
  },
  computed: {
    hasAccess() {
      return this.$can(OPEN_CASHIER);
    },
    closedCashiers() {
      const openedCashiers = this.openedCashiers.map(item => item.bankAccount.id);
      return this.cashiers.filter(item => openedCashiers.indexOf(item.id) === -1);
    },
  },
  validations() {
    const rules = {
      form: {
        cashier: {
          id: { required },
        },
        initialValue: {},
      },
    };
    return rules;
  },
  async mounted() {
    if (this.hasAccess) {
      this.loading = true;
      await this.getCashiers();
      await this.getOpenCashiers();
      if (this.closedCashiers.length === 1) {
        this.form.cashier.id = this.closedCashiers[0].id;
      }
      this.loading = false;
    }
  },
  methods: {
    async getCashiers() {
      await this.$http.get('/cashiers/accounts')
        .then(({ data }) => {
          this.cashiers = data.items.map(cashier => ({
            // eslint-disable-next-line no-underscore-dangle
            id: cashier.bankAccount._id,
            name: cashier.bankAccount.name,
          }));
        })
        .catch(() => {});
    },
    async getOpenCashiers() {
      const params = {
        limit: 0,
      };
      await this.$http.get('/cashiers?status=open', { params })
        .then(({ data }) => {
          this.openedCashiers = data.items;
          this.openedCashiers.forEach((openCashier) => {
            const cashier = this.cashiers
              .find(cashierItem => cashierItem.id === openCashier.bankAccount.id);
            if (!cashier) {
              const idx = this.openedCashiers.indexOf(openCashier);
              this.openedCashiers.splice(idx, 1);
            }
          });
        })
        .catch(() => {});
    },
    async openCashier() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      if (!this.form.cashier.id) {
        this.$toast.show(
          'Por favor, selecione um caixa para realizar a abertura',
          { type: 'error' },
        );
        return;
      }
      this.saving = true;
      const data = {
        bankAccount: {
          id: this.form.cashier.id,
        },
        operation: 'incoming',
        value: this.form.initialValue,
        notes: this.form.notes,
      };

      await this.$http.post('/cashiers', data)
        .then(() => {
          this.getOpenCashiers();
          this.$toast.show('Caixa aberto com sucesso');
          this.clearForm();
        })
        .catch((e) => {
          let errorMessage = 'Não foi possível realizar a abertura do caixa';
          if (e.response) {
            if (e.response.status === 403) {
              errorMessage = 'Acesso não autorizado';
            } else if (e.response.data && e.response.data.message) {
              errorMessage = e.response.data.message;
            }
          }
          this.$toast.show(errorMessage, { type: 'error' });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    async hasFinancialTransactions(cashierId) {
      let financialTransactions = [];
      await this.$http.get(`/cashiers/${cashierId}/cash-flow`)
        .then(({ data }) => {
          financialTransactions = data.items;
        })
        .catch(() => {});
      return financialTransactions;
    },
    async cancelCashier(cashierId) {
      const cashier = this.openedCashiers.find(item => item.id === cashierId);

      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + `<div>Deseja realmente cancelar a abertura do caixa '${cashier.bankAccount.name}'?</div>`
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              this.$http.delete(`/cashiers/${cashierId}`)
                .then(() => {
                  this.$toast.show('Abertura cancelada com sucesso');
                  this.openedCashiers.forEach((item) => {
                    if (item.id === cashierId) {
                      const idx = this.openedCashiers.indexOf(item);
                      this.openedCashiers.splice(idx, 1);
                    }
                  });
                })
                .catch((e) => {
                  let errorMessage = 'Não foi possível cancelar a abertura do caixa';
                  if (e.response) {
                    if (e.response.status === 403) {
                      errorMessage = 'Acesso não autorizado';
                    } else if (e.response.data && e.response.data.message) {
                      errorMessage = e.response.data.message;
                    }
                  }
                  this.$toast.show(errorMessage, { type: 'error' });
                })
                .finally(() => {
                  this.deleting = false;
                });
              close();
            },
          },
        ],
      });
    },
    clearForm() {
      this.form.cashier.id = '';
      this.form.initialValue = 0;
      this.form.notes = '';
      this.$v.form.cashier.$reset();
    },
    print(id) {
      this.printing = true;
      return this.$file
        .print(`/cashiers/${id}/print-opening`)
        .catch(() => {
          this.$toast.show('Ocorreu um erro ao gerar a impressão', { type: 'error' });
        })
        .finally(() => {
          this.printing = false;
        });
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
  },
};
</script>
