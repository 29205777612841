<template>
  <div class="form-message error" v-if="canShow">
    <slot>
      <div v-if="isHtml" v-html="message"></div>
      <div v-else>{{ message }}</div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
    },
    show: {
      type: Boolean,
      default: false,
    },
    isHtml: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    canShow() {
      return !!this.message || this.show;
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.form-message {
  background-color: $gray-color-light;
  border: $border-width solid $border-color;
  border-radius: $border-radius;
  font-size: $font-size-sm;
  line-height: $font-size;
  padding: $layout-spacing + $layout-spacing-sm;
  margin-bottom: 1rem;
  &.error {
    background-color: rgba($error-color, .1);
    border-color: $error-color;
    color: $body-font-color;
  }
}
</style>
