<template>
  <div class="page-container clinical-dashboard-page">
    <portal to="page-name">Dashboard Administrativo</portal>

    <div class="filter-group">
      <div class="columns">
        <div class="column col-6 form-group">
          <label class="form-label">Unidade</label>
          <select class="form-select"
                  v-model="filter.branchId"
                  @change="loadSchedules">
            <option value="">[Todas as unidades]</option>
            <option v-for="(item, i) in branches"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
        </div>
        <div class="column col-6 form-group">
          <label class="form-label">Agenda</label>
          <select class="form-select" v-model="filter.scheduleId">
            <option value="">[Todas as agendas]</option>
            <option v-for="(item, i) in schedules"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
        </div>
        <div class="column col-6 form-group">
          <label class="form-label">Tipo</label>
          <select class="form-select" v-model="filter.expenseTypeId">
            <option value="">[Todos os tipos]</option>
            <option v-for="(item, i) in expenseTypes"
                    :value="item.key" :key="i">{{ item.name }}</option>
          </select>
        </div>
        <div class="column form-group">
          <label class="form-label">Período</label>
          <div class="input-group"
               :class="{'has-error': $v.filter.startDate.$error || $v.filter.endDate.$error}">
            <dx-dropdown
              class="input-group-addon"
              :items="periodOptions"
              label="text"
              @select="setPeriod"
              direction="left"
            ><fa-icon :icon="['fal', 'chevron-down']" /></dx-dropdown>
            <dx-input-date
              class="form-input text-center"
              v-model="filter.startDate"
              @blur="$v.filter.startDate.$touch()"
            />
            <dx-input-date
              class="form-input text-center"
              v-model="filter.endDate"
              @blur="$v.filter.endDate.$touch()"
            />
          </div>
        </div>
        <div class="column col-auto form-group" style="display: flex; align-items: flex-end">
          <button class="btn btn-primary btn-icon btn-icon-left mb-2"
                  :class="{loading}" :disabled="loading"
                  @click="load">
            <fa-icon class="icon" :icon="['fal', 'search']"></fa-icon>
            Atualizar
          </button>
        </div>
      </div>
    </div>

    <div class="dashboard-tiles">
      <div class="tile-item">
        <div class="tile-icon">
          <fa-icon :icon="['fal', 'calendar-alt']"></fa-icon>
        </div>
        <div class="tile-label">Total horários</div>
        <div class="tile-value">{{ stats.totalHoursCount }}</div>
      </div>

      <div class="tile-item">
        <div class="tile-icon">
          <fa-icon :icon="['fal', 'calendar-times']"></fa-icon>
        </div>
        <div class="tile-label">Bloqueados</div>
        <div class="tile-value">{{ stats.blockedCount }}</div>
      </div>

      <div class="tile-item">
        <div class="tile-icon">
          <fa-icon :icon="['fal', 'calendar-edit']"></fa-icon>
        </div>
        <div class="tile-label">Disponíveis</div>
        <div class="tile-value">
          {{ stats.subtotalHoursCount }}
        </div>
      </div>

      <div class="tile-item">
        <div class="tile-icon">
          <fa-icon :icon="['fal', 'calendar-day']"></fa-icon>
        </div>
        <div class="tile-label">Agendados</div>
        <div class="tile-value">
          {{ stats.appointmentsCount - stats.slotsCount }}
        </div>
      </div>

      <div class="tile-item">
        <div class="tile-icon">
          <fa-icon :icon="['fal', 'calculator']"></fa-icon>
        </div>
        <div class="tile-label">Ocupação I (%)</div>
        <div class="tile-value">
          {{ stats.occupancyRate | number }}%
        </div>
      </div>

      <div class="tile-item tooltip" data-tooltip="Em desenvolvimento">
        <div class="tile-icon">
          <fa-icon :icon="['fal', 'calendar-minus']"></fa-icon>
        </div>
        <div class="tile-label">Retornos</div>
        <div class="tile-value">0</div>
      </div>

      <div class="tile-item">
        <div class="tile-icon">
          <fa-icon :icon="['fal', 'calendar-exclamation']"></fa-icon>
        </div>
        <div class="tile-label">Absenteísmo</div>
        <div class="tile-value">{{ stats.absencesCount }}</div>
      </div>

      <div class="tile-item">
        <div class="tile-icon">
          <fa-icon :icon="['fal', 'calendar-star']"></fa-icon>
        </div>
        <div class="tile-label">Encaixes</div>
        <div class="tile-value">{{ stats.slotsCount }}</div>
      </div>

      <div class="tile-item">
        <div class="tile-icon">
          <fa-icon :icon="['fal', 'calculator']"></fa-icon>
        </div>
        <div class="tile-label">Ocupação II (%)</div>
        <div class="tile-value">{{ stats.occupancySlotsRate | number }}%</div>
      </div>

      <div class="tile-item">
        <div class="tile-icon">
          <fa-icon :icon="['fal', 'user-md']"></fa-icon>
        </div>
        <div class="tile-label">Corpo Clínico</div>
        <div class="tile-value">
          {{ stats.professionals.length }}/{{ stats.professionalsCount }}
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column col-4 col-md-6 col-sm-12">
        <div class="card chart-card">
          <div class="card-body">
            <div class="card-title">Faturamento (%)</div>
            <st-pie-chart
              :chart-data="charts.insuranceTypes.data"
              :options="charts.insuranceTypes.options"
              :height="230"
            />
          </div>
        </div>
      </div>
      <div class="column col-4 col-md-6 col-sm-12">
        <div class="card chart-card">
          <div class="card-body">
            <div class="card-title h6">Faixa etária</div>
            <st-horizontal-bar-chart
              :chart-data="charts.ageGroups.data"
              :options="charts.ageGroups.options"
              :height="230"
            />
          </div>
        </div>
      </div>
      <div class="column col-4 col-md-6 col-sm-12">
        <div class="card chart-card">
          <div class="card-body">
            <div class="card-title">Sexo biológico (%)</div>
            <st-pie-chart
              :chart-data="charts.gender.data"
              :options="charts.gender.options"
              :height="230"
            />
          </div>
        </div>
      </div>
      <div class="column col-12 col-sm-12">
        <div class="card chart-card">
          <div class="card-body">
            <div class="card-title">Top 10 convênios (quantitativo)</div>
            <st-bar-chart
              :chart-data="charts.insurances.data"
              :options="charts.insurances.options"
              :height="230"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <div class="card-title">Taxa de ocupação das agendas</div>
        <small class="text-info" v-if="stats.schedules.length > 0">
          Total de registros: <strong>{{ stats.schedules.length }}</strong>
        </small>
      </div>
      <div class="card-body text-left">
        <div>
          <label class="form-radio form-inline">
            <input type="radio" name="type" value="schedule"
                   :disabled="loading"
                   v-model="filter.type" @change="load">
            <i class="form-icon"/>Agrupar por agenda
          </label>
          <label class="form-radio form-inline">
            <input type="radio" name="type" value="specialty"
                   :disabled="loading"
                   v-model="filter.type" @change="load">
            <i class="form-icon"/>Agrupar por especialidade
          </label>
          <button class="btn btn-primary btn-icon btn-icon-left float-right"
                  :class="{loading: loading || printing}"
                  :disabled="stats.schedules.length === 0 || printing"
                  @click="print">
            <fa-icon :icon="['fal', 'file-excel']" />Gerar planilha
          </button>
        </div>
        <div class="empty mt-2" v-if="stats.schedules.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Taxa de ocupação das agendas</p>
          <p class="empty-subtitle">
            Nenhum registro encontrado
          </p>
        </div>
        <template v-else>
          <table class="table table-striped mt-2">
            <thead>
            <tr>
              <th style="width: 30px">#</th>
              <th>
                Nome
                <fa-icon class="ml-1 c-hand"
                         :icon="['fal', 'sort']"
                         @click="sortList('name')"
                />
              </th>
              <th class="text-center">
                Total
                <fa-icon class="ml-1 c-hand"
                         :icon="['fal', 'sort']"
                         @click="sortList('totalHoursCount')"
                />
              </th>
              <th class="text-center">
                Bloqueados
                <fa-icon class="ml-1 c-hand"
                         :icon="['fal', 'sort']"
                         @click="sortList('blockedCount')"
                />
              </th>
              <th class="text-center">
                Disponíveis
                <fa-icon class="ml-1 c-hand"
                         :icon="['fal', 'sort']"
                         @click="sortList('freeHoursCount')"
                />
              </th>
              <th class="text-center">
                Agendados
                <fa-icon class="ml-1 c-hand"
                         :icon="['fal', 'sort']"
                         @click="sortList('appointmentsCount')"
                />
              </th>
              <th class="text-center">
                Vagos
                <fa-icon class="ml-1 c-hand"
                         :icon="['fal', 'sort']"
                         @click="sortList('appointmentsCount')"
                />
              </th>
              <th class="text-center">
                Ocupação I (%)
                <fa-icon class="ml-1 c-hand"
                         :icon="['fal', 'sort']"
                         @click="sortList('occupancyRate')"
                />
              </th>
              <th class="text-center">
                Encaixes
                <fa-icon class="ml-1 c-hand"
                         :icon="['fal', 'sort']"
                         @click="sortList('slotsCount')"
                />
              </th>
              <th class="text-center">
                Ocupação II (%)
                <fa-icon class="ml-1 c-hand"
                         :icon="['fal', 'sort']"
                         @click="sortList('occupancySlotsRate')"
                />
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in stats.schedules" :key="i">
              <td>{{ i + 1 }}</td>
              <td>
                <span v-if="filter.type === 'schedule'">{{ item.name }}</span>
                <span v-else>{{ item.specialty ? item.specialty.name : '[Nulo]' }}</span>
              </td>
              <td class="text-center">{{ item.totalHoursCount }}</td>
              <td class="text-center">{{ item.blockedCount }}</td>
              <td class="text-center">{{ item.subtotalHoursCount }}</td>
              <td class="text-center">
                {{ item.appointmentsCount - item.slotsCount }}
              </td>
              <td class="text-center">
                {{ item.subtotalHoursCount - (item.appointmentsCount - item.slotsCount) }}
              </td>
              <td class="text-center">{{ item.occupancyRate | number }}%</td>
              <td class="text-center">{{ item.slotsCount }}</td>
              <td class="text-center">{{ item.occupancySlotsRate | number }}%</td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as procedureType from 'src/data/expense-types';
import moment from 'moment';
import { mergeFrom } from '@/helpers/object';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '../../../data/validators';

export default {
  data() {
    return {
      loading: false,
      printing: false,
      expenseTypes: [],
      branches: [],
      schedules: [],
      insurances: [],
      periodOptions: [],
      procedureType,
      filter: {
        sort: 1,
        branchId: '',
        scheduleId: '',
        expenseTypeId: '',
        insuranceId: '',
        type: 'schedule',
        startDate: moment()
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment()
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
      stats: this.statsBlank(),
      charts: {
        insurances: this.chartBase(),
        insuranceTypes: this.chartBase(),
        ageGroups: this.chartBase(),
        gender: this.chartBase(),
      },
    };
  },
  mounted() {
    this.expenseTypes = this.getTypes();
    this.loadBranches();
    this.loadSchedules();
    // this.loadInsurances();
    this.loadPeriod();
    this.load();
    this.loadAgeGroups();
  },
  validations() {
    const rules = {
      filter: {
        startDate: { required, date },
        endDate: { required, date },
      },
    };

    if (this.filter.startDate && moment(this.filter.startDate).isValid()) {
      rules.filter.endDate = {
        required,
        date,
        minDate: minDate(this.filter.startDate),
      };
    }

    return rules;
  },
  methods: {
    getTypes() {
      const types = [];
      Object.keys(this.procedureType.types).forEach((item) => {
        types.push({
          key: item,
          name: this.procedureType.types[item],
          selected: true,
        });
      });
      return types;
    },
    loadPeriod() {
      const nextMonth = moment().add(1, 'month');
      for (let i = 0; i < 6; i += 1) {
        const currentDate = moment(nextMonth)
          .subtract(i, 'month')
          .startOf('month')
          .toDate();
        this.periodOptions.push(
          {
            date: currentDate,
            text: moment(currentDate).format('MM/YYYY'),
          },
        );
      }
    },
    loadBranches() {
      return this.$http
        .get('/appointment-summaries/branches')
        .then(({ data }) => {
          this.branches = data.items;
        })
        .catch(() => {});
    },
    // loadInsurances() {
    //   const params = {
    //     limit: 0,
    //     active: true,
    //   };
    //   return this.$http.get('/insurances', { params })
    //     .then(({ data }) => {
    //       this.insurances = data.items;
    //     })
    //     .catch(() => {});
    // },
    loadSchedules() {
      this.schedules = [];
      this.filter.scheduleId = '';

      const params = {
        active: true,
      };

      if (this.filter.branchId) {
        params.branchId = this.filter.branchId;
      }

      return this.$http
        .get('/appointment-summaries/schedules', { params })
        .then(({ data }) => {
          this.schedules = data.items;
        })
        .catch(() => {});
    },
    setPeriod(item) {
      this.filter.startDate = moment(item.date)
        .startOf('month')
        .format('YYYY-MM-DD');
      this.filter.endDate = moment(item.date)
        .endOf('month')
        .format('YYYY-MM-DD');
    },
    load() {
      this.$v.filter.$touch();

      if (this.loading || this.$v.filter.$error) {
        return null;
      }

      const maxDuration = moment.duration(moment(this.filter.endDate)
        .diff(moment(this.filter.startDate))).asMonths();

      if (maxDuration >= 12) {
        this.$toast.show('Período máximo (12 meses)', { type: 'error' });
        return false;
      }

      this.loading = true;
      this.stats.schedules = [];

      const params = {
        limit: 0,
        offset: 0,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
        type: this.filter.type,
      };

      if (this.filter.branchId) {
        params.branchId = this.filter.branchId;
      }

      if (this.filter.scheduleId) {
        params.scheduleId = this.filter.scheduleId;
      }

      if (this.filter.insuranceId) {
        params.insuranceId = this.filter.insuranceId;
      }

      if (this.filter.expenseTypeId) {
        params.expenseTypeId = this.filter.expenseTypeId;
      }

      return this.$http
        .get('/appointment-summaries', { params })
        .then(({ data }) => {
          this.stats = mergeFrom(this.statsBlank(), data);
          this.loadChartInsuranceTypes();
          this.loadAgeGroups();
          this.loadChartInsurances();
          this.loadChartGenders();
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro ao carregar os indicadores!');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadChartInsuranceTypes() {
      const particular = this.stats.insurances
        .filter(({ type }) => type === 'particular')
        .reduce((total, item) => total + Number(item.count), 0);
      const insurance = this.stats.insurances
        .filter(({ type }) => type === 'insurance')
        .reduce((total, item) => total + Number(item.count), 0);
      const totalInsurance = insurance + particular;
      this.charts.insuranceTypes = {
        data: {
          labels: ['Convênio', 'Particular'],
          datasets: [
            {
              data: [
                Math.round((insurance / totalInsurance) * 100, 2),
                Math.round((particular / totalInsurance) * 100, 2),
              ],
              backgroundColor: [
                'rgba(75, 192, 192, 0.3)',
                'rgba(255, 206, 86, 0.3)',
              ],
              borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(255, 206, 86, 1)',
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            display: true,
          },
        },
      };
    },
    loadChartInsurances() {
      const insurances = this.stats.insurances.slice(0, 10);
      this.charts.insurances = {
        data: {
          labels: insurances.map(({ name }) => name),
          datasets: [{
            data: insurances.map(({ count }) => count),
            backgroundColor: [
              'rgba(255, 155, 85, 0.3)',
              'rgba(153, 102, 255, 0.3)',
              'rgba(75, 192, 192, 0.3)',
              'rgba(255, 206, 86, 0.3)',
              'rgba(75, 192, 192, 0.3)',
              'rgba(255, 206, 86, 0.3)',
              'rgba(54, 162, 235, 0.3)',
              'rgba(255, 99, 132, 0.3)',
              'rgba(51, 238, 255, 0.3)',
              'rgba(239, 99, 255, 0.3)',
            ],
            borderColor: [
              'rgba(255, 155, 85, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 99, 132, 1)',
              'rgba(51, 238, 255, 1)',
              'rgba(239, 99, 255, 1)',
            ],
            borderWidth: 1,
          }],
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [{
              ticks: {
                callback: (v, i) => i + 1,
              },
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
              },
            }],
          },
        },
      };
    },
    loadChartGenders() {
      const female = this.stats.genders
        .filter(({ type }) => type === 'female')
        .reduce((total, item) => total + Number(item.count), 0);
      const male = this.stats.genders
        .filter(({ type }) => type === 'male')
        .reduce((total, item) => total + Number(item.count), 0);
      const totalGenders = female + male;
      this.charts.gender = {
        data: {
          labels: ['Feminino', 'Masculino'],
          datasets: [
            {
              data: [
                Math.round((female / totalGenders) * 100, 2),
                Math.round((male / totalGenders) * 100, 2),
              ],
              backgroundColor: [
                'rgba(239, 99, 255, 0.3)',
                'rgba(54, 162, 235, 0.3)',
              ],
              borderColor: [
                'rgba(239, 99, 255, 1)',
                'rgba(54, 162, 235, 1)',
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            display: true,
          },
        },
      };
    },
    loadAgeGroups() {
      const labels = [
        '0 até 10',
        '11 até 20',
        '21 até 30',
        '31 até 40',
        '41 até 50',
        '51 até 60',
        '+60',
      ];

      const femaleData = this.stats.ageGroups.map(({ femaleCount }) => femaleCount * -1);
      const maleData = this.stats.ageGroups.map(({ maleCount }) => maleCount);

      this.charts.ageGroups = {
        data: {
          labels,
          datasets: [{
            label: 'Feminino',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 0.7)',
            borderWidth: 2,
            data: femaleData,
          }, {
            label: 'Masculino',
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 0.7)',
            borderWidth: 2,
            data: maleData,
          }],
        },
        options: {
          maintainAspectRatio: false,
          tooltips: {
            callbacks: {
              title(item) {
                return `Faixa etária: ${item[0].yLabel}`;
              },
              label(tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                return `${label}: ${Math.abs(tooltipItem.xLabel)} agendamentos`;
              },
            },
          },
          elements: {
            rectangle: {
              borderWidth: 2,
            },
          },
          scales: {
            xAxes: [{
              ticks: {
                callback: value => Math.abs(value),
              },
            }],
            yAxes: [{
              stacked: true,
            }],
          },
        },
      };
    },
    print() {
      if (this.printing) {
        return null;
      }

      this.printing = true;

      const params = {
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
        type: this.filter.type,
      };

      if (this.filter.branchId) {
        params.branchId = this.filter.branchId;
      }

      if (this.filter.scheduleId) {
        params.scheduleId = this.filter.scheduleId;
      }

      return this.$file
        .download('/appointment-summaries/print', params)
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.printing = false;
        });
    },
    statsBlank() {
      return {
        absencesCount: 0,
        appointmentsCount: 0,
        attendedCount: 0,
        blockedCount: 0,
        freeHoursCount: 0,
        slotsCount: 0,
        subtotalHoursCount: 0,
        totalHoursCount: 0,
        professionalsCount: 0,
        occupancyRate: 0,
        occupancySlotsRate: 0,
        professionals: [],
        schedules: [],
        insurances: [],
        genders: [],
        ageGroups: [],
      };
    },
    chartBase() {
      return {
        data: {
          labels: [],
          datasets: [],
        },
      };
    },
    sortList(item) {
      this.filter.sort = this.filter.sort * -1;
      this.stats.schedules.sort((a, b) => {
        if (item === 'name') {
          if (this.filter.type === 'schedule') {
            if (a.name > b.name) return this.filter.sort;
            if (a.name < b.name) return this.filter.sort * -1;
          } else {
            if (a.specialty && a.specialty.name > b.specialty.name) {
              return this.filter.sort;
            }
            if (a.specialty && a.specialty.name < b.specialty.name) {
              return this.filter.sort * -1;
            }
          }
        }
        if (item === 'totalHoursCount') {
          if (a.totalHoursCount > b.totalHoursCount) return this.filter.sort;
          if (a.totalHoursCount < b.totalHoursCount) return this.filter.sort * -1;
        }
        if (item === 'appointmentsCount') {
          if (a.appointmentsCount > b.appointmentsCount) return this.filter.sort;
          if (a.appointmentsCount < b.appointmentsCount) return this.filter.sort * -1;
        }
        if (item === 'freeHoursCount') {
          if (a.freeHoursCount > b.freeHoursCount) return this.filter.sort;
          if (a.freeHoursCount < b.freeHoursCount) return this.filter.sort * -1;
        }
        if (item === 'occupancyRate') {
          if (a.occupancyRate > b.occupancyRate) return this.filter.sort;
          if (a.occupancyRate < b.occupancyRate) return this.filter.sort * -1;
        }
        if (item === 'occupancySlotsRate') {
          if (a.occupancySlotsRate > b.occupancySlotsRate) return this.filter.sort;
          if (a.occupancySlotsRate < b.occupancySlotsRate) return this.filter.sort * -1;
        }
        if (item === 'slotsCount') {
          if (a.slotsCount > b.slotsCount) return this.filter.sort;
          if (a.slotsCount < b.slotsCount) return this.filter.sort * -1;
        }
        if (item === 'blockedCount') {
          if (a.blockedCount > b.blockedCount) return this.filter.sort;
          if (a.blockedCount < b.blockedCount) return this.filter.sort * -1;
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  @import "./src/assets/scss/mixins";

  .clinical-dashboard-page {
    .filter-group {
      background-color: $gray-color-ultra-light;
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      margin-bottom: $layout-spacing-lg;
      padding: $layout-spacing $layout-spacing-lg;
    }
    .dashboard-tiles {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: $layout-spacing-lg;
      grid-row-gap: $layout-spacing-lg;
      margin-bottom: $layout-spacing-lg;
      .tile-item {
        background-color: $light-color;
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        padding: 20px;
        text-align: center;

        .tile-icon {
          margin-bottom: $layout-spacing;

          svg {
            color: $gray-color-dark;
            display: block;
            height: 1rem;
            margin: 0 auto;
            width: auto;
          }
        }

        .tile-value {
          color: $primary-color;
          font-size: .9rem;
          font-weight: 600;
          line-height: $font-size;
          margin-top: $layout-spacing;
        }
      }
    }
    .chart-card {
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      height: 280px;
      margin-bottom: $layout-spacing-lg;
      .card-title {
        font-size: $font-size-lg;
        text-align: center;
      }
    }
    .table tr td {
      font-size: $font-size-xs;
    }
  }
</style>
