export const modules = [
  {
    key: 'clinical',
    name: 'Clínico',
    icon: ['fal', 'clinic-medical'],
    url: '/',
    external: false,
  },
  {
    key: 'financial',
    name: 'Financeiro',
    icon: ['fal', 'usd-circle'],
    url: '/financial',
    external: false,
  },
  {
    key: 'stock',
    name: 'Estoque',
    icon: ['fal', 'archive'],
    url: '/stock',
    external: false,
  },
  {
    key: 'referral',
    name: 'Encaminhamento',
    icon: ['fal', 'share-all'],
    url: '/forwards',
    external: false,
  },
  {
    key: 'occupational',
    name: 'Medicina do trabalho',
    icon: ['fal', 'user-hard-hat'],
    url: '/asos',
    external: false,
  },
  {
    key: 'hospitalization',
    name: 'Internação',
    icon: ['fal', 'procedures'],
    url: '/hospitalization',
    external: false,
  },
  {
    key: 'surgery',
    name: 'Centro cirúrgico',
    icon: ['fal', 'scalpel'],
    url: '/surgery-appointments',
    external: false,
  },
  {
    key: 'pcs',
    name: 'Painel de chamadas',
    icon: ['fal', 'tv'],
    url({ branch }) {
      return `https://pcs.stenci.app/login?public-id=${branch.identityId}`;
    },
    external: true,
  },
];

export function getModule(key) {
  return modules.find(item => item.key === key) || null;
}

export default modules;
