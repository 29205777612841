<template>
  <div class="input-group">
    <select
      class="form-select"
      style="width: 30%"
      :value="localType"
      :disabled="disabled"
      @change="onTypeChange"
    >
      <option
        v-for="type in availableTypes"
        :key="type.type"
        :value="type.type"
      >{{ type.label }}</option>
    </select>
    <input
      ref="input"
      type="text"
      id="identity"
      class="form-input"
      style="flex: 0 1 70%"
      :value="localValue"
      @input="onInput"
      @blur="onBlur"
      @change="onChange"
      :disabled="disabled"
      autocomplete="nope"
      :placeholder="placeholder"
    >
  </div>
</template>

<script>
import types, {
  CPF,
  PASSPORT,
} from '@/data/identity-types';

export default {
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    types: {
      type: Array,
      default: () => [CPF, PASSPORT],
    },
  },
  data() {
    return {
      localType: '',
      localValue: '',
    };
  },
  watch: {
    'value.type': function watchType(newType) {
      this.localType = newType;
    },
    'value.value': function watchValue(newValue) {
      this.localValue = this.mask(newValue);
    },
  },
  created() {
    this.localType = this.value.type || this.types[0];
    this.localValue = this.mask(this.value.value);
  },
  computed: {
    availableTypes() {
      return types.filter(({ type }) => this.types.includes(type));
    },
    currentType() {
      return types.find(({ type }) => type === this.localType);
    },
    placeholder() {
      return this.currentType ? this.currentType.placeholder : '';
    },
    filter() {
      return this.currentType ? this.currentType.filter : null;
    },
  },
  methods: {
    onTypeChange(e) {
      this.localType = e.target.value;
      this.localValue = '';
      this.$refs.input.focus();
      this.emit();
    },
    onInput(e) {
      this.refresh(e.target.value);
    },
    onBlur(e) {
      this.$emit('blur', e);
    },
    onChange(e) {
      this.$emit('change', e);
    },
    refresh(value) {
      const maskedValue = this.mask(value);
      this.$refs.input.value = maskedValue;

      if (maskedValue !== this.localValue) {
        this.localValue = maskedValue;
        this.emit();
      }
    },
    emit() {
      this.$emit('input', {
        type: this.localType,
        value: this.localValue,
      });
    },
    mask(value) {
      if (!value) {
        return '';
      }
      return this.filter ? this.filter(value) : value;
    },
  },
};
</script>
