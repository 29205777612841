<template>
  <div class="page-container">
    <portal to="page-name">Caixa</portal>
    <div class="card card-page" v-if="hasAccess">
      <div class="card-header">
        <h1 class="card-title">Fechamento de caixa</h1>
      </div>
      <div class="card-body" v-if="loading">
        <div class="loading"></div>
      </div>
      <div class="card-body" v-if="!loading && openedCashiers.length > 0">
        <div class="columns">
          <div class="column col-6 form-group" :class="{'has-error': $v.form.cashier.id.$error}">
            <label>Caixa</label>
            <select class="form-select" v-model="form.cashier.id" @change="changeCashier"
                    @blur="$v.form.cashier.id.$touch()">
              <option value="">Selecione um caixa</option>
              <option v-for="(item, i) in openedCashiers" :value="item.id" :key="i">
                {{ item.bankAccount.name }}
              </option>
            </select>
          </div>
          <div class="column col-3 form-group">
            <label>Sangria</label>
            <dx-input-number id="value" v-model="form.cashier.value"
                             class="form-input text-right"
                             :precision="2" maxlength="10" :disabled="true"/>
          </div>
          <div class="column col-12 form-group">
            <label for="notes">Observação</label>
            <textarea id="notes" class="form-input" type="text" v-model="form.notes" rows="2" />
          </div>
          <div class="column col-2">
            <button class="btn btn-primary" @click="closeCashier"
                    :disabled="saving" :class="{loading: saving}">
              Realizar fechamento
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" v-if="!loading && openedCashiers.length === 0">
        <div class="empty mt-2">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Nenhum caixa aberto</p>
          <p class="empty-subtitle">
            Não foi encontrado nenhum caixa pendente de fechamento
          </p>
        </div>
      </div>
      <div class="card-body" v-if="!loading">
        <h6 class="text-secondary">Fechamentos realizados</h6>
        <div class="columns">
          <div class="column col-2 form-group" :class="{'has-error': $v.filter.startDate.$error}">
            <label for="filter-startDate" class="form-label">Data inicial</label>
            <dx-input-date type="text" id="filter-startDate" name="filter-startDate"
                           @blur="$v.filter.startDate.$touch()" v-model="filter.startDate"
                           class="form-input" placeholder="00/00/0000" autocomplete="nope" />
          </div>
          <div class="column col-2 form-group" :class="{'has-error': $v.filter.endDate.$error}">
            <label for="filter-endDate" class="form-label">Data final</label>
            <dx-input-date type="text" id="filter-endDate" name="filter-startDate"
                           @blur="$v.filter.endDate.$touch()" v-model="filter.endDate"
                           class="form-input" placeholder="00/00/0000" autocomplete="nope" />
          </div>
          <div class="column col-2">
            <button class="btn btn-primary" style="margin-top: 21px;"
                    @click="getClosedCashiers" :disabled="loading" :class="{loading}">
              Pesquisar
            </button>
          </div>
        </div>
        <div class="columns">
          <div v-if="closedCashiers.length > 0" class="column col-12">
            <table class="table table-hover">
              <thead>
              <tr>
                <th>Abertura</th>
                <th>Fechamento</th>
                <th>Caixa</th>
                <th>Usuário</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(cashier, i) in closedCashiers" :key="i">
                <td>{{ dateFormat(cashier.opening.date) }}</td>
                <td>{{ dateFormat(cashier.closure.date) }}</td>
                <td>{{ cashier.bankAccount.name }}</td>
                <td>{{ cashier.closure.user.name }}</td>
                <td class="text-right" width="80px">
                  <button class="btn btn-sm btn-action btn-icon btn-gray tooltip tooltip-left mr-1"
                          data-tooltip="Imprimir relatório de fechamento"
                          :disabled="printing" :class="{loading: printing}"
                          @click="print(cashier.id)">
                    <fa-icon :icon="['fal', 'print']"/>
                  </button>
                  <button class="btn btn-sm btn-action btn-icon btn-gray tooltip"
                          data-tooltip="Reabrir caixa" @click="reopenCashier(cashier.id)"
                          :disabled="deleting" :class="{loading: deleting}">
                    <fa-icon :icon="['fal', 'times']"/>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="column col-12">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Nenhum fechamento encontrado</p>
              <p class="empty-subtitle">
                Nenhum fechamento realizado no período informado
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <forbidden v-else></forbidden>
  </div>
</template>

<script>
import moment from 'moment';
import { CLOSE_CASHIER } from '@/data/actions/modules/financial';
import { required } from 'vuelidate/lib/validators';
import Forbidden from '@/components/auth/Forbidden.vue';
import { date, minDate, maxDate } from '@/data/validators';

export default {
  data() {
    return {
      cashierSettings: [],
      openedCashiers: [],
      closedCashiers: [],
      loading: false,
      saving: false,
      printing: false,
      deleting: false,
      filter: {
        startDate: '',
        endDate: '',
      },
      form: {
        cashier: {
          id: '',
          value: 0,
        },
        notes: '',
      },
    };
  },
  components: {
    Forbidden,
  },
  computed: {
    hasAccess() {
      return this.$can(CLOSE_CASHIER);
    },
  },
  validations() {
    const rules = {
      form: {
        cashier: {
          id: { required },
        },
      },
      filter: {
        startDate: {},
        endDate: {},
      },
    };

    if (this.filter.startDate && this.filter.endDate) {
      rules.filter.startDate = { required, date, maxDate: maxDate(this.filter.endDate) };
      rules.filter.endDate = { required, date, minDate: minDate(this.filter.startDate) };
    } else if (this.filter.startDate || this.filter.endDate) {
      rules.filter.startDate = { required, date };
      rules.filter.endDate = { required, date };
    }

    return rules;
  },
  async mounted() {
    if (this.hasAccess) {
      this.loading = true;
      await this.getCashiers();
      await this.getOpenedCashiers();
      if (this.openedCashiers.length === 1) {
        this.form.cashier.id = this.openedCashiers[0].id;
        this.changeCashier();
      }
    }
    this.loading = false;
  },
  methods: {
    async getCashiers() {
      await this.$http.get('/cashiers/accounts')
        .then(({ data }) => {
          this.cashierSettings = data.items.map(cashier => ({
            // eslint-disable-next-line no-underscore-dangle
            id: cashier.bankAccount._id,
            name: cashier.bankAccount.name,
          }));
        })
        .catch(() => {});
    },
    async getOpenedCashiers() {
      const params = {
        limit: 0,
      };

      await this.$http.get('/cashiers?status=open', { params })
        .then(({ data }) => {
          this.openedCashiers = data.items;
          this.openedCashiers.forEach((openCashier) => {
            const cashier = this.cashierSettings
              .find(item => openCashier.bankAccount.id === item.id);
            if (cashier) {
              openCashier.enabled = true;
            } else {
              openCashier.enabled = false;
            }
          });
          this.openedCashiers = this.openedCashiers
            .filter(openCashier => openCashier.enabled === true);
        })
        .catch(() => {});
    },
    async getClosedCashiers() {
      if (!this.filter.startDate) {
        this.filter.startDate = moment().format('YYYY-MM-DD');
      }
      if (!this.filter.endDate) {
        this.filter.endDate = moment().format('YYYY-MM-DD');
      }

      this.$v.filter.$touch();
      if (this.$v.form.$error) {
        return;
      }

      this.loading = true;

      const params = {
        status: 'closed',
        initialEndDate: moment(this.filter.startDate).format('YYYY-MM-DD'),
        finalEndDate: moment(this.filter.endDate).format('YYYY-MM-DD'),
      };

      await this.$http.get('/cashiers', { params })
        .then(({ data }) => {
          this.closedCashiers = data.items;
          this.closedCashiers.forEach((closedCashier) => {
            const cashier = this.cashierSettings
              .find(item => closedCashier.bankAccount.id === item.id);
            closedCashier.enabled = !!cashier;
          });
          this.closedCashiers = this.closedCashiers
            .filter(closedCashier => closedCashier.enabled === true);
        })
        .catch((e) => {
          let errorMessage = 'Não foi possível realizar a pesquisa';
          if (e.response) {
            if (e.response.status === 403) {
              errorMessage = 'Acesso não autorizado';
            } else if (e.response.data && e.response.data.message) {
              errorMessage = e.response.data.message;
            }
          }
          this.$toast.show(errorMessage, { type: 'error' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reopenCashier(cashierId) {
      const cashier = this.closedCashiers.find(item => item.id === cashierId);
      const data = {
        status: 'open',
      };
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + `<div>Deseja realmente reabrir o caixa '${cashier.bankAccount.name}'?</div>`
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              this.$http.put(`/cashiers/${cashierId}`, data)
                .then(() => {
                  this.closedCashiers.forEach((item) => {
                    if (item.id === cashierId) {
                      item.status = 'open';
                      item.closure = {
                        date: null,
                        user: null,
                      };
                      const idx = this.closedCashiers.indexOf(item);
                      this.openedCashiers.push(item);
                      this.closedCashiers.splice(idx, 1);
                    }
                    this.$toast.show('Caixa reaberto com sucesso');
                  });
                })
                .catch((e) => {
                  let errorMessage = 'Não foi possível realizar a reabertura do caixa';
                  if (e.response) {
                    if (e.response.status === 403) {
                      errorMessage = 'Acesso não autorizado';
                    } else if (e.response.data && e.response.data.message) {
                      errorMessage = e.response.data.message;
                    }
                  }
                  this.$toast.show(errorMessage, { type: 'error' });
                })
                .finally(() => {
                  this.deleting = false;
                });
              close();
            },
          },
        ],
      });
    },
    closeCashier() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      const cashier = this.openedCashiers.find(item => item.id === this.form.cashier.id);
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + `<div>Deseja realmente realizar o fechamento do caixa '${cashier.bankAccount.name}'?</div>`
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.saving = true;
              const params = {
                status: 'closed',
                operation: 'outgoing',
                value: this.form.cashier.value,
                bankAccount: {
                  id: cashier.bankAccount.id,
                },
                notes: this.form.notes,
              };
              this.$http.put(`/cashiers/${this.form.cashier.id}`, params)
                .then(() => {
                  const idx = this.openedCashiers.indexOf(cashier);
                  this.openedCashiers.splice(idx, 1);
                  this.clearForm();
                  this.$toast.show('Fechamento do caixa realizado com sucesso');
                })
                .catch(() => {
                  this.$toast.show(
                    'Não foi possível realizar o fechamento do caixa',
                    { type: 'error' },
                  );
                })
                .finally(() => {
                  this.saving = false;
                });
              close();
            },
          },
        ],
      });
    },
    changeCashier() {
      if (this.form.cashier.id) {
        const cashier = this.openedCashiers.find(item => item.id === this.form.cashier.id);
        if (cashier) {
          this.form.cashier.value = cashier.balance;
        }
      } else {
        this.form.cashier.value = 0;
        this.form.notes = '';
      }
    },
    clearForm() {
      this.form.cashier.id = '';
      this.form.cashier.value = 0;
      this.form.notes = '';
      this.$v.form.$reset();
    },
    print(id) {
      this.printing = true;
      return this.$file
        .print(`/cashiers/${id}/print-cash-flow`)
        .then(() => {
          this.printing = false;
        })
        .catch(() => {
          this.$toast.show('Ocorreu um erro ao realização a impressão do documento', { type: 'error' });
        })
        .finally(() => {
          this.printing = false;
        });
    },
    dateFormat(dateTime) {
      return moment(dateTime).format('DD/MM/YYYY HH:mm');
    },
  },
};
</script>
