import {
  LIST_FINANCIAL_TRANSACTIONS,
  CREATE_FINANCIAL_TRANSACTION,
  UPDATE_FINANCIAL_TRANSACTION,
  PAY_FINANCIAL_TRANSACTION,
} from '@/data/actions/modules/financial';

export default [
  {
    path: '/financial/transactions',
    component: () => import(/* webpackChunkName: "transactions" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_FINANCIAL_TRANSACTIONS,
    },
  },
  {
    path: '/financial/transactions/create',
    component: () => import(/* webpackChunkName: "transactions" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_FINANCIAL_TRANSACTION,
    },
  },
  {
    path: '/financial/transactions/:id/edit',
    component: () => import(/* webpackChunkName: "transactions" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_FINANCIAL_TRANSACTION,
    },
  },
  {
    path: '/financial/transactions/:id/payment',
    component: () => import(/* webpackChunkName: "transactions" */ './components/Payment.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: PAY_FINANCIAL_TRANSACTION,
    },
  },
];
