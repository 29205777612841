import Toast from 'src/components/toast/Toast.vue';

let toast;
let timeout;

export function close() {
  toast.show = false;
}

export function show(message, params = {}) {
  const localParams = Object.assign(
    {
      type: 'success', // success|error|neutral
      timeout: 7000,
    },
    params,
  );

  toast.show = true;
  toast.type = localParams.type;
  toast.message = message;

  if (localParams.timeout) {
    clearTimeout(timeout);
    timeout = setTimeout(close, localParams.timeout);
  }

  return toast;
}

export function success(content, options = {}) {
  show(content, { ...options, type: 'success' });
}

export function warning(content, options = {}) {
  show(content, { ...options, type: 'warning' });
}

export function info(content, options = {}) {
  show(content, { ...options, type: 'info' });
}

export function error(e, options = {}) {
  options = {
    parse: true,
    ...options,
    type: 'error',
  };

  let message = 'Erro não especificado';

  if (e) {
    if (typeof e === 'string') {
      message = e || message;
    } else if (options.parse) {
      if (e.response && e.response.data && e.response.data.message) {
        ({ message } = e.response.data);
      } else if (e.message) {
        ({ message } = e);
      }
    }
  }

  show(message, options);
}

function mount(Vue) {
  const ToastClass = Vue.extend(Toast);
  toast = new ToastClass();
  toast.$mount();
  toast.$on('close', close);
}

export default function install(Vue) {
  mount(Vue);

  Object.defineProperty(Vue.prototype, '$toast', {
    get() {
      return {
        show,
        success,
        warning,
        info,
        error,
        close,
      };
    },
  });
}
