import {
  LIST_INVENTORIES,
  CREATE_INVENTORY,
  UPDATE_INVENTORY,
} from '@/data/actions/modules/stock';

export default [
  {
    path: '/stock/inventories',
    component: () => import(/* webpackChunkName: "inventories" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_INVENTORIES,
    },
  },
  {
    path: '/stock/inventories/create',
    component: () => import(/* webpackChunkName: "inventories" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_INVENTORY,
    },
  },
  {
    path: '/stock/inventories/:id/edit',
    component: () => import(/* webpackChunkName: "inventories" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_INVENTORY,
    },
  },
];
