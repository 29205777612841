<template>
  <nav>
    <router-link to="/financial">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'tachometer-alt']"/>
      </div>
      <span class="side-menu-label">Dashboard</span>
    </router-link>
    <router-link to="/financial/billing-attendances">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'hospital-user']"/>
      </div>
      <span class="side-menu-label">Atendimentos</span>
    </router-link>
    <router-link to="/financial/billing-invoices">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'file-invoice-dollar']"/>
      </div>
      <span class="side-menu-label">Faturas</span>
    </router-link>
    <router-link to="/financial/billing-transfer">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'handshake']"/>
      </div>
      <span class="side-menu-label">Repasses</span>
    </router-link>
    <router-link to="/financial/transactions">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'exchange']"/>
      </div>
      <span class="side-menu-label">Movimentações</span>
    </router-link>
    <router-link to="/financial/statements">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'coins']"/>
      </div>
      <span class="side-menu-label">Extrato</span>
    </router-link>
    <router-link to="/financial/others">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'ellipsis-h-alt']"/>
      </div>
      <span class="side-menu-label">Outros recursos</span>
    </router-link>
  </nav>
</template>

<script>
export default {};
</script>
