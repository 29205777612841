import {
  LIST_MARKERS,
  CREATE_MARKER,
  UPDATE_MARKER,
} from '@/data/actions/modules/general';

export default [
  {
    path: '/markers',
    component: () => import(/* webpackChunkName: "markers" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_MARKERS,
    },
  },
  {
    path: '/markers/create',
    component: () => import(/* webpackChunkName: "markers" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_MARKER,
    },
  },
  {
    path: '/markers/:id/edit',
    component: () => import(/* webpackChunkName: "markers" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_MARKER,
    },
  },
];
