<template>
  <div class="input-group">
    <button
      class="btn input-group-addon input-group-btn btn-icon"
      @click.prevent="decrement"
      :disabled="!canDecrement || disabled || readonly"
      tabindex="-1">
      <fa-icon :icon="['fal', 'minus']" class="text-gray"></fa-icon>
    </button>
    <input
      type="text"
      v-bind="$attrs"
      class="form-input text-center"
      :value="value"
      @input="onInput"
      :disabled="disabled"
      :readonly="readonly"
      ref="input"
    >
    <button
      class="btn input-group-addon input-group-btn btn-icon"
      @click.prevent="increment"
      :disabled="!canIncrement || disabled || readonly"
      tabindex="-1">
      <fa-icon :icon="['fal', 'plus']" class="text-gray"></fa-icon>
    </button>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
    },
    minValue: {
      type: Number,
    },
    maxValue: {
      type: Number,
    },
    step: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    canDecrement() {
      return typeof this.minValue === 'undefined' || this.value > this.minValue;
    },
    canIncrement() {
      return typeof this.maxValue === 'undefined' || this.value < this.maxValue;
    },
  },
  methods: {
    parseValue(value) {
      if (typeof this.minValue !== 'undefined' && value < this.minValue) {
        return this.minValue;
      }
      if (typeof this.maxValue !== 'undefined' && value > this.maxValue) {
        return this.maxValue;
      }
      return value;
    },
    decrement() {
      this.$emit('input', this.parseValue(this.value - this.step));
    },
    increment() {
      this.$emit('input', this.parseValue(this.value + this.step));
    },
    onInput(e) {
      let { value } = e.target;

      if (/[^-0-9]/.test(value)) {
        value = value.replace(/[^-0-9]/g, '');
        this.$refs.input.value = value;
      }

      value = Number(value);

      if (!Number.isNaN(value)) {
        value = this.parseValue(value);
        this.$refs.input.value = value;
        this.$emit('input', value);
      }
    },
  },
};
</script>
