import {
  LIST_QUOTATIONS,
  CREATE_QUOTATION,
  UPDATE_QUOTATION,
} from '@/data/actions/modules/stock';

export default [
  {
    path: '/stock/quotations',
    component: () => import(/* webpackChunkName: "quotations" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_QUOTATIONS,
    },
  },
  {
    path: '/stock/quotations/create',
    component: () => import(/* webpackChunkName: "quotations" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_QUOTATION,
    },
  },
  {
    path: '/stock/quotations/:id/edit',
    component: () => import(/* webpackChunkName: "quotations" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_QUOTATION,
    },
  },
];
