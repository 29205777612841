export const LIST_ATTENDANCES = 'financial:listAttendances';
export const UPDATE_ATTENDANCE = 'financial:updateAttendance';
export const DELETE_ATTENDANCE = 'financial:deleteAttendance';
export const PRINT_ATTENDANCE_LIST = 'financial:printAttendanceList';

export const LIST_ACCOUNTS = 'financial:listAccounts';
export const UPDATE_ACCOUNT = 'financial:updateAccount';
export const DELETE_ACCOUNT = 'financial:deleteAccount';
export const PRINT_ACCOUNT = 'financial:printAccount';
export const PRINT_ACCOUNT_LIST = 'financial:printAccountList';

export const LIST_BILLINGS = 'financial:listBillings';
export const CREATE_BILLING = 'financial:createBilling';
export const UPDATE_BILLING = 'financial:updateBilling';
export const DELETE_BILLING = 'financial:deleteBilling';
export const PRINT_BILLING = 'financial:printBilling';
export const PRINT_BILLING_LIST = 'financial:printBillingList';
export const GENERATE_BILLING_XML = 'financial:generateBillingXML';

export const LIST_BILLING_TRANSFERS = 'financial:listBillingTransfers';
export const PAY_BILLING_TRANSFER = 'financial:payBillingTransfer';
export const PRINT_BILLING_TRANSFER = 'financial:printBillingTransfer';
export const PRINT_BILLING_TRANSFER_LIST = 'financial:printBillingTransferList';

export const LIST_FINANCIAL_TRANSACTIONS = 'financial:listFinancialTransactions';
export const CREATE_FINANCIAL_TRANSACTION = 'financial:createFinancialTransaction';
export const UPDATE_FINANCIAL_TRANSACTION = 'financial:updateFinancialTransaction';
export const DELETE_FINANCIAL_TRANSACTION = 'financial:deleteFinancialTransaction';
export const PRINT_FINANCIAL_TRANSACTION = 'financial:printFinancialTransaction';
export const PRINT_FINANCIAL_TRANSACTION_LIST = 'financial:printFinancialTransactionList';
export const PAY_FINANCIAL_TRANSACTION = 'financial:payFinancialTransaction';
export const REVERSE_FINANCIAL_TRANSACTION = 'financial:reverseFinancialTransaction';

export const LIST_BUDGETS = 'financial:listBudgets';
export const CREATE_BUDGET = 'financial:createBudget';
export const UPDATE_BUDGET = 'financial:updateBudget';
export const DELETE_BUDGET = 'financial:deleteBudget';
export const PRINT_BUDGET = 'financial:printBudget';
export const PRINT_BUDGET_LIST = 'financial:printBudgetList';

export const LIST_BANK_ACCOUNTS = 'financial:listBankAccounts';
export const CREATE_BANK_ACCOUNT = 'financial:createBankAccount';
export const UPDATE_BANK_ACCOUNT = 'financial:updateBankAccount';
export const DELETE_BANK_ACCOUNT = 'financial:deleteBankAccount';
export const PRINT_BANK_ACCOUNT_LIST = 'financial:printBankAccountList';

export const LIST_COST_CENTERS = 'financial:listCostCenters';
export const CREATE_COST_CENTER = 'financial:createCostCenter';
export const UPDATE_COST_CENTER = 'financial:updateCostCenter';
export const DELETE_COST_CENTER = 'financial:deleteCostCenter';
export const PRINT_COST_CENTER_LIST = 'financial:printCostCenterList';

export const LIST_PAYMENT_METHODS = 'financial:listPaymentMethods';
export const CREATE_PAYMENT_METHOD = 'financial:createPaymentMethod';
export const UPDATE_PAYMENT_METHOD = 'financial:updatePaymentMethod';
export const DELETE_PAYMENT_METHOD = 'financial:deletePaymentMethod';
export const PRINT_PAYMENT_METHOD_LIST = 'financial:printPaymentMethodList';

export const LIST_CATEGORIES = 'financial:listCategories';
export const CREATE_CATEGORY = 'financial:createCategory';
export const UPDATE_CATEGORY = 'financial:updateCategory';
export const DELETE_CATEGORY = 'financial:deleteCategory';
export const PRINT_CATEGORY_LIST = 'financial:printCategoryList';

export const REPORT_ACCOUNTS = 'financial:reportAccounts';
export const REPORT_BILLING_RESULTS = 'financial:reportBillingResults';
export const REPORT_FORWARD_RESULTS = 'financial:reportForwardResults';
export const REPORT_INDICATION_RESULTS = 'financial:reportIndicationResults';
export const REPORT_REQUEST_RESULTS = 'financial:report_request_results';
export const REPORT_PAYABLES_AND_RECEIVABLES = 'financial:reportPayablesAndReceivables';
export const REPORT_DRE = 'financial:reportDRE';
export const REPORT_DFC = 'financial:reportDFC';
export const REPORT_CASH_TRANSACTIONS = 'financial:reportCashTransactions';
export const REPORT_RECEIPTS_ISSUED = 'financial:reportReceiptsIssued';
export const REPORT_PROFESSIONAL_TRANSFER = 'financial:reportProfessionalTransfer';
export const REPORT_CATEGORIES = 'financial:reportCategories';

export const OPEN_CASHIER = 'financial:openCashier';
export const REOPEN_CASHIER = 'financial:reopenCashier';
export const CANCEL_CASHIER = 'financial:cancelCashier';
export const CLOSE_CASHIER = 'financial:closeCashier';
export const LIST_CASHIERS = 'financial:listCashiers';
export const CREATE_CASHIER_CASH_OPERATION = 'financial:createCashierCashOperation';
export const LIST_CASHIER_FINANCIAL_TRANSACTIONS = 'financial:listCashierFinancialTransactions';
export const LIST_CASHIER_ATTENDANCES = 'financial:listCashierAttendances';
export const LIST_CASHIER_REFERRALS = 'financial:listCashierReferrals';
export const LIST_CASHIER_TRANSACTIONS = 'financial:listCashierTransactions';
export const ALLOW_CASHIER_DISCOUNT_INTEREST = 'financial:allowCashierDiscountInterest';
export const MANAGE_CASHIER_SETTINGS = 'financial:manageCashierSettings';
export const PRINT_CASHIER_TRANSACTION_LIST = 'financial:printCashierTransactionList';

export const LIST_STATEMENTS = 'financial:listStatements';

export default [
  {
    action: LIST_ATTENDANCES,
    name: 'Atendimentos: Listar',
  },
  {
    action: UPDATE_ATTENDANCE,
    name: 'Atendimentos: Atualizar',
  },
  {
    action: DELETE_ATTENDANCE,
    name: 'Atendimentos: Excluir',
  },
  {
    action: PRINT_ATTENDANCE_LIST,
    name: 'Atendimentos: Imprimir lista',
  },
  {
    action: LIST_ACCOUNTS,
    name: 'Contas do paciente: Listar',
  },
  {
    action: UPDATE_ACCOUNT,
    name: 'Contas do paciente: Atualizar',
  },
  {
    action: DELETE_ACCOUNT,
    name: 'Contas do paciente: Excluir',
  },
  {
    action: PRINT_ACCOUNT,
    name: 'Contas do paciente: Imprimir',
  },
  {
    action: PRINT_ACCOUNT_LIST,
    name: 'Contas do paciente: Imprimir lista',
  },
  {
    action: LIST_BILLINGS,
    name: 'Faturas: Listar',
  },
  {
    action: CREATE_BILLING,
    name: 'Faturas: Criar',
  },
  {
    action: UPDATE_BILLING,
    name: 'Faturas: Atualizar',
  },
  {
    action: DELETE_BILLING,
    name: 'Faturas: Excluir',
  },
  {
    action: PRINT_BILLING,
    name: 'Faturas: Imprimir',
  },
  {
    action: PRINT_BILLING_LIST,
    name: 'Faturas: Imprimir lista',
  },
  {
    action: GENERATE_BILLING_XML,
    name: 'Faturas: Gerar arquivo XML',
  },
  {
    action: LIST_BILLING_TRANSFERS,
    name: 'Repasse dos profissionais: Listar',
  },
  {
    action: PAY_BILLING_TRANSFER,
    name: 'Repasse dos profissionais: Pagar',
  },
  {
    action: PRINT_BILLING_TRANSFER,
    name: 'Repasse dos profissionais: Imprimir Recibo',
  },
  {
    action: PRINT_BILLING_TRANSFER_LIST,
    name: 'Repasse dos profissionais: Imprimir lista',
  },
  {
    action: LIST_FINANCIAL_TRANSACTIONS,
    name: 'Movimentações financeiras: Listar',
  },
  {
    action: CREATE_FINANCIAL_TRANSACTION,
    name: 'Movimentações financeiras: Criar',
  },
  {
    action: UPDATE_FINANCIAL_TRANSACTION,
    name: 'Movimentações financeiras: Atualizar',
  },
  {
    action: DELETE_FINANCIAL_TRANSACTION,
    name: 'Movimentações financeiras: Excluir',
  },
  {
    action: PRINT_FINANCIAL_TRANSACTION,
    name: 'Movimentações financeiras: Imprimir',
  },
  {
    action: PRINT_FINANCIAL_TRANSACTION_LIST,
    name: 'Movimentações financeiras: Imprimir lista',
  },
  {
    action: PAY_FINANCIAL_TRANSACTION,
    name: 'Movimentações financeiras: Baixar (pagar/receber)',
  },
  {
    action: REVERSE_FINANCIAL_TRANSACTION,
    name: 'Movimentações financeiras: Estornar',
  },
  {
    action: LIST_BUDGETS,
    name: 'Orçamentos: Listar',
  },
  {
    action: CREATE_BUDGET,
    name: 'Orçamentos: Criar',
  },
  {
    action: UPDATE_BUDGET,
    name: 'Orçamentos: Atualizar',
  },
  {
    action: DELETE_BUDGET,
    name: 'Orçamentos: Excluir',
  },
  {
    action: PRINT_BUDGET,
    name: 'Orçamentos: Imprimir',
  },
  {
    action: PRINT_BUDGET_LIST,
    name: 'Orçamentos: Imprimir lista',
  },
  {
    action: LIST_BANK_ACCOUNTS,
    name: 'Contas bancárias: Listar',
  },
  {
    action: CREATE_BANK_ACCOUNT,
    name: 'Contas bancárias: Criar',
  },
  {
    action: UPDATE_BANK_ACCOUNT,
    name: 'Contas bancárias: Atualizar',
  },
  {
    action: DELETE_BANK_ACCOUNT,
    name: 'Contas bancárias: Excluir',
  },
  {
    action: PRINT_BANK_ACCOUNT_LIST,
    name: 'Contas bancárias: Imprimir lista',
  },
  {
    action: LIST_COST_CENTERS,
    name: 'Centros de custo: Listar',
  },
  {
    action: CREATE_COST_CENTER,
    name: 'Centros de custo: Criar',
  },
  {
    action: UPDATE_COST_CENTER,
    name: 'Centros de custo: Atualizar',
  },
  {
    action: DELETE_COST_CENTER,
    name: 'Centros de custo: Excluir',
  },
  {
    action: PRINT_COST_CENTER_LIST,
    name: 'Centros de custo: Imprimir lista',
  },
  {
    action: LIST_PAYMENT_METHODS,
    name: 'Formas de pagamento: Listar',
  },
  {
    action: CREATE_PAYMENT_METHOD,
    name: 'Formas de pagamento: Criar',
  },
  {
    action: UPDATE_PAYMENT_METHOD,
    name: 'Formas de pagamento: Atualizar',
  },
  {
    action: DELETE_PAYMENT_METHOD,
    name: 'Formas de pagamento: Excluir',
  },
  {
    action: PRINT_PAYMENT_METHOD_LIST,
    name: 'Formas de pagamento: Imprimir lista',
  },
  {
    action: LIST_CATEGORIES,
    name: 'Categorias: Listar',
  },
  {
    action: CREATE_CATEGORY,
    name: 'Categorias: Criar',
  },
  {
    action: UPDATE_CATEGORY,
    name: 'Categorias: Atualizar',
  },
  {
    action: DELETE_CATEGORY,
    name: 'Categorias: Excluir',
  },
  {
    action: PRINT_CATEGORY_LIST,
    name: 'Categorias: Imprimir lista',
  },
  {
    action: REPORT_ACCOUNTS,
    name: 'Relatórios: Contas dos pacientes',
  },
  {
    action: REPORT_BILLING_RESULTS,
    name: 'Relatórios: Demonstrativo de faturamento',
  },
  {
    action: REPORT_FORWARD_RESULTS,
    name: 'Relatórios: Demonstrativo de encaminhamento',
  },
  {
    action: REPORT_INDICATION_RESULTS,
    name: 'Relatórios: Agendamentos por indicação',
  },
  {
    action: REPORT_REQUEST_RESULTS,
    name: 'Relatórios: Contas por solicitate',
  },
  {
    action: REPORT_PAYABLES_AND_RECEIVABLES,
    name: 'Relatórios: Documentos a pagar e a receber',
  },
  {
    action: REPORT_DRE,
    name: 'Relatórios: Demonstrativo de resultado do exercício',
  },
  {
    action: REPORT_DFC,
    name: 'Relatórios: Demonstrativo de fluxo de caixa',
  },
  {
    action: REPORT_CASH_TRANSACTIONS,
    name: 'Relatórios: Movimentação do caixa',
  },
  {
    action: REPORT_RECEIPTS_ISSUED,
    name: 'Relatórios: Recibos emitidos',
  },
  {
    action: REPORT_PROFESSIONAL_TRANSFER,
    name: 'Relatórios: Repasse dos profissionais',
  },
  {
    action: REPORT_CATEGORIES,
    name: 'Relatórios: Demonstrativo por categorias',
  },
  {
    action: OPEN_CASHIER,
    name: 'Caixa: Realizar abertura',
  },
  {
    action: REOPEN_CASHIER,
    name: 'Caixa: Reabertura',
  },
  {
    action: CREATE_CASHIER_CASH_OPERATION,
    name: 'Caixa: Registrar aporte/sangria',
  },
  {
    action: LIST_CASHIER_ATTENDANCES,
    name: 'Caixa: Atendimentos',
  },
  {
    action: LIST_CASHIER_FINANCIAL_TRANSACTIONS,
    name: 'Caixa: Listar Receita/Despesa',
  },
  {
    action: LIST_CASHIER_TRANSACTIONS,
    name: 'Caixa: Listar fluxo de caixa',
  },
  {
    action: CLOSE_CASHIER,
    name: 'Caixa: Fechamento de caixa',
  },
  {
    action: LIST_CASHIER_REFERRALS,
    name: 'Caixa: Encaminhamentos',
  },
  {
    action: MANAGE_CASHIER_SETTINGS,
    name: 'Caixa: Gerenciar configuração',
  },
  {
    action: ALLOW_CASHIER_DISCOUNT_INTEREST,
    name: 'Caixa: Permitir lançamentos de descontos e juros',
  },
  {
    action: CANCEL_CASHIER,
    name: 'Caixa: Cancelar abertura',
  },
  {
    action: CLOSE_CASHIER,
    name: 'Caixa: Realizar fechamento',
  },
  {
    action: LIST_CASHIERS,
    name: 'Caixa: Pesquisa caixas finalizados',
  },
  {
    action: PRINT_CASHIER_TRANSACTION_LIST,
    name: 'Caixa: Imprimir fluxo de caixa',
  },
  {
    action: LIST_STATEMENTS,
    name: 'Extrato: Listar',
  },
];
