<template>
  <div class="bar">
    <div class="bar-item tooltip" :data-tooltip="formattedPercent" :style="style">
      <slot>{{ percent }}%</slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percent: {
      type: Number,
    },
  },
  computed: {
    formattedPercent() {
      return `${this.percent}%`;
    },
    style() {
      return {
        width: this.formattedPercent,
      };
    },
  },
};
</script>

<style lang="scss">
.bar {
  .bar-item {
    transition: width .2s ease;
  }
}
</style>
