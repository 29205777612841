import {
  LIST_COST_CENTERS,
} from '@/data/actions/modules/financial';

export default [
  {
    path: '/financial/cost-centers',
    component: () => import(/* webpackChunkName: "cost-centers" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_COST_CENTERS,
    },
  },
];
