<template>
  <div class="columns">
    <div class="column col-3 col-md-6 col-sm-12 form-group"
         :class="{'has-error': $v.form.person.cpf.$error}">
      <label for="person-cpf" class="form-label">CPF</label>
      <input type="text" id="person-cpf" name="person-cpf" v-model="form.person.cpf"
             class="form-input" autocomplete="nope"
             @blur="$v.form.person.cpf.$touch()" placeholder="000.000.000-00" v-mask-cpf>
      <template v-if="$v.form.person.cpf.$error">
        <div class="form-input-hint"
             v-if="!$v.form.person.cpf.required">Campo obrigatório</div>
        <div class="form-input-hint"
             v-else-if="!$v.form.person.cpf.cpf">CPF inválido</div>
      </template>
    </div>
    <div class="column col-5 col-md-6 col-sm-12 form-group"
         :class="{'has-error': $v.form.name.$error}">
      <label for="person-name" class="form-label">Nome completo</label>
      <input type="text" id="person-name" name="person-name" v-model="form.name"
             class="form-input" @blur="$v.form.name.$touch()"
             placeholder="Nome completo" autocomplete="nope">
      <template v-if="$v.form.name.$error">
        <div class="form-input-hint"
             v-if="!$v.form.name.required">Campo obrigatório</div>
      </template>
    </div>
    <div class="column col-2 col-md-6 col-sm-12 form-group"
         :class="{'has-error': $v.form.person.birthDate.$error}">
      <label for="person-birth-date" class="form-label">Nascimento</label>
      <input type="text" id="person-birth-date" name="person-birth-date"
             autocomplete="nope" v-model="form.person.birthDate" class="form-input"
             v-mask-date.br @blur="$v.form.person.birthDate.$touch()"
             placeholder="00/00/0000">
      <template v-if="$v.form.person.birthDate.$error">
        <div class="form-input-hint"
             v-if="!$v.form.person.birthDate.required">Campo obrigatório</div>
        <div class="form-input-hint"
             v-else-if="!$v.form.person.birthDate.date">Data inválida</div>
        <div class="form-input-hint"
             v-else-if="!$v.form.person.birthDate.minDate">Min</div>
        <div class="form-input-hint"
             v-else-if="!$v.form.person.birthDate.maxDate">Max</div>
      </template>
    </div>
    <div class="column col-2 col-md-6 col-sm-12 form-group"
         :class="{'has-error': $v.form.person.gender.$error}">
      <label for="person-gender" class="form-label">Sexo</label>
      <select id="person-gender" name="person-gender" v-model="form.person.gender"
              class="form-select" @change="$v.form.person.gender.$touch()">
        <option value="">[Selecione]</option>
        <option value="female">Feminino</option>
        <option value="male">Masculino</option>
      </select>
      <template v-if="$v.form.person.gender.$error">
        <div class="form-input-hint"
             v-if="!$v.form.person.gender.required">Campo obrigatório</div>
      </template>
    </div>
    <div class="column col-3 col-md-6 col-sm-12 form-group">
      <label for="person-civil-status" class="form-label">Estado civil</label>
      <select id="person-civil-status" name="person-civil-status"
              v-model="form.person.civilStatus" class="form-select">
        <option value="">[Selecione]</option>
      </select>
    </div>
    <div class="column col-5 col-md-6 col-sm-12 form-group">
      <label for="person-occupation" class="form-label">Profissão</label>
      <select id="person-occupation" name="person-occupation"
              v-model="form.person.occupation" class="form-select">
        <option value="">[Selecione]</option>
      </select>
    </div>
    <div class="column col-4 col-md-6 col-sm-12 form-group">
      <label for="person-document" class="form-label">Documento</label>
      <input type="text" id="person-document" name="person-document"
             v-model="form.person.document" class="form-input" autocomplete="nope">
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import {
  cpf, date, minDate, maxDate,
} from 'src/data/validators';

export default {
  data() {
    return {
      form: {
        person: {
          cpf: '',
          birthDate: '',
          gender: '',
          civilStatus: '',
          occupation: '',
          document: '',
        },
      },
    };
  },
  validations() {
    const rules = {
      form: {
        name: { required },
      },
      nameAndDob: ['form.name', 'form.person.birthDate'],
    };

    rules.form.person = {
      cpf: { required, cpf },
      birthDate: {
        required,
        date,
        minDate: minDate('1900-01-01'),
        maxDate: maxDate(),
      },
      gender: { required },
    };

    return rules;
  },
  methods: {
    load() {
    },
  },
};
</script>
