<template>
  <nav>
<!--    <router-link to="/">-->
<!--      <div class="side-menu-icon">-->
<!--        <fa-icon :icon="['fal', 'tachometer-alt']"/>-->
<!--      </div>-->
<!--      <span class="side-menu-label">Dashboard</span>-->
<!--    </router-link>-->
    <router-link to="/surgery/appointments">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'calendar-day']"/>
      </div>
      <span class="side-menu-label">Agenda</span>
    </router-link>
    <router-link to="/patients">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'user-friends']"/>
      </div>
      <span class="side-menu-label">Pacientes</span>
    </router-link>
    <router-link to="/surgery/reports">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'print']"/>
      </div>
      <span class="side-menu-label">Relatórios</span>
    </router-link>
    <router-link to="/others">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'ellipsis-h-alt']"/>
      </div>
      <span class="side-menu-label">Outros recursos</span>
    </router-link>
  </nav>
</template>

<script>
export default {};
</script>
