const meta = {
  layout: 'master',
  requiresAuth: true,
};

export default [
  {
    path: '/hospitalization',
    component: () => import(/* webpackChunkName: "hospitalization" */ './components/Dashboard.vue'),
    meta,
  },
  {
    path: '/hospitalization/others',
    component: () => import(/* webpackChunkName: "stock" */ './components/OtherMain.vue'),
    meta,
  },
];
