export const MANAGE_CUSTOMER_ACCOUNT = 'general:manageCustomerAccount';

export const LIST_BRANCHES = 'general:listBranches';
export const CREATE_BRANCH = 'general:createBranch';
export const UPDATE_BRANCH = 'general:updateBranch';
export const DELETE_BRANCH = 'general:deleteBranch';
export const PRINT_BRANCH_LIST = 'general:printBranchList';

export const LIST_COLLABORATORS = 'general:listCollaborators';
export const CREATE_COLLABORATOR = 'general:createCollaborator';
export const UPDATE_COLLABORATOR = 'general:updateCollaborator';
export const DELETE_COLLABORATOR = 'general:deleteCollaborator';
export const PRINT_COLLABORATOR_LIST = 'general:printCollaboratorList';

export const LIST_PROFESSIONALS = 'general:listProfessionals';
export const CREATE_PROFESSIONAL = 'general:createProfessional';
export const UPDATE_PROFESSIONAL = 'general:updateProfessional';
export const DELETE_PROFESSIONAL = 'general:deleteProfessional';
export const PRINT_PROFESSIONAL_LIST = 'general:printProfessionalList';

export const LIST_INSURANCES = 'general:listInsurances';
export const CREATE_INSURANCE = 'general:createInsurance';
export const UPDATE_INSURANCE = 'general:updateInsurance';
export const DELETE_INSURANCE = 'general:deleteInsurance';
export const PRINT_INSURANCE_LIST = 'general:printInsuranceList';

export const LIST_PATIENTS = 'general:listPatients';
export const CREATE_PATIENT = 'general:createPatient';
export const UPDATE_PATIENT = 'general:updatePatient';
export const DELETE_PATIENT = 'general:deletePatient';
export const PRINT_PATIENT_LIST = 'general:printPatientList';
export const PRINT_PATIENT_ALERT = 'general:printPatientAlert';

export const LIST_EXPENSES = 'general:listExpenses';
export const CREATE_EXPENSE = 'general:createExpense';
export const UPDATE_EXPENSE = 'general:updateExpense';
export const DELETE_EXPENSE = 'general:deleteExpense';
export const PRINT_EXPENSE_LIST = 'general:printExpenseList';

export const LIST_SUPPLIERS = 'general:listSuppliers';
export const CREATE_SUPPLIER = 'general:createSupplier';
export const UPDATE_SUPPLIER = 'general:updateSupplier';
export const DELETE_SUPPLIER = 'general:deleteSupplier';
export const PRINT_SUPPLIER_LIST = 'general:printSupplierList';

export const LIST_MARKERS = 'general:listMarkers';
export const CREATE_MARKER = 'general:createMarker';
export const UPDATE_MARKER = 'general:updateMarker';
export const DELETE_MARKER = 'general:deleteMarker';
export const PRINT_MARKER_LIST = 'general:printMarkerList';

export const LIST_PLACES = 'general:listPlaces';
export const CREATE_PLACE = 'general:createPlace';
export const UPDATE_PLACE = 'general:updatePlace';
export const DELETE_PLACE = 'general:deletePlace';
export const PRINT_PLACE_LIST = 'general:printPlaceList';

export const LIST_PHONEBOOKS = 'general:listPhoneBooks';
export const CREATE_PHONEBOOK = 'general:createPhoneBook';
export const UPDATE_PHONEBOOK = 'general:updatePhoneBook';
export const DELETE_PHONEBOOK = 'general:deletePhoneBook';
export const PRINT_PHONEBOOK_LIST = 'general:printPhoneBookList';

export const LIST_PERMISSION_GROUPS = 'general:listPermissionGroups';
export const CREATE_PERMISSION_GROUP = 'general:createPermissionGroup';
export const UPDATE_PERMISSION_GROUP = 'general:updatePermissionGroup';
export const DELETE_PERMISSION_GROUP = 'general:deletePermissionGroup';
export const PRINT_PERMISSION_GROUP_LIST = 'general:printPermissionGroupList';

export const LIST_CBHPM_TABLE = 'general:listCbhpmTable';
export const CREATE_CBHPM_TABLE = 'general:createCbhpmTable';
export const UPDATE_CBHPM_TABLE = 'general:updateCbhpmTable';
export const DELETE_CBHPM_TABLE = 'general:deleteCbhpmTable';

export const LIST_CAMPAIGNS = 'general:listCampaigns';
export const CREATE_CAMPAIGN = 'general:createCampaign';
export const UPDATE_CAMPAIGN = 'general:updateCampaign';

export default [
  {
    action: MANAGE_CUSTOMER_ACCOUNT,
    name: 'Gerenciar conta Stenci',
  },
  {
    action: LIST_BRANCHES,
    name: 'Empresas: Listar',
  },
  {
    action: CREATE_BRANCH,
    name: 'Empresas: Criar',
  },
  {
    action: UPDATE_BRANCH,
    name: 'Empresas: Atualizar',
  },
  {
    action: DELETE_BRANCH,
    name: 'Empresas: Excluir',
  },
  {
    action: PRINT_BRANCH_LIST,
    name: 'Empresas: Imprimir lista',
  },
  {
    action: LIST_COLLABORATORS,
    name: 'Colaboradores: Listar',
  },
  {
    action: CREATE_COLLABORATOR,
    name: 'Colaboradores: Criar',
  },
  {
    action: UPDATE_COLLABORATOR,
    name: 'Colaboradores: Atualizar',
  },
  {
    action: DELETE_COLLABORATOR,
    name: 'Colaboradores: Excluir',
  },
  {
    action: PRINT_COLLABORATOR_LIST,
    name: 'Colaboradores: Imprimir lista',
  },
  {
    action: LIST_PROFESSIONALS,
    name: 'Profissionais: Listar',
  },
  {
    action: CREATE_PROFESSIONAL,
    name: 'Profissionais: Criar',
  },
  {
    action: UPDATE_PROFESSIONAL,
    name: 'Profissionais: Atualizar',
  },
  {
    action: DELETE_PROFESSIONAL,
    name: 'Profissionais: Excluir',
  },
  {
    action: PRINT_PROFESSIONAL_LIST,
    name: 'Profissionais: Imprimir lista',
  },
  {
    action: LIST_INSURANCES,
    name: 'Convênio: Listar',
  },
  {
    action: CREATE_INSURANCE,
    name: 'Convênio: Criar',
  },
  {
    action: UPDATE_INSURANCE,
    name: 'Convênio: Atualizar',
  },
  {
    action: DELETE_INSURANCE,
    name: 'Convênio: Excluir',
  },
  {
    action: PRINT_INSURANCE_LIST,
    name: 'Convênio: Imprimir lista',
  },
  {
    action: LIST_PATIENTS,
    name: 'Pacientes: Listar',
  },
  {
    action: CREATE_PATIENT,
    name: 'Pacientes: Criar',
  },
  {
    action: UPDATE_PATIENT,
    name: 'Pacientes: Atualizar',
  },
  {
    action: DELETE_PATIENT,
    name: 'Pacientes: Excluir',
  },
  {
    action: PRINT_PATIENT_LIST,
    name: 'Pacientes: Imprimir lista',
  },
  {
    action: PRINT_PATIENT_ALERT,
    name: 'Pacientes: Imprimir lista de alertas',
  },
  {
    action: LIST_EXPENSES,
    name: 'Procedimentos: Listar',
  },
  {
    action: CREATE_EXPENSE,
    name: 'Procedimentos: Criar',
  },
  {
    action: UPDATE_EXPENSE,
    name: 'Procedimentos: Atualizar',
  },
  {
    action: DELETE_EXPENSE,
    name: 'Procedimentos: Excluir',
  },
  {
    action: PRINT_EXPENSE_LIST,
    name: 'Procedimentos: Imprimir lista',
  },
  {
    action: LIST_SUPPLIERS,
    name: 'Fornecedores: Listar',
  },
  {
    action: CREATE_SUPPLIER,
    name: 'Fornecedores: Criar',
  },
  {
    action: UPDATE_SUPPLIER,
    name: 'Fornecedores: Atualizar',
  },
  {
    action: DELETE_SUPPLIER,
    name: 'Fornecedores: Excluir',
  },
  {
    action: PRINT_SUPPLIER_LIST,
    name: 'Fornecedores: Imprimir lista',
  },
  {
    action: LIST_MARKERS,
    name: 'Marcadores: Listar',
  },
  {
    action: CREATE_MARKER,
    name: 'Marcadores: Criar',
  },
  {
    action: UPDATE_MARKER,
    name: 'Marcadores: Atualizar',
  },
  {
    action: DELETE_MARKER,
    name: 'Marcadores: Excluir',
  },
  {
    action: PRINT_MARKER_LIST,
    name: 'Marcadores: Imprimir lista',
  },
  {
    action: LIST_PLACES,
    name: 'Locais: Listar',
  },
  {
    action: CREATE_PLACE,
    name: 'Locais: Criar',
  },
  {
    action: UPDATE_PLACE,
    name: 'Locais: Atualizar',
  },
  {
    action: DELETE_PLACE,
    name: 'Locais: Excluir',
  },
  {
    action: PRINT_PLACE_LIST,
    name: 'Locais: Imprimir lista',
  },
  {
    action: LIST_PHONEBOOKS,
    name: 'Agenda telefônica: Listar',
  },
  {
    action: CREATE_PHONEBOOK,
    name: 'Agenda telefônica: Criar',
  },
  {
    action: UPDATE_PHONEBOOK,
    name: 'Agenda telefônica: Atualizar',
  },
  {
    action: DELETE_PHONEBOOK,
    name: 'Agenda telefônica: Excluir',
  },
  {
    action: PRINT_PHONEBOOK_LIST,
    name: 'Agenda telefônica: Imprimir lista',
  },
  {
    action: LIST_PERMISSION_GROUPS,
    name: 'Grupos de permissões: Listar',
  },
  {
    action: CREATE_PERMISSION_GROUP,
    name: 'Grupos de permissões: Criar',
  },
  {
    action: UPDATE_PERMISSION_GROUP,
    name: 'Grupos de permissões: Atualizar',
  },
  {
    action: DELETE_PERMISSION_GROUP,
    name: 'Grupos de permissões: Excluir',
  },
  {
    action: PRINT_PERMISSION_GROUP_LIST,
    name: 'Grupos de permissões: Imprimir lista',
  },
  {
    action: LIST_CBHPM_TABLE,
    name: 'Tabela CBHPM: Listar',
  },
  {
    action: CREATE_CBHPM_TABLE,
    name: 'Tabela CBHPM: Criar',
  },
  {
    action: UPDATE_CBHPM_TABLE,
    name: 'Tabela CBHPM: Atualizar',
  },
  {
    action: DELETE_CBHPM_TABLE,
    name: 'Tabela CBHPM: Excluir',
  },
  {
    action: LIST_CAMPAIGNS,
    name: 'Marketing e relacionamento: Listar',
  },
  {
    action: CREATE_CAMPAIGN,
    name: 'Marketing e relacionamento: Criar',
  },
  {
    action: UPDATE_CAMPAIGN,
    name: 'Marketing e relacionamento: Atualizar',
  },
];
