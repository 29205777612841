import { LIST_APPOINTMENTS } from '@/data/actions/modules/surgery';

export default [
  {
    path: '/surgery/appointments',
    component: () => import(/* webpackChunkName: "appointments" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_APPOINTMENTS,
    },
  },
];
