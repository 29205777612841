import {
  LIST_SCHEDULES,
  CREATE_SCHEDULE,
  UPDATE_SCHEDULE,
} from '@/data/actions/modules/clinical';

export default [
  {
    path: '/clinical/schedules',
    component: () => import(/* webpackChunkName: "schedules" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_SCHEDULES,
    },
  },
  {
    path: '/clinical/schedules/create',
    component: () => import(/* webpackChunkName: "schedules" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_SCHEDULE,
    },
  },
  {
    path: '/clinical/schedules/:id/edit',
    component: () => import(/* webpackChunkName: "schedules" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_SCHEDULE,
    },
  },
];
