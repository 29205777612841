import {
  LIST_PAYMENT_METHODS,
  CREATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
} from '@/data/actions/modules/financial';

export default [
  {
    path: '/financial/payment-methods',
    component: () => import(/* webpackChunkName: "payment-methods" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_PAYMENT_METHODS,
    },
  },
  {
    path: '/financial/payment-methods/create',
    component: () => import(/* webpackChunkName: "payment-methods" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_PAYMENT_METHOD,
    },
  },
  {
    path: '/financial/payment-methods/:id/edit',
    component: () => import(/* webpackChunkName: "payment-methods" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_PAYMENT_METHOD,
    },
  },
];
