<template>
  <div class="exam-production-page">
    <h6>Relatório de produção dos profissionais</h6>
    <div class="columns">
      <div class="column col-3 col-md-6 col-sm-12 form-group">
        <label class="form-label">Mês</label>
        <select class="form-select" v-model="filters.month">
          <option v-for="(month, i) in months" :value="month.value" :key="i">
            {{ month.text }}
          </option>
        </select>
      </div>
      <div class="column col-3 col-md-6 col-sm-12 form-group">
        <label class="form-label">Ano</label>
        <select class="form-select" v-model="filters.year">
          <option v-for="(year, i) in years" :value="year" :key="i">
            {{ year }}
          </option>
        </select>
      </div>
      <div class="column col-6 form-group">
        <label class="form-label">Profissional</label>
        <select class="form-select" v-model="filters.professionalId">
          <option value="">[Todos os profissionais]</option>
          <option v-for="(item, i) in professionals"
                  :value="item.id" :key="i">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="column col-12 form-group">
        <div class="exam-list">
          <div class="scroll-list-wrapper">
            <div class="scroll-list">
              <div class="list-header">
                <label class="form-checkbox">
                  <input type="checkbox"
                         @change="selectAllInsurances"
                         :checked="getInsurancesSelected"
                         :indeterminate.prop="getInsurancesSelected === null">
                  <i class="form-icon"></i>
                </label>
                <div class="list-title">Convênios</div>
                <div class="input-group">
                  <input type="text" class="form-input"
                         v-model="filters.insurance"
                         placeholder="Filtrar convênio">
                  <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                          tabindex="-1" v-if="!filters.insurance.trim()">
                    <fa-icon :icon="['fal', 'search']"/>
                  </button>
                  <button class="btn btn-gray input-group-btn btn-icon"
                          @click="filters.insurance = ''"
                          tabindex="-1" v-else>
                    <fa-icon :icon="['fal', 'times']"/>
                  </button>
                </div>
              </div>
              <table class="table table-hover">
                <tbody>
                <tr v-for="(item, i) in filteredInsurances" :key="i">
                  <td width="30px">
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="item.selected">
                      <i class="form-icon"></i>
                    </label>
                  </td>
                  <td>{{ item.name }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="column col-12 form-group">
        <label class="form-label">
          Filtrar apenas os procedimentos selecionados (opcional)
        </label>
        <dx-autocomplete
          id="expense"
          ref="expense"
          v-model="expense"
          :source="findExpense"
          label="expense"
          track-by="expense-id"
          @select="setExpense"
          :debounce="800"
          placeholder="Pesquisar exame por código ou descrição">
          <button slot="action"
                  class="btn btn-neutral btn-action input-group-btn btn-icon">
            <fa-icon :icon="['fal', 'search']"/>
          </button>
          <template v-slot="{ item }">
              <span>
                <fa-icon class="text-primary" :icon="['fal', 'file-medical-alt']"></fa-icon>
                {{ item.name }} ({{ item.code | tuss }})
              </span>
            <span>{{ item.display }}</span>
          </template>
        </dx-autocomplete>
      </div>
      <div class="column col-12 form-group">
        <table class="table table-striped" v-if="filters.expenses.length > 0">
          <thead>
          <tr>
            <th>#</th>
            <th>Código</th>
            <th>Descrição</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(expense, i) in filters.expenses" :key="i">
            <td>{{ i + 1}}</td>
            <td>{{ expense.code | tuss }}</td>
            <td class="text-ellipsis" style="max-width: 200px">{{ expense.name }}</td>
            <td class="text-right">
              <button class="btn btn-icon btn-action btn-sm btn-error"
                      @click="remove(i)">
                <fa-icon :icon="['fal', 'times']" />
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-gray btn-icon btn-icon-left mr-1"
              :class="{loading: printing && printType === 'sheet'}"
              :disabled="printing"
              @click="print('sheet')">
        <fa-icon :icon="['fal', 'file-excel']"/>Gerar planilha
      </button>
      <button class="btn btn-primary btn-icon-left"
              :class="{loading: printing && printType === 'pdf'}"
              @click="print('pdf')" :disabled="printing">
        <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import fuzzy from 'fuzzy';
import formMixin from '@/mixins/form';
import { mapActions } from 'vuex';

export default {
  mixins: [formMixin],
  data() {
    return {
      printing: false,
      months: [],
      years: [],
      insuranceItems: [],
      professionals: [],
      expense: null,
      printType: null,
      filters: {
        month: moment().format('MM'),
        year: moment().format('YYYY'),
        insurance: '',
        professionalId: '',
        expenses: [],
      },
    };
  },
  computed: {
    getInsurancesSelected() {
      if (!this.filteredInsurances || this.filteredInsurances.length === 0) {
        return false;
      }

      const insurancesSelected = this.filteredInsurances.filter(item => item.selected);
      if (insurancesSelected.length === 0) {
        return false;
      }
      if (insurancesSelected.length === this.filteredInsurances.length) {
        return true;
      }
      return null;
    },
    filteredInsurances() {
      let { insuranceItems } = this;

      if (this.filters.insurance.trim()) {
        const results = fuzzy.filter(this.filters.insurance, insuranceItems, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        insuranceItems = results.map(({ original }) => original);
      }

      return insuranceItems;
    },
  },
  mounted() {
    this.loadYears();
    this.loadMonths();
    this.loadInsurances();
    this.loadProfessionals();
  },
  methods: {
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    loadYears() {
      const startYear = moment().subtract(5, 'year').year();
      const endYear = moment().year();
      for (let i = startYear; i <= endYear; i += 1) {
        this.years.push(i);
      }
    },
    loadMonths() {
      const startYear = moment().startOf('year').toDate();
      for (let i = 0; i < 12; i += 1) {
        this.months.push({
          value: moment(startYear).add(i, 'month').format('MM'),
          text: moment(startYear).add(i, 'month').format('MMMM'),
        });
      }
    },
    selectAllInsurances(e) {
      this.filteredInsurances.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.insuranceItems = data.items.map(item => ({
            id: item.plan.id,
            name: item.customName,
            selected: true,
          }));
        })
        .catch(() => {});
    },
    loadProfessionals() {
      return this.apiFetchProfessionals()
        .then((items) => {
          this.professionals = items.map(item => ({
            id: item.id,
            name: item.name,
          }));
        })
        .catch(() => {});
    },
    findExpense(search) {
      const params = {
        types: 'procedure',
        search,
        limit: 100,
      };

      return this.$http.get('/tuss', { params })
        .then(({ data }) => data.items);
    },
    setExpense(data) {
      this.expense = null;
      const found = this.filters.expenses.find(item => item.id === data.id);
      if (!found) {
        this.filters.expenses.push({
          id: data.id,
          code: data.code,
          name: data.name,
        });
      }
    },
    remove(idx) {
      this.filters.expenses.splice(idx, 1);
    },
    print(printType) {
      const selectedInsurance = this.insuranceItems.filter(({ selected }) => selected);

      if (!selectedInsurance || selectedInsurance.length === 0) {
        this.$toast.error('Selecione o(s) convênio(s)!');
        return null;
      }

      this.printType = printType;
      this.printing = true;

      const params = {
        month: this.filters.month,
        year: this.filters.year,
        insuranceIds: this.insuranceItems
          .filter(({ selected }) => selected)
          .map(item => item.id).join(','),
        printType,
      };

      if (this.filters.professionalId) {
        params.professionalId = this.filters.professionalId;
      }

      if (this.filters.expenses.length > 0) {
        params.expenseCodes = this.filters.expenses.map(({ code }) => code).join(',');
      }

      if (printType === 'sheet') {
        return this.$file
          .download('/reports/exam-productions', params, {
            name: 'Relação de produção dos profissionais.xlsx',
          })
          .catch((e) => {
            if (e.response
              && e.response.data
              && e.response.data.message) {
              this.$toast.error(e.response);
            }
          })
          .then(() => {
            this.printing = false;
          });
      }
      return this.$file
        .print('/reports/exam-productions', params)
        .then(() => {
          this.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  @import '~assets/scss/mixins';
  .exam-production-page {
    .exam-list {
      display: flex;
      flex-direction: column;
      height: 12rem;
      overflow-y: auto;
      .list-header {
        align-items: center;
        border-bottom: $border-width solid $border-color;
        display: flex;
        padding-bottom: $layout-spacing;
        .list-title {
          flex: 1;
          font-weight: bold;
        }
      }
      .scroll-list-wrapper {
        border: $border-color solid $border-width;
        border-radius: $border-radius;
        flex-grow: 1;
        position: relative;
      }
      .scroll-list {
        bottom: 0;
        overflow-y: auto;
        padding: $layout-spacing $layout-spacing-lg;
        position: absolute;
        top: 0;
        width: 100%;
        @include scroll-bar();
        .table th, .table td {
          padding: 0;
        }
        .table td {
          border-bottom: none;
        }
      }
    }
    .footer {
      margin-top: $layout-spacing-lg;
      text-align: right;
    }
  }
</style>
