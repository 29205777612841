<template>
  <div class="password-reset-page">
    <h2>Recupere sua senha</h2>
    <p class="m-0">
      <template v-if="!hasId">
        Para garantir sua segurança, precisamos confirmar sua identidade.
        Por favor, informe o endereço de e-mail ou CPF associado à sua conta.
      </template>
      <template v-else>
        Ótimo! Encontramos seus dados. Enviamos um código de verificação.
        Por favor, verifique seu e-mail e insira o código no campo abaixo para continuar.
      </template>
    </p>

    <template v-if="!hasId">
      <form class="form-body" @submit.stop.prevent>
        <div class="form-group" :class="{ 'has-error': $v.username.$error }">
          <label class="form-label" for="username">Usuário</label>
          <input
            v-model.trim="username"
            id="username"
            class="form-input input-lg"
            placeholder="Email ou CPF"
          />
          <template v-if="$v.username.$error">
            <div class="form-input-hint" v-if="!$v.username.required">Campo obrigatório</div>
          </template>
        </div>
      </form>
      <button
        class="btn btn-primary btn-lg btn-block"
        :class="{ loading: processing }"
        @click="request"
        :disabled="!hasUsername || processing"
      >Enviar</button>
    </template>

    <template v-else>
      <form class="form-body" @submit.stop.prevent>
        <div class="form-group" :class="{ 'has-error': hasToken && !validated }">
          <label class="form-label" for="token">Código de 6 dígitos</label>
          <div class="has-icon-right">
            <input
              v-model.trim="token"
              type="text"
              id="token"
              class="form-input input-lg"
              :readonly="validated"
              placeholder="000000"
              v-mask="'000000'"
            />
            <template v-if="hasToken">
              <i class="form-icon loading" v-if="processing && !validated"></i>
              <fa-icon
                v-else
                class="form-icon"
                :icon="['fal', validated ? 'check' : 'times']"
                :class="validated ? 'text-success' : 'text-error'"
              />
            </template>
          </div>
        </div>

        <div class="form-group" :class="{ 'has-error': $v.password.$error }">
          <label class="form-label" for="password">Nova senha</label>
          <input
            v-model.trim="password"
            type="password"
            id="password"
            class="form-input input-lg"
            :disabled="!validated"
            placeholder="******"
            autocomplete="new-password"
          />
          <template v-if="$v.password.$error">
            <div class="form-input-hint" v-if="!$v.password.required">Campo obrigatório</div>
          </template>
        </div>
      </form>

      <button
        class="btn btn-primary btn-lg btn-block"
        :class="{ loading: validated && processing }"
        @click="changePassword"
        :disabled="!validated || processing"
      >Redefinir senha</button>
    </template>

    <router-link
      type="button"
      class="btn btn-link btn-block mt-2"
      to="/login"
    >Voltar para o login</router-link>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      id: null,
      username: '',
      password: '',
      token: '',
      validated: false,
      processing: false,
    };
  },
  validations: {
    username: { required },
    password: { required },
  },
  mounted() {
    this.id = this.$route.params.id || null;
    this.setFocus(this.hasId ? 'token' : 'username');
  },
  computed: {
    hasId() {
      return !!this.id;
    },
    hasUsername() {
      return this.username && this.username.length > 10;
    },
    hasToken() {
      return !!this.token && this.token.length === 6;
    },
  },
  watch: {
    token() {
      this.validateToken();
    },
  },
  methods: {
    ...mapActions(['attemptLogin']),
    validate(field) {
      const validator = field ? this.$v[field] : this.$v;
      validator.$touch();
      return !validator.$error;
    },
    async request() {
      if (!this.validate('username')) return;

      this.processing = true;
      try {
        const { data } = await this.$http.post('/password-resets', {
          username: this.username,
        });
        this.id = data.id;
        await this.$router.replace(`${this.$route.path}/${data.id}`);
        this.setFocus('token');
      } catch (e) {
        this.$toast.error(e);
      }
      this.processing = false;
    },
    async validateToken() {
      this.validated = false;
      if (!this.hasToken) return;

      this.processing = true;
      try {
        const { data } = await this.$http
          .post(`/password-resets/${this.id}/validate`, {
            token: this.token,
          });
        this.validated = data.valid;
        this.setFocus('password');
      } catch (e) {
        this.$toast.error(e);
      }
      this.processing = false;
    },
    async changePassword() {
      if (!this.validate('password')) return;

      this.processing = true;
      try {
        const { data } = await this.$http
          .put(`/password-resets/${this.id}`, {
            token: this.token,
            password: this.password,
          });

        this.$toast.success('Senha alterada com sucesso');

        await this.attemptLogin({
          username: data.username,
          password: this.password,
        });
        await this.$router.push('/');
      } catch (e) {
        this.$toast.error(e);
      }
      this.processing = false;
    },
    setFocus(inputId) {
      this.$nextTick(() => {
        const input = document.querySelector(`#${inputId}`);
        if (input) {
          input.focus();
        }
      });
    },
  },
};
</script>
