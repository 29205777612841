import moment from 'moment';

function timeDiff(dateFrom, dateTo = null, format = 'HH:mm:ss') {
  // eslint-disable-next-line no-param-reassign
  dateTo = dateTo === null ? moment() : moment(dateTo);

  return moment.utc(dateTo.diff(moment(dateFrom))).format(format);
}

export default function install(Vue) {
  Object.defineProperty(Vue.prototype, '$timeDiff', {
    get() {
      return timeDiff;
    },
  });
}
