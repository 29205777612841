export default [
  {
    path: '/financial/accounts',
    component: () => import(/* webpackChunkName: "accounts" */ './components/List.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
    },
  },
  {
    path: '/financial/accounts/:id/edit',
    component: () => import(/* webpackChunkName: "edit" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
    },
  },
];
