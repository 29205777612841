import { memedScriptUrl } from '@/config';
import addressFormatter from '@/filters/address';
import dateFormatter from '@/filters/date';
import { http } from '@/plugins/http';

const MEMED = {
  scriptPromise: null,
  scriptLoaded: false,
  initPromise: null,
  core: null,
  hub: null,
  events: new Map(),
  lastPatient: null,
  error: null,
};

export const PRESCRIPTION_MODULE = 'plataforma.prescricao';
export const PRESCRIPTION_FINISH_EVENT = 'prescricaoImpressa';
export const PRESCRIPTION_DELETE_EVENT = 'prescricaoExcluida';
export const MEDICINE_ADD_EVENT = 'medicamentoAdicionado';
export const MEDICINE_DELETE_EVENT = 'medicamentoRemovido';

export function addEvent(eventName, cb) {
  MEMED.events.set(eventName, cb);
  MEMED.hub.event.add(eventName, MEMED.events.get(eventName));
}

export function resetEvents() {
  MEMED.events.forEach((value, key) => {
    MEMED.hub.event.remove(key);
    MEMED.events.delete(key);
  });
}

async function getToken() {
  const { data } = await http.post('/memed/professionals');
  return data.token;
}

async function loadScript() {
  if (MEMED.scriptLoaded) {
    return Promise.resolve();
  }

  if (MEMED.error) {
    return Promise.reject(MEMED.error);
  }

  if (!MEMED.scriptPromise) {
    MEMED.scriptPromise = new Promise(async (resolve, reject) => {
      try {
        const script = document.createElement('script');
        script.src = memedScriptUrl;
        script.dataset.token = await getToken();
        script.addEventListener('load', resolve);
        document.body.appendChild(script);
        MEMED.scriptLoaded = true;
      } catch (e) {
        MEMED.error = e;
        reject(e);
      }
    });
  }

  return MEMED.scriptPromise;
}

export async function init() {
  await loadScript();

  if (!MEMED.initPromise) {
    MEMED.core = window.MdSinapsePrescricao;

    MEMED.core.event.add('core:moduleInit', () => {
      if (MEMED.hub === null) {
        MEMED.hub = window.MdHub;
      }
    });

    MEMED.core.event.add('core:moduleHide', (module) => {
      if (module.moduleName === PRESCRIPTION_MODULE) {
        resetEvents();
      }
    });

    MEMED.initPromise = new Promise(async (resolve) => {
      MEMED.core.event.add('core:allmodulesareready', resolve);
    });
  }

  return MEMED.initPromise;
}

export async function setPatient(patient) {
  if (MEMED.lastPatient === patient.id) {
    return;
  }

  MEMED.lastPatient = patient.id;

  const cpf = patient.identity && patient.identity.type === 'cpf'
    ? patient.identity.value
    : null;

  const patientData = {
    idExterno: patient.id, // required
    nome: patient.name, // required
    cpf, // required
    withoutCpf: !cpf,
    data_nascimento: dateFormatter(patient.birthDate),
  };

  if (patient.socialName) {
    patientData.nome_social = patient.socialName;
  }

  if (patient.cellphone) {
    patientData.telefone = patient.cellphone;
  }

  if (patient.addresses && patient.addresses.length) {
    patientData.endereco = addressFormatter(patient.addresses[0]);
    patientData.cidade = patient.addresses[0].city;
  }

  await MEMED.hub.command.send(PRESCRIPTION_MODULE, 'setPaciente', patientData);
}

export async function showPrescription(patient) {
  await init();
  await setPatient(patient);
  MEMED.hub.module.show(PRESCRIPTION_MODULE);
}

export function hasError() {
  return !!MEMED.error;
}

export function getErrorMessage() {
  let message = null;

  if (hasError()) {
    const e = MEMED.error;

    if (e) {
      if (typeof e === 'string') {
        message = e || message;
      } else if (e.response && e.response.data && e.response.data.message) {
        ({ message } = e.response.data);
      } else if (e.message) {
        ({ message } = e);
      }
    }
  }

  return message;
}
