<template>
  <dx-modal id="expense-price-modal" title="Consulta procedimentos"
            :value="show" @input="close" size="lg">
    <div class="columns form-group">
      <div class="column col-5 form-group">
        <label class="form-label">Convênio</label>
        <select id="insurance" class="form-select"
                v-model="filters.insurancePlanId">
          <option value="">[Todos os convênios]</option>
          <option v-for="(item, i) in insurances" :key="i"
                  :value="item.plan.id">{{ item.customName }}</option>
        </select>
      </div>
      <div class="column form-group">
        <label for="search" class="form-label">Nome do procedimento</label>
        <input type="text" id="search" class="form-input"
               v-model="filters.search"
               @keypress.enter="findExpense"
               placeholder="Informe o nome ou código do procedimento e tecle [Enter]">
      </div>
      <div class="column col-auto form-group" style="align-self: flex-end">
        <button class="btn btn-primary btn-icon btn-icon-left mb-2"
                :class="{loading: loading}"
                :disabled="loading"
                @click="findExpense">
          <fa-icon :icon="['fal', 'search']"/>Pesquisar
        </button>
      </div>
    </div>
    <div class="empty mt-2" v-if="data.items.length === 0">
      <div class="empty-icon">
        <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
      </div>
      <p class="empty-title h5">Procedimentos</p>
      <p class="empty-subtitle">
        Nenhum procedimento encontrado. Verifique os filtros para realizar novas buscas
      </p>
    </div>
    <template v-else>
      <table class="table">
        <thead>
        <tr>
          <th style="width: 120px">Código</th>
          <th>Nome</th>
          <th>Convênio</th>
          <th class="text-right">Total</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in data.items" :key="i">
          <td>{{ item.code | tuss }}</td>
          <td class="text-ellipsis" style="max-width: 250px">
            {{ item.expense.name }}
            <small v-if="item.professional && item.professional.name">
              ({{ item.professional.name }})
            </small>
          </td>
          <td>{{ item.insurance.customName }}</td>
          <td class="text-right">
            <span v-if="item.definition.particularValue > 0">
              {{ item.definition.particularValue | currency }}
            </span>
            <fa-icon class="text-error" :icon="['fal', 'lock']" v-else />
          </td>
        </tr>
        </tbody>
      </table>
      <div class="load-more" v-if="data.items.length > 0">
        <button
          v-if="data.hasMore && !loading"
          class="btn btn-primary"
          @click="loadMore"
          :disabled="loadingMore"
          :class="{ loading: loadingMore }"
        >Carregar mais...</button>
      </div>
    </template>
    <template slot="footer">
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      loadingMore: false,
      filters: {
        insurancePlanId: '',
        search: '',
      },
      insurances: [],
      data: {
        items: [],
        hasMore: false,
        limit: 15,
        offset: 0,
      },
    };
  },
  mounted() {
    this.loadInsurances();
    this.load();
  },
  methods: {
    findExpense() {
      this.data.offset = 0;
      this.load();
    },
    load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
        withParticularValue: true,
      };

      if (this.filters.insurancePlanId) {
        params.insurancePlanId = this.filters.insurancePlanId;
      }

      if (this.filters.search) {
        params.search = this.filters.search;
      }

      return this.$http.get('/expense-pricing', { params })
        .then(({ data }) => {
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .finally(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.insurances = data.items;
        })
        .catch(() => {});
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/variables';
@import '~assets/scss/mixins';

#expense-price-modal {
  .load-more {
    margin: $layout-spacing-lg 0;
    text-align: center;
    .btn {
      padding: 0 $layout-spacing-xl;
    }
  }
}
</style>
