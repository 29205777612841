import {
  LIST_COLLABORATORS,
  CREATE_COLLABORATOR,
  UPDATE_COLLABORATOR,
} from '@/data/actions/modules/general';

export default [
  {
    path: '/collaborators',
    component: () => import(/* webpackChunkName: "collaborators" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_COLLABORATORS,
    },
  },
  {
    path: '/collaborators/create',
    component: () => import(/* webpackChunkName: "collaborators/create" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_COLLABORATOR,
    },
  },
  {
    path: '/collaborators/:id/edit',
    component: () => import(/* webpackChunkName: "collaborators/edit" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_COLLABORATOR,
    },
  },
];
