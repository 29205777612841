import { withParams } from 'vuelidate/lib';
import moment from 'moment';

export default withParams({ type: 'hour' }, (value) => {
  if (!value) {
    return true;
  }

  if (value.length < 5) {
    return false;
  }
  return moment(value, 'HH:mm').isValid();
});
