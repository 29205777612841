// import {
//   LIST_ENTRIES,
//   CREATE_ENTRY,
//   UPDATE_ENTRY,
// } from '@/data/actions/modules/stock';

export default [
  {
    path: '/hospitalization/:id/therapeutic-plan',
    component: () => import(/* webpackChunkName: "therapeutic-plan" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      // permission: LIST_ENTRIES,
    },
  },
];
