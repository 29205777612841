export const serviceTypes = {
  home_care: 'Atendimento Domiciliar',
  consultation: 'Consulta',
  removal: 'Remoção',
  exam: 'Exame',
  outpatient_examination: 'Exame Ambulatorial',
  hospitalization: 'Internação',
  other_therapies: 'Outras Terapias',
  small_service: 'Pequeno Atendimento (sutura, gesso e outros)',
  minor_surgery: 'Pequena Cirurgia',
  emergency_room: 'Pronto Socorro',
  chemotherapy: 'Quimioterapia',
  radiotherapy: 'Radioterapia',
  occupational_health_admission: 'Saúde Ocupacional - Admissional',
  occupational_health_dismissal: 'Saúde Ocupacional - Demissional',
  occupational_health_periodical: 'Saúde Ocupacional - Periódico',
  occupational_health_back_to_work: 'Saúde Ocupacional - Retorno ao trabalho',
  occupational_health_change_duty: 'Saúde Ocupacional - Mudança de função',
  occupational_health_promotion_health: 'Saúde Ocupacional - Promoção a saúde',
  occupational_health_new_beneficiary: 'Saúde Ocupacional - Beneficiário novo',
  occupational_health_fired_assistance: 'Saúde Ocupacional - Assistência a demitidos',
  telemedicine: 'Telemedicina',
  renal_replacement_therapy: 'Terapia Renal Substitutiva (TRS)',
};

export function getName(value) {
  return serviceTypes[value];
}
