import {
  LIST_OUTPUTS,
  CREATE_OUTPUT,
  UPDATE_OUTPUT,
} from '@/data/actions/modules/stock';

export default [
  {
    path: '/stock/outputs',
    component: () => import(/* webpackChunkName: "outputs" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_OUTPUTS,
    },
  },
  {
    path: '/stock/outputs/create',
    component: () => import(/* webpackChunkName: "outputs" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_OUTPUT,
    },
  },
  {
    path: '/stock/outputs/:id/edit',
    component: () => import(/* webpackChunkName: "outputs" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_OUTPUT,
    },
  },
];
