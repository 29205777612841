import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueMask from 'di-vue-mask';
import PortalVue from 'portal-vue';
import VueSpectre from '@devindex/vue-spectre';
import moment from 'moment';
import localforage from 'localforage';
import App from './app/Main.vue';
import router from './router/index';
import store from './store/index';
import { plugin as permission } from './permission';
import './assets/main.scss';
import './assets/icons/index';
import './components';
import './filters';
import './plugins';
import './directives';

Vue.config.productionTip = false;
moment.locale('pt-BR');
localforage.config({ name: 'stenci' });

Vue.use(permission);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(VueSpectre);
Vue.use(PortalVue);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
