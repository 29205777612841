import {
  LIST_BRANCHES,
  CREATE_BRANCH,
  UPDATE_BRANCH,
} from '@/data/actions/modules/general';

export default [
  {
    path: '/branches',
    component: () => import(/* webpackChunkName: "branches" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_BRANCHES,
    },
  },
  {
    path: '/branches/create',
    component: () => import(/* webpackChunkName: "branches" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_BRANCH,
    },
  },
  {
    path: '/branches/:id/edit',
    component: () => import(/* webpackChunkName: "branches" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_BRANCH,
    },
  },
];
