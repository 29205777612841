export default [
  {
    path: '/me',
    component: () => import(/* webpackChunkName: "me" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
    },
  },
];
