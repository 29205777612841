<template>
  <div class="date-nav-bar">
    <div class="date-nav-wrapper">
      <template v-if="loading">
        <div class="loading" />
      </template>
      <template v-else>
        <button class="btn btn-icon btn-prev" @click="prev" :disabled="!canPrev">
          <fa-icon :icon="['fal', 'angle-left']"/>
        </button>
        <span class="date" @click.stop="toggleCalendar">{{ formatted }}</span>
        <button class="btn btn-icon btn-next" @click="next" :disabled="!canNext">
          <fa-icon :icon="['fal', 'angle-right']"/>
        </button>
      </template>
    </div>
    <dx-calendar
      v-if="showCalendar"
      :value="value"
      @input="onCalendarInput"
      :min="min"
      :max="max"
      :days-of-week="['D', 'S', 'T', 'Q', 'Q', 'S', 'S']"
      v-click-outside="closeCalendar">
      <fa-icon :icon="['fal', 'angle-left']" slot="prev-nav"/>
      <fa-icon :icon="['fal', 'angle-right']" slot="next-nav"/>
    </dx-calendar>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    value: {
      type: Date,
    },
    min: {
      type: Date,
    },
    max: {
      type: Date,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCalendar: false,
    };
  },
  methods: {
    toggleCalendar() {
      if (!this.disabled) {
        this.showCalendar = !this.showCalendar;
      }
    },
    closeCalendar() {
      this.showCalendar = false;
    },
    onCalendarInput(date) {
      this.showCalendar = false;
      this.$emit('input', date);
    },
    next() {
      this.showCalendar = false;
      this.$emit(
        'input',
        moment(this.value).add(1, 'day').toDate(),
      );
    },
    prev() {
      this.showCalendar = false;
      this.$emit(
        'input',
        moment(this.value).subtract(1, 'day').toDate(),
      );
    },
  },
  computed: {
    canNext() {
      return !this.disabled && (
        !this.max || moment(this.value).isBefore(this.max)
      );
    },
    canPrev() {
      return !this.disabled && (
        !this.min || moment(this.value).isAfter(this.min)
      );
    },
    formatted() {
      const formatted = moment(this.value)
        .format('dddd, DD [de] MMMM [de] YYYY')
        .replace('-feira', '')
        .toLowerCase();

      return formatted.charAt(0).toUpperCase() + formatted.substring(1);
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";

  .date-nav-bar {
    border: $border-color-dark solid $border-width;
    border-radius: $border-radius;
    position: relative;
    .date-nav-wrapper {
      align-items: center;
      justify-content: center;
      display: flex;
      height: 1.3rem;
      .date {
        color: $body-font-color;
        cursor: pointer;
        flex: 1 0 auto;
        font-size: $font-size;
        text-align: center;
      }
      .btn, .btn:hover, .btn:focus {
        background: transparent;
        box-shadow: none;
        color: $primary-color;
        border: 0;
      }
    }
    .calendar {
      position: absolute;
      top: 105%;
      left: 50%;
      transform: translateX(-50%);
      z-index: $zindex-1;
      .btn-action {
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
</style>
