import {
  LIST_OPERATION_TYPES,
  CREATE_OPERATION_TYPE,
  UPDATE_OPERATION_TYPE,
} from '@/data/actions/modules/stock';

export default [
  {
    path: '/stock/operation-types',
    component: () => import(/* webpackChunkName: "operation-types" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_OPERATION_TYPES,
    },
  },
  {
    path: '/stock/operation-types/create',
    component: () => import(/* webpackChunkName: "operation-types" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_OPERATION_TYPE,
    },
  },
  {
    path: '/stock/operation-types/:id/edit',
    component: () => import(/* webpackChunkName: "operation-types" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_OPERATION_TYPE,
    },
  },
];
