<template>
  <div class="columns">
    <div class="column col-3 col-md-6 col-sm-12 form-group"
         :class="{'has-error': $v.form.address.cep.$error}">
      <label for="address-cep" class="form-label">CEP</label>
      <div class="input-group">
        <input type="text" id="address-cep" name="address-cep"
               v-model="form.address.cep" class="form-input"
               placeholder="00.000-000" v-mask-cep autocomplete="nope">
        <button class="btn btn-gray btn-icon btn-action input-group-btn">
          <fa-icon :icon="['fal', 'search']"></fa-icon>
        </button>
      </div>
      <template v-if="$v.form.address.cep.$error">
        <div class="form-input-hint"
             v-if="!$v.form.address.cep.cep">CEP inválido</div>
      </template>
    </div>
    <div class="column col-9 col-md-6 col-sm-12 form-group"></div>
    <div class="column col-7 col-md-6 col-sm-12 form-group">
      <label for="address-street" class="form-label">Logradouro</label>
      <input type="text" id="address-street" name="address-street"
             v-model="form.address.street" class="form-input"
             placeholder="Rua, Travessa, Avenida..." autocomplete="nope">
    </div>
    <div class="column col-2 col-md-6 col-sm-12 form-group">
      <label for="address-nb" class="form-label">Número</label>
      <input type="text" id="address-nb" name="address-nb"
             v-model="form.address.number" class="form-input"
             placeholder="000" v-mask="'00000'" autocomplete="nope">
    </div>
    <div class="column col-3 col-md-6 col-sm-12 form-group">
      <label for="address-complement" class="form-label">Complemento</label>
      <input type="text" id="address-complement" name="address-complement"
             v-model="form.address.complement" class="form-input"
             placeholder="casa, sobrado, loja..." autocomplete="nope">
    </div>
    <div class="column col-5 col-md-6 col-sm-12 form-group">
      <label for="address-neighborhood" class="form-label">Bairro</label>
      <input type="text" id="address-neighborhood" name="address-neighborhood"
             placeholder="Bairro" v-model="form.address.neighborhood"
             class="form-input" autocomplete="nope">
    </div>
    <div class="column col-4 col-md-6 col-sm-12 form-group">
      <label for="address-city" class="form-label">Cidade</label>
      <input type="text" id="address-city" name="address-city"
             v-model="form.address.city" class="form-input"
             placeholder="Cidade" autocomplete="nope">
    </div>
    <div class="column col-3 col-md-6 col-sm-12 form-group">
      <label for="address-state" class="form-label">Estado</label>
      <select id="address-state" name="address-state"
              v-model="form.address.state" class="form-select">
        <option value="">[Selecione]</option>
      </select>
    </div>
  </div>
</template>

<script>
import { cep } from 'src/data/validators';

export default {
  data() {
    return {
      form: {
        address: {
          cep: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: '',
        },
      },
    };
  },
  validations() {
    const rules = {
      form: {
        address: {
          cep: { cep },
        },
      },
    };
    return rules;
  },
  methods: {
    load() {
    },
  },
};
</script>
