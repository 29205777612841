import {
  LIST_BUDGETS,
  CREATE_BUDGET,
  UPDATE_BUDGET,
} from '@/data/actions/modules/financial';

export default [
  {
    path: '/financial/budgets',
    component: () => import(/* webpackChunkName: "budgets" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_BUDGETS,
    },
  },
  {
    path: '/financial/budgets/create',
    component: () => import(/* webpackChunkName: "budgets" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_BUDGET,
    },
  },
  {
    path: '/financial/budgets/:id/edit',
    component: () => import(/* webpackChunkName: "budgets" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_BUDGET,
    },
  },
];
