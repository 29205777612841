import {
  LIST_BRANCHES,
  LIST_COLLABORATORS,
  LIST_PERMISSION_GROUPS,
  LIST_PLACES,
  LIST_SUPPLIERS,
  LIST_MARKERS,
} from '@/data/actions/modules/general';

const meta = {
  layout: 'master',
  requiresAuth: true,
};

export default [
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "settings-main" */ './components/Main.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "settings" */ './components/Settings.vue'),
        meta,
      },
      {
        path: 'branches',
        component: () => import(/* webpackChunkName: "branches" */ '../branch/components/Main.vue'),
        meta: { ...meta, permission: LIST_BRANCHES },
      },
      {
        path: 'places',
        component: () => import(/* webpackChunkName: "places" */ '../place/components/Main.vue'),
        meta: { ...meta, permission: LIST_PLACES },
      },
      {
        path: 'collaborators',
        component: () => import(/* webpackChunkName: "collaborators" */ '../collaborator/components/Main.vue'),
        meta: { ...meta, permission: LIST_COLLABORATORS },
      },
      {
        path: 'providers',
        component: () => import(/* webpackChunkName: "providers" */ '../provider/components/Main.vue'),
        meta: { ...meta, permission: LIST_SUPPLIERS },
      },
      {
        path: 'markers',
        component: () => import(/* webpackChunkName: "markers" */ '../marker/components/Main.vue'),
        meta: { ...meta, permission: LIST_MARKERS },
      },
      {
        path: 'permission-groups',
        component: () => import(/* webpackChunkName: "permission-groups" */ '../auth/components/permission/GroupList.vue'),
        meta: { ...meta, permission: LIST_PERMISSION_GROUPS },
      },
      {
        path: 'doctoralia',
        component: () => import(/* webpackChunkName: "doctoralia" */ '../doctoralia/components/Main.vue'),
        meta: { ...meta },
      },
    ],
    meta,
  },
];
