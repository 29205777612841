<template>
  <dx-modal title="Sessões anteriores"
            :value="show" @input="close" id="modal-previous">
    <div class="loading loading-lg" v-if="loading || loadingReferral" />
    <template v-else>
      <div class="has-error-message">
        Apenas guias finalizadas como "Outras terapias" ou "Pequenos atendimentos".
      </div>
      <div class="empty" v-if="data.items && data.items.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Sessões anteriores</p>
        <p class="empty-subtitle">
          Não existem sessões anteriores para este paciente ou:<br>
          <ul>
            <li>Verifique se a fatura anterior está aberta ou faturando</li>
            <li>Verifique se faz mais de 60 dias do último atendimento</li>
            <li>Verifique se o tipo do atendimento anterior está<br>como Outras terapias
              ou Pequenos atendimentos</li>
          </ul>
        </p>
      </div>
      <table class="table table-striped table-hover" v-else>
        <thead>
        <tr>
          <th>#</th>
          <th>Data e hora</th>
          <th>Guia</th>
          <th>Senha</th>
          <th>Validade</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr class="c-hand" v-for="(item, i) in data.items" :key="i"
            @click="selected(item.id)">
          <td>{{ i + 1 }}</td>
          <td>{{ item.startDate | date('DD/MM/YY HH:mm') }}</td>
          <td>{{ item.referral.provider || item.referral.insurance || item.referral.main }}</td>
          <td>{{ item.authorization ? item.authorization.password : '-' }}</td>
          <td>
            <span v-if="item.authorization">{{ item.authorization.validity | date }}</span>
            <span v-else>-</span>
          </td>
          <td><fa-icon :icon="['fal', 'chevron-right']"/></td>
        </tr>
        </tbody>
      </table>
    </template>
    <template slot="footer">
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>

export default {
  props: {
    show: {
      type: Boolean,
    },
    patientId: {
      type: String,
    },
    insurancePlanId: {
      type: String,
    },
    loadingReferral: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      data: {
        hasMore: false,
        items: [],
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    load() {
      this.loading = true;

      const params = {
        insurancePlanId: this.insurancePlanId,
      };

      this.$http.get(`/patients/${this.patientId}/previous-accounts`, { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    selected(id) {
      this.$emit('selected', id);
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../assets/scss/variables';
  @import '~assets/scss/mixins';

  #modal-previous {
    .has-error-message {
      background-color: lighten($secondary-color, 45%);
      border: $secondary-color solid $border-width;
      border-radius: $border-radius;
      font-size: $font-size-sm;
      margin-bottom: $layout-spacing;
      padding: $layout-spacing-sm $layout-spacing;
    }
  }
</style>
