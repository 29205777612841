export const occupationalHealth = {
  admission: 'Admissional',
  resignation: 'Demissional',
  periodical: 'Periódico',
  back_to_work: 'Retorno ao trabalho',
  job_change: 'Mudança de função',
  health_promotion: 'Promoção à saúde',
};

export function getName(value) {
  return occupationalHealth[value];
}
