import { Ability } from '@casl/ability';

const ability = new Ability([]);

function parseActionAbility(item) {
  const [subject, action] = item.split(':');
  return { action, subject };
}

export function update(actions = []) {
  ability.update(actions.map(parseActionAbility));
}

export function can(action) {
  const actionAbility = parseActionAbility(action);
  return ability.can(actionAbility.action, actionAbility.subject);
}

export function plugin(Vue) {
  Object.defineProperty(Vue.prototype, '$can', {
    get() {
      return can;
    },
  });
}

export default can;
