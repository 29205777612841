<template>
  <div class="page-container">
    <portal to="page-name">Caixa</portal>
    <div class="card card-page" v-if="hasAccess">
      <div class="card-header">
        <h1 class="card-title">Encaminhamentos</h1>
      </div>
      <div class="card-body" v-if="loading">
        <div class="loading"></div>
      </div>
      <div class="card-body" v-if="!loading && forwards.length > 0">
        <table class="table table-striped table-hover">
          <thead>
          <tr>
            <th>Data</th>
            <th>Paciente</th>
            <th>Código</th>
            <th class="text-right">Valor</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(forward, i) in forwards" :key="i">
            <td>{{ forward.createdAt | date }}</td>
            <td>{{ forward.patient.name }}</td>
            <td>{{ forward.code }}</td>
            <td class="text-right">{{ forward.total | currency }}</td>
            <td class="text-right">
              <router-link :to="`/forwards/${forward.id}/edit`" target="_blank">
                <span class="btn btn-sm btn-action btn-icon btn-gray tooltip mr-1"
                        data-tooltip="Editar encaminhamento">
                  <fa-icon :icon="['fal', 'pencil']"/>
                </span>
              </router-link>
              <button class="btn btn-sm btn-action btn-icon btn-gray tooltip"
                      data-tooltip="Excluir encaminhamento" @click="removeForward(forward.id)"
                      :disabled="deleting">
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body" v-else-if="!loading && forwards.length === 0">
        <div class="empty mt-2">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Nenhum encaminhamento encontrado</p>
          <p class="empty-subtitle">
            Nenhum encaminhamento pendente de recebimento
          </p>
        </div>
      </div>
      <div class="card-footer" v-if="!loading && forwards.length > 0">
        <div class="columns">
          <div class="column col-3">
            <span>Total a receber:
              <span class="ml-2 text-primary text-bold">
                {{ forwards.reduce((a, b) => a + b.total, 0) | currency }}
              </span>
            </span>
          </div>
          <div class="column col-3">
            <span>Encaminhamentos:
              <span class="ml-2 text-primary text-bold">{{ forwards.length }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <forbidden v-else></forbidden>
  </div>
</template>

<script>
import { LIST_CASHIER_REFERRALS } from '@/data/actions/modules/financial';
import Forbidden from '@/components/auth/Forbidden.vue';

export default {
  data() {
    return {
      loading: false,
      deleting: false,
      forwards: [],
    };
  },
  components: {
    Forbidden,
  },
  computed: {
    hasAccess() {
      return this.$can(LIST_CASHIER_REFERRALS);
    },
  },
  async mounted() {
    if (this.hasAccess) {
      this.loading = true;
      await this.getForwards();
    }
    this.loading = false;
  },
  methods: {
    async getForwards() {
      await this.$http.get('/cashiers/forwards')
        .then(({ data }) => {
          this.forwards = data.items;
        })
        .catch(() => {});
    },
    async removeForward(id) {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este encaminhamento?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              this.$http.delete(`/forwards/${id}`)
                .then(() => {
                  this.forwards.forEach((item) => {
                    if (item.id === id) {
                      const idx = this.forwards.indexOf(item);
                      this.forwards.splice(idx, 1);
                    }
                  });
                })
                .catch((e) => {
                  if (e.response
                    && e.response.data
                    && e.response.data.message) {
                    this.$toast.show(e.response.data.message, { type: 'error' });
                  }
                })
                .then(() => {
                  this.deleting = false;
                  close();
                });
            },
          },
        ],
      });
    },
  },
};
</script>
