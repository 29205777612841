export const modalities = {
  AS: 'Angioscopia',
  BDUS: 'Ultrassonografia Densitometria Óssea',
  BI: 'Biomagnetic Imagem',
  BMD: 'Densitometria Mineral',
  CD: 'Fluxo de cor Doppler',
  CF: 'Cinefluorografia',
  CP: 'Colposcopia',
  CR: 'Radiogradia Computadorizada',
  CS: 'Cistoscopia',
  CT: 'Tomografia Computadorizada',
  DD: 'Duplex Doppler',
  DF: 'Digital fluoroscopia',
  DG: 'Diaphanography',
  DM: 'Microscopia Digital',
  DS: 'Subtração Digital Angiografia',
  DR: 'Radiografia Digital',
  DX: 'Raio-X Digital',
  EC: 'Ecocardiografia',
  ECG: 'Eletrocardiograma',
  ES: 'Endoscopia',
  EUA: 'Ultra-som',
  FA: 'Angiofluoresceinografia',
  FS: 'Fundoscopia',
  HC: 'Hard Copy',
  LP: 'Laparoscopia',
  LS: 'Laser Superfície de digitalização',
  MA: 'Angiografia por ressonância magnética',
  MG: 'Mamografia',
  MR: 'Ressonância Magnética',
  MS: 'Espectroscopia de Ressonância Magnética',
  NM: 'Medicina Nuclear',
  OT: 'Outros',
  PT: 'Pósitron Emissão Tomografia (PET)',
  RF: 'Rádio fluoroscopia',
  RG: 'Radiográfica Imagem',
  RTDOSE: 'Radioterapia Dose',
  RTIMAGE: 'Radioterapia Imagem',
  RTPLAN: 'Plano de Radioterapia',
  RTSTRUCT: 'Radioterapia Set Estrutura',
  ST: 'Fóton Emissão Tomografia Computadorizada',
  TG: 'Termografia',
  US: 'Ultrassonografia',
  VF: 'Videofluorography',
  XA: 'Raio-X Angiografia',
  XC: 'Câmera Externa',
};

export function getName(value) {
  return modalities[value];
}
