import {
  LIST_PROFESSIONALS,
  CREATE_PROFESSIONAL,
  UPDATE_PROFESSIONAL,
} from '@/data/actions/modules/general';

export default [
  {
    path: '/clinical/professionals',
    component: () => import(/* webpackChunkName: "professionals" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_PROFESSIONALS,
    },
  },
  {
    path: '/clinical/professionals/create',
    component: () => import(/* webpackChunkName: "professionals" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_PROFESSIONAL,
    },
  },
  {
    path: '/clinical/professionals/:id/edit',
    component: () => import(/* webpackChunkName: "professionals" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_PROFESSIONAL,
    },
  },
];
