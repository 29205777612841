import logoNeoFor from '@/assets/images/logo-neofor.webp';
import { getValue } from '@/helpers/env';

export const apiUrl = process.env.VUE_APP_ROOT_API;
export const apiUrlX = process.env.VUE_APP_ROOT_API_X || apiUrl;
export const showOnboarding = process.env.VUE_APP_ONBOARDING === 'true';

export const ablyApiKey = process.env.VUE_APP_ABLY_API_KEY;

export const pcsAdminUrl = 'https://wzc49n2640.execute-api.sa-east-1.amazonaws.com/prd';
export const pcsMainUrl = 'https://ykyjwffud5.execute-api.sa-east-1.amazonaws.com/prd';

export const publicUrl = getValue('VUE_APP_PUBLIC_URL', {
  production: 'https://p.stenci.app',
  staging: 'https://p-stg.stenci.app',
  default: 'http://localhost:8082',
});

export const memedScriptUrl = getValue('VUE_APP_MEMED_SCRIPT_URL', {
  production: 'https://partners.memed.com.br/integration.js',
  staging: 'https://integrations.memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js',
  default: 'https://integrations.memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js',
});

export function customLogo() {
  if (document.location.hostname === 'neofordoctor.com.br') {
    document.title = 'Neofor - Stenci';
    return logoNeoFor;
  }
  return null;
}

export const appMode = process.env.VUE_APP_MODE;
export const isProduction = appMode === 'production';

export default { apiUrl, apiUrlX };
