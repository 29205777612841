import { httpX } from '@/plugins/http';

const API_CACHE = {};

export function resetCache() {
  Object.keys(API_CACHE).forEach((key) => {
    delete API_CACHE[key];
  });
}

function API(route, params = {}, httpClient = null) {
  if (!httpClient) {
    httpClient = httpX;
  }

  const cacheKey = Object.keys(params || {})
    .sort()
    .reduce((acc, key) => (`${acc}/${key}/${params[key]}`), route)
    .replace(/\//g, '_');

  return {
    async fetch() {
      if (!(cacheKey in API_CACHE)) {
        API_CACHE[cacheKey] = httpClient.get(route, { params });
      }

      return API_CACHE[cacheKey].then(({ data }) => data);
    },
  };
}

export default API;
