import {
  LIST_ACCOMMODATIONS,
  CREATE_ACCOMMODATION,
  UPDATE_ACCOMMODATION,
} from '@/data/actions/modules/hospitalization';

export default [
  {
    path: '/accommodation-types',
    component: () => import(/* webpackChunkName: "accommodation" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_ACCOMMODATIONS,
    },
  },
  {
    path: '/accommodation-types/create',
    component: () => import(/* webpackChunkName: "accommodation" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_ACCOMMODATION,
    },
  },
  {
    path: '/accommodation-types/:id/edit',
    component: () => import(/* webpackChunkName: "accommodation" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_ACCOMMODATION,
    },
  },
];
