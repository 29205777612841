import { CREATE_MEDICAL_REPORT } from '@/data/actions/modules/clinical';

export default [
  {
    path: '/medical-reports',
    component: () => import(/* webpackChunkName: "medical-reports" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
    },
  },
  {
    path: '/medical-reports/:id',
    component: () => import(
      /* webpackChunkName: "medical-reports/id" */ './components/MedicalReport.vue'
      ),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_MEDICAL_REPORT,
    },
  },
];
