import {
  LIST_ASOS,
  CREATE_ASO,
  UPDATE_ASO,
} from '@/data/actions/modules/occupational';

export default [
  {
    path: '/asos',
    component: () => import(/* webpackChunkName: "aso" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_ASOS,
    },
  },
  {
    path: '/asos/create',
    component: () => import(/* webpackChunkName: "aso" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_ASO,
    },
  },
  {
    path: '/asos/:id/edit',
    component: () => import(/* webpackChunkName: "aso" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_ASO,
    },
  },
];
