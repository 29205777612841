import Vue from 'vue';
// import icon from 'src/assets/images/stenci.png';

/*
function chatNotification(title, options, events) {
  const result = {
    permission: 'none',
    notification: Promise.resolve('none'),
  };

  if (window.Notification) {
    result.permission = Notification.permission;
    result.notification = Promise.resolve(result.permission);

    if (result.permission !== 'denied') {
      const check = result.permission === 'granted'
        ? Promise.resolve(result.permission)
        : Notification.requestPermission();

      // eslint-disable-next-line no-shadow
      result.notification = check.then((result) => {
        if (result === 'granted') {
          const notification = new Notification(title, Object.assign(options, { icon }));
          notification.onclick = () => {
            if (events && 'onclick' in events) {
              events.onclick();
            }
            notification.close();
          };
          setTimeout(notification.close.bind(notification), 30000);
        }
        return result;
      });
    }
  }

  return result;
}
 */

export default {
  newChatChannel({ state, dispatch }, data) {
    const memberIds = data.members
      .filter(({ id }) => id !== state.user.id)
      .map(({ id }) => id);

    const chat = state.chat.chats.find(({ members }) => (
      members.some(({ id }) => memberIds.includes(id))
    ));

    if (chat) {
      chat.id = data.id;
      chat.members = data.members;

      if (data.lastMessage) {
        dispatch('newChatMessage', {
          ...data.lastMessage,
          channelId: data.id,
        });
      }
    }
  },
  newChatMessage({ state, getters, commit }, data) {
    const chat = state.chat.chats.find(({ id }) => data.channelId === id);

    if (chat) {
      if (data.authorId !== state.auth.user.id) {
        if (!getters.chatCurrent || getters.chatCurrent.id !== chat.id) {
          chat.unread = true;
        }
        if (!state.chat.show) {
          const member = chat.members.find(({ id }) => id === data.authorId);
          Vue.notification.show(member.name, {
            timeout: 10000,
            type: 'info',
            content: 'Nova mensagem',
            click: (close) => {
              commit('Chat.SET_CURRENT', chat);
              state.chat.show = true;
              close();
            },
          });
        }
      }

      chat.lastMessage = {
        text: data.text,
        authorId: data.authorId,
        date: data.date,
      };
    }
  },
  resetChat({ commit }) {
    commit('Chat.SHOW', false);
    commit('Chat.SET_CHATS', []);
  },
};
