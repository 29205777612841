import { withParams } from 'vuelidate/lib';
import moment from 'moment';

export default withParams({ type: 'competenceDate' }, (value) => {
  if (!value) {
    return true;
  }
  const competenceDate = `01/${value}`;
  return moment(competenceDate, ['YYYY-MM-DD', 'L'], true).isValid();
});
