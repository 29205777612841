import {
  LIST_PLACES,
  CREATE_PLACE,
  UPDATE_PLACE,
} from '@/data/actions/modules/general';

export default [
  {
    path: '/places',
    component: () => import(/* webpackChunkName: "places" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_PLACES,
    },
  },
  {
    path: '/places/create',
    component: () => import(/* webpackChunkName: "places" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_PLACE,
    },
  },
  {
    path: '/places/:id/edit',
    component: () => import(/* webpackChunkName: "places" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_PLACE,
    },
  },
  {
    path: '/bed-management',
    component: () => import(/* webpackChunkName: "beds" */ './components/BedManagementList.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_PLACES,
    },
  },
  {
    path: '/bed-management/:id/edit',
    component: () => import(/* webpackChunkName: "beds" */ './components/BedManagementForm.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_PLACE,
    },
  },
];
