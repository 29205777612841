<template>
  <div class="request-report-page">
    <h6>Relatório de contas por solicitante</h6>
    <div class="columns">
      <div class="column col-3 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.startDate.$error}">
        <label class="form-label">Data início</label>
        <div class="input-group">
          <dx-input-date
            id="appointment-patient-birth-date"
            class="form-input text-center"
            v-model="filters.startDate"
            @blur="$v.filters.startDate.$touch()"
          />
        </div>
        <template v-if="$v.filters.startDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.startDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.startDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-3 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.endDate.$error}">
        <label class="form-label">Data término</label>
        <dx-input-date
          id="end-date"
          class="form-input text-center"
          v-model="filters.endDate"
          @blur="$v.filters.endDate.$touch()"
        />
        <template v-if="$v.filters.endDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.endDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.endDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-12 billing-list" style="height: 15rem">
        <div class="scroll-list-wrapper">
          <div class="scroll-list">
            <div class="list-header">
              <label class="form-checkbox">
                <input type="checkbox"
                       @change="selectAllInsurances"
                       :checked="getInsurancesSelected"
                       :indeterminate.prop="getInsurancesSelected === null">
                <i class="form-icon"></i>
              </label>
              <div class="list-title">Convênios</div>
              <div class="input-group">
                <input type="text" class="form-input"
                       v-model="filters.insurance"
                       placeholder="Filtrar convênio">
                <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                        tabindex="-1" v-if="!filters.insurance.trim()">
                  <fa-icon :icon="['fal', 'search']"/>
                </button>
                <button class="btn btn-gray input-group-btn btn-icon"
                        @click="filters.insurance = ''"
                        tabindex="-1" v-else>
                  <fa-icon :icon="['fal', 'times']"/>
                </button>
              </div>
            </div>
            <table class="table table-hover">
              <tbody>
              <tr v-for="(item, i) in filteredInsurances" :key="i">
                <td width="30px">
                  <label class="form-checkbox">
                    <input type="checkbox" v-model="item.selected">
                    <i class="form-icon"></i>
                  </label>
                </td>
                <td>{{ item.name }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="column col-12 form-group">
        <label class="form-label">
          Filtrar apenas os procedimentos selecionados (opcional)
        </label>
        <dx-autocomplete
          id="expense"
          ref="expense"
          v-model="expense"
          :source="findExpense"
          label="expense"
          track-by="expense-id"
          @select="setExpense"
          :debounce="800"
          placeholder="Pesquisar exame por código ou descrição">
          <button slot="action"
                  class="btn btn-neutral btn-action input-group-btn btn-icon">
            <fa-icon :icon="['fal', 'search']"/>
          </button>
          <template v-slot="{ item }">
            <fa-icon class="text-primary" :icon="['fal', 'file-medical-alt']"></fa-icon>
            {{ item.name }} <small v-if="item.tuss">({{ item.tuss.code | tuss }})</small>
          </template>
        </dx-autocomplete>
      </div>
      <div class="column col-12 form-group" v-if="filters.expenses.length > 0">
        <table class="table table-striped">
          <thead>
          <tr>
            <th>#</th>
            <th>Código</th>
            <th>Descrição</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(expense, i) in filters.expenses" :key="i">
            <td>{{ i + 1}}</td>
            <td>{{ expense.code | tuss }}</td>
            <td class="text-ellipsis" style="max-width: 200px">{{ expense.name }}</td>
            <td class="text-right">
              <button class="btn btn-icon btn-action btn-sm btn-error"
                      @click="remove(i)">
                <fa-icon :icon="['fal', 'times']" />
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-gray btn-icon btn-icon-left mr-1"
              :class="{loading: printing}"
              :disabled="printing"
              @click="print('sheet')">
        <fa-icon :icon="['fal', 'file-excel']"/>Gerar planilha
      </button>
      <button class="btn btn-primary btn-icon-left"
              :class="{loading: printing}"
              :disabled="printing"
              @click="print('pdf')">
        <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';
import fuzzy from 'fuzzy';
import formMixin from '@/mixins/form';

export default {
  mixins: [formMixin],
  data() {
    return {
      printing: false,
      insuranceItems: [],
      expense: null,
      filters: {
        startDate: '',
        endDate: '',
        insurance: '',
        expenses: [],
      },
    };
  },
  validations() {
    const rules = {
      filters: {
        startDate: { date, required },
        endDate: { date, required },
      },
    };
    if (this.filters.startDate) {
      rules.filters.endDate = {
        date,
        required,
        minDate: minDate(this.filters.startDate),
      };
    }

    return rules;
  },
  mounted() {
    this.filters.startDate = moment()
      .startOf('month')
      .format('YYYY-MM-DD');
    this.filters.endDate = moment()
      .endOf('month')
      .format('YYYY-MM-DD');
    this.loadInsurances();
  },
  computed: {
    getInsurancesSelected() {
      if (!this.filteredInsurances || this.filteredInsurances.length === 0) {
        return false;
      }

      const insurancesSelected = this.filteredInsurances.filter(item => item.selected);
      if (insurancesSelected.length === 0) {
        return false;
      }
      if (insurancesSelected.length === this.filteredInsurances.length) {
        return true;
      }
      return null;
    },
    filteredInsurances() {
      let { insuranceItems } = this;

      if (this.filters.insurance.trim()) {
        const results = fuzzy.filter(this.filters.insurance, insuranceItems, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        insuranceItems = results.map(({ original }) => original);
      }

      return insuranceItems;
    },
  },
  methods: {
    selectAllInsurances(e) {
      this.filteredInsurances.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.insuranceItems = data.items.map(item => ({
            id: item.plan.id,
            name: item.customName,
            selected: true,
          }));
        })
        .catch(() => {});
    },
    findExpense(search) {
      const params = {
        search: search.trim(),
        limit: 100,
      };

      return this.$http.get('/expenses', { params })
        .then(({ data }) => data.items);
    },
    setExpense(data) {
      this.expense = null;
      const found = this.filters.expenses.find(item => item.id === data.id);
      if (!found) {
        this.filters.expenses.push({
          id: data.id,
          code: data.tuss ? data.tuss.code : null,
          name: data.name,
        });
      }
    },
    remove(idx) {
      this.filters.expenses.splice(idx, 1);
    },
    print(printType) {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return false;
      }
      const diff = moment(this.filters.endDate)
        .diff(moment(this.filters.startDate), 'months');

      if (diff > 4) {
        this.$toast.show('Período máximo (4 meses)', { type: 'error' });
        return null;
      }

      this.printing = true;

      const params = {
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
        insuranceIds: this.insuranceItems
          .filter(({ selected }) => selected)
          .map(item => item.id).join(','),
        printType,
      };


      if (this.filters.expenses.length > 0) {
        params.expenseIds = this.filters.expenses
          .map(item => item.id).join(',');
      }

      if (printType === 'sheet') {
        return this.$file
          .download('/reports/request-account', params, {
            method: 'POST',
            name: 'contas-solicitantes.xlsx',
          })
          .catch(() => {})
          .then(() => {
            this.printing = false;
          });
      }

      return this.$file
        .print('/reports/request-account', params, { method: 'POST' })
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  @import '~assets/scss/mixins';
  .request-report-page {
    .billing-list {
      display: flex;
      flex-direction: column;
      height: 40rem;
      overflow-y: auto;
      .list-header {
        align-items: center;
        border-bottom: $border-width solid $border-color;
        display: flex;
        padding-bottom: $layout-spacing;
        .list-title {
          flex: 1;
          font-weight: bold;
        }
      }
      .scroll-list-wrapper {
        border: $border-color solid $border-width;
        border-radius: $border-radius;
        flex-grow: 1;
        margin-bottom: $layout-spacing;
        position: relative;
      }
      .scroll-list {
        bottom: 0;
        overflow-y: auto;
        padding: $layout-spacing $layout-spacing-lg;
        position: absolute;
        top: 0;
        width: 100%;
        @include scroll-bar();
        .table th, .table td {
          padding: 0;
        }
        .table td {
          border-bottom: none;
        }
      }
    }
    .footer {
      margin-top: $layout-spacing-lg;
      text-align: right;
    }
  }
</style>
