<template>
  <div class="financial-category-report-page">
    <h6>Demonstrativo por categorias</h6>
    <div class="columns">
      <div class="column col-6 form-group">
        <label class="form-label">Ano</label>
        <select class="form-select" v-model="filter.year">
          <option v-for="(year, i) in years" :value="year" :key="i">
            {{ year }}
          </option>
        </select>
      </div>
      <div class="column col-6 form-group">
        <label class="form-label">Trimestre</label>
        <select class="form-select" v-model="filter.period">
          <option value="1">Janeiro - Março</option>
          <option value="4">Abril - Junho</option>
          <option value="7">Julho - Setembro</option>
          <option value="10">Outubro - Dezembro</option>
        </select>
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-gray btn-icon btn-icon-left mr-1"
              :class="{loading: printing}"
              @click="print('sheet')"
              :disabled="printing">
        <fa-icon :icon="['fal', 'file-excel']"/>Gerar planilha
      </button>
      <button class="btn btn-primary btn-icon-left"
              :class="{loading: printing}"
              @click="print('pdf')" :disabled="printing">
        <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      printing: false,
      years: [],
      filter: {
        period: 1,
        year: moment().year(),
      },
    };
  },
  mounted() {
    this.loadYears();
  },
  methods: {
    loadYears() {
      const startYear = moment().subtract(10, 'year').year();
      const endYear = moment().year();
      for (let i = startYear; i <= endYear; i += 1) {
        this.years.push(i);
      }
    },
    print(printType) {
      this.printing = true;

      const params = {
        printType,
        period: this.filter.period,
        year: this.filter.year,
      };

      if (printType === 'sheet') {
        return this.$file
          .download('/reports/financial-categories', params)
          .catch(() => {
            this.$toast.error('Ocorreu um erro. Tente novamente!');
          })
          .finally(() => {
            this.printing = false;
          });
      }

      return this.$file
        .print('/reports/financial-categories', params)
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  .financial-category-report-page {
    .footer {
      margin-top: $layout-spacing-lg;
      text-align: right;
    }
  }
</style>
