export default (value, length, pad = 0) => {
  let localValue = value;

  if (localValue && localValue.toString().length < length) {
    for (let i = 0; i < length; i += 1) {
      localValue = `${pad}${value}`;
    }

    localValue = localValue.slice(-length);
  }

  return localValue;
};
