import { LIST_BILLINGS } from '@/data/actions/modules/financial';

export default [
  {
    path: '/financial/billing-invoices',
    component: () => import(/* webpackChunkName: "accounts" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_BILLINGS,
    },
  },
];
