<template>
  <div>
    <transition name="modal">
      <div class="modal active" v-if="show" :class="classes">
        <div class="modal-overlay" @click="close()"></div>
        <div class="modal-container">
          <div class="modal-header">
            <button class="btn btn-clear float-right" v-if="closable" @click="close()"></button>
            <div class="modal-title">{{ title }}</div>
          </div>

          <div class="modal-body">
            <div class="content">
              <slot></slot>
            </div>
          </div>

          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    closable: {
      type: Boolean,
      default: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
    },
    title: String,
  },
  mounted() {
    this.$nextTick(() => {
      document.body.appendChild(this.$el);

      if (this.closable) {
        document.addEventListener('keydown', (event) => {
          if (this.show && event.keyCode === 27) {
            this.close();
          }
        });
      }
    });
  },
  destroyed() {
    this.$el.remove();
  },
  methods: {
    close() {
      if (this.closable) {
        this.$emit('close');
      }
    },
  },
  computed: {
    classes() {
      const classes = [];

      if (this.size !== null) {
        classes.push(`modal-${this.size}`);
      }

      return classes;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/_variables.scss';

.modal {
  &.modal-enter,
  &.modal-leave-active {
    opacity: 0;
    transform: scale(1.1);
  }
  .modal-container {
    width: $size-sm;
  }
  &.modal-md {
    .modal-container {
      width: $size-md;
    }
  }
  &.modal-lg {
    .modal-container {
      width: $size-lg;
    }
  }
  &.modal-xl {
    .modal-container {
      width: $size-xl;
      max-width: $size-xl;
    }
  }
  .modal-body {
    max-height: 80vh;
  }
}
</style>
