import * as expense from './expense';
import * as facility from './facility';
import * as insurance from './insurance';
import * as mapping from './mapping';
import * as professional from './professional';

export default {
  expense,
  facility,
  insurance,
  mapping,
  professional,
};
