<template>
  <component :is="currentComponent" v-if="ready"></component>
</template>

<script>
import MasterLayout from '../layouts/Master.vue';
import AuthLayout from '../layouts/Auth.vue';

export default {
  name: 'App',
  data() {
    return {
      ready: false,
      layout: null,
    };
  },
  components: {
    MasterLayout,
    AuthLayout,
  },
  mounted() {
    this.setLayout(this.$route);
    this.ready = true;
  },
  watch: {
    $route(to) {
      this.setLayout(to);
    },
  },
  computed: {
    currentComponent() {
      return this.layout !== null ? `${this.layout}-layout` : 'router-view';
    },
  },
  methods: {
    setLayout(route) {
      this.layout = route.meta.layout || null;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .page-container {
    margin: 0 auto;
    max-width: $control-width-xl;
    padding: $layout-spacing-lg;
  }
</style>
