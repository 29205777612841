<template>
  <div class="auth-layout">
    <div class="logo-stenci">
      <img v-if="customLogo" :src="customLogo" alt="stenci"/>
      <img v-else src="@/assets/svg/logo-stenci.svg" alt="stenci"/>
    </div>
    <aside class="auth-layout-side hide-md">
      <div class="login-image" />
    </aside>
    <main class="auth-layout-main">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import { customLogo } from '@/config';

export default {
  data() {
    return {
      customLogo: customLogo(),
    };
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

$bg-elements: '../assets/images/login-elements.png';

.auth-layout {
  background-color: #fafafa;
  display: flex;
  height: 100vh;
  .logo-stenci {
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    img {
      width: 7rem;
    }
  }
  .auth-layout-side {
    display: flex;
    justify-content: center;
    width: 100%;
    .login-image {
      background: url($bg-elements) no-repeat center;
      background-size: contain;
      max-width: 30rem;
      width: 70%;
    }
  }
  .auth-layout-main {
    align-items: center;
    background-color: $light-color;
    border-left: $border-color solid $border-width;
    display: flex;
    flex: 0 0 22rem;
    justify-content: center;
    padding: $unit-16;
    & > div {
      width: 100%;
    }
    .form-body {
      margin: 2rem 0;
      @media screen and (max-width: $size-md) {
        flex: 0 0 100%;
      }
    }
  }
}
</style>
