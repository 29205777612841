export default {
  encodeChannel(state) {
    const { customerId } = state.user.branch;
    return userId => `chat_${customerId}_${userId}`;
  },
  chatUnread(state) {
    return state.chat.chats.some(({ unread }) => unread);
  },
  chatCurrent(state) {
    return state.chat.chats.find(({ current }) => current);
  },
};
