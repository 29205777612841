<template>
  <div class="billing-report-page">
    <h6>Demonstrativo de encaminhamento</h6>
    <div class="columns">
      <div class="column col-3 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.startDate.$error}">
        <label class="form-label">Data início</label>
        <div class="input-group">
          <dx-input-date
            id="appointment-patient-birth-date"
            class="form-input text-center"
            v-model="filters.startDate"
            @blur="$v.filters.startDate.$touch()"
          />
        </div>
        <template v-if="$v.filters.startDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.startDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.startDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-3 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.endDate.$error}">
        <label class="form-label">Data término</label>
        <dx-input-date
          id="end-date"
          class="form-input text-center"
          v-model="filters.endDate"
          @blur="$v.filters.endDate.$touch()"
        />
        <template v-if="$v.filters.endDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.endDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.endDate.date" >Data inválida</div>
        </template>
      </div>
    </div>
    <div class="billing-list" style="height: 25rem">
      <div class="scroll-list-wrapper">
        <div class="scroll-list">
          <div class="list-header">
            <label class="form-checkbox">
              <input type="checkbox"
                     @change="selectAllPartners"
                     :checked="getPartnersSelected"
                     :indeterminate.prop="getPartnersSelected === null">
              <i class="form-icon"></i>
            </label>
            <div class="list-title">Parceiros</div>
            <div class="input-group">
              <input type="text" class="form-input"
                     v-model="filters.partner"
                     placeholder="Filtrar parceiros">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1" v-if="!filters.partner.trim()">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <button class="btn btn-gray input-group-btn btn-icon"
                      @click="filters.partner = ''"
                      tabindex="-1" v-else>
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </div>
          </div>
          <table class="table table-hover">
            <tbody>
            <tr v-for="(item, i) in filteredPartners" :key="i">
              <td width="30px">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>{{ item.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-gray btn-icon btn-icon-left mr-1"
              :class="{loading: printing}"
              :disabled="printing"
              @click="print('sheet')">
        <fa-icon :icon="['fal', 'file-excel']"/>Gerar planilha
      </button>
      <button class="btn btn-primary btn-icon-left"
              :class="{loading: printing}"
              :disabled="printing"
              @click="print('pdf')">
        <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';
import fuzzy from 'fuzzy';
import formMixin from '@/mixins/form';

export default {
  mixins: [formMixin],
  data() {
    return {
      printing: false,
      partnerItems: [],
      filters: {
        startDate: '',
        endDate: '',
        insurance: '',
        partner: '',
      },
    };
  },
  validations() {
    const rules = {
      filters: {
        startDate: { date, required },
        endDate: { date, required },
      },
    };
    if (this.filters.startDate) {
      rules.filters.endDate = {
        date,
        required,
        minDate: minDate(this.filters.startDate),
      };
    }

    return rules;
  },
  mounted() {
    this.filters.startDate = moment()
      .startOf('month')
      .format('YYYY-MM-DD');
    this.filters.endDate = moment()
      .endOf('month')
      .format('YYYY-MM-DD');
    this.loadPartners();
  },
  computed: {
    getPartnersSelected() {
      if (!this.filteredPartners || this.filteredPartners.length === 0) {
        return false;
      }

      const partnersSelected = this.filteredPartners.filter(item => item.selected);
      if (partnersSelected.length === 0) {
        return false;
      }
      if (partnersSelected.length === this.filteredPartners.length) {
        return true;
      }
      return null;
    },
    filteredPartners() {
      let { partnerItems } = this;

      if (this.filters.partner.trim()) {
        const results = fuzzy.filter(this.filters.partner, partnerItems, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        partnerItems = results.map(({ original }) => original);
      }

      return partnerItems;
    },
  },
  methods: {
    selectAllPartners(e) {
      this.filteredPartners.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    loadPartners() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/partners', { params })
        .then(({ data }) => {
          this.partnerItems = data.items.map(item => ({
            id: item.id,
            name: item.name,
            selected: true,
          }));
        })
        .catch(() => {});
    },
    print(printType) {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return false;
      }

      this.printing = true;

      const params = {
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
        partnersId: this.partnerItems
          .filter(({ selected }) => selected)
          .map(item => item.id).join(','),
        printType,
      };

      if (printType === 'sheet') {
        return this.$file
          .download('/reports/forwards-demonstrative', params, {
            method: 'POST',
            name: 'DemonstrativoEncaminhamento.xlsx',
          })
          .catch(() => {})
          .then(() => {
            this.printing = false;
          });
      }

      return this.$file
        .print('/reports/forwards-demonstrative', params, { method: 'POST' })
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/variables";
@import '~assets/scss/mixins';
.billing-report-page {
  .billing-list {
    display: flex;
    flex-direction: column;
    height: 40rem;
    overflow-y: auto;
    .list-header {
      align-items: center;
      border-bottom: $border-width solid $border-color;
      display: flex;
      padding-bottom: $layout-spacing;
      .list-title {
        flex: 1;
        font-weight: bold;
      }
    }
    .scroll-list-wrapper {
      border: $border-color solid $border-width;
      border-radius: $border-radius;
      flex-grow: 1;
      margin-bottom: $layout-spacing-lg * 2;
      position: relative;
    }
    .scroll-list {
      bottom: 0;
      overflow-y: auto;
      padding: $layout-spacing $layout-spacing-lg;
      position: absolute;
      top: 0;
      width: 100%;
      @include scroll-bar();
      .table th, .table td {
        padding: 0;
      }
      .table td {
        border-bottom: none;
      }
    }
  }
  .footer {
    text-align: right;
  }
}
</style>
