<template>
  <tbody>
  <tr>
    <td>{{ index + 1 }}</td>
    <td style="width: 110px;">{{ data.startDate | date('DD/MM/YY HH:mm') }}</td>
    <td class="text-ellipsis" style="max-width: 300px">
      {{ data.code | tuss }} - {{ data.name }}
    </td>
    <td class="text-center">{{ data.quantity | number }}</td>
    <td class="text-right">
      <span v-if="data.return">R$0,00</span>
      <span v-else>{{ data.value.particular | currency }}</span>
    </td>
    <td :class="hideInsuranceValue ? 'text-center' : 'text-right'">
      <span v-if="data.return">R$0,00</span>
      <span v-else>
        <fa-icon class="text-error" :icon="['fal', 'lock']" v-if="hideInsuranceValue" />
        <span v-else>{{ data.value.insurance | currency }}</span>
      </span>
    </td>
    <td class="text-right">
      <span v-if="data.return">R$0,00</span>
      <span v-else>{{ total | currency }}</span>
    </td>
    <td class="text-right" style="width: 100px">
      <button class="btn btn-sm btn-action btn-icon btn-neutral tooltip tooltip-left"
              data-tooltip="Procedimento já repassado ao profissional"
              @click="openTransaction"
              v-if="paid">
        <fa-icon :icon="['fal', 'info-circle']" />
      </button>
      <template v-else>
        <button class="btn btn-sm btn-action btn-icon btn-secondary mr-1"
                @click="edit">
          <fa-icon :icon="['fal', 'pencil']" />
        </button>
        <button class="btn btn-sm btn-action btn-icon btn-error"
                @click="remove">
          <fa-icon :icon="['fal', 'times']" />
        </button>
      </template>
    </td>
  </tr>
  </tbody>
</template>

<script>
import formMixin from 'src/mixins/form';

export default {
  mixins: [formMixin],
  props: {
    index: {
      type: Number,
    },
    data: {
      type: Object,
    },
    hideInsuranceValue: {
      type: Boolean,
    },
  },
  computed: {
    total() {
      if (this.hideInsuranceValue) {
        return (this.data.value.particular * this.data.factor) * this.data.quantity;
      }
      return this.data.value.total;
    },
    paid() {
      return !!this.data.financialBundleId;
    },
  },
  methods: {
    edit() {
      this.$emit('edit', this.index);
    },
    openTransaction() {
      this.$emit('transaction', this.data.financialBundleId);
    },
    remove() {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este registro?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.$emit('remove', this.index);
              close();
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";

</style>
