import localforage from 'localforage';

let instance;

export function update(branch) {
  instance = branch
    ? localforage.createInstance({
      name: 'stenci',
      storeName: branch.id,
      description: branch.name,
    })
    : null;
}

export function getInstance(root = false) {
  return root ? localforage : instance;
}

export function get(key, root = false) {
  return getInstance(root).getItem(key);
}

export function set(key, value, root = false) {
  return getInstance(root).setItem(key, value);
}

export default function install(Vue) {
  Vue.storage = { get, set };

  Object.defineProperty(Vue.prototype, '$storage', {
    get() {
      return { get, set };
    },
  });
}
