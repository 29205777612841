<template>
  <nav>
    <router-link to="/hospitalization">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'tachometer-alt']"/>
      </div>
      <span class="side-menu-label">Dashboard</span>
    </router-link>
    <router-link to="/attendances/hospitalizations">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'user-md']"/>
      </div>
      <span class="side-menu-label">Atendimento</span>
    </router-link>
    <router-link to="/patients">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'user-friends']"/>
      </div>
      <span class="side-menu-label">Pacientes</span>
    </router-link>
    <router-link to="/bed-management">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'procedures']"/>
      </div>
      <span class="side-menu-label">Leitos</span>
    </router-link>
    <router-link to="/hospitalization/others">
      <div class="side-menu-icon">
        <fa-icon :icon="['fal', 'ellipsis-h-alt']"/>
      </div>
      <span class="side-menu-label">Outros recursos</span>
    </router-link>
  </nav>
</template>

<script>
export default {};
</script>
