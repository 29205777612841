<template>
  <div class="financial-dre-report-page">
    <h6>DRE - Demonstrativo de resultados do exercício</h6>
    <div class="columns">
      <div class="column col-sm-12 form-group"
           :class="classes">
        <label for="period" class="form-label">Período</label>
        <select id="period" class="form-select" v-model="filter.period">
<!--          <option value="daily">Diário</option>-->
          <option value="monthly">Mensal</option>
<!--          <option value="yearly">Anual</option>-->
        </select>
      </div>
      <div class="column col-3 col-md-6 col-sm-12 form-group"
           :class="{'has-error': $v.filter.month.$error}"
           v-if="filter.period === 'daily'">
        <label for="month" class="form-label">Mês</label>
        <select id="month" class="form-select"
                v-model="filter.month"
                @blur="$v.filter.month.$touch()">
          <option v-for="(month, i) in months" :value="month.value" :key="i">
            {{ month.text }}
          </option>
        </select>
        <template v-if="$v.filter.month.$error">
          <div class="form-input-hint"
               v-if="!$v.filter.month.required">Campo obrigatório</div>
        </template>
      </div>
      <div class="column col-sm-12 form-group"
           :class="classes">
        <label for="year" class="form-label">Ano</label>
        <select id="year" class="form-select"
                v-model="filter.year"
                @blur="$v.filter.year.$touch()">
          <option v-for="(year, i) in years" :value="year" :key="i">
            {{ year }}
          </option>
        </select>
        <template v-if="$v.filter.year.$error">
          <div class="form-input-hint"
               v-if="!$v.filter.year.required">Campo obrigatório</div>
        </template>
      </div>
      <div class="column col-sm-12 form-group"
           :class="classes">
        <label for="type" class="form-label">Tipo</label>
        <select id="type" class="form-select" v-model="filter.type">
<!--          <option value="analytical">Analítico</option>-->
          <option value="synthetic">Sintético</option>
        </select>
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-primary btn-icon-left"
              :class="{loading: printing}"
              @click="print" :disabled="printing">
        <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      printing: false,
      years: [],
      months: [],
      filter: {
        period: 'monthly',
        type: 'synthetic',
        month: moment().format('M'),
        year: moment().year(),
      },
    };
  },
  validations() {
    return {
      filter: {
        period: { required },
        type: { required },
        month: { required },
        year: { required },
      },
    };
  },
  computed: {
    classes() {
      const items = [];
      if (this.filter.period === 'daily') {
        items.push('col-3 col-md-6');
      }
      if (this.filter.period === 'monthly') {
        items.push('col-4 col-md-4');
      }
      return items;
    },
  },
  mounted() {
    this.loadYears();
    this.loadMonths();
  },
  methods: {
    loadYears() {
      const startYear = moment().subtract(10, 'year').year();
      const endYear = moment().year();
      for (let i = startYear; i <= endYear; i += 1) {
        this.years.push(i);
      }
    },
    loadMonths() {
      const startYear = moment().startOf('year').toDate();
      for (let i = 0; i < 12; i += 1) {
        this.months.push({
          value: i + 1,
          text: moment(startYear).add(i, 'month').format('MMMM'),
        });
      }
    },
    print() {
      this.$v.filter.$touch();
      if (this.$v.filter.$error) {
        return false;
      }

      this.printing = true;

      const params = this.filter;

      return this.$file
        .print('/reports/financial-dre', params)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  .financial-dre-report-page {
    .footer {
      margin-top: $layout-spacing-lg;
      text-align: right;
    }
  }
</style>
