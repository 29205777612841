<template>
  <div class="billing-report-page">
    <h6>Relação de contas</h6>
    <div class="columns">
      <div class="column col-3 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.startDate.$error}">
        <label class="form-label">Data início</label>
        <div class="input-group">
          <dx-input-date
            id="appointment-patient-birth-date"
            class="form-input text-center"
            v-model="filters.startDate"
            @blur="$v.filters.startDate.$touch()"
          />
        </div>
        <template v-if="$v.filters.startDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.startDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.startDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-3 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.endDate.$error}">
        <label class="form-label">Data término</label>
        <dx-input-date
          id="end-date"
          class="form-input text-center"
          v-model="filters.endDate"
          @blur="$v.filters.endDate.$touch()"
        />
        <template v-if="$v.filters.endDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.endDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.endDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-3 col-md-4 col-sm-12 form-group">
        <label class="form-label">Ordenado por</label>
        <select id="group" class="form-select" v-model="filters.sort">
          <option value="date">Data</option>
          <option value="patient">Paciente</option>
        </select>
      </div>
<!--      <div class="column col-3 col-md-4 col-sm-12 form-group">-->
<!--        <label for="report-type" class="form-label">Modalidade</label>-->
<!--        <select id="report-type" class="form-select" v-model="filters.type">-->
<!--          <option value="summary">Resumido</option>-->
<!--          <option value="detailed">Detalhado</option>-->
<!--        </select>-->
<!--      </div>-->
    </div>
    <div class="billing-list">
      <div class="scroll-list-wrapper">
        <div class="scroll-list">
          <div class="list-header">
            <label class="form-checkbox">
              <input type="checkbox"
                     @change="selectAllInsurances"
                     :checked="getInsurancesSelected"
                     :indeterminate.prop="getInsurancesSelected === null">
              <i class="form-icon"></i>
            </label>
            <div class="list-title">Convênios</div>
            <div class="input-group">
              <input type="text" class="form-input"
                     v-model="filters.insurance"
                     placeholder="Filtrar convênio">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1" v-if="!filters.insurance.trim()">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <button class="btn btn-gray input-group-btn btn-icon"
                      @click="filters.insurance = ''"
                      tabindex="-1" v-else>
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </div>
          </div>
          <table class="table table-hover">
            <tbody>
            <tr v-for="(item, i) in filteredInsurances" :key="i">
              <td width="30px">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>{{ item.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="scroll-list-wrapper">
        <div class="scroll-list">
          <div class="list-header">
            <label class="form-checkbox">
              <input type="checkbox"
                     @change="selectAllServiceTypes"
                     :checked="getServiceTypesSelected"
                     :indeterminate.prop="getServiceTypesSelected === null">
              <i class="form-icon"></i>
            </label>
            <div class="list-title">Tipo do Atendimento</div>
            <div class="input-group">
              <input type="text" class="form-input"
                     v-model="filters.serviceType"
                     placeholder="Filtrar tipo de atendimento">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1" v-if="!filters.serviceType.trim()">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <button class="btn btn-gray input-group-btn btn-icon"
                      @click="filters.serviceType = ''"
                      tabindex="-1" v-else>
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </div>
          </div>
          <table class="table table-hover">
            <tbody>
            <tr v-for="(item, i) in filteredServiceTypes" :key="i">
              <td width="30px">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>{{ item.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="scroll-list-wrapper">
        <div class="scroll-list">
          <div class="list-header">
            <label class="form-checkbox">
              <input type="checkbox"
                     @change="selectAllStatuses"
                     :checked="getStatusSelected"
                     :indeterminate.prop="getStatusSelected === null">
              <i class="form-icon"></i>
            </label>
            <div class="list-title">Status</div>
            <div class="input-group">
              <input type="text" class="form-input"
                     v-model="filters.status"
                     placeholder="Filtrar status">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1" v-if="!filters.status.trim()">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <button class="btn btn-gray input-group-btn btn-icon"
                      @click="filters.status = ''"
                      tabindex="-1" v-else>
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </div>
          </div>
          <table class="table table-hover">
            <tbody>
            <tr v-for="(item, i) in filteredStatuses" :key="i">
              <td width="30px">
                <label class="form-checkbox">
                  <input type="checkbox" v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>{{ item.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-gray btn-icon btn-icon-left mr-1"
              :class="{loading: printing}"
              :disabled="printing"
              @click="print('sheet')">
        <fa-icon :icon="['fal', 'file-excel']"/>Gerar planilha
      </button>
      <button class="btn btn-primary btn-icon-left"
              :class="{loading: printing}"
              @click="print('pdf')" :disabled="printing">
        <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';
import { serviceTypes } from '@/data/service-types';
import fuzzy from 'fuzzy';
import formMixin from '@/mixins/form';

export default {
  mixins: [formMixin],
  data() {
    return {
      printing: false,
      serviceTypes,
      insuranceItems: [],
      serviceTypeItems: [],
      statusItems: [
        { id: 'open', name: 'Em aberto', selected: true },
        { id: 'in_billing', name: 'Faturando', selected: true },
        { id: 'closed', name: 'Fechada', selected: true },
        { id: 'in_appeal', name: 'Recurso', selected: true },
        { id: 'finished', name: 'Finalizada', selected: true },
      ],
      filters: {
        startDate: '',
        endDate: '',
        insurance: '',
        serviceType: '',
        type: 'summary',
        sort: 'date',
        status: '',
      },
    };
  },
  validations() {
    const rules = {
      filters: {
        startDate: { date, required },
        endDate: { date, required },
      },
    };
    if (this.filters.startDate) {
      rules.filters.endDate = {
        date,
        required,
        minDate: minDate(this.filters.startDate),
      };
    }

    return rules;
  },
  computed: {
    getInsurancesSelected() {
      if (!this.filteredInsurances || this.filteredInsurances.length === 0) {
        return false;
      }

      const insurancesSelected = this.filteredInsurances.filter(item => item.selected);
      if (insurancesSelected.length === 0) {
        return false;
      }
      if (insurancesSelected.length === this.filteredInsurances.length) {
        return true;
      }
      return null;
    },
    getServiceTypesSelected() {
      if (!this.filteredServiceTypes || this.filteredServiceTypes.length === 0) {
        return false;
      }

      const serviceTypeSelected = this.filteredServiceTypes.filter(item => item.selected);
      if (serviceTypeSelected.length === 0) {
        return false;
      }
      if (serviceTypeSelected.length === this.filteredServiceTypes.length) {
        return true;
      }
      return null;
    },
    getStatusSelected() {
      if (!this.filteredStatuses || this.filteredStatuses.length === 0) {
        return false;
      }

      const statusSelected = this.filteredStatuses.filter(item => item.selected);
      if (statusSelected.length === 0) {
        return false;
      }
      if (statusSelected.length === this.filteredStatuses.length) {
        return true;
      }
      return null;
    },
    filteredInsurances() {
      let { insuranceItems } = this;

      if (this.filters.insurance.trim()) {
        const results = fuzzy.filter(this.filters.insurance, insuranceItems, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        insuranceItems = results.map(({ original }) => original);
      }

      return insuranceItems;
    },
    filteredStatuses() {
      let { statusItems } = this;

      if (this.filters.status.trim()) {
        const results = fuzzy.filter(this.filters.status, statusItems, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        statusItems = results.map(({ original }) => original);
      }

      return statusItems;
    },
    filteredServiceTypes() {
      let { serviceTypeItems } = this;

      if (this.filters.serviceType.trim()) {
        const results = fuzzy.filter(this.filters.serviceType, serviceTypeItems, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        serviceTypeItems = results.map(({ original }) => original);
      }

      return serviceTypeItems;
    },
  },
  mounted() {
    this.filters.startDate = moment()
      .startOf('month')
      .format('YYYY-MM-DD');
    this.filters.endDate = moment()
      .endOf('month')
      .format('YYYY-MM-DD');
    this.loadInsurances();
    this.loadServiceTypes();
  },
  methods: {
    selectAllInsurances(e) {
      this.filteredInsurances.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    selectAllServiceTypes(e) {
      this.filteredServiceTypes.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    selectAllStatuses(e) {
      this.filteredStatuses.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.insuranceItems = data.items.map(item => ({
            id: item.plan.id,
            name: item.customName,
            selected: true,
          }));
        })
        .catch(() => {});
    },
    loadServiceTypes() {
      const items = Object.entries(this.serviceTypes);
      this.serviceTypeItems = items.map(item => ({
        id: item[0],
        name: item[1],
        selected: true,
      }));
    },
    print(printType) {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return false;
      }

      this.printing = true;

      const params = {
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
        insuranceIds: this.insuranceItems
          .filter(({ selected }) => selected)
          .map(item => item.id).join(','),
        serviceTypeIds: this.serviceTypeItems
          .filter(({ selected }) => selected)
          .map(item => item.id).join(','),
        statusIds: this.statusItems
          .filter(({ selected }) => selected)
          .map(item => item.id).join(','),
        type: this.filters.type,
        sort: this.filters.sort,
        printType,
      };

      if (printType === 'sheet') {
        return this.$file
          .download('/reports/billing-accounts', params)
          .catch(() => {})
          .then(() => {
            this.printing = false;
          });
      }

      return this.$file
        .print('/reports/billing-accounts', params)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  @import '~assets/scss/mixins';
  .billing-report-page {
    .billing-list {
      display: flex;
      flex-direction: column;
      height: 40rem;
      overflow-y: auto;
      .list-header {
        align-items: center;
        border-bottom: $border-width solid $border-color;
        display: flex;
        padding-bottom: $layout-spacing;
        .list-title {
          flex: 1;
          font-weight: bold;
        }
      }
      .scroll-list-wrapper {
        border: $border-color solid $border-width;
        border-radius: $border-radius;
        flex-grow: 1;
        margin-bottom: $layout-spacing-lg * 2;
        position: relative;
      }
      .scroll-list {
        bottom: 0;
        overflow-y: auto;
        padding: $layout-spacing $layout-spacing-lg;
        position: absolute;
        top: 0;
        width: 100%;
        @include scroll-bar();
        .table th, .table td {
          padding: 0;
        }
        .table td {
          border-bottom: none;
        }
      }
    }
    .footer {
      text-align: right;
    }
  }
</style>
