<template>
  <div class="page-container reports-page">
    <portal to="page-name">Relatórios</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Relatórios</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-4 col-md-12 col-sm-12 form-group">
            <ul class="menu">
              <li class="menu-item" v-for="(menu, i) in menus" :key="i"
                  @click="openPrint(menu.path)">
                {{ menu.label }}
              </li>
            </ul>
          </div>
          <div class="column col-8 col-md-12 col-sm-12 form-group">
            <template v-if="canAccess">
              <cashier-receipt v-if="selected === 'cashier-receipt'" />
              <cashier-report v-else-if="selected === 'cashier-demonstrative'" />
              <billing-account-report
                v-else-if="selected === 'billing-accounts'"
                :insurances = insurances
                :professionals = professionals
              />
              <billing-report
                v-else-if="selected === 'billing-demonstrative'"
                :insurances = insurances
                :professionals = professionals
                :expenses = expenses
              />
              <forward-report
                v-else-if="selected === 'forward-demonstrative'"
              />
              <financial-transaction-report
                v-else-if="selected === 'financial-transaction'"
              />
              <financial-category-report
                v-else-if="selected === 'financial-category'"
              />
              <financial-dre-report
                v-else-if="selected === 'financial-dre'"
              />
              <financial-dfc-report
                v-else-if="selected === 'financial-dfc'"
              />
              <billing-professional-transfer
                v-else-if="selected === 'billing-professional-transfer'"
              />
              <request-report
                v-else-if="selected === 'request-account-report'"
              />
            </template>
            <forbidden v-else></forbidden>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  REPORT_ACCOUNTS,
  REPORT_BILLING_RESULTS,
  REPORT_FORWARD_RESULTS,
  REPORT_DFC,
  REPORT_DRE,
  REPORT_PAYABLES_AND_RECEIVABLES,
  REPORT_CASH_TRANSACTIONS,
  REPORT_RECEIPTS_ISSUED,
  REPORT_PROFESSIONAL_TRANSFER,
  REPORT_CATEGORIES,
  REPORT_REQUEST_RESULTS,
} from '@/data/actions/modules/financial';
import Forbidden from '@/components/auth/Forbidden.vue';
import BillingReport from './billingReport.vue';
import ForwardReport from './forwardReport.vue';
import BillingAccountReport from './billingAccountReport.vue';
import BillingProfessionalTransfer from './billingProfessionalTransfer.vue';
import CashierReport from './cashierReport.vue';
import CashierReceipt from './cashierReceipt.vue';
import financialCategoryReport from './financialCategoryReport.vue';
import financialDreReport from './financialDreReport.vue';
import financialDfcReport from './financialDfcReport.vue';
import FinancialTransactionReport from './financialTransactionReport.vue';
import RequestReport from './requestReport.vue';

export default {
  components: {
    RequestReport,
    CashierReport,
    CashierReceipt,
    BillingReport,
    BillingAccountReport,
    BillingProfessionalTransfer,
    financialCategoryReport,
    financialDreReport,
    financialDfcReport,
    FinancialTransactionReport,
    Forbidden,
    ForwardReport,
  },
  data() {
    return {
      selected: 'billing-demonstrative',
      insurances: [],
      professionals: [],
      expenses: [],
      menus: [
        {
          path: 'billing-demonstrative',
          label: 'Demonstrativo de faturamento',
          permission: REPORT_BILLING_RESULTS,
        },
        {
          path: 'forward-demonstrative',
          label: 'Demonstrativo de encaminhamento',
          permission: REPORT_FORWARD_RESULTS,
        },
        {
          path: 'billing-professional-transfer',
          label: 'Repasses dos profissionais',
          permission: REPORT_PROFESSIONAL_TRANSFER,
        },
        {
          path: 'cashier-demonstrative',
          label: 'Movimentação do caixa',
          permission: REPORT_CASH_TRANSACTIONS,
        },
        {
          path: 'cashier-receipt',
          label: 'Recibos emitidos',
          permission: REPORT_RECEIPTS_ISSUED,
        },
        {
          path: 'financial-transaction',
          label: 'Documentos a pagar e a receber',
          permission: REPORT_PAYABLES_AND_RECEIVABLES,
        },
        {
          path: 'financial-category',
          label: 'Demonstrativo por categorias',
          permission: REPORT_CATEGORIES,
        },
        {
          path: 'financial-dre',
          label: 'DRE - Demonstrativo de resultados do exercício',
          permission: REPORT_DRE,
        },
        {
          path: 'financial-dfc',
          label: 'DFC - Demonstrativo de fluxo de caixa',
          permission: REPORT_DFC,
        },
        {
          path: 'billing-accounts',
          label: 'Relatório de contas',
          permission: REPORT_ACCOUNTS,
        },
        {
          path: 'request-account-report',
          label: 'Relatório de contas por solicitante',
          permission: REPORT_REQUEST_RESULTS,
        },
      ],
    };
  },
  computed: {
    canAccess() {
      const currentMenu = this.menus.find(({ path }) => path === this.selected);

      if (currentMenu && currentMenu.permission) {
        return this.$can(currentMenu.permission);
      }
      return true;
    },
  },
  methods: {
    openPrint(path) {
      this.selected = path;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .reports-page {
    .menu {
      border-right: $border-width solid $border-color;
      box-shadow: none;
      li {
        padding: $layout-spacing 0;
        border-bottom: $border-width solid $border-color;
        &:hover {
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
</style>
