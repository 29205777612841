import {
  LIST_CLIENTS,
  CREATE_CLIENT,
  UPDATE_CLIENT,
} from '@/data/actions/modules/occupational';

export default [
  {
    path: '/clients',
    component: () => import(/* webpackChunkName: "clients" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_CLIENTS,
    },
  },
  {
    path: '/clients/create',
    component: () => import(/* webpackChunkName: "clients" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_CLIENT,
    },
  },
  {
    path: '/clients/:id/edit',
    component: () => import(/* webpackChunkName: "clients" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_CLIENT,
    },
  },
];
