const stopPropagationListener = (e) => {
  e.stopPropagation();
};

export default {
  bind(el, binding) {
    setTimeout(() => {
      el.addEventListener('click', stopPropagationListener);
      document.body.addEventListener('click', binding.value);
    }, 1);
  },
  unbind(el, binding) {
    el.removeEventListener('click', stopPropagationListener);
    document.body.removeEventListener('click', binding.value);
  },
  stopProp(event) { event.stopPropagation(); },
};
