export const LIST_ACCOMMODATIONS = 'hospitalization:listAccommodations';
export const CREATE_ACCOMMODATION = 'hospitalization:createAccommodation';
export const UPDATE_ACCOMMODATION = 'hospitalization:updateAccommodation';
export const PRINT_ACCOMMODATION_LIST = 'hospitalization:printAccommodationList';

export const LIST_HOSPITALIZATIONS = 'hospitalization:listHospitalizations';

export default [
  {
    action: LIST_ACCOMMODATIONS,
    name: 'Tipos de acomodação: Listar',
  },
  {
    action: CREATE_ACCOMMODATION,
    name: 'Tipos de acomodação: Criar',
  },
  {
    action: UPDATE_ACCOMMODATION,
    name: 'Tipos de acomodação: Atualizar',
  },
  {
    action: PRINT_ACCOMMODATION_LIST,
    name: 'Tipos de acomodação: Imprimir lista',
  },
  {
    action: LIST_HOSPITALIZATIONS,
    name: 'Internamento: Listar',
  },
];
