import axios from 'axios';
import { apiUrl, apiUrlX } from 'src/config';
import interceptors from './interceptors';

export const http = axios.create({
  baseURL: apiUrl,
  timeout: 90000,
});

export const httpX = axios.create({
  baseURL: apiUrlX,
  timeout: 90000,
});

export function setToken(token) {
  http.defaults.headers.common.Authorization = `JWT ${token}`;
  httpX.defaults.headers.common.Authorization = `JWT ${token}`;
  // http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
}

export function setBranchId(branchId) {
  const field = ['Branch-ID'];
  if (branchId) {
    http.defaults.headers.common[field] = branchId;
    httpX.defaults.headers.common[field] = branchId;
  } else {
    delete http.defaults.headers.common[field];
    delete httpX.defaults.headers.common[field];
  }
}

export default function install(Vue, { router }) {
  interceptors(http, router);
  interceptors(httpX, router);

  /* eslint-disable no-param-reassign */
  Vue.http = http;
  Vue.httpX = httpX;

  Object.defineProperty(Vue.prototype, '$http', {
    get() {
      return http;
    },
  });
  Object.defineProperty(Vue.prototype, '$httpX', {
    get() {
      return httpX;
    },
  });
}
