import { LIST_CAMPAIGNS } from '@/data/actions/modules/general';
import Dashboard from './components/Dashboard.vue';
import Campaign from './components/Campaign.vue';
import Settings from './components/Settings.vue';

export default [
  {
    path: '/marketing',
    component: () => import(/* webpackChunkName: "hospitalization" */ './components/Main.vue'),
    children: [
      {
        path: 'dashboard',
        component: Dashboard,
        meta: {
          layout: 'master',
          requiresAuth: true,
          permission: LIST_CAMPAIGNS,
        },
      },
      {
        path: 'campaigns',
        component: Campaign,
        meta: {
          layout: 'master',
          requiresAuth: true,
          permission: LIST_CAMPAIGNS,
        },
      },
      {
        path: 'settings',
        component: Settings,
        meta: {
          layout: 'master',
          requiresAuth: true,
          permission: LIST_CAMPAIGNS,
        },
      },
    ],
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_CAMPAIGNS,
    },
  },
];
