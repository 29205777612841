import {
  LIST_PRICE_TABLES,
  CREATE_PRICE_TABLE,
  UPDATE_PRICE_TABLE,
} from '@/data/actions/modules/stock';

export default [
  {
    path: '/stock/price-tables',
    component: () => import(/* webpackChunkName: "price-tables" */ './components/Main.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: LIST_PRICE_TABLES,
    },
  },
  {
    path: '/stock/price-tables/create',
    component: () => import(/* webpackChunkName: "price-tables" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: CREATE_PRICE_TABLE,
    },
  },
  {
    path: '/stock/price-tables/:id/edit',
    component: () => import(/* webpackChunkName: "price-tables" */ './components/Form.vue'),
    meta: {
      layout: 'master',
      requiresAuth: true,
      permission: UPDATE_PRICE_TABLE,
    },
  },
];
